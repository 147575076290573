import React from "react";
import { Logo } from "./svgs/Logo";
import { FaInfoCircle } from "react-icons/fa";

export const LoginPage = ({
  greeting,
  loginWithRedirect,
}: {
  greeting: string;
  loginWithRedirect: () => void;
}) => {
  return (
    <div className={"vw-100 vh-100 position-relative bg-secondary"}>
      <header className="position-absolute p-3">
        <Logo />
      </header>
      <main className="container min-h-100 d-flex flex-column">
        <div className="vh-100 row align-content-center justify-content-center">
          <div className="col-sm-9 col-md-8 col-lg-6 col-xl-5 mt-n4">
            <div className=" text-center">
              <h2 className="text-primary font-weight-light mb-4">
                {greeting}
              </h2>
              <button
                onClick={loginWithRedirect}
                className="btn btn-outline-primary px-5 shadow-none"
                type="button"
              >
                Sign in
              </button>
            </div>
            <div className="d-flex flex-column mt-5">
              <div className="d-flex">
                <div>
                  <FaInfoCircle />
                </div>
                <div className="ml-2">
                  <p>
                    Equiem requires Two-Factor Authentication (2FA) to access
                    the Admin Panel for enhanced security.
                  </p>
                  <p>
                    If you haven&rsquo;t set up 2FA yet, please download an
                    authentication app such as Google Authenticator from the App
                    Store or Play Store before starting the setup process. If
                    you've already set it up, simply log in and use the app to
                    generate your 2FA code.
                  </p>
                  <p>
                    For more information or assistance, please visit our{" "}
                    <a
                      href="https://help.getequiem.com/space/CS/1569521665/Using%2BTwo%2BFactor%2BAuthentication%2B%282FA%29"
                      target="_blank"
                      rel="noreferrer"
                    >
                      support page
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
