import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { userFormNameCheck, UserFormData } from "./model/UserFormData";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { UserFormContext } from "./context/UserFormContext";

interface Props {
  showToggle?: boolean;
}

/**
 * Communication preference component.
 */
export const UserFormCommunicationPreference: React.FC<Props> = ({
  showToggle = false
}) => {
  const ctx = useContext(UserFormContext);
  const methods = useFormContext<UserFormData>();
  const siteProfile = ctx.user?.profile?.siteProfiles.edges.find((e) => {
    return e.node?.site.uuid === ctx.activeSite;
  });

  return (
    <ToggleContainer title="Communication preferences" show={showToggle}>
      <div>
        <p>
          Preferences shown below are the preferences associated with the user
          at the selected site. Important emergency notifications cannot be
          overriden and will be sent to the user irrespective of their
          preferences.
        </p>
        <Form.Check
          type="switch"
          defaultChecked={siteProfile?.node?.subscribedToEmails ?? false}
          id="email-subscription"
          label="Emails"
          {...methods.register(userFormNameCheck("emailSubscription"))}
        />
        <Form.Check
          className="mt-2"
          type="switch"
          defaultChecked={siteProfile?.node?.subscribedToNotifications ?? false}
          id="notification-subscription"
          label="Push notifications"
          {...methods.register(userFormNameCheck("notificationSubscription"))}
        />
      </div>
    </ToggleContainer>
  );
};
