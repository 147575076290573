import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { AddButton } from "../../../components/AddButton";
import { RemoveButton } from "../../../components/RemoveButton";
import { OptionType } from "../../../model/OptionType";
import { companyValidationOptions } from "./companyValidationOptions";
import { useCompanyValidationValuesState } from "./hook/useCompanyValidationValuesState";
import { CompanyFormData } from "./model/CompanyFormData";
import { ValidationValueField } from "./model/ValidationValueField";

interface CompanyValidationValuesProps {
  fields: Partial<ValidationValueField & { id: string }>[];
  remove: (index?: number | number[] | undefined) => void;
  append: (
    value: Partial<ValidationValueField> | Partial<ValidationValueField>[]
  ) => void;
  disableEdit: boolean;
}

const requiredRule = { required: "Required." };

/**
 * Company validation values.
 */
export const CompanyValidationValues: React.FC<CompanyValidationValuesProps> = ({
  fields,
  remove,
  append,
  disableEdit
}) => {
  const methods = useFormContext<CompanyFormData>();
  const {
    validationValue,
    onChangeInput,
    onChangeSelect,
    onAdd
  } = useCompanyValidationValuesState(append);

  return (
    <Container>
      {fields &&
        fields.map((item, i) => (
          <Row
            key={item.id}
            className={`border border-bottom-0 ${
              disableEdit ? "bg-disabled" : ""
            }`}
          >
            <Col className="py-2 px-3" xs="5">
              <Form.Control
                type="text"
                placeholder="Value"
                defaultValue={item.value}
                {...methods.register(`validationValues[${i}].value` as any)}
                disabled={disableEdit}
              />
            </Col>
            <Col className="py-2 px-3" xs="5">
              <Controller
                render={(props) =>
                  <Select<OptionType>
                    isDisabled={disableEdit}
                    classNamePrefix="eq"
                    options={companyValidationOptions}
                    className="react-select"
                    value={props.field.value}
                    onChange={props.field.onChange}
                  />
                }
                rules={requiredRule}
                control={methods.control}
                name={`validationValues[${i}].rule` as any}
                defaultValue={item.rule}
              />
            </Col>
            {!disableEdit && (
              <Col
                xs="2"
                className="justify-content-end d-flex align-items-center"
              >
                <RemoveButton
                  data-testid={`removebtn-${i}`}
                  onClick={() => remove(i)}
                />
              </Col>
            )}
          </Row>
        ))}

      <Row className="border">
        <Col className="py-2 px-3" xs="5">
          <Form.Control
            type="text"
            placeholder="Value"
            isInvalid={
              validationValue.dirty && validationValue.value.length === 0
            }
            value={validationValue.value}
            onChange={onChangeInput}
          />
        </Col>
        <Col className="py-2 px-3" xs="5">
          <Select<OptionType>
            classNamePrefix="eq"
            options={companyValidationOptions}
            placeholder="Select your rule"
            onChange={onChangeSelect}
            value={validationValue.rule}
            className={`react-select${
              validationValue.dirty && validationValue.rule == null
                ? " is-invalid"
                : ""
            }`}
          />
        </Col>
        <Col xs="2" className="d-flex justify-content-end align-items-center">
          <AddButton onClick={onAdd} />
        </Col>
      </Row>
    </Container>
  );
};
