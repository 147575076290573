import { ValidationValueField } from "../model/ValidationValueField";
import { MessageFn, EqMessageError } from "../../../message/EqMessage";
import { useState } from "react";
import { AssociationValidationRule } from "../../../../generated/admin";
import { OptionType } from "../../../../model/OptionType";

interface List {
  dirty: boolean;
  value: string;
  rule: OptionType | null;
}

/**
 * Company validation hook.
 */
export function useCompanyValidationValuesState(
  append: (
    value: Partial<ValidationValueField> | Partial<ValidationValueField>[]
  ) => void,
  errorMsgFn: MessageFn = EqMessageError
) {
  const initialValidationValue = {
    dirty: false,
    value: "",
    rule: null
  };
  const [validationValue, setValidationValue] = useState<List>(
    initialValidationValue
  );

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationValue({
      ...validationValue,
      dirty: true,
      value: event.target.value
    });
  };

  const onChangeSelect = (selected: any) => {
    const rule = selected as OptionType;
    setValidationValue({
      ...validationValue,
      dirty: true,
      rule: rule != null && rule?.value?.length > 0 ? rule : null
    });
  };

  const onAdd = () => {
    if (
      validationValue.rule == null ||
      validationValue.rule.value.length === 0 ||
      validationValue.value.length === 0
    ) {
      return;
    }

    if (
      validationValue.rule.value ===
        AssociationValidationRule.ExactEmailDomain &&
      validationValue.value.indexOf("@") !== -1
    ) {
      errorMsgFn({ text: "The value cannot include the @ symbol." });
      return;
    }

    append({
      value: validationValue.value,
      rule: validationValue.rule
    });
    setValidationValue(initialValidationValue);
  };

  return {
    validationValue,
    onChangeInput,
    onChangeSelect,
    onAdd
  };
}
