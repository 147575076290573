import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "../modules/message/EqMessage";
import { FaRegCopy } from "react-icons/fa";
import { trimString } from "../util/trimString";

export const CopyField: React.FC<{
  fieldName: string;
  value: string;
  showValue?: boolean;
  valueDisplayLength?: number;
}> = ({ fieldName, value, showValue = false, valueDisplayLength = 20 }) => (
  <span>
    {showValue && trimString(value, valueDisplayLength)}
    <OverlayTrigger
      overlay={<Tooltip id={"copy" + value}>Copy {fieldName}</Tooltip>}
    >
      <CopyToClipboard onCopy={() => toast(fieldName + " copied")} text={value}>
        <button
          onClick={(e) => e.stopPropagation()}
          className="btn btn-link pt-0 align-top"
        >
          <FaRegCopy />
        </button>
      </CopyToClipboard>
    </OverlayTrigger>
  </span>
);
