import React, { useContext, useCallback, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import { SearchBar } from "../../../admin-components/Searchbar";
import {
  CompanyLeaseListQuery,
  CompanyV2SortField,
  CompanyV2Sort,
  useCompanyLeaseListQuery,
  LeaseFragmentFragment,
} from "../../../generated/admin";
import { LeaseOperation } from "../LeaseOperation";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import useDebounce from "../../../hooks/useDebounce";
import { Sorter } from "../../../components/Sorter";
import { PageHeading } from "../../../components/PageHeading";
import { UserContext } from "../../user/UserContext";
import { EqTable } from "../../../components/Table";
import { notNullOrUndefined } from "../../../util/notNullOrUndefined";
import { LeaseListing } from "../LeaseListing";
import { useConfig } from "../../../providers/ConfigProvider";
import { useTableSearch } from "../../../hooks/useTableSearch";

const first = 1000;

function sortKeyToDestKey(key: string): CompanyV2SortField {
  switch (key) {
    case "name":
      return CompanyV2SortField.CompanyName;
    case "industry.name":
      return CompanyV2SortField.Industry;
    case "destinationCount":
      return CompanyV2SortField.SiteCount;
    default:
      return CompanyV2SortField.CompanyName;
  }
}

function sortTransform(
  sort: {
    field: string;
    asc: boolean;
  } | null
): CompanyV2Sort | undefined {
  return sort != null
    ? {
        asc: sort.asc,
        field: sortKeyToDestKey(sort.field),
      }
    : undefined;
}

const updateCompanyLeases = (
  query: CompanyLeaseListQuery,
  companyUuid: string | undefined,
  updateFn: (leases: LeaseFragmentFragment[]) => LeaseFragmentFragment[]
): CompanyLeaseListQuery => ({
  ...query,
  companiesV2: {
    ...query.companiesV2,
    edges: query.companiesV2.edges.map((e) =>
      e.node == null || e.node.uuid !== companyUuid
        ? e
        : {
            ...e,
            node: {
              ...e.node,
              companyDestination: {
                ...e.node.companyDestination,
                leases: updateFn(e.node.companyDestination?.leases ?? []),
              },
            },
          }
    ),
  },
});

const appendLease = (
  lease: LeaseFragmentFragment,
  query: CompanyLeaseListQuery
): CompanyLeaseListQuery =>
  updateCompanyLeases(query, lease.company?.uuid, (leases) =>
    leases.concat(lease)
  );

const removeLease = (
  lease: LeaseFragmentFragment,
  query: CompanyLeaseListQuery
): CompanyLeaseListQuery =>
  updateCompanyLeases(query, lease.company?.uuid, (leases) =>
    leases.filter((l) => l.uuid !== lease.uuid)
  );

const replaceLease = (
  lease: LeaseFragmentFragment,
  query: CompanyLeaseListQuery
): CompanyLeaseListQuery =>
  updateCompanyLeases(query, lease.company?.uuid, (leases) =>
    leases.map((l) => (l.uuid === lease.uuid ? lease : l))
  );

/**
 * Company Lease list.
 */
export const CompanyLeaseList: React.FC = () => {
  const { defaultSortQuery, searchText, onTableSort, onTextSearch } =
    useTableSearch({ defaultSortFieldName: "company.name" });

  const userCtx = useContext(UserContext);
  const [search, setSearch] = React.useState<string>(searchText);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [sort, setSort] = useState<{
    field: string;
    asc: boolean;
  } | null>(defaultSortQuery);
  const { areaUnits } = useConfig();

  const activeDestinationUuid = userCtx?.activeDestination?.uuid ?? null;

  const sorter = useMemo(
    () =>
      new Sorter(
        [
          { key: "company.name", label: "Company" },
          { key: "lease.name", label: "Leasing entity", sortable: false },
          {
            key: "lease.businessNumber",
            label: "Business number",
            sortable: false,
          },
          {
            key: "lease.employeeCount",
            label: "No. of employees",
            sortable: false,
          },
          {
            key: "lease.size",
            label: `Size of lease (${areaUnits})`,
            sortable: false,
          },
          {
            key: "lease.startDateReadable",
            label: "Start date",
            sortable: false,
          },
          {
            key: "lease.breakDateReadable",
            label: "Break date",
            sortable: false,
          },
          {
            key: "lease.notificationDateReadable",
            label: "Notification date",
            sortable: false,
          },
          {
            key: "lease.expiryDateReadable",
            label: "Expiry date",
            sortable: false,
          },
        ],
        sort,
        setSort,
        onTableSort
      ),
    [sort, setSort, areaUnits, onTableSort]
  );

  const { loading, error, data, fetchMore, updateQuery } =
    useCompanyLeaseListQuery({
      variables: {
        search: debouncedSearchTerm,
        first,
        sort: sortTransform(sort),
        destinationUuid: activeDestinationUuid,
      },
      fetchPolicy: "network-only",
      skip: activeDestinationUuid == null,
    });

  const [loadingMore, setLoadingMore] = useState(false);

  const more = useCallback(async () => {
    if (loadingMore) {
      return;
    }
    setLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          search: debouncedSearchTerm,
          first,
          after: data?.companiesV2.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }): CompanyLeaseListQuery => {
          setLoadingMore(false);
          const edges = [
            ...prev.companiesV2?.edges,
            ...(fetchMoreResult?.companiesV2?.edges ?? []),
          ];
          const edgeMap = edges.reduce((carry, item) => {
            if (item.node == null) return carry;
            carry[item.node.uuid] = item;
            return carry;
          }, {} as { [key: string]: CompanyLeaseListQuery["companiesV2"]["edges"][number] });
          return {
            ...prev,
            companiesV2: {
              ...prev.companiesV2,
              edges: Object.values(edgeMap),
              pageInfo:
                fetchMoreResult?.companiesV2.pageInfo ??
                prev.companiesV2.pageInfo,
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [fetchMore, data, debouncedSearchTerm, loadingMore]);

  const sorted: LeaseListing[] = useMemo(
    () =>
      sorter.sortItems(
        data?.companiesV2.edges
          .map((e) => e.node)
          .filter(notNullOrUndefined)
          .flatMap((node) => {
            const defaultItems = [{ uuid: node.uuid, company: node }];
            const leases =
              node.companyDestination?.leases?.map<LeaseListing>((lease) => ({
                uuid: node.uuid,
                company: node,
                lease: {
                  ...lease,
                  startDateReadable: dayjs(lease.startDate).format(
                    "D MMM YYYY"
                  ),
                  expiryDateReadable: dayjs(lease.expiryDate).format(
                    "D MMM YYYY"
                  ),
                  notificationDateReadable:
                    lease.notificationDate == null
                      ? undefined
                      : dayjs(lease.notificationDate).format("D MMM YYYY"),
                  breakDateReadable:
                    lease.breakDate == null
                      ? undefined
                      : dayjs(lease.breakDate).format("D MMM YYYY"),
                },
              })) ?? defaultItems;

            return leases.length > 0 ? leases : defaultItems;
          }) ?? []
      ),
    [data?.companiesV2.edges, sorter]
  );

  const serverLoading = useMemo(
    () => ({
      total: data?.companiesV2.totalCount ?? 0,
      loadMore: more,
      hasNextPage: data?.companiesV2.pageInfo.hasNextPage ?? false,
      loading: loadingMore,
    }),
    [data, loadingMore, more]
  );

  const clear = useCallback(() => {
    setSearch("");
    onTextSearch("");
  }, [setSearch, onTextSearch]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
      onTextSearch(e.currentTarget.value);
    },
    [setSearch, onTextSearch]
  );

  const operations = useMemo(
    () => [
      (item: LeaseListing) => ({
        render: () => (
          <LeaseOperation
            key={item.uuid}
            {...item}
            onCreate={(newLease) => {
              updateQuery((prev) => appendLease(newLease, prev));
            }}
            onModify={(lease) => {
              updateQuery((prev) => replaceLease(lease, prev));
            }}
            onRewrite={(lease) => {
              updateQuery((prev) => replaceLease(lease, prev));
            }}
            onDelete={(deletedLease) => {
              updateQuery((prev) => removeLease(deletedLease, prev));
            }}
            onTerminate={(terminatedLease) => {
              updateQuery((prev) => removeLease(terminatedLease, prev));
            }}
          />
        ),
      }),
    ],
    [updateQuery]
  );

  return (
    <>
      <PageHeading title="Leasing"></PageHeading>
      <Container className="pb-5" fluid>
        <Row className="justify-content-between pt-3 pb-4">
          <Col md="7" lg="4">
            <SearchBar
              clear={clear}
              value={search}
              onChange={handleSearchChange}
              placeholder={"Search for companies..."}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="pb-5">
            <LoadingOrErrorDisplay loading={loading} error={error}>
              <EqTable
                items={sorted}
                sorter={sorter}
                serverLoading={serverLoading}
                pageSize={1000}
                pagination={false}
                operations={operations}
                compact
                emptyMessage={
                  activeDestinationUuid == null
                    ? "You must select a site to see its lease data"
                    : "No lease data found"
                }
                wrapHeadings
              />
            </LoadingOrErrorDisplay>
          </Col>
        </Row>
      </Container>
    </>
  );
};
