import React from "react";
import { UserAttribute } from "../model/UserAttribute";
import { EditRow } from "./EditRow";
import { NormalRow } from "./NormalRow";
import { UserAttributeStore } from "../model/UserAttributeStore";

interface Props {
  attribute: UserAttribute;
  store: UserAttributeStore;
}

/**
 * Toggle edit.
 */
export const ToggleEdit: React.FC<Props> = ({ attribute, store }) => {
  const [editing, setEditing] = React.useState(false);

  return editing ? (
    <EditRow attribute={attribute} store={store} setEditing={setEditing} />
  ) : (
    <NormalRow attribute={attribute} store={store} setEditing={setEditing} />
  );
};
