import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { FormikContextType } from "formik";
import { WhitelabelFormData } from "../WhitelabelFormData";

interface Props {
  methods: FormikContextType<WhitelabelFormData>;
}

interface CategoryOption {
  value: string;
  label: string;
}

const categories: CategoryOption[] = [
  { value: "Business", label: "Business" },
  { value: "Lifestyle", label: "Lifestyle" },
  { value: "Productivity", label: "Productivity" },
];

export const AppStoreCategory: React.FC<Props> = ({
  methods,
}) => {
  const options = [...categories];
  var currentValue = categories[0].value;
  if (methods.values.categories.length > 0 &&  methods.values.categories[0] !== "") {
    currentValue = methods.values.categories[0];
  } else {
    methods.setFieldValue(
      "categories",
      [currentValue]
    );
  }
  const value = options.find((c) => c.value === currentValue);
  
  return (
    <Form.Group as={Row} controlId="category">
      <Form.Label column md="3">
        Category
      </Form.Label>
      <Col md="9" lg="6">
        <Select<CategoryOption>
          classNamePrefix="eq"
          name="category"
          aria-label="category"
          options={options.sort((a, b) => a.label.localeCompare(b.label))}
          value={value}
          onChange={(newValue) => {
            methods.setFieldValue(
              "categories",
              [newValue?.value]
            );
          }}
        />
        <Form.Text className="text-muted">
          Select a category for which end-users can search and discover your app in
          the respective app stores.
        </Form.Text>
      </Col>
    </Form.Group>
  );
};
