import React, { useContext } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { TableButton, ActionButton } from "../../../components/TableButton";
import { useUpdateLevelMutation } from "../../../generated/admin";
import { deleteCheck } from "../../../components/deleteCheck";
import { EqMessageSuccess, EqMessageError } from "../../message/EqMessage";
import { LevelStore } from "./LevelStore";
import { UserContext } from "../../user/UserContext";

const EditLevelRow = ({
  level,
  store,
  setEditing
}: {
  level: { uuid: string; name: string };
  store: LevelStore;
  setEditing: (edit: boolean) => void;
}) => {
  const [name, setName] = React.useState(level.name);
  const [save, { loading }] = useUpdateLevelMutation();
  const user = useContext(UserContext);

  return (
    <tr>
      <td>
        <Form.Group>
          <InputGroup>
            <Form.Control
              required
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value.slice(0, 50))
              }
            />
          </InputGroup>
        </Form.Group>
      </td>
      <td>
        <>
          <TableButton onClick={() => setEditing(false)}>Cancel</TableButton> |{" "}
          <ActionButton
            onClick={async () => {
              const result = await save({
                variables: {
                  input: {
                    uuid: level.uuid,
                    name,
                    destinationUuid: user?.activeDestination?.uuid
                  }
                }
              });
              if (result.data?.updateLevel) {
                setEditing(false);
                store.updateLevel(level.uuid, name);
                await EqMessageSuccess({
                  text: "Level name updated"
                });
              } else {
                await EqMessageError({
                  text: "There was an error updating the level name"
                });
              }
            }}
            actionLabel="Saving..."
            restLabel="Save"
            isInAction={loading}
          />
        </>
      </td>
    </tr>
  );
};

const NormalLevelRow = ({
  level,
  store,
  setEditing
}: {
  level: { uuid: string; name: string };
  store: LevelStore;
  setEditing: (edit: boolean) => void;
}) => {
  const user = useContext(UserContext);
  const inSiteContext = user?.activeDestination?.uuid != null;

  return (
    <tr>
      <td>{level.name}</td>
      <td>
        <>
          <TableButton onClick={() => setEditing(true)}>Edit</TableButton> |{" "}
          <ActionButton
            onClick={async () => {
              inSiteContext
                ? await store.deleteLevel(level.uuid, false)
                : await deleteCheck(
                    async () => await store.deleteLevel(level.uuid, true),
                    { confirmButtonText: "Delete level" }
                  );
            }}
            actionLabel={"Deleting..."}
            restLabel={"Delete"}
            isInAction={store.deleting === level.uuid}
          />
        </>
      </td>
    </tr>
  );
};

export const LevelRow = ({
  level,
  store
}: {
  level: { uuid: string; name: string };
  store: LevelStore;
}) => {
  const [editing, setEditing] = React.useState(false);

  return editing ? (
    <EditLevelRow level={level} store={store} setEditing={setEditing} />
  ) : (
    <NormalLevelRow level={level} store={store} setEditing={setEditing} />
  );
};
