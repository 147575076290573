import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
interface Props {
  handleUpload: (val?: string) => void;
  defaultValue?: string;
}

const UploadContainer = styled.div.attrs({
  className: "p-2",
})`
  background: #f7f7f7;
`;
const StyleWrapper = styled.div`
  & input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    font-weight: 500;
    border: 2px solid #00adef;
    color: #00adef;
    margin: 0;
  }
`;

export const FileUpload: React.FC<Props> = ({ handleUpload, defaultValue }) => {
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const onRemove = () => {
    inputRef.current!.value = "";
    setFile(undefined);
    handleUpload();
  };

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }

      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = async (ev) => {
        const text = ev!.target!.result;
        handleUpload(text as string);
      };
      reader.readAsText(e.target.files[0]);
    },
    [handleUpload]
  );

  return (
    <div>
      <StyleWrapper>
        <UploadContainer>
          <div className="d-flex">
            <div className="fileUploader eq-img-upload">
              <input
                type="file"
                name="file"
                ref={inputRef}
                onChange={handleFileChange}
              />
              {`${file?.name ?? defaultValue ?? ""} `}
              <Button className="chooseFileButton" onClick={handleUploadClick}>
                Browse
              </Button>
            </div>
            {(file != null || defaultValue != null) && (
              <div className="ml-auto">
                <Button variant="link" className="p-0" onClick={onRemove}>
                  <FaTimes className="text-danger" size="20px" />
                </Button>
              </div>
            )}
          </div>
        </UploadContainer>
      </StyleWrapper>
    </div>
  );
};
