import React, { useState, useCallback } from "react";
import { CreateButton } from "../../../../components/CreateButton";
import { SubTitle } from "../../../../components";
import { Modal, Row, Col } from "react-bootstrap";
import { Catalogue } from "../../../catalogue/Catalogue";
import { useUserAttributesCatalogueFactory } from "./useUserAttributesCatalogue";
import { UserAttributeStore } from "../model/UserAttributeStore";

interface Props {
  store: UserAttributeStore;
}

const text = {
  selectedItemsTitle: "My User Attributes",
  itemsTitle: "User Attributes Catalogue",
  saveBtnTxt: "Add User Attributes"
};

/**
 * User attribute catalogue landing.
 */
export const UserAttributesCatalogueLanding: React.FC<Props> = ({ store }) => {
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => setShow(false), [setShow]);
  const handleShow = useCallback(() => setShow(true), [setShow]);
  const result = useUserAttributesCatalogueFactory({
    store,
    closeModalFn: handleClose
  });

  return (
    <>
      <SubTitle>User Attributes Catalogue</SubTitle>
      <p className="mb-4">
        Choose from a catalogue of existing user attributes to add to your site.
        These attributes will be unique to your site and will become available
        in the table below.
      </p>
      <Row className="justify-content-end">
        <Col md="4" lg="3">
          <CreateButton onClick={handleShow}>Open Catalogue</CreateButton>
        </Col>
      </Row>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header
          className="border-bottom-0 pb-0 pt-2"
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Catalogue catalogueItemsStore={result} text={text} />
        </Modal.Body>
      </Modal>
    </>
  );
};
