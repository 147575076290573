import React from "react";
import { SuggestionContainer } from "./SuggestionContainer";
import { useCompanyListQuery } from "../../../../generated/admin";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { useCompanySuggestion } from "../hook/useCompanySuggestion";
import { Spinner } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { useSiteContextHelper } from "../../../../hooks/useSiteContextHelper";

interface Props {
  search: string;
  siteUuid: string;
  hideFn: () => void;
}

const MINIMUM_SEARCH_LENGTH = 2;

/**
 * Company suggestion.
 */
export const CompanySuggestion: React.FC<Props> = ({
  search,
  siteUuid,
  hideFn,
}) => {
  const siteCtxHelper = useSiteContextHelper();
  const { addCompany, addingDestination } = useCompanySuggestion(
    siteCtxHelper.redirect
  );
  const { data, loading, error } = useCompanyListQuery({
    variables: {
      search,
      first: 5,
    }
  })
  if (search.length < MINIMUM_SEARCH_LENGTH || siteUuid == null) {
    return <></>;
  }

  return (
    <LoadingOrErrorDisplay error={error} loading={loading}>
      <SuggestionContainer>
        {data != null && data.companiesV2.edges.length > 0 && (
          <>
            <div className="p-2 font-italic">
              The following companies already exist, did you mean:
              <FaTimes
                size="20px"
                className="cursor-pointer float-right"
                onClick={hideFn}
              />
            </div>
            {data.companiesV2.edges.map((c) => (
              <div
                key={`suggestion-${c.node?.uuid}`}
                className="list d-flex"
              >
                <div className="name p-2">{c.node?.name}</div>
                <div
                  onClick={() => addCompany(c.node?.uuid, siteUuid)}
                  className="btn-action ml-auto text-primary py-2 border border-light border-right-0 px-3 cursor-pointer"
                >
                  {addingDestination ? (
                    <span>
                      <Spinner animation="grow" size="sm" /> Saving...
                    </span>
                  ) : (
                    "Add to site"
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </SuggestionContainer>
    </LoadingOrErrorDisplay>
  );
};
