import React from "react";
import { UserListActionButton } from "./UserListActions";
import { CompactListIcon } from "../../../components/compactListIcon";
import { CapsuleButton } from "../../../components/CapsuleButton";

export const CompactListButton: React.FC<UserListActionButton> = (props) => (
  <CapsuleButton
    onClick={props.onclick}
    variant={"link"}
    size="sm"
    className="p-0"
  >
    <CompactListIcon />
  </CapsuleButton>
);
