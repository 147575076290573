import React, { useEffect, useState } from "react";
import { WhitelabelFormData } from "./WhitelabelFormData";
import { Form } from "react-bootstrap";
import { ColorPallet } from "../../../components/ColorPallet";
import { FormikContextType } from "formik";
import { InputRow } from "./components/InputRow";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { SiteSelector } from "./components/SiteSelector";
import { useConfig } from "../../../providers/ConfigProvider";
import { useDestinationsWithoutWhitelabelListQuery, useSiteListQuery } from "../../../generated/admin";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { WhitelabelAndroid } from "./WhitelabelAndroid";
import { WhitelabelIos } from "./WhitelabelIos";

interface Props {
  methods: FormikContextType<WhitelabelFormData>;
}

export const AppDetails: React.FC<Props> = ({ methods }) => {
  const config = useConfig();
  const allSitesResult = useSiteListQuery({
    variables: { name: "", installation: config.installation },
  });
  const cleanSitesResult = useDestinationsWithoutWhitelabelListQuery({
    fetchPolicy: "network-only"
  });
  const [siteOptions, setSiteOptions] = useState<{ value: string; label: string}[]>([]);

  useEffect(() => {
    if (allSitesResult.data != null && cleanSitesResult != null) {
      const siteOptions = allSitesResult.data?.searchDestinations.map((site) => ({
        value: site.uuid,
        label: site.name,
        isDisabled: cleanSitesResult.data?.destinationsWithoutWhitelabel.some((d) => d.uuid === site.uuid),
      }));
      setSiteOptions(siteOptions);
    }
  }, [allSitesResult, cleanSitesResult]);

  return (
    <ToggleContainer className="lg" title="App Details" show={false}>
      <InputRow title="Name">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="appName"
            isInvalid={methods.errors.appName != null}
            onChange={methods.handleChange}
            value={methods.values.appName ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.appName) && (
          <div className="invalid-feedback d-block">{methods.errors.appName}</div>
        )}
        <Form.Text className="text-muted">
          The name of your app as it will appear on the Device.
          This can't be longer than 12 characters.
        </Form.Text>
      </InputRow>

      <InputRow title="App colour">
        <div className="border-bottom d-flex align-items-center">
          {methods.values.color && (
            <ColorPallet bgcolor={methods.values.color} />
          )}
          <Form.Control
            type="text"
            name="color"
            isInvalid={methods.errors.color != null}
            onChange={methods.handleChange}
            value={methods.values.color ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.color) && (
          <div className="invalid-feedback d-block">{methods.errors.color}</div>
        )}
        <Form.Text className="text-muted">
          The app colour is used on initial start-up of the app when
          registering, or logging in, to the app. A hex code must be entered in
          the format #000000.
        </Form.Text>
      </InputRow>

      <SiteSelector methods={methods} siteOptions={siteOptions} />

      <ToggleContainer className="lg" title="App Images" show={false}>
          <ToggleContainer className="lg" title="iOS" show={false}>
            <WhitelabelIos methods={methods} />
          </ToggleContainer>
          <ToggleContainer className="lg" title="Android" show={false}>
            <WhitelabelAndroid methods={methods} />
          </ToggleContainer>
        </ToggleContainer>
    </ToggleContainer>
  );
};
