import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "./Auth0Provider";
import { useConfig } from "./ConfigProvider";

export const LocalApolloProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const config = useConfig();

  const link = onError(({ graphQLErrors }) => {
    graphQLErrors?.forEach(({ message }) => {
      if (message.includes("jwt expired")) {
        loginWithRedirect();
      }
    });
  });

  const adminLink = createHttpLink({
    uri: config.adminApi,
  });
  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getTokenSilently();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...(config.equiemEdition != null
          ? { "x-equiem-edition": config.equiemEdition }
          : {}),
        "X-Equiem-Application": "admin",
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const adminClient = new ApolloClient({
    name: "admin",
    defaultOptions: {
      query: {
        errorPolicy: "all",
      },
      watchQuery: {
        errorPolicy: "all",
      },
    },
    link: link.concat(authLink).concat(adminLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={adminClient}>{children}</ApolloProvider>;
};
