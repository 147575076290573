import React from "react";
import { PageHeading } from "../components/PageHeading";

export const HelpPage: React.FC = () => {
  return (
    <>
      <PageHeading title="Help" />
      <div className="pl-3">
        <p>
          If you have any questions or issues, please contact support on
          <br />
          <a
            href="mailto:support@getequiem.com"
            className="text-body font-weight-bold"
          >
            support@getequiem.com
          </a>
        </p>

        <p>
          Have some general feedback for us? We'd love to hear from you. Email
          us on <br />
          <a
            href="mailto:feedback.adminpanel@getequiem.com"
            className="text-body font-weight-bold"
          >
            feedback.adminpanel@getequiem.com
          </a>
        </p>
      </div>
    </>
  );
};
