import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AddButton } from "../../../../components/AddButton";
import { FormikContextType, Field, FieldArray } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { SocialLinkType } from "../../../../generated/admin";
import startCase from "lodash/startCase";
import { RemoveButton } from "../../../../components/RemoveButton";
import { FormikSelect } from "../../../../components/formik/FormikSelect";
import { EqMessageError } from "../../../message/EqMessage";

interface Props {
  methods: FormikContextType<SiteConfigFormData>;
}

const socialLinkOptions = Object.values(SocialLinkType).map((t) => ({
  label: startCase(t.toLowerCase()),
  value: t
}));

const contains = (
  source: string,
  target: string,
  pos: { start: number; end: number }
) =>
  source.indexOf(target) >= 0 &&
  source.substring(pos.start, pos.end) === target;

const isValidSocialLink = (linkInput: { value: string; type: string }) => {
  if (linkInput.type === "EMAIL") {
    return contains(linkInput.value, "mailto:", { start: 0, end: 7 });
  }
  if (linkInput.type === "PHONE") {
    return contains(linkInput.value, "tel:", { start: 0, end: 4 });
  }
  let url;
  try {
    url = new URL(linkInput.value);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

const MAX_SOCIAL_LINKS = 7;

export const SocialLinks: React.FC<Props> = ({ methods }) => {
  return (
    <div>
      <p>
        Add social links below to be shown across the platform and in automated
        system emails. Links will be illustrated by an icon based on the
        selected type. A maximum of 7 links can be included and must be
        formatted as absolute paths or including <strong>mailto:</strong> or{" "}
        <strong>tel:</strong> if respectively adding email or phone links.
      </p>
      <Container fluid className="p-0 mb-5">
        <FieldArray name="socialLinks">
          {(arrayHelpers) => (
            <Row className="justify-content-between">
              <Col>
                <Container fluid>
                  {methods.values.socialLinks.map((link, i) => (
                    <Row
                      className={`py-1 border ${i === 0 ? "" : "border-top-0"}`}
                      key={`list-${i}`}
                    >
                      <Col xs="5" lg="5">
                        <Field
                          name={`socialLinks.${i}.value`}
                          as={Form.Control}
                        />
                      </Col>
                      <Col xs="5" lg="5">
                        <FormikSelect
                          name={`socialLinks.${i}.type`}
                          options={socialLinkOptions}
                        />
                      </Col>
                      <Col
                        xs="2"
                        className="d-flex justify-content-end align-items-center"
                      >
                        <RemoveButton onClick={() => arrayHelpers.remove(i)} />
                      </Col>
                    </Row>
                  ))}

                  {methods.values.socialLinks.length >=
                  MAX_SOCIAL_LINKS ? null : (
                    <Row className="border border-top-0">
                      <Col className="py-1" xs="5">
                        <Field
                          name={"socialLinkInput.value"}
                          placeholder={"Add a link"}
                          as={Form.Control}
                        />
                      </Col>
                      <Col className="py-1 social-link-type" xs="5">
                        <FormikSelect
                          name={"socialLinkInput.type"}
                          placeholder={"Link type"}
                          aria-label="social-link-type"
                          options={socialLinkOptions}
                        />
                      </Col>
                      <Col
                        xs="2"
                        className="d-flex justify-content-end align-items-center"
                      >
                        <AddButton
                          variant="text-primary"
                          data-testid="add-sociallinks-btn"
                          onClick={() => {
                            if (
                              !isValidSocialLink({
                                type:
                                  methods.values.socialLinkInput?.type ?? "",
                                value:
                                  methods.values.socialLinkInput?.value ?? ""
                              })
                            ) {
                              EqMessageError({
                                html: `
                                <div class="mb-3">
                                An invalid input has been entered. The following prefixes are required before each link type:
                                </div>
                                <p>Web link: <strong>https://</strong>
                                <p>Phone: <strong>tel:</strong>
                                <p>Email: <strong>mailto:</strong>
                              `
                              });
                              return;
                            }
                            arrayHelpers.push({
                              ...methods.values.socialLinkInput
                            });
                            methods.setFieldValue("socialLinkInput", {
                              type: "",
                              value: ""
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>
            </Row>
          )}
        </FieldArray>
        {methods.errors.socialLinks != null ? (
          <div className="invalid-feedback d-block">
            {methods.errors.socialLinks as React.ReactNode}
          </div>
        ) : null}
      </Container>
    </div>
  );
};
