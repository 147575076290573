import React from "react";
import { useUser } from "../modules/user/UserContext";

/**
 * One of the design goals of the system is to have the super admin be
 * a super set of the site manager. Having the site context view be
 * different for super admins and site managers breaks this. When checking
 * if something for super admins should be shown, only check if the user
 * is in a site context. A site manager cannot use the system without
 * selecting a site context so being in the global context implicitly means
 * the user is a super admin.
 */
export const RenderForSystemAdmin: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  const ctx = useUser();
  if (ctx?.activeDestination != null) {
    return null;
  }
  return <>{children}</>;
};
