import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { TabNavigationButton } from "../../../components/NavigationButton";

interface Props {
  tab: BuildingDetailsTab;
  setTab: React.Dispatch<React.SetStateAction<BuildingDetailsTab>>;
  isTaxAdmin: boolean;
  hasIntegrations: boolean;
  children: React.ReactNode;
}

export enum BuildingDetailsTab {
  GENERAL = "GENERAL",
  TAX_RATES = "TAX RATES",
  INTEGRATION = "INTEGRATION",
}

export const BuildingDetailsTabContainer: React.FC<Props> = ({
  tab,
  setTab,
  isTaxAdmin,
  hasIntegrations,
  children,
}) => (
  <>
    <div className="p-3">
      <TabNavigationButton
        className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
        title={BuildingDetailsTab.GENERAL}
        active={tab === BuildingDetailsTab.GENERAL}
        onClick={() => setTab(BuildingDetailsTab.GENERAL)}
      />
      {isTaxAdmin && (
        <TabNavigationButton
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
          title={BuildingDetailsTab.TAX_RATES}
          active={tab === BuildingDetailsTab.TAX_RATES}
          onClick={() => setTab(BuildingDetailsTab.TAX_RATES)}
        />
      )}
      {hasIntegrations && (
        <TabNavigationButton
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
          title={BuildingDetailsTab.INTEGRATION}
          active={tab === BuildingDetailsTab.INTEGRATION}
          onClick={() => setTab(BuildingDetailsTab.INTEGRATION)}
        />
      )}
    </div>
    <hr className="my-0" />
    <Container>
      <Row>
        <Col className="py-5" xs="12">
          {children}
        </Col>
      </Row>
    </Container>
  </>
);
