import {
  useUserAttributesQuery,
  useUpsertUserSiteAttributesMutation
} from "../../../../generated/admin";
import { useState, useMemo, useEffect, useCallback } from "react";
import { CatalogueItemsStore } from "../../../catalogue/model/CatalogueItemsStore";
import { CatalogueItemsState } from "../../../catalogue/model/CatalogueItemsState";
import {
  EqMessageSuccess,
  MessageFn,
  EqMessageError
} from "../../../message/EqMessage";
import { ItemSelector } from "../../../catalogue/model/ItemSelector";
import { UserAttributeStore } from "../model/UserAttributeStore";

interface Props {
  store: UserAttributeStore;
  closeModalFn: () => void;
  successMsgFn?: MessageFn;
  errorMsgFn?: MessageFn;
}

/**
 * Company catalogue load hook.
 */
export function useUserAttributesCatalogueFactory({
  store,
  closeModalFn,
  successMsgFn = EqMessageSuccess,
  errorMsgFn = EqMessageError
}: Props): CatalogueItemsStore {
  const [state, setState] = useState<CatalogueItemsState>({
    searchEnabled: true,
    searchPlaceholderText: "Search for user attributes...",
    loading: false,
    searchKeyword: "",
    items: null,
    hasMore: false
  });

  const [mutation, { loading }] = useUpsertUserSiteAttributesMutation();
  const handler = useCallback((text: string) => {
    setState((cstate) => ({ ...cstate, searchKeyword: text }));
  }, []);

  const results = useUserAttributesQuery({
    variables: {},
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    setState((cstate) => ({
      ...cstate,
      loading: results.loading,
      error: results.error,
      items: results.data?.attributes ?? [],
      hasMore: false
    }));
  }, [results]);

  return useMemo(
    () => ({
      ...state,
      items:
        state.searchKeyword !== ""
          ? state.items?.filter((x) => {
              return x.name.toLowerCase().search(state.searchKeyword) !== -1;
            }) ?? null
          : state.items,
      loadMore: () => {},
      clearSearch: () =>
        setState((cstate) => ({ ...cstate, searchKeyword: "" })),
      handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handler(e.currentTarget.value);
      },
      save: {
        loading,
        fn: async (selectedItems: ItemSelector) => {
          const selectedNames = Object.values(selectedItems).map((x) => x.name);
          if (selectedNames.length === 0) {
            return;
          }

          const result = await mutation({
            variables: {
              input: selectedNames.map((name) => ({
                name,
                siteUuid: store.activeDestination
              }))
            }
          });

          const success =
            result.data?.upsertUserSiteAttributes.flatMap((r) => {
              return r.__typename === "SiteAttributeSuccessResponse"
                ? [
                    {
                      uuid: r.siteAttribute.uuid,
                      name: r.siteAttribute.name
                    }
                  ]
                : [];
            }) ?? [];

          const errors =
            result.data?.upsertUserSiteAttributes.flatMap((r) => {
              return r.__typename === "FailureResponse" ? [r.reason] : [];
            }) ?? [];

          const txt = `attribute${selectedNames.length > 1 ? "s" : ""}`;

          if (success.length === 0 && errors.length > 0) {
            errorMsgFn({
              text: `Failed to add user ${txt}. Reason: ${errors.join(", ")}`
            });
            return;
          }

          store.addAttributes(success);
          closeModalFn();
          successMsgFn({ text: `User ${txt} successfully added.` });
        }
      }
    }),
    [
      closeModalFn,
      errorMsgFn,
      handler,
      loading,
      mutation,
      state,
      store,
      successMsgFn
    ]
  );
}
