import { useEffect } from "react";
import { useLocation } from "react-router";
import { Config } from "../config";
import { DataType, useMetricMutation } from "../generated/admin";
import { User } from "../modules/user/User";
import { useUser } from "../modules/user/UserContext";
import { useConfig } from "../providers/ConfigProvider";
import { RootRoutes } from "../RouterOutlet";

export function useUserTracking() {
  const location = useLocation();
  const user = useUser();
  const [mutation] = useMetricMutation();
  const config = useConfig();
  try {
    useEffect(() => {
      setTitle(location.pathname);
      const variables = formatMetric(config, user, location.pathname);
      mutation({
        variables,
      }).catch((e) => {
        console.log(e);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
  } catch (e) {
    console.log(e);
  }
}

function formatMetric(config: Config, user: User, pathname: string) {
  return {
    input: {
      AI: "admin",
      DT: DataType.Count,
      VL: 1,
      ME: "admin_action",
      TT: "view",
      TD: document.title,
      TC: document.title.split(" - ").pop(),
      TR: pathname,
      PI: user.activeDestination?.uuid,
      TS: new Date().getTime(),
      UI: user.uuid,
    },
  };
}

function setTitle(pathname: string) {
  if (pathname.includes(RootRoutes.SITES)) {
    document.title = "Admin Panel - sites";
    return;
  }
  if (pathname.includes(RootRoutes.BUILDINGS)) {
    document.title = "Admin Panel - buildings";
    return;
  }
  if (pathname.includes(RootRoutes.REGISTRATION)) {
    document.title = "Admin Panel - registration";
    return;
  }
  if (pathname.includes(RootRoutes.INVITES)) {
    document.title = "Admin Panel - user invites";
    return;
  }
  if (pathname.includes(RootRoutes.USERS)) {
    document.title = "Admin Panel - users";
    return;
  }
  if (pathname.includes(RootRoutes.COMPANIES)) {
    document.title = "Admin Panel - companies";
    return;
  }
  if (pathname.includes(RootRoutes.TAXONOMY)) {
    document.title = "Admin Panel - taxonomy";
    return;
  }
  if (pathname.includes(RootRoutes.HELP)) {
    document.title = "Admin Panel - help";
    return;
  }
  if (pathname.includes(RootRoutes.SITEPICKER)) {
    document.title = "Admin Panel - site picker";
    return;
  }
  if (pathname.includes(RootRoutes.APARTMENTS)) {
    document.title = "Admin Panel - apartments";
    return;
  }
}
