import { useState, useMemo } from "react";
import { Destination } from "../generated/admin";

/**
 * Hooks for destination select all.
 */
export function useDestinationSelectAll(
  destinations: Pick<Destination, "uuid" | "name">[],
  selected: string[],
  totalDisplayCol: number
) {
  const totalRow = Math.ceil(destinations.length / totalDisplayCol);
  const total = totalRow * totalDisplayCol - destinations.length;
  const dummies = Array.from(Array(total > 0 ? total : 0), (_, i) => ({
    uuid: `none-${i}`,
    name: "none",
    selected: false,
    dummy: true
  }));

  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const [allDestinations, setAllDestinations] = useState(
    destinations
      .map((d) => {
        return {
          ...d,
          selected: selected.includes(d.uuid),
          dummy: false
        };
      })
      .concat(dummies)
  );

  return useMemo(
    () => ({
      totalRow,
      allDestinations,
      lastSelected,
      onSelectAll: () => {
        setAllDestinations(
          allDestinations.map((d) => {
            return {
              ...d,
              selected: true
            };
          })
        );
      },
      onReset: () => {
        setAllDestinations(
          allDestinations.map((d) => {
            return {
              ...d,
              selected: false
            };
          })
        );
      },
      onClick: (uuid: string, shiftKeyDown: boolean) => {
        if (shiftKeyDown && lastSelected != null) {
          const currentIndex = allDestinations.findIndex(
            (d) => d.uuid === uuid
          );
          const lastSelectedIndex = allDestinations.findIndex(
            (d) => d.uuid === lastSelected
          );
          const value = allDestinations[lastSelectedIndex].selected;
          const [start, end] = [currentIndex, lastSelectedIndex].sort(
            (a, b) => a - b
          );
          setAllDestinations(
            allDestinations.map((d, index) => {
              return {
                ...d,
                selected: index >= start && index <= end ? value : d.selected
              };
            })
          );
        } else {
          setAllDestinations(
            allDestinations.map((d) => {
              return {
                ...d,
                selected: uuid === d.uuid ? !d.selected : d.selected
              };
            })
          );
        }
        setLastSelected(uuid);
      }
    }),
    [allDestinations, lastSelected, totalRow]
  );
}
