import React from "react";
import { UserContext } from "../../user/UserContext";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { SitePicker as SitePickerUI } from "../../../admin-components/SitePicker";

/**
 * Site picker component.
 */
export const SitePicker: React.FC<{ pathName?: string }> = ({ pathName }) => {
  const siteCtxHelper = useSiteContextHelper();
  const user = React.useContext(UserContext);
  const showAllSelection = user?.isAdminOrRegionalManager ?? false;
  const showCloseBtn =
    user?.isAdminOrRegionalManager === true || user?.activeDestination != null;

  return (
    <SitePickerUI
      destinations={user?.destinations ?? []}
      activeDestination={user?.activeDestination ?? undefined}
      setActiveDestination={(uuid) => {
        const pickedAllSitesOption = uuid == null;
        const destination = user?.destinations
          .filter((d) => d.uuid === uuid)
          .pop();
        user?.setActiveDestination(destination);
        siteCtxHelper.goBack(destination?.uuid, pathName, pickedAllSitesOption);
      }}
      close={showCloseBtn ? () => siteCtxHelper.goBack() : undefined}
      showAllSelection={showAllSelection}
    />
  );
};
