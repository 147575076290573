import React from "react";
import { Form, Row, Col } from "react-bootstrap";

import type { SyncYardiBuildingIntegrationInput } from "../../../generated/admin";
import { SubTitle } from "../../../components";
import { BuildingDetailsTabProps } from "./BuildingDetailsTabProps";

export const BuildingDetailsIntegration: React.FC<BuildingDetailsTabProps> = ({
  state,
  errors,
  setState,
}) => {
  const onChange = (
    name: keyof SyncYardiBuildingIntegrationInput,
    value: string
  ) => {
    setState({
      ...state,
      integrations: {
        ...(state.integrations ?? {}),
        yardi: { ...(state.integrations?.yardi ?? {}), [name]: value },
      },
    });
  };

  return (
    <>
      <SubTitle>Yardi</SubTitle>

      <Form.Group controlId="integrations.yardi.propertyCode" as={Row}>
        <Form.Label column md="3" lg="2">
          Property code
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            name="integrations.yardi.propertyCode"
            value={state.integrations?.yardi?.propertyCode ?? ""}
            maxLength={8}
            onChange={(e) => onChange("propertyCode", e.currentTarget.value)}
          />
          <Form.Text className="text-muted">
            Enter the Property code to use on the Yardi bookings export.
          </Form.Text>
        </Col>
      </Form.Group>

      <Form.Group controlId="integrations.yardi.accountId" as={Row}>
        <Form.Label column md="3" lg="2">
          Account
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            name="integrations.yardi.accountId"
            value={state.integrations?.yardi?.accountId ?? ""}
            maxLength={10}
            onChange={(e) => onChange("accountId", e.currentTarget.value)}
          />
          <Form.Text className="text-muted">
            Enter the Account ID to use on the Yardi bookings export.
          </Form.Text>
        </Col>
      </Form.Group>

      <Form.Group controlId="integrations.yardi.accrualId" as={Row}>
        <Form.Label column md="3" lg="2">
          Accrual
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            name="integrations.yardi.accrualId"
            value={state.integrations?.yardi?.accrualId ?? ""}
            maxLength={9}
            onChange={(e) => onChange("accrualId", e.currentTarget.value)}
          />
          <Form.Text className="text-muted">
            Enter the Accrual ID to use on the Yardi bookings export.
          </Form.Text>
        </Col>
      </Form.Group>

      <Form.Group controlId="integrations.yardi.offsetId" as={Row}>
        <Form.Label column md="3" lg="2">
          Offset
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            name="integrations.yardi.offsetId"
            value={state.integrations?.yardi?.offsetId ?? ""}
            maxLength={9}
            onChange={(e) => onChange("offsetId", e.currentTarget.value)}
          />
          <Form.Text className="text-muted">
            Enter the Offset ID to use on the Yardi bookings export.
          </Form.Text>
        </Col>
      </Form.Group>

      <Form.Group controlId="integrations.yardi.checknumId" as={Row}>
        <Form.Label column md="3" lg="2">
          Checknum
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            name="integrations.yardi.checknumId"
            value={state.integrations?.yardi?.checknumId ?? ""}
            maxLength={8}
            onChange={(e) => onChange("checknumId", e.currentTarget.value)}
          />
          <Form.Text className="text-muted">
            Enter the Checknum ID to use on the Yardi bookings export.
          </Form.Text>
        </Col>
      </Form.Group>
    </>
  );
};
