import React, { useMemo, useState } from "react";
import {
  useCompanyAttributeStore,
  attributesSort
} from "../hook/useCompanyAttributesStore";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { ArrayPagination } from "../../../../components/Pagination";
import { Sorter } from "../../../../components/Sorter";
import { FlushRightTable } from "../../../../components/FlushRightTable";
import { ToggleEdit } from "./ToggleEdit";

/**
 * Attribute list.
 */
export const AttributeList: React.FC = () => {
  const store = useCompanyAttributeStore();
  const [sort, setSort] = useState<{
    field: string;
    asc: boolean;
  }>({ field: "name", asc: true });
  const sorter = useMemo(() => new Sorter(
    [{ key: "name", label: "Company attribute" }],
    sort,
    setSort
  ), [sort]);
  const sorted = useMemo(() => sorter.sortItems(store.attributes.slice(), attributesSort), [sorter, store.attributes]);

  return (
    <LoadingOrErrorDisplay
      loading={store.loading}
      error={store.initalLoadError}
    >
      <ArrayPagination items={sorted}>
        {(items) => (
          <FlushRightTable>
            <sorter.Heading>
              <th className="w-200-px text-right">Operations</th>
            </sorter.Heading>
            <tbody>
              {items.map((attribute) => (
                <ToggleEdit
                  key={`row-${attribute.uuid}`}
                  attribute={attribute}
                  store={store}
                />
              ))}
            </tbody>
          </FlushRightTable>
        )}
      </ArrayPagination>
    </LoadingOrErrorDisplay>
  );
};
