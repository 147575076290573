import { FormikProps } from "formik";
import {
  GetCafmConfigurationsBySiteUuidQuery,
  useGetCafmConfigurationsBySiteUuidQuery,
} from "../../../../../generated/admin";
import { SiteConfigFormData } from "../../SiteConfigFormData";
import { RequestManagementConnection } from "./RequestManagementConnection";
import {
  RequestManagementConnectionFormData,
  cafmProviderOptions,
} from "./RequestManagementConnectionAuxiliary";

interface Props {
  siteFormData: FormikProps<SiteConfigFormData>;
}

export const RequestManagementConnectionList: React.FC<Props> = ({
  siteFormData,
}) => {
  const { data, loading } = useGetCafmConfigurationsBySiteUuidQuery({
    variables: {
      siteUuid: siteFormData.values.destinationUuid,
    },
  });

  const mapToFormData = (
    data: GetCafmConfigurationsBySiteUuidQuery
  ): RequestManagementConnectionFormData[] => {
    return data?.reqMgt?.cafmConfigurationsBySiteUuid.map((x) => ({
      uuid: x.uuid,
      name: x.name,
      endpoint: x.endpoint,
      username: x.credentialsInfo.username,
      cafmProviderType: cafmProviderOptions.find((y) => x.type === y.value)!,
      isPasswordSet: x.credentialsInfo.isPasswordSet,
      isCallbackConnectionSet: x.credentialsInfo.isCallbackConnectionSet,
      simproSiteId: x.cafmProviderSettings?.simproSiteId,
      conceptEvolutionCreateMethodName:
        x.cafmProviderSettings?.conceptEvolutionCreateMethodName,
      conceptEvolutionUpdateMethodName:
        x.cafmProviderSettings?.conceptEvolutionUpdateMethodName,
      elogbooksPriorityName: x.cafmProviderSettings?.elogbooksPriorityName,
      elogbooksSiteName: x.cafmProviderSettings?.elogbooksSiteName,
      elogbooksServiceProviderName:
        x.cafmProviderSettings?.elogbooksServiceProviderName,
      maximoCreateMethodName: x.cafmProviderSettings?.maximoCreateMethodName,
      maximoUpdateMethodName: x.cafmProviderSettings?.maximoUpdateMethodName,
    }));
  };

  return (
    <div className="container-main">
      <h5>Existing connections</h5>
      {data &&
        !loading &&
        mapToFormData(data).map((x) => (
          <RequestManagementConnection
            key={x.uuid}
            siteUuid={siteFormData.values.destinationUuid}
            formData={x}
          ></RequestManagementConnection>
        ))}
    </div>
  );
};
