import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { CreateButton } from "../../../components/CreateButton";
import { useFormContext } from "react-hook-form";
import { UserFormData } from "./model/UserFormData";
import { UserFormContext } from "./context/UserFormContext";
import {
  useAssignUserToApartmentMutation,
  useDeleteUserAllSystemsMutation,
  useUpdateUserMutation,
} from "../../../generated/admin";
import { useUserFormSubmit } from "./hook/useUserFormSubmit";
import { DeleteButton } from "../../../components/DeleteButton";
import { EqDangerMessage, EqMessage } from "../../message/EqMessage";
import { UserContext } from "../UserContext";

interface UserFormHeaderProps {
  closeFn: () => void;
}

const listGroupItem = (success: boolean, text: string) => /* html */ `
  <li class="list-group-item list-group-item-${
    success ? "success" : "danger"
  }">${text} (${success ? "removed" : "error"})</li>
`;

/**
 * User form header.
 */
export const UserFormHeader: React.FC<UserFormHeaderProps> = ({ closeFn }) => {
  const { handleSubmit } = useFormContext<UserFormData>();
  const ctx = useContext(UserFormContext);
  const user = useContext(UserContext);
  const [mutation, { loading: saving }] = useUpdateUserMutation({});
  const [erase, { loading: erasing }] = useDeleteUserAllSystemsMutation({});
  const [assignUserToApartmentMutation, { loading: isSaving }] =
    useAssignUserToApartmentMutation({});
  const { onSubmit } = useUserFormSubmit(
    ctx.user,
    mutation,
    assignUserToApartmentMutation,
    closeFn
  );

  const currentUserIsAdmin = user?.roles.includes("ADMIN") ?? false;
  const displayDeleteButton =
    user?.activeDestination == null && currentUserIsAdmin;

  return (
    <div className="sticky-top bg-white border-bottom d-flex align-items-center p-2">
      <button
        type="button"
        className="close float-none"
        aria-label="Close"
        onClick={closeFn}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      {displayDeleteButton && (
        <DeleteButton
          className="ml-auto"
          onClick={async () => {
            if (ctx.user == null) {
              return;
            }
            const confirm = await EqDangerMessage({
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Delete User",
              title: "Are you sure?",
              html: "Deleting the user will remove their access and any historical records associated with their account.",
            });
            if (confirm.value !== true) {
              return;
            }
            const result = (
              await erase({
                variables: {
                  userUuid: ctx.user.uuid,
                },
              })
            ).data;
            const success =
              result?.eraseProfilePII.success &&
              result.adminDeleteSegmentationUser &&
              result.deleteAuthUser.success;
            const icon = success ? "success" : "error";
            EqMessage({
              icon,
              html: /* html*/ `
              <ul class="list-group">
                ${listGroupItem(
                  result?.eraseProfilePII.success ?? false,
                  "Profile"
                )}
                ${listGroupItem(
                  result?.deleteAuthUser.success ?? false,
                  "Auth0"
                )}
                ${listGroupItem(
                  result?.adminDeleteSegmentationUser ?? false,
                  "Segmentation"
                )}
              </ul>
              `,
            });
            closeFn();
          }}
        >
          {erasing ? (
            <span>
              <Spinner animation="grow" size="sm" /> Deleting...
            </span>
          ) : (
            "Delete"
          )}
        </DeleteButton>
      )}
      <CreateButton
        type="button"
        className={displayDeleteButton ? "ml-2" : "ml-auto"}
        disabled={saving || isSaving}
        onClick={handleSubmit(onSubmit)}
      >
        {saving || isSaving ? (
          <span>
            <Spinner animation="grow" size="sm" /> Saving...
          </span>
        ) : (
          "Save"
        )}
      </CreateButton>
    </div>
  );
};
