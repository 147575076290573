import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormikProps, useFormikContext } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { NewRequestManagementConnection } from "./RequestManagementConnection/NewRequestManagementConnection";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const Requests: React.FC<Props> = ({ methods }) => {
  const fm = useFormikContext<SiteConfigFormData>();
  if (methods.values.featureModules?.requests == null) {
    return null;
  }

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between pb-2 border-bottom">
        <h4 className="font-weight-normal">Request Management</h4>
        <Form.Check
          name="featureModules.requests.enabled"
          type="switch"
          label=""
          id="featureModules.requests.enabled"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            methods.setFieldValue(
              "featureModules.requests.enabled",
              e.target.checked
            );
          }}
          defaultChecked={methods.values.featureModules.requests.enabled}
        />
      </div>

      {fm.values.featureModules?.requests.enabled === true && (
        <Form.Group controlId="requestsEnableMenu" as={Row} className="mt-4">
          <Form.Label column md="3" lg="2">
            Enable End User Access
          </Form.Label>
          <Col className="pt-1">
            <div className="d-flex align-items-center">
              <Form.Check
                name="featureModules.requests.enableMenu"
                type="switch"
                label=""
                id="featureModules.requests.enableMenu"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setFieldValue(
                    "featureModules.requests.enableMenu",
                    e.target.checked
                  );
                }}
                defaultChecked={
                  methods.values.featureModules.requests.enableMenu
                }
              />
            </div>
            <Form.Text className="text-muted">
              Once enabled, a link to the module will be shown in the
              user-facing menu on both web and mobile. If disabled, end-users
              will still be able to access the module directly but the module
              will not show in their navigation.
            </Form.Text>
          </Col>
        </Form.Group>
      )}
      <NewRequestManagementConnection
        siteFormData={fm}
      ></NewRequestManagementConnection>
    </div>
  );
};
