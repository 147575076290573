import React, { useCallback } from "react";
import { UserAttribute } from "../model/UserAttribute";
import { TableButton, ActionButton } from "../../../../components/TableButton";
import { deleteCheck } from "../../../../components/deleteCheck";
import { UserAttributeStore } from "../model/UserAttributeStore";

interface Props {
  attribute: UserAttribute;
  store: UserAttributeStore;
  setEditing: (edit: boolean) => void;
}

export const NormalRow: React.FC<Props> = ({
  attribute,
  store,
  setEditing
}) => {
  const handleEdit = useCallback(() => setEditing(true), [setEditing]);
  const handleDelete = useCallback(async () => {
    await deleteCheck(async () => await store.deleteAttribute(attribute.uuid), {
      confirmButtonText: "Delete user attribute"
    });
  }, [store, attribute]);
  return (
    <tr>
      <td>{attribute.name}</td>
      <td>
        <TableButton onClick={handleEdit}>Edit</TableButton> |&nbsp;
        <ActionButton
          onClick={handleDelete}
          actionLabel="Deleting..."
          restLabel="Delete"
          isInAction={store.deleting === attribute.uuid}
        />
      </td>
    </tr>
  );
};
