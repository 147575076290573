import { Region } from "../config";
import { AdminClientRegion, ClientRegion } from "../generated/admin";

export const mapRegionToAdminClientRegion = (
  region: Region
): AdminClientRegion => {
  switch (region) {
    case "au":
    case "stage2":
      return AdminClientRegion.Au;
    case "ca":
      return AdminClientRegion.Ca;
    case "eu":
      return AdminClientRegion.Eu;
    case "us":
    case "staging":
      return AdminClientRegion.Us;
  }
};

export const mapRegionToClientRegion = (region: Region): ClientRegion => {
  switch (region) {
    case "au":
    case "stage2":
      return ClientRegion.Au;
    case "ca":
      return ClientRegion.Ca;
    case "eu":
      return ClientRegion.Eu;
    case "us":
    case "staging":
      return ClientRegion.Us;
  }
};
