import styled from "styled-components";
import { fontWeights } from "./text";

// @todo replace with css / bootstrap.
export const SubTitle = styled.h2.attrs({
  className: "mb-4 text-primary font-weight-light"
})`
  font-size: 21px;
  font-weight: ${fontWeights.regular};
`;
