import React from "react";
import { FaPlus } from "react-icons/fa";

/**
 * Add button.
 */
export const AddButton: React.FC<any> = ({ ...props }) => {
  const variant = props.variant ?? "text-primary";
  return (
    <FaPlus {...props} size="16px" className={`${variant} cursor-pointer`} />
  );
};
