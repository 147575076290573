import {
  BuildingListQuery,
  useBuildingListQuery
} from "../../../generated/admin";
import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  useMemo,
  useCallback
} from "react";
import { UserContext } from "../../user/UserContext";
import { useTableSearch } from "../../../hooks/useTableSearch";

type Building = NonNullable<BuildingListQuery["buildings"]["edges"][0]["node"]>;

interface State {
  readonly buildings: Building[];
  readonly loading: boolean;
  readonly error?: { message: string };
  readonly searchTerm: string;
  readonly filteredBuildings: Building[];
}

export interface BuildingStore extends State {
  addNode: (building: Building) => void;
  removeNode: (uuid: string) => void;
  setSearch: (searchTerm: string) => void;
}

const filterBuildings = (state: State): State => ({
  ...state,
  filteredBuildings: state.buildings.filter((b) => {
    return (
      b.name.toLocaleLowerCase().includes(state.searchTerm) ||
      b.destination?.name.toLowerCase().includes(state.searchTerm)
    );
  })
});

const transformBuildingQuery = ({
  loading,
  error,
  data
}: ReturnType<typeof useBuildingListQuery>): Partial<State> => {
  return {
    loading,
    error,
    buildings:
      data?.buildings.edges.flatMap((e) => (e.node != null ? [e.node] : [])) ??
      []
  };
};

/**
 * Generate BuildingStore object
 */
function useBuildingStoreFactory(): BuildingStore {
  const userCtx = useContext(UserContext);

  const {
    searchText,
    onTextSearch
  } = useTableSearch({ defaultSortFieldName: "name" });

  const [state, setState] = useState<State>({
    buildings: [],
    loading: false,
    searchTerm: searchText,
    filteredBuildings: []
  });

  const set = useCallback(
    (update: Partial<State>) => setState((state) => ({ ...state, ...update })),
    [setState]
  );

  const result = useBuildingListQuery({
    variables: {
      first: 100,
      destinationUuid: userCtx?.activeDestination?.uuid ?? null
    }
  });

  useEffect(() => {
    set(transformBuildingQuery(result));
  }, [result, set]);

  useEffect(() => {
    setState(filterBuildings);
  }, [state.buildings, state.searchTerm]);

  function addNode(building: Building) {
    setState((state) => ({
      ...state,
      buildings: [
        ...state.buildings.filter((b) => b.uuid !== building.uuid),
        {
          __typename: "Building",
          ...building
        }
      ]
    }));
  }

  function removeNode(uuid: string) {
    setState((state) => ({
      ...state,
      buildings: state.buildings.filter((b) => b.uuid !== uuid)
    }));
  }

  const setSearch = useCallback(
    (searchTerm: string) => {
      set({ searchTerm });
      onTextSearch(searchTerm);
    },
    [set, onTextSearch]
  );

  return useMemo(() => ({ ...state, addNode, removeNode, setSearch }), [
    state,
    setSearch
  ]);
}

const BuildingsContext = createContext<BuildingStore | null>(null);

/**
 * Retrieve initialized store throughout application
 */
export function useBuildingStore(): BuildingStore {
  const store = useContext(BuildingsContext);
  if (store == null) {
    throw new Error("Context used without provider");
  }
  return store;
}

/**
 * Set building store instance to be used for all components rendered below this
 */
export const BuildingStoreProvider: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  const store = useBuildingStoreFactory();
  return (
    <BuildingsContext.Provider value={store}>
      {children}
    </BuildingsContext.Provider>
  );
};
