import { useMemo } from "react";
import { useUserOptionsQuery } from "../../../../generated/admin";
import useDebounce from "../../../../hooks/useDebounce";
import { OptionType } from "../../../../model/OptionType";
import { useUser } from "../../../user/UserContext";

export function useEquiemOwnerOptions(
  initial?: OptionType,
  search?: string
): { options: Array<{ value: string; label: string }>; loading: boolean } {
  const user = useUser();
  const debouncedSearchTerm = useDebounce(search, 500);
  const usersResult = useUserOptionsQuery({
    variables: {
      page: { first: 20 },
      filter: {
        companyUuid: user.company.uuid,
        email: debouncedSearchTerm,
        name: debouncedSearchTerm,
      }
    },
    errorPolicy: "all"
  });

  const options = useMemo(() => {
    const op = usersResult.data?.users.edges
      .filter((u) => u.node?.uuid !== initial?.value)
      .flatMap((u) =>
        u.node != null && u.node.profile != null
          ? [{ value: u.node.uuid, label: `${u.node.profile.displayName} (${u.node.profile.email})` }]
          : []
      );
    return op ?? [];
  }, [usersResult.data, initial]);

  return {
    options: initial != null ? [...options, initial] : options,
    loading: usersResult.loading
  };
}
