import {
  useGroupsQuery,
  useUpsertSiteGroupsMutation,
} from "../../../../generated/admin";
import { useState, useMemo, useEffect, useCallback } from "react";
import { CatalogueItemsStore } from "../../../catalogue/model/CatalogueItemsStore";
import { CatalogueItemsState } from "../../../catalogue/model/CatalogueItemsState";
import {
  EqMessageSuccess,
  MessageFn,
  EqMessageError,
} from "../../../message/EqMessage";
import { ItemSelector } from "../../../catalogue/model/ItemSelector";
import { GroupStore } from "../model/GroupStore";

interface Props {
  store: GroupStore;
  closeModalFn: () => void;
  successMsgFn?: MessageFn;
  errorMsgFn?: MessageFn;
}

/**
 * Groups catalogue hook.
 */
export function useGroupsCatalogue({
  store,
  closeModalFn,
  successMsgFn = EqMessageSuccess,
  errorMsgFn = EqMessageError,
}: Props): CatalogueItemsStore {
  const [state, setState] = useState<CatalogueItemsState>({
    searchEnabled: true,
    searchPlaceholderText: "Search for groups...",
    loading: false,
    searchKeyword: "",
    items: null,
    hasMore: false,
  });

  const [mutation, { loading }] = useUpsertSiteGroupsMutation();

  const handler = useCallback((text: string) => {
    setState((cstate) => ({ ...cstate, searchKeyword: text }));
  }, []);

  const results = useGroupsQuery({ fetchPolicy: "network-only" });
  useEffect(() => {
    setState((cstate) => ({
      ...cstate,
      loading: results.loading,
      error: results.error,
      items: results.data?.groups ?? [],
      hasMore: false,
    }));
  }, [results]);

  return useMemo(
    () => ({
      ...state,
      items:
        state.searchKeyword !== ""
          ? state.items?.filter((x) => {
              return x.name.toLowerCase().search(state.searchKeyword) !== -1;
            }) ?? null
          : state.items,
      loadMore: () => {},
      clearSearch: () =>
        setState((cstate) => ({ ...cstate, searchKeyword: "" })),
      handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handler(e.currentTarget.value);
      },
      save: {
        loading,
        fn: async (selectedItems: ItemSelector) => {
          const selected = Object.values(selectedItems);
          if (selected.length === 0 || store.activeDestination == null) {
            return;
          }

          const result = await mutation({
            variables: {
              input: selected.map((group) => ({
                name: group.name,
                siteUuid: store.activeDestination,
                groupUuid: group.uuid,
              })),
            },
          });

          const success =
            result.data?.upsertSiteGroups.flatMap((r) => {
              return r.__typename === "SiteGroupSuccessResponse"
                ? [
                    {
                      uuid: r.siteGroup.uuid,
                      name: r.siteGroup.name,
                    },
                  ]
                : [];
            }) ?? [];

          const errors =
            result.data?.upsertSiteGroups.flatMap((r) => {
              return r.__typename === "FailureResponse" ? [r.reason] : [];
            }) ?? [];

          if (success.length === 0 && errors.length > 0) {
            errorMsgFn({
              text: `Failed to add group${
                selected.length > 1 ? "s" : ""
              }. Reason: ${errors.join(", ")}`,
            });
            return;
          }

          store.addGroups(success);
          closeModalFn();
          successMsgFn({
            text: `Group${selected.length > 1 ? "s" : ""} successfully added.`,
          });
        },
      },
    }),
    [
      closeModalFn,
      errorMsgFn,
      handler,
      loading,
      mutation,
      state,
      store,
      successMsgFn,
    ]
  );
}
