import styled from "styled-components";
import { colors } from "../../components/colors";

/**
 * Catalogue item.
 */
export const CatalogueItem = styled.div`
  color: ${colors.grey};
  cursor: pointer;
  padding: 0.5rem 1rem;

  &.active {
    background: ${colors.lightGrey};
  }

  &:hover,
  &.selected {
    background: ${colors.lightBlue};
    color: #fff;
  }
`;
