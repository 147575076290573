import { FieldAttributes, useField } from "formik";
import React, { useCallback } from "react";
import { InputGroup, Form } from "react-bootstrap";

interface InputWithCharCountProps {
  maxLength: number;
  placeholder?: string;
}

/**
 * Input with char count.
 */
export const FormikInputWithCharCount: React.FC<
  FieldAttributes<any> & InputWithCharCountProps
> = ({ maxLength, placeholder, ...props }) => {
  const [field, meta] = useField(props);

  const onChangeCharCount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length <= maxLength) {
        field.onChange(event);
      }
    },
    [field, maxLength]
  );
  const leftover = maxLength - field.value.length;

  return (
    <InputGroup>
      <Form.Control
        type="text"
        name={field.name}
        value={field.value}
        isInvalid={meta.error != null}
        onChange={onChangeCharCount}
        placeholder={placeholder}
      />
      <InputGroup.Append className="border-bottom border-placeholder pl-3 d-flex align-items-end pb-2 ml-0">
        <small className="text-muted">{leftover} characters remaining</small>
      </InputGroup.Append>
      {meta.error && (
        <Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      )}
    </InputGroup>
  );
};
