import React, { useContext } from "react";
import { PageHeading } from "../../../components/PageHeading";
import { Navigation } from "../Navigation";
import { Container, Col, Row } from "react-bootstrap";
import { GroupsListView } from "./GroupsListView";
import { GroupsStoreProvider, useGroupsStore } from "./store/useGroupsStore";
import { SubTitle } from "../../../components";
import { AddGroupForm } from "./AddGroupForm";
import { Divider } from "../../../components/Divider";
import { UserContext } from "../../user/UserContext";
import { GroupsCatalogue } from "./GroupsCatalogue";
import {
  SiteGroupsStoreProvider,
  useSiteGroupsStore,
} from "./store/useSiteGroupsStore";

const InternalEquiemAdmin: React.FC = () => {
  const store = useGroupsStore();

  return (
    <Container>
      <Row>
        <Col className="mt-n2">
          Groups can be assigned to user profiles and used for segmentation
          purposes. A user profile can have multiple groups assigned to it.
          Administrators are able to create groups, which form part of a
          catalogue that are available for all sites to use.
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col>
          <SubTitle>Manage Groups</SubTitle>
          <GroupsListView store={store} />
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col className="pb-5">
          <SubTitle>Add Groups</SubTitle>
          <AddGroupForm store={store} />
        </Col>
      </Row>
    </Container>
  );
};

const InternalSiteManager: React.FC = () => {
  const store = useSiteGroupsStore();

  return (
    <Container>
      <Row>
        <Col>
          Groups can be assigned to user profiles and can be used throughout
          other Equiem systems for segmentation purposes. A user profile can
          have multiple groups assigned to it. Administrators are able to create
          groups, which form part of a catalogue that are available for all
          sites to use.
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col>
          <GroupsCatalogue store={store} />
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubTitle>My Groups</SubTitle>
          <p>
            Groups displayed below are available to assign to user profiles at
            your site and can be used throughout other Equiem systems for
            segmentation purposes. You can add additional groups, unique to your
            site, using the form below this table.
          </p>
          <GroupsListView store={store} />
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col className="pb-5">
          <SubTitle>Add Groups</SubTitle>
          <p>
            Additional groups, unique to your site, can be added using the below
            form. These will be added to the list of groups in the above table.
          </p>
          <AddGroupForm store={store} />
        </Col>
      </Row>
    </Container>
  );
};

/**
 * Groups home.
 */
export const GroupsHome: React.FC = () => {
  const ctx = useContext(UserContext);

  return (
    <div>
      <PageHeading title="Groups" subHeading={<Navigation />}></PageHeading>
      {ctx?.activeDestination?.uuid ? (
        <SiteGroupsStoreProvider>
          <InternalSiteManager />
        </SiteGroupsStoreProvider>
      ) : (
        <GroupsStoreProvider>
          <InternalEquiemAdmin />
        </GroupsStoreProvider>
      )}
    </div>
  );
};
