import React, { useMemo, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { Region } from "../config";
import { SearchBar } from "./Searchbar";

type Props = {
  close?: () => void;
  region: Region;
};

const spacesUrl = (region: string) => {
  switch (region) {
    case "au":
      return "https://spaces.equiem.com.au/login";
    case "us":
      return "https://us.equiemspaces.com/login";
    case "eu":
      return "https://uk.equiemspaces.com/login";
    default:
      return `https://${region}.spaces.getequiem.com`;
  }
};

const requestManagerLink = (region: string) => {
  switch (region) {
    case "us":
      return [
        { name: "Request Manager", link: "https://us.requests.getequiem.com/" }
      ];
    case "eu":
      return [
        { name: "Request Manager", link: "https://uk.requests.getequiem.com/" }
      ];
    default:
      return [];
  }
};

const getApps = (
  region: string
): Array<{ name: string; active?: boolean; link: string }> => [
  {
    name: "Admin Panel",
    active: true,
    link: `https://${region}.admin.getequiem.com`
  },
  { name: "Iris", link: `https://iris.equiem.com.au` },
  { name: "Reporting", link: `https://${region}.reporting.getequiem.com` },
  ...requestManagerLink(region),
  { name: "Spaces", link: spacesUrl(region) },
  { name: "VMS", link: `https://${region}.vendor.getequiem.com` }
];

export const AppPicker: React.FC<Props> = ({ close, region }) => {
  const apps = getApps(region);
  const [search, setSearch] = useState("");
  const filtered = useMemo(
    () =>
      apps.filter((d) => d.name.toLowerCase().includes(search.toLowerCase())),
    [apps, search]
  );
  return (
    <div className="w-100 min-vh-100 bg-secondary text-muted text-center pb-5">
      {close != null && (
        <div className="d-flex flex-row-reverse">
          <div className="btn p-4 font-weight-bold">
            <FaTimes size="20px" className="cursor-pointer" onClick={close} />
          </div>
        </div>
      )}
      <div>
        <h2 className="text-primary font-weight-light mb-3">
          Which app would you like to access?
        </h2>
        <h6 className="text-primary font-weight-light mb-4">
          You can switch apps later by using the menu on the top right
        </h6>
      </div>

      <Row className="justify-content-center mb-3 row border-bottom pb-2">
        <div className="col-2">
          <SearchBar
            onChange={(e) => setSearch(e.currentTarget.value)}
            clear={() => setSearch("")}
            value={search}
            placeholder="Search for an app..."
          />
        </div>
      </Row>

      {filtered.map(({ name, link, active }) => (
        <div>
          <Button
            className={`${active === true ? "active" : "text-muted"}`}
            variant="link"
            href={link}
            target={"_blank"}
            aria-label={name}
          >
            {name}
          </Button>
        </div>
      ))}
    </div>
  );
};
