import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select, { StylesConfig } from "react-select";
import { OptionType } from "../../../../model/OptionType";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { DestinationStatus } from "../../../../generated/admin";
import { FormikContextType } from "formik";
import startCase from "lodash/startCase";

interface Props {
  methods: FormikContextType<SiteConfigFormData>;
}

const options = Object.values(DestinationStatus).flatMap((value) => {
  return value === DestinationStatus.Hidden
    ? []
    : [
        {
          label: startCase(value.toLowerCase()),
          value,
        },
      ];
});

const getColor = (selection: string) => {
  if (selection === DestinationStatus.Deactivated) {
    return "var(--deactivated)";
  }
  if (selection === DestinationStatus.Live) {
    return "var(--lime)";
  }

  return "#686868";
};

const styles: StylesConfig<OptionType> = {
  option: (styles, { data, isSelected, isFocused }) => {
    return {
      ...styles,
      color: getColor(data.value),
      fontWeight: 400,
      backgroundColor: isSelected ? "#ddd" : isFocused ? "#f2f2f2" : undefined,
    };
  },
  singleValue: (styles, { data }) => {
    return { ...styles, color: getColor(data.value), fontWeight: 400 };
  },
};

export const SiteStatusFormGroup = ({ methods }: Props) => {
  return (
    <>
      <Form.Group
        as={Row}
        className="site-status-select"
        controlId="siteStatus"
      >
        <Form.Label column md="3">
          Site status
        </Form.Label>
        <Col md="9" lg="6">
          <Select<OptionType>
            classNamePrefix="eq"
            options={options}
            value={options?.find(
              (option) =>
                (option.value as string) ===
                (methods.values.siteStatus as string)
            )}
            onChange={(option: any) =>
              methods.setFieldValue("siteStatus", option.value)
            }
            placeholder="Site status"
            styles={styles}
            aria-label="site-status-select"
            className={`react-select${
              methods.errors.siteStatus != null ? " is-invalid" : ""
            }`}
          />
          {methods.errors.siteStatus && (
            <Form.Control.Feedback type="invalid">
              {methods.errors.siteStatus}
            </Form.Control.Feedback>
          )}

          <Form.Text className="text-muted">
            ‘Live’ will display both the web and mobile apps to end users.
            ‘Deactivated’ will disable the site altogether and it won’t be
            accessible on either the web or mobile app.
          </Form.Text>
        </Col>
      </Form.Group>
    </>
  );
};
