import React, { useState } from "react";
import { Navbar, Dropdown } from "react-bootstrap";
import { Icon } from "./svgs/Icon";

export const TopMenu = (props: {
  email: string;
  name: string;
  avatar?: string;
  currentDestinationName?: string;
  switchSite: () => void;
  switchApp: () => void;
  logout: () => void;
}) => {
  const [show, setShow] = useState(false);
  return (
    <Navbar className="navbar-expand-lg bg-white">
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <span data-testid="active-sitename" className="text-primary">
            {props.currentDestinationName}
          </span>
        </Navbar.Text>
        <Dropdown show={show} alignRight>
          <Dropdown.Toggle
            className="bg-transparent border-0 text-dark shadow-none mt-2 mr-n4"
            id="dropdown-top"
            onClick={() => setShow(!show)}
            data-testid="dropdown-top"
            variant="light"
          >
            {props.avatar != null ? (
              <img src={props.avatar} alt="avatar" />
            ) : (
              <Icon />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className={"shadow bg-white rounded text-center"}
            style={{ right: "0", left: "unset" }}
          >
            <Dropdown.Header
              className={"text-muted mt-1 mb-n3"}
              style={{ fontSize: 16 }}
            >
              {props.name}
            </Dropdown.Header>
            <Dropdown.Header
              className={"text-muted mb-n1"}
              style={{ fontSize: 12 }}
            >
              {props.email}
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item
              className={"text-primary"}
              onClick={props.switchSite}
            >
              Switch site
            </Dropdown.Item>
            <Dropdown.Item className={"text-primary"} onClick={props.switchApp}>
              Switch app
            </Dropdown.Item>
            <Dropdown.Item className={"text-primary"} onClick={props.logout}>
              Log out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};
