import { useState } from "react";
import {
  useCreateAvatarUploadUrlMutation,
  AvatarImageType
} from "../../../../generated/admin";
import { EqMessageError } from "../../../message/EqMessage";
import { AxiosStatic } from "axios";

/**
 * User form image actions.
 */
export function useUserFormImageActions(
  existingPhoto: string,
  axios: AxiosStatic
) {
  const [uploading, setUploading] = useState(false);
  const [displayPic, setDisplayPic] = useState(existingPhoto);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [avatar, setAvatar] = useState("");
  const [mutation] = useCreateAvatarUploadUrlMutation();

  const onUploadProgress = (progress: { loaded?: number; total?: number }) => {
    if (progress.loaded == null || progress.total == null) {
      return;
    }

    setUploadProgress(Math.round((progress.loaded * 100) / progress.total));
  };

  const onChange = async (files: File[]) => {
    if (uploading) {
      return;
    }

    // Upload the image to s3.
    try {
      setUploading(true);

      if (files[0] == null) {
        throw new Error("No file found.");
      }

      if (files.length > 1) {
        throw new Error("Only support 1 image.");
      }

      let contentType: AvatarImageType;
      switch (files[0].type) {
        case "image/jpeg":
          contentType = AvatarImageType.Jpeg;
          break;
        case "image/png":
          contentType = AvatarImageType.Png;
          break;
        case "image/gif":
          contentType = AvatarImageType.Gif;
          break;
        default:
          throw new Error("Only jpg, png, gif formats are supported");
      }

      const createUrl = await mutation({
        variables: {
          input: {
            contentType,
            filename: files[0].name
          }
        }
      });
      if (
        createUrl.data == null ||
        createUrl.data.createAvatarUploadUrl == null
      ) {
        throw new Error("Fail creating upload url.");
      }

      setUploadProgress(0);
      const result = await axios.put(
        createUrl.data.createAvatarUploadUrl.signedUrl,
        files[0],
        {
          headers: { "Content-Type": files[0].type },
          onUploadProgress
        }
      );
      setUploadProgress(null);

      if (result.status === 200) {
        setDisplayPic(createUrl.data.createAvatarUploadUrl.imgixUrl);
        setAvatar(createUrl.data.createAvatarUploadUrl.imgixUrl);
      }
    } catch (e) {
      setUploadProgress(null);
      EqMessageError({ text: "Fail uploading image." });
    }

    setUploading(false);
  };

  return {
    uploadProgress,
    displayPic,
    setDisplayPic,
    uploading,
    avatar,
    onChange
  };
}
