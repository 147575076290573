import React from "react";
import { CompanyAttributeStore } from "../hook/useCompanyAttributesStore";
import { CompanyAttribute } from "../model/CompanyAttribute";
import { EditRow } from "./EditRow";
import { NormalRow } from "./NormalRow";

interface Props {
  attribute: CompanyAttribute;
  store: CompanyAttributeStore;
}

/**
 * Toggle edit.
 */
export const ToggleEdit: React.FC<Props> = ({ attribute, store }) => {
  const [editing, setEditing] = React.useState(false);

  return editing ? (
    <EditRow attribute={attribute} store={store} setEditing={setEditing} />
  ) : (
    <NormalRow attribute={attribute} store={store} setEditing={setEditing} />
  );
};
