import { useMemo } from "react";
import { useUserOptionsQuery } from "../../../generated/admin";
import useDebounce from "../../../hooks/useDebounce";
import { useUser } from "../../user/UserContext";
import { OptionType } from "../../../model/OptionType";

export function useUserOptions(
  initial?: OptionType,
  search?: string
): { options: Array<{ value: string; label: string }>; loading: boolean } {
  const user = useUser();
  const debouncedSearchTerm = useDebounce(search, 500);
  const usersResult = useUserOptionsQuery({
    variables: {
      page: { first: 20 },
      destinationUuid: user.activeDestination?.uuid,
      filter: {
        email: debouncedSearchTerm,
        name: debouncedSearchTerm
      }
    },
    errorPolicy: "all"
  });

  const options = useMemo(() => {
    const op = usersResult.data?.users.edges.flatMap((u) =>
      u.node != null && u.node.profile != null
        ? [{ value: u.node.uuid, label: u.node.profile.email }]
        : []
    );
    return op ?? [];
  }, [usersResult.data]);

  return {
    options: initial != null ? [...options, initial] : options,
    loading: usersResult.loading
  };
}
