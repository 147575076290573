import { PageHeading } from "../../../components/PageHeading";
import { useConfig } from "../../../providers/ConfigProvider";

export const UserInvites = () => {
  const config = useConfig();

  return (
    <>
      <PageHeading title="Invited Users"></PageHeading>
      <div className="alert alert-info">
        This feature is now available to be managed directly in{" "}
        <a href={config.eqOneUrl} target="_blank" rel="noreferrer">
          Equiem One
        </a>{" "}
        if you have the Property Manager or Workplace Manager roles assigned to
        your profile for this site.
      </div>
    </>
  );
};
