import React from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { TextFormGroup } from "../site-details/fields/TextFormGroup";
import { Formik, FormikErrors } from "formik";
import { PageHeading } from "../../../components/PageHeading";
import { AddressInput } from "../site-details/fields/address/AddressInput";
import { ClientNameFormGroup } from "../site-details/fields/ClientNameFormGroup";
import {
  useCreateSiteMutation,
  SiteListDocument,
} from "../../../generated/admin";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { RootRoutes } from "../../../RouterOutlet";
import { EqMessageError, EqDangerMessage } from "../../message/EqMessage";
import { CreateButton } from "../../../components/CreateButton";
import { useConfig } from "../../../providers/ConfigProvider";
import type { AddressInfo } from "../../../util/address";
import { validateAddress } from "../../../util/address";

type FormData = {
  siteName: string;
  client: string;
  addressInput: AddressInfo;
};

const initialValues: FormData = {
  siteName: "",
  client: "",
  addressInput: {
    address: "",
    postcode: "",
    city: "",
    country: "",
    latitude: 0,
    longitude: 0,
    state: "",
    streetName: "",
    timezone: "",
  },
};

export const CreateSite: React.FC = () => {
  const siteCtxHelper = useSiteContextHelper();
  const config = useConfig();
  const [createSite] = useCreateSiteMutation();
  return (
    <Formik<FormData>
      initialValues={initialValues}
      onSubmit={async (input) => {
        const confirm = await EqDangerMessage({
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Create Site",
          title: "Are you sure?",
          html: "Ensure all details are correct before creating the site. The <b>Site name</b> cannot be edited once it has been created as it will be used throughout reporting metrics.",
        });
        if (confirm.value !== true) {
          return;
        }
        const result = await createSite({
          refetchQueries: [
            {
              query: SiteListDocument,
              variables: {
                name: "",
                installation: config.installation,
              },
            },
          ],
          variables: {
            input: {
              client: input.client,
              name: input.siteName.trim(),
              address: input.addressInput.address,
              streetName: input.addressInput.streetName,
              postcode: input.addressInput.postcode,
              city: input.addressInput.city,
              state: input.addressInput.state,
              country: input.addressInput.country,
              timezone: input.addressInput.timezone,
              latitude: input.addressInput.latitude,
              longitude: input.addressInput.longitude,
              installation: config.installation,
              reportingRegion: config.reportingRegion,
            },
          },
        });
        if (
          result.data?.adminCreateDestination.__typename ===
          "DestinationSuccess"
        ) {
          siteCtxHelper.redirect(RootRoutes.SITES);
        } else if (
          result.data?.adminCreateDestination.__typename ===
          "DestinationFailure"
        ) {
          EqMessageError({
            text: result.data.adminCreateDestination.reason,
          });
        } else {
          EqMessageError({
            text: "An unexpected error occurred",
          });
        }
      }}
      validate={(values) => {
        const errors: FormikErrors<FormData> = {};
        if (values.client === "") {
          errors.client = "Required.";
        }
        if (values.siteName === "") {
          errors.siteName = "Required.";
        }
        if (!validateAddress(values.addressInput)) {
          errors.addressInput = {
            address: "Enter and select a valid address.",
          };
        }
        return errors;
      }}
    >
      {(methods) => (
        <>
          <PageHeading title="Create New Site" />
          <Container className="mb-5">
            <Row>
              <Col>
                <h2 className="mb-4 text-primary font-weight-light">
                  Site Details
                </h2>
              </Col>
            </Row>
            <ClientNameFormGroup methods={methods} />

            <TextFormGroup
              title={"Site name"}
              name={"siteName"}
              subText={
                "The site name is used across systems to identify the site."
              }
              error={
                methods.touched.siteName ? methods.errors.siteName : undefined
              }
            />
            <AddressInput methods={methods} />
            <Row className="justify-content-between mt-5">
              <Col md="7" lg="8"></Col>
              <Col className="mr-4" md="4" lg="3">
                <CreateButton
                  disabled={methods.isSubmitting}
                  onClick={methods.handleSubmit}
                >
                  {methods.isSubmitting ? (
                    <span>
                      <Spinner animation="grow" size="sm" /> Saving...
                    </span>
                  ) : (
                    "Create New Site"
                  )}
                </CreateButton>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Formik>
  );
};
