import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { useUser } from "../../../user/UserContext";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const Marketplace: React.FC<Props> = ({ methods }) => {
  const user = useUser();
  if (user.inSiteContext) {
    return null;
  }

  return (
    <ToggleContainer className="lg" title="Marketplace">
      <Form.Group as={Row} controlId="storeEnabled">
        <Col>
          <Form.Check
            type="switch"
            label="Enable marketplace module"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setFieldValue("storeEnabled", e.target.checked ?? false);
            }}
            defaultChecked={methods.values.storeEnabled}
          />
          <Form.Text className="text-muted">
            Once enabled, the marketplace will be available to end users on both
            web and mobile.
          </Form.Text>

          {methods.errors.storeEnabled && (
            <Form.Control.Feedback type="invalid">
              {methods.errors.storeEnabled}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>
    </ToggleContainer>
  );
};
