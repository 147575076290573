import { Formik, useFormikContext } from "formik";
import React, { FC, useContext, useEffect } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { CreateButton } from "../../../../components/CreateButton";
import {
  ProfileDetailsDocument,
  useSyncUserToEvolutionMutation,
  useToggleAndSyncEvolutionUserMutation,
  useUpdateUserEvolutionIdMutation,
} from "../../../../generated/admin";
import { useConfig } from "../../../../providers/ConfigProvider";
import { EqMessageError } from "../../../message/EqMessage";
import { TextFormGroup } from "../../../sites/site-details/fields/TextFormGroup";
import { UserFormContext } from "../context/UserFormContext";

export const EvolutionPermission = () => {
  const ctx = useContext(UserFormContext);
  const allowed = ctx.user?.integrationsV2?.evolution?.allowed ?? false;
  const [toggle, { loading }] = useToggleAndSyncEvolutionUserMutation();
  const [sync, { loading: syncing }] = useSyncUserToEvolutionMutation();
  const [setId] = useUpdateUserEvolutionIdMutation();
  const accountId = ctx.user?.integrationsV2?.evolution?.accounts
    .map((a) => a.id)
    .pop();

  const UpdateAccountId: FC<{ accountId?: number }> = ({ accountId }) => {
    const formik = useFormikContext<{ accountId?: number }>();
    useEffect(() => {
      if (accountId !== formik.values.accountId) {
        formik.setFieldValue("accountId", accountId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);
    return null;
  };

  const config = useConfig();
  if ((ctx.user?.integrationsV2?.evolution?.accounts.length ?? 0) > 1) {
    return <p>Multi database users currently unsupported</p>;
  }
  if (ctx.activeSite == null) {
    return null;
  }

  return (
    <Formik<{ accountId?: number }>
      initialValues={{
        accountId: ctx.user?.integrationsV2?.evolution?.accounts
          .map((a) => a.id)
          .pop(),
      }}
      onSubmit={async (values) => {
        if (
          values.accountId == null ||
          ctx.activeSite == null ||
          ctx.user == null
        ) {
          return;
        }
        const result = await setId({
          refetchQueries: [
            {
              query: ProfileDetailsDocument,
              variables: {
                uuid: ctx.user.uuid,
                installation: config.installation,
              },
            },
          ],
          variables: {
            accountId: Number(values.accountId),
            userUuid: ctx.user.uuid,
            destinationUuid: ctx.activeSite,
          },
        });
        if (!result.data?.updateUserEvolutionId.success) {
          EqMessageError({
            text: `An unexpected error occurred ${result.data?.updateUserEvolutionId.message}`,
          });
        }
      }}
    >
      {(methods) => (
        <>
          <UpdateAccountId accountId={accountId} />
          <Row>
            <Col>
              <h5 className="mb-4">JLL Evolution</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="evolution-access">
                {loading ? (
                  <>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </>
                ) : (
                  <Form.Check
                    type="checkbox"
                    label={"Access Evolution"}
                    checked={allowed}
                    disabled={loading}
                    onChange={async (
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      if (ctx.user == null || loading) {
                        return;
                      }
                      const refetchQueries = [
                        {
                          query: ProfileDetailsDocument,
                          variables: {
                            uuid: ctx.user.uuid,
                            installation: config.installation,
                          },
                        },
                      ];
                      const result = await toggle({
                        refetchQueries,
                        variables: {
                          enabled: !allowed,
                          destinationUuid: ctx.activeSite,
                          userUuid: ctx.user.uuid,
                        },
                      });

                      if (!result.data?.toggleAndSyncEvolutionUser.success) {
                        EqMessageError({
                          text: `An error occurred: ${result.data?.toggleAndSyncEvolutionUser.message}`,
                        });
                      }
                    }}
                  />
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <TextFormGroup
                title={"Account ID"}
                name={"accountId"}
                handleChange={methods.handleChange}
                value={methods.values.accountId}
                error={methods.errors.accountId}
              />
            </Col>
            <Col xs="3">
              <CreateButton
                className="mr-4 float-right"
                disabled={methods.isSubmitting}
                onClick={methods.handleSubmit}
              >
                {methods.isSubmitting ? (
                  <span>
                    <Spinner animation="grow" size="sm" /> Saving...
                  </span>
                ) : (
                  "Save"
                )}
              </CreateButton>
            </Col>
          </Row>
          <Row>
            {allowed && (
              <Col>
                <Button
                  onClick={async () => {
                    if (ctx.user == null || loading) {
                      return;
                    }
                    const result = await sync({
                      variables: {
                        userUuid: ctx.user.uuid,
                        destinationUuid: ctx.activeSite,
                      },
                    });
                    if (!result.data?.syncUserToEvolution.success) {
                      EqMessageError({
                        text: `An unexpected error occurred ${result.data?.syncUserToEvolution.message}`,
                      });
                    }
                  }}
                >
                  {syncing ? "Syncing..." : "Sync"}
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
    </Formik>
  );
};
