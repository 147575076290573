import React from "react";
import { IndustryStore } from "../hook/useIndustryStore";
import { Industry } from "../model/Industry";
import { EditRow } from "./EditRow";
import { NormalRow } from "./NormalRow";

interface Props {
  industry: Industry;
  store: IndustryStore;
}

/**
 * Toggle edit.
 */
export const ToggleEdit: React.FC<Props> = ({ industry, store }) => {
  const [editing, setEditing] = React.useState(false);

  return editing ? (
    <EditRow industry={industry} store={store} setEditing={setEditing} />
  ) : (
    <NormalRow industry={industry} store={store} setEditing={setEditing} />
  );
};
