import { SyncBuildingInput } from "../../../generated/admin";
import { validateAddress } from "../../../util/address";

export type BuildingFormErrors = {
  [key in keyof SyncBuildingInput]?: string | null;
};

export function buildingFormErrors(
  state: SyncBuildingInput
): BuildingFormErrors {
  return {
    name:
      state.name === "" || state.name == null
        ? "Required."
        : state.name.length > 50
        ? "Name exceeds the 50 character limit."
        : null,
    destinationUuid:
      state.destinationUuid === "" || state.destinationUuid == null
        ? "Required."
        : null,
    area:
      state.area > 100000000 || state.area < 0
        ? "Area must be between 0 and 100,000,000."
        : !Number.isInteger(state.area)
        ? "Area must be an integer."
        : null,
    occupants:
      state.occupants > 100000000 || state.occupants < 0
        ? "Occupants must be between 0 and 100,000,000."
        : !Number.isInteger(state.occupants)
        ? "Occupants must be an integer."
        : null,
    buildingAddress:
      state.buildingAddress != null && !validateAddress(state.buildingAddress)
        ? "Enter and select a valid address."
        : null,
  };
}
