import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  useSiteGroupsQuery,
  useUpsertSiteGroupsMutation,
  useDeleteSiteGroupMutation,
  useUpsertSiteGroupMutation,
  SiteGroupsDocument,
} from "../../../../generated/admin";
import {
  EqMessageError,
  EqMessageSuccess,
  MessageFn,
} from "../../../message/EqMessage";
import { UserContext } from "../../../user/UserContext";
import { Group } from "../model/Group";
import { GroupContext } from "../GroupContext";
import { GroupStore } from "../model/GroupStore";
import { GroupState } from "../model/GroupState";

export const groupsSort = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });

export function useSiteGroupsStoreFactory(
  activeDestination: string,
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
): GroupStore {
  const [state, setState] = useState<GroupState>({
    groups: [],
    saveErrors: [],
    loading: false,
    newGroupName: "",
    groupsToAdd: [],
    saving: false,
    deleting: "",
    sortedGroups: [],
    activeDestination,
  });

  const results = useSiteGroupsQuery({
    variables: { destinationUuid: activeDestination },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: results.loading,
      initalLoadError: results.error,
      groups: results.data?.siteGroups ?? [],
    }));
  }, [results]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      sortedGroups: [...state.groups].sort(groupsSort),
    }));
  }, [state.groups]);

  const [deleteMutation] = useDeleteSiteGroupMutation();

  const [groupsUpsertMutation, upsertSiteGroupsResult] =
    useUpsertSiteGroupsMutation();
  useEffect(() => {
    setState((state) => ({
      ...state,
      saving: upsertSiteGroupsResult.loading,
    }));
  }, [upsertSiteGroupsResult.loading]);

  const [upsertGroupSiteMutation, upsertSiteGroupResult] =
    useUpsertSiteGroupMutation();
  useEffect(() => {
    setState((state) => ({
      ...state,
      saving: upsertSiteGroupResult.loading,
    }));
  }, [upsertSiteGroupResult.loading]);

  return useMemo(() => {
    const refetchQueries = [
      {
        query: SiteGroupsDocument,
        variables: {
          destinationUuid: activeDestination,
        },
      },
    ];

    const updateGroup = (uuid: string, name: string) => {
      setState((state) => ({
        ...state,
        groups: state.groups.map((l) => {
          if (l.uuid === uuid) {
            return {
              ...l,
              name,
            }
          }
          return l;
        }),
      }));
    };

    return {
      ...state,
      groupsSort,
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const newGroupName = event.target.value;
        setState((state) => ({
          ...state,
          newGroupName,
        }));
      },
      addGroup: () => {
        if (state.newGroupName === "") {
          errorMsgFn({ text: "Please click on add before saving." });
          return;
        }
        if (state.groupsToAdd.map((l) => l.name).includes(state.newGroupName)) {
          errorMsgFn({ text: "Cannot create duplicate group name." });
          return;
        }
        setState((state) => ({
          ...state,
          groupsToAdd: [...state.groupsToAdd, { name: state.newGroupName }],
          newGroupName: "",
        }));
      },
      addGroups: (newGroups: Group[]) => {
        setState((state) => ({
          ...state,
          groups: [...state.groups, ...newGroups],
        }));
      },
      saveGroup: async (uuid: string, name: string): Promise<boolean> => {
        const result = await upsertGroupSiteMutation({
          variables: { uuid, name, siteUuid: activeDestination },
        });
        if (
          result.data?.upsertSiteGroup.__typename === "SiteGroupSuccessResponse"
        ) {
          updateGroup(uuid, name);
          await EqMessageSuccess({ text: "Group name updated." });
        } else {
          await EqMessageError({
            text:
              result.data?.upsertSiteGroup.__typename === "FailureResponse"
                ? result.data.upsertSiteGroup.reason
                : "There was an error updating the group.",
          });
        }

        return (
          result.data?.upsertSiteGroup.__typename === "SiteGroupSuccessResponse"
        );
      },
      saveGroups: async () => {
        if (state.groupsToAdd.length === 0) {
          errorMsgFn({
            text: "Please click on add before saving.",
          });
          return;
        }
        const result = await groupsUpsertMutation({
          variables: {
            input: state.groupsToAdd.map((r) => ({
              ...r,
              siteUuid: activeDestination,
            })),
          },
          refetchQueries,
        });

        const addedGroups: Group[] =
          result.data?.upsertSiteGroups.flatMap((attr) => {
            if (attr.__typename === "SiteGroupSuccessResponse") {
              return [
                {
                  uuid: attr.siteGroup.uuid,
                  name: attr.siteGroup.name,
                },
              ];
            }

            return [];
          }) ?? [];

        const errors =
          result.data?.upsertSiteGroups.flatMap((attr) => {
            if (attr.__typename === "FailureResponse") {
              return [attr.reason];
            }
            return [];
          }) ?? [];

        setState((state) => ({
          ...state,
          groups: [...state.groups, ...addedGroups],
          saveErrors: errors.map((message) => ({
            message,
          })),
          groupsToAdd: [],
        }));

        if (errors.length > 0) {
          errorMsgFn({
            html: errors.join("<br/>"),
          });
        }
        if (addedGroups.length > 0) {
          const label = addedGroups.length > 1 ? "Groups" : "Group";
          successMsgFn({ text: `${label} created.` });
        }
      },
      deleteGroup: async (uuid: string) => {
        setState((state) => ({ ...state, deleting: uuid }));

        const result = await deleteMutation({
          variables: { uuid },
          refetchQueries,
        });
        setState((state) => ({ ...state, deleting: "" }));

        if (result.data == null) {
          errorMsgFn({
            text: "Something went wrong, please refresh the page and try again.",
          });
          return;
        }

        if (result.data.deleteSiteGroup.__typename === "FailureResponse") {
          errorMsgFn({ text: result.data.deleteSiteGroup.reason });
          return;
        }

        successMsgFn({ text: "Group has been deleted!" });
        setState((state) => ({
          ...state,
          groups: state.groups.filter((l) => l.uuid !== uuid),
        }));
      },
      removeGroup: (name: string) => {
        setState((state) => ({
          ...state,
          groupsToAdd: state.groupsToAdd.filter((l) => l.name !== name),
        }));
      },
      updateGroup,
      updateLocalGroup:
        (original: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
          setState((state) => ({
            ...state,
            groupsToAdd: state.groupsToAdd.map((l) =>
              l.name === original ? { name: e.target.value } : l
            ),
          }));
        },
    };
  }, [
    state,
    errorMsgFn,
    upsertGroupSiteMutation,
    activeDestination,
    groupsUpsertMutation,
    successMsgFn,
    deleteMutation,
  ]);
}

export function useSiteGroupsStore(): GroupStore {
  const store = useContext(GroupContext);
  if (store == null) {
    throw Error("Site groups store context not initialized.");
  }
  return store;
}

export const SiteGroupsStoreProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const ctx = useContext(UserContext);
  if (ctx?.activeDestination?.uuid == null) {
    throw Error("Missing active destination");
  }
  const store = useSiteGroupsStoreFactory(ctx.activeDestination.uuid);

  return (
    <GroupContext.Provider value={store}>{children}</GroupContext.Provider>
  );
};
