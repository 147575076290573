import type { ComponentProps } from "react";
import React, { useState } from "react";
import {
  LoadScript,
  Autocomplete as WithAutocomplete,
  AutocompleteProps,
} from "@react-google-maps/api";
import { Form } from "react-bootstrap";

import {
  googleMapsApiKey,
  formatPlaceResult,
  AddressInfo,
} from "../util/address";

interface Props {
  name: string;
  value: AddressInfo;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  onChange(addressInfo: AddressInfo): void;
}

interface GoogleMapsProps {
  onLoad: AutocompleteProps["onLoad"];
  onPlaceChanged: AutocompleteProps["onPlaceChanged"];
  children: AutocompleteProps["children"];
}

type Autocomplete = google.maps.places.Autocomplete;
type Libraries = NonNullable<ComponentProps<typeof LoadScript>["libraries"]>;

const libraries: Libraries = ["places"];

const WithGoogleMaps: React.FC<GoogleMapsProps> = ({
  onLoad,
  onPlaceChanged,
  children,
}) => {
  return googleMapsApiKey == null ? (
    <>{children}</>
  ) : (
    <LoadScript libraries={libraries} googleMapsApiKey={googleMapsApiKey}>
      <WithAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        {children}
      </WithAutocomplete>
    </LoadScript>
  );
};

export const AddressInputField: React.FC<Props> = ({
  name,
  value,
  disabled,
  touched,
  error,
  onChange,
}) => {
  const [autocomplete, setAutocomplete] = useState<null | Autocomplete>(null);

  const onPlaceChanged = async () => {
    const place = autocomplete?.getPlace();
    if (place) {
      onChange(await formatPlaceResult(place, value));
    }
  };

  const onRawValueChanged = (address: string) => {
    onChange({ ...value, address });
  };

  return (
    <WithGoogleMaps onLoad={setAutocomplete} onPlaceChanged={onPlaceChanged}>
      <>
        <Form.Control
          type="text"
          name={name}
          onChange={(e) => onRawValueChanged(e.target.value)}
          onBlur={() => onRawValueChanged(value.address)}
          disabled={disabled}
          value={value.address}
          isInvalid={touched && error != null}
          style={disabled ? { background: "#f2f2f2" } : {}}
        />
        {touched && error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
      </>
    </WithGoogleMaps>
  );
};
