import { Routes, Route } from "react-router-dom";
import React from "react";
import { GroupsHome } from "./roles/GroupsHome";
import { IndustriesHome } from "./industries/IndustriesHome";
import { AttributesHome } from "./AttributesHome";
import { ClientsHome } from "./clients/ClientsHome";

export const TaxonomyModule = () => {
  return (
    <Routes>
      <Route path={`/groups`} element={<GroupsHome />} />
      <Route path={`/industries`} element={<IndustriesHome />} />
      <Route path={`/clients`} element={<ClientsHome />} />
      <Route path="*" element={<AttributesHome />} />
    </Routes>
  );
};
