import { useResetPasswordMutation } from "../../../../generated/admin";
import { useContext } from "react";
import { UserFormContext } from "../context/UserFormContext";
import { UserContext } from "../../UserContext";

export function useUserResetPasswordAction() {
  const ctx = useContext(UserFormContext);
  const userCtx = useContext(UserContext);
  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();

  const resetPassword = async () =>
    resetPasswordMutation({
      variables: {
        email: ctx.user?.profile?.email,
        siteUuid: userCtx?.activeDestination?.uuid,
        triggeredBy: userCtx?.uuid
      }
    });
  return {
    resetPassword,
    loading
  };
}
