import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { FormikInputWithCharCount } from "../../../../components/FormikInputWithCharCount";
import { useUser } from "../../../user/UserContext";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const Marketplace: React.FC<Props> = ({ methods }) => {
  const user = useUser();
  if (user.inSiteContext && !methods.values.storeEnabled) {
    return null;
  }

  return (
    <ToggleContainer className="lg" title="Marketplace">
      <Form.Group as={Row} controlId="storeWebEnabled">
        <Form.Label column md="3">
          Enable marketplace
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Check
            type="switch"
            label=""
            id="storeWebEnabled"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setFieldValue("storeWebEnabled", e.target.checked);
            }}
            disabled={!methods.values.storeEnabled}
            defaultChecked={
              methods.values.storeEnabled && methods.values.storeWebEnabled
            }
          />

          {methods.errors.storeWebEnabled && (
            <Form.Control.Feedback type="invalid">
              {methods.errors.storeWebEnabled}
            </Form.Control.Feedback>
          )}
          {!methods.values.storeEnabled && (
            <Form.Text className="text-muted">
              Marketplace on web can only be enabled once it is enabled in the
              general site settings.
            </Form.Text>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="marketplaceTitle">
        <Form.Label column md="3">
          Page title
        </Form.Label>
        <Col md="9" lg="6">
          <FormikInputWithCharCount name="marketplaceTitle" maxLength={20} />
          <Form.Text className="text-muted">
            This title is shown for both the navigation and the page title.
          </Form.Text>
        </Col>
      </Form.Group>
    </ToggleContainer>
  );
};
