import React, { useMemo, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { SearchBar } from "./Searchbar";

type Dest = { uuid: string; name: string };

type Props = {
  close?: () => void;
  showAllSelection: boolean;
  destinations: Array<Dest>;
  activeDestination?: Dest;
  setActiveDestination: (uuid?: string) => void;
};

export const SitePicker: React.FC<Props> = ({
  close,
  activeDestination,
  destinations,
  showAllSelection,
  setActiveDestination
}) => {
  const [search, setSearch] = useState("");
  const filtered = useMemo(
    () =>
      destinations.filter((d) =>
        d.name.toLowerCase().includes(search.toLowerCase())
      ),
    [destinations, search]
  );
  return (
    <div className="w-100 min-vh-100 bg-secondary text-muted text-center pb-5">
      {close != null && (
        <div className="d-flex flex-row-reverse">
          <div className="btn p-4 font-weight-bold">
            <FaTimes size="20px" className="cursor-pointer" onClick={close} />
          </div>
        </div>
      )}
      <div>
        <h2 className="text-primary font-weight-light mb-3">
          Which site would you like to view?
        </h2>
        <h6 className="text-primary font-weight-light mb-4">
          You can switch sites later by using the menu on the top right
        </h6>
      </div>

      <Row className="justify-content-center mb-3 row border-bottom pb-2">
        <div className="col-2">
          <SearchBar
            onChange={(e) => setSearch(e.currentTarget.value)}
            clear={() => setSearch("")}
            value={search}
            placeholder="Search for a site..."
          />
        </div>
      </Row>

      {showAllSelection && (
        <div>
          <Button
            variant="link"
            className={`${
              activeDestination?.uuid == null ? "active" : "text-muted"
            }`}
            onClick={() => setActiveDestination()}
            aria-label="All sites"
          >
            All sites
          </Button>
        </div>
      )}
      {filtered.map((destination) => (
        <div key={destination.uuid}>
          <DestinationButton
            destination={destination}
            activeDestination={activeDestination}
            setActiveDestination={setActiveDestination}
          />
        </div>
      ))}
    </div>
  );
};

const DestinationButton = ({
  destination,
  activeDestination,
  setActiveDestination
}: {
  destination: Dest;
  activeDestination?: Dest;
  setActiveDestination: (uuid?: string) => void;
}) => {
  const active = destination.uuid === activeDestination?.uuid;
  return (
    <div>
      <Button
        className={`${active === true ? "active" : "text-muted"}`}
        variant="link"
        onClick={() => setActiveDestination(destination.uuid)}
        aria-label={destination.name}
      >
        {destination.name}
      </Button>
    </div>
  );
};
