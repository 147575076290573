import React, { useMemo, useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { LeftArrow } from "../svgs/LeftArrow";
import { RightArrow } from "../svgs/RightArrow";
import { colors } from "./colors";
import { CapsuleButton } from "./CapsuleButton";
import { useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import { Location } from "history";
import queryString from "query-string";
import { toBoolParam, toIntParam } from "../util/queryParamTransform";

interface Props {
  range: {
    start: number;
    end: number;
    currentPage: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  pageSize: number;
  total: number;
  next: () => void;
  previous: () => void;
  page: (val: number) => void;
  setPageSize: (size: number) => void;
  pageSizeOptions: number[];
  pagesToDisplay: {
    prefix: string;
    pages: number[];
    suffix: string;
  };
}

interface ArrayPaginationProps<T> {
  items: T[];
  pageSize?: number;
  serverLoading?: {
    total: number;
    loadMore: () => void;
    hasNextPage: boolean;
    loading: boolean;
  };
  topLeftContent?: JSX.Element;
  urlSearchParams?: boolean;
  children: (result: T[]) => JSX.Element | null;
}

const defaultPageSizes = [10, 20, 50, 100];

export function useArrayPagination<T>(
  props: ArrayPaginationProps<T>,
  history: NavigateFunction,
  location: Location
) {
  const { urlSearchParams } = props;
  const searchParamsObject = queryString.parse(location.search);
  const {
    start,
    end,
    currentPage,
    hasNextPage,
    hasPreviousPage,
    pageSize: urlPagesize,
  } = searchParamsObject;

  const defaultPagesize = toIntParam(urlPagesize) || props.pageSize;
  const [pageSize, setPageSize] = React.useState(defaultPagesize ?? 10);

  const { total, pages } = useMemo(() => {
    const total = props.serverLoading?.total ?? props.items.length;
    const pageCount = Math.ceil(total / pageSize);
    const pages = [
      ...(Array(pageCount < 1 ? 1 : pageCount).keys() as unknown as number[]),
    ];
    return { total, pages };
  }, [pageSize, props]);

  const defaultRange = {
    start: toIntParam(start) || 0,
    end: toIntParam(end) || total > pageSize ? pageSize : total,
    currentPage: toIntParam(currentPage) || 0,
    hasNextPage: toBoolParam(hasNextPage) || total > pageSize,
    hasPreviousPage: toBoolParam(hasPreviousPage) || false,
  };

  const [range, setRange] = React.useState(defaultRange);
  useEffect(() => {
    setRange((range) => {
      const end = range.start + pageSize;
      return {
        ...range,
        start: range.start,
        end: end > total ? total : end,
        currentPage: range.currentPage,
        hasNextPage: end < total,
        hasPreviousPage: range.start > 0,
      };
    });
  }, [props.items, pages, total, pageSize]);
  const getEnd = useCallback(
    (end: number) => (end > total ? total : end),
    [total]
  );

  const setRangeUrl = useCallback(
    (data: any) => {
      if (!urlSearchParams) return;
      const paramsObject = {
        ...searchParamsObject,
        ...data,
      };
      const searchParams = new URLSearchParams(paramsObject).toString();
      history({
        search: searchParams,
      }, {
        replace: true,
      });
    },
    [history, searchParamsObject, urlSearchParams]
  );

  const load = useCallback(() => {
    if (props.serverLoading != null) {
      if (props.serverLoading.loading) {
        return;
      }
      if (range.end > props.items.length && props.serverLoading.hasNextPage) {
        props.serverLoading.loadMore();
      }
    }
  }, [props, range]);

  load();

  return useMemo(
    () => ({
      range,
      next: () => {
        if (!range.hasNextPage) {
          return;
        }
        const end = getEnd(range.end + pageSize);
        if (props.serverLoading?.loading === true && end > props.items.length) {
          return;
        }

        const newRange = {
          ...range,
          start: range.start + pageSize,
          end,
          currentPage: range.currentPage + 1,
          hasNextPage: end < total,
          hasPreviousPage: true,
        };

        setRangeUrl(newRange);
        setRange(newRange);
      },
      previous: () => {
        if (!range.hasPreviousPage) {
          return;
        }
        const start = range.start - pageSize;
        const newRange = {
          start,
          end: start + pageSize,
          currentPage: range.currentPage - 1,
          hasNextPage: true,
          hasPreviousPage: start > 0,
        };
        setRangeUrl(newRange);
        setRange({
          ...range,
          ...newRange,
        });
      },
      total,
      page: (p: number) => {
        const start = p * pageSize;
        const end = getEnd(p * pageSize + pageSize);
        if (props.serverLoading?.loading === true && end > props.items.length) {
          return;
        }
        const newRange = {
          start,
          end,
          currentPage: p,
          hasNextPage: end < total,
          hasPreviousPage: p > 0,
        };
        setRangeUrl(newRange);
        setRange({
          ...range,
          ...newRange,
        });
      },
      setPageSize: (p: number) => {
        setPageSize(p);
        const newRange = {
          start: 0,
          currentPage: 0,
        };
        setRangeUrl({
          ...newRange,
          pageSize: p,
        });
        setRange((range) => ({
          ...range,
          ...newRange,
        }));
      },
      pageSize,
      currentPage: props.items.slice(range.start, range.end),
      pageSizeOptions:
        props.pageSize == null || defaultPageSizes.includes(props.pageSize)
          ? defaultPageSizes
          : [props.pageSize, ...defaultPageSizes].sort((a, b) => a - b),
      pagesToDisplay: {
        prefix:
          pages.length > PageLinkCount && range.currentPage > 0 ? "..." : "",
        pages:
          pages.length > PageLinkCount
            ? pages.slice(range.currentPage, range.currentPage + PageLinkCount)
            : pages,
        suffix: pages.length > range.currentPage + PageLinkCount ? "..." : "",
      },
    }),
    [getEnd, pageSize, props, range, total, pages, setPageSize, setRangeUrl]
  );
}

export const ArrayPagination: <T>(
  props: ArrayPaginationProps<T>
) => JSX.Element = (props) => {
  const history = useNavigate();
  const location = useLocation();

  const {
    total,
    range,
    pageSize,
    next,
    previous,
    page,
    setPageSize,
    currentPage,
    pageSizeOptions,
    pagesToDisplay,
  } = useArrayPagination(props, history, location);

  return (
    <span>
      <div className="d-flex py-2 align-items-center">
        <div className="pagination-left">{props.topLeftContent}</div>
        <div className="ml-auto pagination-right">
          <Pagination
            range={range}
            total={total}
            pageSize={pageSize}
            next={next}
            previous={previous}
            page={page}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
            pagesToDisplay={pagesToDisplay}
          />
        </div>
      </div>
      {props.children(currentPage)}
    </span>
  );
};

const CustomToggle = React.forwardRef<
  HTMLSpanElement,
  React.DOMAttributes<HTMLSpanElement>
>(({ children, onClick }, ref) => {
  const click = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      if (onClick != null) {
        onClick(e);
      }
    },
    [onClick]
  );
  return (
    <span ref={ref} onClick={click}>
      {children}
    </span>
  );
});

const PageLinkCount = 5;

export const Pagination = ({
  range: { start, end, currentPage, hasNextPage, hasPreviousPage },
  pageSize,
  total,
  next,
  previous,
  page,
  pagesToDisplay,
  setPageSize,
  pageSizeOptions,
}: Props) => {
  const previousStyle = useMemo(
    () =>
      hasPreviousPage
        ? {}
        : { opacity: 0.5, color: colors.grey, cursor: "default" },
    [hasPreviousPage]
  );

  const [show, setShow] = useState(false);

  const setAndClose = useCallback(
    (size: number) => {
      setShow(false);
      setPageSize(size);
    },
    [setPageSize]
  );

  const nextStyle = useMemo(
    () =>
      hasNextPage
        ? {}
        : { opacity: 0.5, color: colors.grey, cursor: "default" },
    [hasNextPage]
  );

  return (
    <>
      <Dropdown
        data-cy="pagination-dropdown"
        show={show}
        className="d-inline-block mr-2"
      >
        <Dropdown.Toggle
          onClick={() => setShow(!show)}
          as={CustomToggle}
          id="dropdown-custom-components"
        >
          <CapsuleButton className="mr-1">
            {start + 1} - {end}
          </CapsuleButton>
          of {total}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {pageSizeOptions.map((option) => (
            <PageSizeDropdown
              key={"PageSizeDropdown" + option}
              setPageSize={setAndClose}
              option={option}
              pageSize={pageSize}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <span
        className="cursor-pointer user-select-none mr-2"
        style={previousStyle}
        onClick={previous}
      >
        <LeftArrow /> {"  Prev  "}
      </span>

      {pagesToDisplay.prefix && (
        <span className="mr-2">{pagesToDisplay.prefix}</span>
      )}

      {pagesToDisplay.pages.map((p) => (
        <Page key={"page" + p} currentPage={currentPage} p={p} page={page} />
      ))}

      {pagesToDisplay.suffix && (
        <span className="mr-2">{pagesToDisplay.suffix}</span>
      )}

      <span
        className="cursor-pointer user-select-none"
        style={nextStyle}
        onClick={next}
      >
        {"  Next  "} <RightArrow />
      </span>
    </>
  );
};

const Page = ({
  p,
  currentPage,
  page,
}: {
  p: number;
  currentPage: number;
  page: (p: number) => void;
}) => {
  const click = useCallback(() => page(p), [page, p]);
  const style = useMemo(
    () => (p === currentPage ? { color: "#2600AA" } : {}),
    [p, currentPage]
  );
  return (
    <span
      className="cursor-pointer user-select-none mr-2"
      key={p}
      style={style}
      onClick={click}
    >
      {p + 1}
    </span>
  );
};

const PageSizeDropdown = ({
  setPageSize,
  option,
  pageSize,
}: {
  setPageSize: (size: number) => void;
  option: number;
  pageSize: number;
}) => {
  const click = useCallback(() => setPageSize(option), [setPageSize, option]);
  return (
    <Dropdown.Item
      key={"page-size" + option}
      onClick={click}
      className="text-dark"
      active={pageSize === option}
    >
      {option}
    </Dropdown.Item>
  );
};
