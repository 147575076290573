import React from "react";
import { CatalogueItem } from "./CatalogueItem";
import { CatalogueBox } from "./CatalogueBox";
import { Item } from "./model/Item";
import { useCatalogueContext } from "./hook/useCatalogueContext";

/**
 * My catalogue items.
 */
export const MyCatalogueItems: React.FC<{ title: string }> = ({ title }) => {
  const { selectorStore } = useCatalogueContext();
  const catalogueOnClick = (c: Item) => () => selectorStore.selectAddedItem(c);
  const addedItems = selectorStore.addedItems();

  return (
    <CatalogueBox title={title}>
      <div className="overflow-y-auto max-h-60">
        {Object.keys(addedItems).map((uuid) => (
          <CatalogueItem
            onClick={catalogueOnClick(addedItems[uuid])}
            key={uuid}
            className={`${
              addedItems[uuid].status === "addedSelected" ? " selected" : ""
            }`}
          >
            {addedItems[uuid].name}
          </CatalogueItem>
        ))}
      </div>
    </CatalogueBox>
  );
};
