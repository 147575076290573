import React from "react";
import { Form, Col, Row } from "react-bootstrap";

interface Props {
  title: string;
  children: React.ReactNode;
}

export const InputRow: React.FC<Props> = ({ title, children }) => {
  return (
    <Form.Group as={Row}>
      <Form.Label column md="3" className="pt-0">
        {title}
      </Form.Label>
      <Col md="9" lg="6">
        {children}
      </Col>
    </Form.Group>
  );
};
