import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AddButton } from "../../../../components/AddButton";
import {
  FormikContextType,
  Field,
  FieldArray,
  FieldArrayRenderProps,
} from "formik";
import {
  BUILDINGINFO_CATEGORY_MAX_CHAR,
  SiteConfigFormData,
} from "../SiteConfigFormData";
import { RemoveButton } from "../../../../components/RemoveButton";
import { EqMessageError } from "../../../message/EqMessage";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";
import { deleteCheck } from "../../../../components/deleteCheck";
import ReactDragListView from "react-drag-listview";
import { FaArrowsAlt } from "react-icons/fa";
import { FormikInputWithCharCount } from "../../../../components/FormikInputWithCharCount";

interface Props {
  methods: FormikContextType<SiteConfigFormData>;
}

const MAX_CATEGORIES = 8;

const add = (
  arrayHelpers: FieldArrayRenderProps,
  methods: FormikContextType<SiteConfigFormData>
) => {
  const name = methods.values.buildingInfoCategoryNameInput.trim();

  if (!stringNotEmpty(name)) {
    return;
  }

  if (methods.values.buildingInfoCategories.length >= MAX_CATEGORIES) {
    EqMessageError({
      text: `Only a maximum of ${MAX_CATEGORIES} subpages can be added.`,
    });
    return;
  }

  if (name.length > BUILDINGINFO_CATEGORY_MAX_CHAR) {
    EqMessageError({
      text: `Name can't be more than ${BUILDINGINFO_CATEGORY_MAX_CHAR} characters.`,
    });
    return;
  }

  const lowercase = name.toLowerCase();
  if (
    methods.values.buildingInfoCategories.some(
      (c) => c.name.toLowerCase() === lowercase
    )
  ) {
    EqMessageError({ text: `${name} exists in the list.` });

    return;
  }

  arrayHelpers.push({
    name: name.trim(),
    weight: methods.values.buildingInfoCategories.length,
  });

  methods.setFieldValue("buildingInfoCategoryNameInput", "");
};

const remove = async (arrayHelpers: FieldArrayRenderProps, index: number) => {
  await deleteCheck(
    () => {
      arrayHelpers.remove(index);
    },
    {
      confirmButtonText: "Delete subpage",
      text: `
        If a subpage is deleted, any content already associated with it
        will remain intact, however, it will default to only showing
        on the all content view.
      `,
    }
  );
};

export const BuildingInfoCategories: React.FC<Props> = ({ methods }) => {
  return (
    <div className="pt-2">
      <p>
        A maximum of {MAX_CATEGORIES} subpages can be added to the building
        page. ‘Building Info Post’ content created in Iris can be assigned to
        these pages via Iris and will be displayed on the relevant sections on
        the web and mobile apps. The order of the subpages on these applications
        will appear as ordered below. Note that changes to this section can take
        up to an hour to sync through to Iris.
      </p>
      <Container fluid className="p-0 mb-5">
        <FieldArray name="buildingInfoCategories">
          {(arrayHelpers) => (
            <Row className="justify-content-between">
              <Col>
                <Container fluid>
                  <ReactDragListView
                    lineClassName="border-color-lime"
                    handleSelector="span.mover"
                    nodeSelector="div.draggingnih"
                    onDragEnd={(fromIndex, toIndex) => {
                      arrayHelpers.move(fromIndex, toIndex);
                    }}
                  >
                    {methods.values.buildingInfoCategories.map((cat, i) => (
                      <Row
                        className={`py-1 border draggingnih bg-white ${
                          i === 0 ? "" : "border-top-0"
                        }`}
                        key={`list-${cat.uuid}-${i}`}
                      >
                        <Col xs="7">
                          <Field
                            as={Form.Control}
                            name={`buildingInfoCategories.${i}.uuid`}
                            className="d-none"
                          />
                          <FormikInputWithCharCount
                            name={`buildingInfoCategories.${i}.name`}
                            maxLength={BUILDINGINFO_CATEGORY_MAX_CHAR}
                          />
                        </Col>
                        <Col
                          xs="2"
                          className="d-flex offset-3 justify-content-end align-items-center"
                        >
                          <span className="mover mr-2">
                            <FaArrowsAlt size="20" />
                          </span>
                          <RemoveButton
                            data-testid={`remove-subpage-${i}`}
                            onClick={async () => await remove(arrayHelpers, i)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </ReactDragListView>
                  {methods.values.buildingInfoCategories.length <
                  MAX_CATEGORIES ? (
                    <Row className="border border-top-0">
                      <Col className="py-1" xs="7">
                        <FormikInputWithCharCount
                          name="buildingInfoCategoryNameInput"
                          maxLength={BUILDINGINFO_CATEGORY_MAX_CHAR}
                          placeholder="Add a subpage"
                        />
                      </Col>
                      <Col
                        xs="2"
                        className="d-flex offset-3 justify-content-end align-items-center"
                      >
                        <AddButton
                          variant="text-primary"
                          data-testid="add-subpage-btn"
                          onClick={() => add(arrayHelpers, methods)}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Container>
              </Col>
            </Row>
          )}
        </FieldArray>

        {methods.errors.buildingInfoCategories != null ? (
          <div className="invalid-feedback d-block">
            {methods.errors.buildingInfoCategories as React.ReactNode}
          </div>
        ) : null}
      </Container>
    </div>
  );
};
