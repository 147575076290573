import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { UserAttribute } from "../model/UserAttribute";
import { TableButton, ActionButton } from "../../../../components/TableButton";
import { UserAttributeStore } from "../model/UserAttributeStore";

interface Props {
  attribute: UserAttribute;
  store: UserAttributeStore;
  setEditing: (edit: boolean) => void;
}

/**
 * Edit row.
 */
export const EditRow: React.FC<Props> = ({ attribute, store, setEditing }) => {
  const [name, setName] = React.useState(attribute.name);
  const handleName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setName(e.target.value.slice(0, 50)),
    [setName]
  );
  const cancelEdit = useCallback(() => setEditing(false), [setEditing]);

  const handleSave = useCallback(async () => {
    if (name === "") {
      return;
    }
    const success = await store.saveAttribute(attribute.uuid, name);
    if (success) {
      setEditing(false);
    }
  }, [attribute, name, setEditing, store]);
  return (
    <tr>
      <td className="py-1">
        <Form.Control value={name} onChange={handleName} />
      </td>
      <td>
        {!store.saving && (
          <>
            <TableButton onClick={cancelEdit}>Cancel</TableButton> |&nbsp;
          </>
        )}
        <ActionButton
          onClick={handleSave}
          actionLabel="Saving..."
          restLabel="Save"
          isInAction={store.saving}
        />
      </td>
    </tr>
  );
};
