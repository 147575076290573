import React from "react";
import { PageNavigationButton } from "../../../components/NavigationButton";
import { RootRoutes } from "../../../RouterOutlet";
import { useParams } from "react-router-dom";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { useUser } from "../../user/UserContext";

/**
 * Navigation within the site details.
 */
export const Navigation: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const user = useUser();
  const siteCtxHelper = useSiteContextHelper();

  return (
    <>
      <div className="p-3">
        <PageNavigationButton
          to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${uuid}`)}
          btnClasses="px-3 py-2"
          dataTestId="general-setting-subtab"
          title="General"
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
        />
        <PageNavigationButton
          to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${uuid}/web`)}
          btnClasses="px-3 py-2"
          dataTestId="web-setting-subtab"
          title="Web"
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
        />
        <PageNavigationButton
          to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${uuid}/mobile`)}
          btnClasses="px-3 py-2"
          dataTestId="mobile-setting-subtab"
          title="Mobile App"
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
        />
        {!user.inSiteContext ? (
          <PageNavigationButton
            to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${uuid}/integrations`)}
            btnClasses="px-3 py-2"
            title="Integration"
            className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
          />
        ) : null}
        {!user.inSiteContext ? (
          <PageNavigationButton
            to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${uuid}/modules`)}
            btnClasses="px-3 py-2"
            dataTestId="modules-setting-subtab"
            title="Modules"
            className="d-block d-sm-inline-block"
          />
        ) : null}
      </div>
      <hr className="my-0" />
    </>
  );
};
