import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import {
  CompanyV2SortField,
  CompanyListDocument,
  CompanyListQueryVariables,
  CompanyListQuery
} from "../../../generated/admin";
import AsyncSelect from "react-select/async";
import { OptionType } from "../../../model/OptionType";
import debounce from "debounce-promise";
import { useApolloClient } from "@apollo/client";

interface CompaniesDropdownProps {
  name: string;
  defaultValue: OptionType | null;
}

const rules = {
  required: "Required."
};

/**
 * Companies dropdown.
 * @todo: need to seperate the logic from ui.
 */
export const CompaniesDropdown: React.FC<CompaniesDropdownProps> = ({name, ...props}) => {
  const methods = useFormContext();
  const client = useApolloClient();

  const loadOptions = async (search: string) => {
    if (search.length < 1) {
      return [];
    }

    const result = await client.query<
      CompanyListQuery,
      CompanyListQueryVariables
    >({
      query: CompanyListDocument,
      variables: {
        first: 100,
        search,
        sort: {
          asc: true,
          field: CompanyV2SortField.CompanyName
        }
      }
    });

    return result.data.companiesV2.edges.flatMap((c) => {
      return c.node != null && c.node.name != null
        ? [
            {
              value: c.node.uuid,
              label: c.node.name
            }
          ]
        : [];
    });
  };
  // Loading debounce: https://github.com/JedWatson/react-select/issues/3075.
  const debouncedLoadOptions = debounce(loadOptions, 700, {
    leading: true
  });

  return (
    <div>
      <Controller
        render={({field: { value, onChange }}) =>
          <AsyncSelect<OptionType>
            loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
            isSearchable
            classNamePrefix="eq"
            noOptionsMessage={(obj: { inputValue: string }) => {
              return obj.inputValue.length < 1
                ? "Search for a company"
                : "No options";
            }}
            placeholder="Select a company"
            className={`react-select${
              methods.formState.errors.name ? " is-invalid" : ""
            }`}
            value={value}
            onChange={onChange}
          />
        }
        rules={rules}
        control={methods.control}
        name={name}
        defaultValue={props.defaultValue ?? undefined}
      />
      {methods.formState.errors.name && (
        <Form.Control.Feedback type="invalid">
          {methods.formState.errors.name.message as string}
        </Form.Control.Feedback>
      )}
    </div>
  );
};
