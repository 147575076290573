import React, { useState, useRef, useMemo } from "react";
import "jodit";
import JoditEditor, { JoditProps } from "jodit-react";
import { Maybe } from "graphql/jsutils/Maybe";

interface Props {
  content?: Maybe<string>;
  placeholder?: string;
  finishCallback: (text: string) => void;
}

// In the package we use jodit version of this guy due to issue isProd.
// https: //github.com/jodit/jodit-react/issues/77,
// "jodit": "git://github.com/DaveVanRijn/jodit.git"
// We will have to revert to latest release once his changes is up (any version after 3.4.14).
export const JoditTextEditor: React.FC<Props> = ({
  content,
  placeholder,
  finishCallback
}) => {
  const editor = useRef(null);
  const [value, setContent] = useState(content ?? "");
  const config: JoditProps["config"] = useMemo(
    () =>
      ({
        readonly: false,
        toolbarSticky: false,
        toolbarAdaptive: false,
        iframe: process.env.NODE_ENV !== "test",
        height: 500,
        placeholder,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        showXPathInStatusbar: false,
        style: {
          font: "18px Roboto Normal, sans-serif",
          color: "rgba(0,0,0,.8)"
        },
        defaultActionOnPaste: "insert_as_text",
        buttons: [
          "source",
          "|",
          "bold",
          "strikethrough",
          "underline",
          "italic",
          "superscript",
          "subscript",
          "hr",
          "|",
          "ul",
          "ol",
          "|",
          "fontsize",
          "paragraph",
          "|",
          "image",
          "video",
          "table",
          "link",
          "align"
        ]
      } as any),
    [placeholder]
  );

  const onEditorBlur = (newContent: string) => {
    setContent(newContent);
    finishCallback(newContent);
  };

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      // preferred to use only this option to update the content for performance reasons.
      onBlur={onEditorBlur}
    />
  );
};
