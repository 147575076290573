import React from "react";
import dayjs from "dayjs";

import { BuildingHistoryFieldChangeFragmentFragment } from "../../../generated/admin";
import { CardAccordion } from "../../../components/SingleEqAccordion";
import { LongText } from "../../../components/LongText";

interface Props {
  history: BuildingHistoryFieldChangeFragmentFragment[];
}

const dateFormat = "DD/MM/YY HH:mm";

const Row: React.FC<BuildingHistoryFieldChangeFragmentFragment> = ({
  field,
  oldValue,
  newValue,
  changedBy,
  changedAt,
}) => {
  const fullName = `${changedBy.profile?.firstName} ${changedBy.profile?.lastName}`;
  return (
    <tr>
      <td>
        <LongText limit={20} content={field} />
      </td>
      <td>{oldValue}</td>
      <td>{newValue}</td>
      <td>{fullName}</td>
      <td>{dayjs(changedAt).format(dateFormat)}</td>
    </tr>
  );
};

export const BuildingDetailsTaxRateHistory: React.FC<Props> = ({ history }) => {
  //
  return (
    <CardAccordion title="History">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Field</th>
            <th scope="col">Value before</th>
            <th scope="col">Value after</th>
            <th scope="col">Changed by</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {history.map((change, i) => (
            <Row key={i} {...change} />
          ))}
        </tbody>
      </table>
    </CardAccordion>
  );
};
