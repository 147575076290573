import { Routes, Route } from "react-router-dom";
import React from "react";
import { CompanyList } from "./list-view/CompanyList";
import { CompanyDetails } from "./detail-view/CompanyDetails";

export const CompaniesModule = () => {
  return (
    <Routes>
      <Route path={`/edit/:uuid/*`} element={<CompanyDetails />} />
      <Route path={`/create`} element={<CompanyDetails />} />
      <Route path="*" element={<CompanyList />} />
    </Routes>
  );
};
