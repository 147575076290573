import dayjs from "dayjs";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";
import {
  LeaseFragmentFragment,
  useLeaseModifyMutation,
} from "../../../generated/admin";
import { EqMessageError } from "../../message/EqMessage";
import { LeaseSizeField } from "../form-fields/LeaseSizeField";
import { LeaseExpiryDateField } from "../form-fields/LeaseExpiryDateField";
import { LeaseBreakDateField } from "../form-fields/LeaseBreakDateField";
import { LeaseNotificationDateField } from "../form-fields/LeaseNotificationDateField";
import { DateOfChangeField } from "../form-fields/DateOfChangeField";
import { EmployeeCountField } from "../form-fields/EmployeeCountField";

interface Props {
  onHide: () => void;
  lease: LeaseFragmentFragment;
  onModify?: (newLease: LeaseFragmentFragment) => void;
}

interface FormValue {
  dateOfChange: string;
  leaseSize: number;
  expiryDate: string;
  notificationDate?: string;
  breakDate?: string;
  employeeCount?: number;
}

/**
 * LeaseModify modal
 */
export const LeaseModify: React.FC<Props> = ({ onHide, onModify, lease }) => {
  const [modifyLease] = useLeaseModifyMutation();
  const dateInputRef = useRef<HTMLInputElement>();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Select value in date input field automatically on load.
    dateInputRef.current?.select();
  }, []);

  return (
    <Modal size="lg" centered scrollable={false} show={true} onHide={onHide}>
      <Formik<FormValue>
        initialValues={{
          // TODO - rename
          dateOfChange: dayjs().format("YYYY-MM-DD"),
          breakDate:
            lease.breakDate == null
              ? ""
              : dayjs(lease.breakDate).format("YYYY-MM-DD"),
          expiryDate: dayjs(lease.expiryDate).format("YYYY-MM-DD"),
          notificationDate:
            lease.notificationDate == null
              ? ""
              : dayjs(lease.notificationDate).format("YYYY-MM-DD"),
          leaseSize: lease.size,
          employeeCount: lease.employeeCount ?? undefined,
        }}
        validateOnChange={submitted}
        validateOnBlur={submitted}
        onSubmit={async (values) => {
          setSubmitted(true);

          const leaseResult = await modifyLease({
            variables: {
              input: {
                breakDate:
                  values.breakDate == null
                    ? null
                    : new Date(values.breakDate).getTime(),
                expiryDate: new Date(values.expiryDate).getTime(),
                dateOfChange: new Date(values.dateOfChange).getTime(),
                notificationDate:
                  values.notificationDate == null
                    ? null
                    : new Date(values.notificationDate).getTime(),
                size: values.leaseSize,
                employeeCount: values.employeeCount,
                uuid: lease.uuid,
              },
            },
          });

          if (leaseResult.data?.modifyLease == null) {
            EqMessageError({
              text: "Failed to modify lease. Please try again.",
            });
          } else {
            onHide();
            if (onModify != null) {
              onModify(leaseResult.data?.modifyLease);
            }
          }
        }}
      >
        {(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;

          return (
            <>
              <Modal.Header>
                <Modal.Title>Modify Lease for {lease.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col md={7}>
                      <DateOfChangeField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                      <EmployeeCountField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                      <LeaseSizeField
                        {...formikProps}
                        initialSize={lease.size}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <LeaseBreakDateField {...formikProps} />
                    </Col>
                    <Col>
                      <LeaseNotificationDateField {...formikProps} />
                    </Col>
                    <Col>
                      <LeaseExpiryDateField {...formikProps} />
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner animation="grow" size="sm" />
                      <span className="ml-1">Modifying...</span>
                    </>
                  ) : (
                    "Modify"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  onClick={onHide}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
