import React, { useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import styled from "styled-components";
import { colors } from "../../../../components/colors";

interface Props {
  name: string;
  loading: boolean;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  placeholder?: string;
}

const PasswordToggle = styled.div`
  position: relative;

  & input {
    padding-right: 40px;
  }

  & .toggle-password {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: ${colors.lightGrey};
  }
`;

export const Password: React.FC<Props> = ({
  name,
  loading,
  onPasswordChange,
  isInvalid,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = useCallback(() => {
    setShowPassword((p) => !p);
  }, []);

  return (
    <PasswordToggle>
      <Form.Control
        name={name}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        disabled={loading}
        isInvalid={isInvalid}
        onChange={onPasswordChange}
      />
      {showPassword ? (
        <AiOutlineEye
          size="25px"
          className={`${name}-toggle-password toggle-password hide-it`}
          onClick={togglePassword}
        />
      ) : (
        <AiOutlineEyeInvisible
          size="25px"
          className={`${name}-toggle-password toggle-password show-it`}
          onClick={togglePassword}
        />
      )}
    </PasswordToggle>
  );
};
