import React, { useContext, useEffect } from "react";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { UserFormRolesDropdown } from "./UserFormRolesDropdown";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { useSiteGroupsLazyQuery } from "../../../generated/admin";
import { UserFormContext } from "./context/UserFormContext";

/**
 * User form Groups component.
 */
export const UserFormRoles: React.FC = () => {
  const ctx = useContext(UserFormContext);
  const [query, { data, loading, error }] = useSiteGroupsLazyQuery();
  useEffect(() => {
    if (ctx.activeSite != null) {
      query({
        variables: {
          destinationUuid: ctx.activeSite,
        },
        fetchPolicy: "network-only",
      })
    }
  }, [query, ctx.activeSite])
  if (ctx.activeSite == null) {
    return <></>;
  }


  return (
    <ToggleContainer title="Groups">
      <div className="pb-4">
        <p>
          Groups shown below are the groups associated with the user at the
          selected site. As an administrator, you can delete them from a
          profile, or add an existing group to the profile. If you need to add a
          new group altogether, this must be added on the Groups page and then
          assigned here.
        </p>
        <LoadingOrErrorDisplay loading={loading} error={error}>
          {data != null && (
            <UserFormRolesDropdown
              options={data.siteGroups.map(({ uuid, name }) => {
                return {
                  value: uuid,
                  label: name,
                };
              })}
            />
          )}
        </LoadingOrErrorDisplay>
      </div>
    </ToggleContainer>
  );
};
