import React, { Component, useMemo } from "react";
import {
  createAuth0Client,
  Auth0ClientOptions,
  Auth0Client,
} from "@auth0/auth0-spa-js";
import { AppLoadingPage } from "../admin-components/AppLoadingPage";
import { LoginPage } from "../admin-components/LoginPage";
import { createContext, useContext } from "react";
import { Config } from "../config";
import { useConfig } from "./ConfigProvider";

export interface Auth0State {
  auth0Client: Auth0Client | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  user?: Auth0User;
  children?: React.ReactNode;
}

export interface Auth0User {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
}

export const Auth0Context = createContext<IAuth0Context>({
  isLoading: true,
  isAuthenticated: false,
  getIdTokenClaims: () => {},
  getTokenSilently: () => {},
  loginWithRedirect: () => {},
  logout: () => {},
});
export const useAuth0 = () => useContext(Auth0Context);

interface IAuth0Context {
  isLoading: boolean;
  isAuthenticated: boolean;
  user?: Auth0User;
  loginWithRedirect: (...p: any[]) => any;
  getTokenSilently: (...p: any[]) => any;
  getIdTokenClaims: (...p: any[]) => any;
  logout: (...p: any[]) => any;
}

// create a provider
export class Auth0Provider extends Component<
  { config: Config; children?: React.ReactNode },
  Auth0State
> {
  state: Auth0State = {
    auth0Client: null,
    isLoading: true,
    isAuthenticated: false,
  };
  config: Auth0ClientOptions = {
    domain: this.props.config.auth0Domain,
    clientId: this.props.config.auth0ClientId,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: this.props.config.auth0Audience,
      responseType: "token id_token",
      scope: "openid equiem:user",
    },
  };

  
  componentDidMount() {
    this.initializeAuth0();
  }

  // initialize the auth0 library
  initializeAuth0 = async () => {
    const auth0Client = await createAuth0Client(this.config);
    this.setState({ auth0Client });

    // check to see if they have been redirected after login
    if (window.location.search.includes("code=")) {
      return this.handleRedirectCallback();
    }

    const isAuthenticated = await auth0Client.isAuthenticated();
    const user = isAuthenticated
      ? await auth0Client.getUser<Auth0User>()
      : undefined;
    this.setState({
      isLoading: false,
      isAuthenticated,
      user,
    });
  };

  handleRedirectCallback = async () => {
    this.setState({ isLoading: true });

    await this.state.auth0Client?.handleRedirectCallback();
    const url = localStorage.getItem("redirectUrl") ?? window.location.pathname;
    const user = await this.state.auth0Client?.getUser<Auth0User>();

    this.setState({ user, isAuthenticated: true, isLoading: false });
    window.history.replaceState({}, document.title, url);
  };

  render() {
    return (
      <AuthProviderInner {...this.state}>
        {this.props.children}
      </AuthProviderInner>
    );
  }
}

const AuthProviderInner: React.FC<Auth0State> = ({
  auth0Client,
  isLoading,
  isAuthenticated,
  user,
  children,
}) => {
  const config = useConfig();
  const baseUrl = config.baseUrl;

  const configObject = useMemo(
    () => ({
      isLoading,
      isAuthenticated,
      user,
      loginWithRedirect: (...p: any[]) => {
        localStorage.setItem("redirectUrl", window.location.pathname);
        return auth0Client?.loginWithRedirect(...p);
      },
      getTokenSilently: (...p: any[]) => auth0Client?.getTokenSilently(...p),
      getIdTokenClaims: () => auth0Client?.getIdTokenClaims(),
      logout: (...p: any[]) =>
        auth0Client?.logout({ logoutParams: { returnTo: baseUrl } }),
    }),
    [isLoading, isAuthenticated, user, auth0Client, baseUrl]
  );

  return (
    <Auth0Context.Provider value={configObject}>
      {(() => {
        if (isLoading) {
          return <AppLoadingPage />;
        }
        if (!isAuthenticated) {
          return (
            <LoginPage
              greeting="Welcome to Admin Panel"
              loginWithRedirect={configObject.loginWithRedirect}
            />
          );
        }
        return children;
      })()}
    </Auth0Context.Provider>
  );
};
