import { useSendEmailVerificationMutation } from "../../../../generated/admin";
import { useContext } from "react";
import { UserFormContext } from "../context/UserFormContext";
import { UserContext } from "../../UserContext";

export function useUserSendEmailVerification() {
  const ctx = useContext(UserFormContext);
  const userCtx = useContext(UserContext);
  const [sendEmailVerificationMutation, { loading }] =
    useSendEmailVerificationMutation();

  const sendEmailVerification = async () =>
    sendEmailVerificationMutation({
      variables: {
        email: ctx.user?.profile?.email,
        siteUuid: userCtx?.activeDestination?.uuid,
      },
    });

  return {
    sendEmailVerification,
    isLoadingSendEmailVerification: loading,
  };
}
