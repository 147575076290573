import { Formik } from "formik";
import { useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { CardAccordion } from "../../../../../components/SingleEqAccordion";
import {
  GetCafmConfigurationsBySiteUuidDocument,
  ReqMgtCafmConfigurationType,
  useCreateCafmConfigurationCallbackConnectionCredentialsMutation,
  useDeleteCafmConfigurationMutation,
  useUpsertCafmConfigurationMutation,
} from "../../../../../generated/admin";
import { OptionType } from "../../../../../model/OptionType";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";
import { TextFormGroup } from "../../fields/TextFormGroup";
import {
  RequestManagementConnectionFormData,
  cafmProviderOptions,
  translateProviderSettingsFormValuesToGraphQL,
  validateNewRequestManagementConnectionForm,
} from "./RequestManagementConnectionAuxiliary";
import { Password } from "../../mobile/Password";
import { stringNotEmpty } from "../../../../../util/stringNotEmpty";

interface Props {
  siteUuid: string;
  formData: RequestManagementConnectionFormData;
}

export const RequestManagementConnection: React.FC<Props> = ({
  formData,
  siteUuid,
}) => {
  const [upsertConfig, { loading: loadingUpsert }] =
    useUpsertCafmConfigurationMutation();

  const [deleteConfig, { loading: loadingDel }] =
    useDeleteCafmConfigurationMutation();

  const [createCredentials, { loading: loadingCreds }] =
    useCreateCafmConfigurationCallbackConnectionCredentialsMutation();

  const isLoading = loadingUpsert || loadingDel || loadingCreds;

  const generateCredentials = useCallback(
    async (uuid: string, siteUuid: string) => {
      const confirm = await EqDangerMessage({
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Generate new password",
        title: "Are you sure?",
        html: "Once a new password is generated, all previous connections will be removed.",
      });

      if (confirm.value !== true || loadingCreds) {
        return;
      }

      console.log(siteUuid);

      try {
        const result = await createCredentials({
          variables: {
            uuid: uuid,
          },
          refetchQueries: [
            {
              query: GetCafmConfigurationsBySiteUuidDocument,
              variables: {
                siteUuid: siteUuid,
              },
            },
          ],
        });
        if (
          !result.data?.reqMgt
            .createCafmConfigurationCallbackConnectionCredentials
        ) {
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          html: `
          <div class="d-flex flex-column overflow-hidden">
            <div class="mb-3 row">
              <label for="configId" class="form-label col-md-3">Username</label>
              <div class="col-lg-6 col-md-9">
                <input name="configId" type="text" id="configId" class="form-control" value=${formData.uuid}>
              </div>
            <div class="mb-3 row">
              <label for="keyId" class="form-label col-md-3">Password</label>
              <div class="col-lg-6 col-md-9">
                <input name="keyId" type="text" id="keyId" class="form-control" value=${result.data.reqMgt.createCafmConfigurationCallbackConnectionCredentials}>
              </div>
            </div>
          </div>
          `,
        });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [createCredentials, formData.uuid, loadingCreds]
  );

  const del = useCallback(
    async (uuid: string, siteUuid: string) => {
      const confirm = await EqDangerMessage({
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete connection",
        title: "Are you sure?",
        html: "Once deleted, you cannot retrieve this cafm provider connection.",
      });
      if (confirm.value !== true || loadingDel) {
        return;
      }

      try {
        const result = await deleteConfig({
          variables: {
            uuid: uuid,
          },
          refetchQueries: [
            {
              query: GetCafmConfigurationsBySiteUuidDocument,
              variables: {
                siteUuid: siteUuid,
              },
            },
          ],
        });
        if (result.data?.reqMgt.deleteCafmConfiguration == null) {
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({ text: "Cafm provider connection deleted. " });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [deleteConfig, loadingDel]
  );

  const update = useCallback(
    async (values: RequestManagementConnectionFormData, siteUuid: string) => {
      try {
        const result = await upsertConfig({
          variables: {
            input: {
              siteUuid: siteUuid,
              name: values.name!,
              endpoint: values.endpoint,
              type: values.cafmProviderType.value,
              user: values.username,
              password: values.password,
              uuid: values.uuid,
              cafmProviderSettings:
                translateProviderSettingsFormValuesToGraphQL(values),
            },
          },
          refetchQueries: [
            {
              query: GetCafmConfigurationsBySiteUuidDocument,
              variables: {
                siteUuid: siteUuid,
              },
            },
          ],
        });

        if (!result.data?.reqMgt.upsertCafmConfiguration.uuid) {
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          text: "Successfully updated cafm provider connection.",
        });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [upsertConfig]
  );

  return (
    <div className="container-main">
      <Formik<RequestManagementConnectionFormData>
        initialValues={formData}
        onSubmit={(values) => update(values, siteUuid)}
        key={formData.uuid}
        validate={validateNewRequestManagementConnectionForm}
      >
        {(methods) => (
          <CardAccordion
            cardClassName="flex-nowrap"
            title={methods.values.name}
          >
            <div>
              <TextFormGroup
                title={"Name"}
                value={methods.values.name}
                subText={"Enter the name"}
                name={"name"}
              />
              {stringNotEmpty(methods.values.uuid) && (
                <TextFormGroup
                  title={"UUID"}
                  value={methods.values.uuid}
                  subText="Configuration UUID (used as a username in credentials given to CAFM providers)"
                  disabled={true}
                  name={"uuid"}
                />
              )}
              <Form.Group as={Row} controlId="cafmProviderType">
                <Form.Label column md="3">
                  {"Provider type"}
                </Form.Label>
                <Col md="9" lg="6" className="site-client-select">
                  <Select<OptionType<ReqMgtCafmConfigurationType>, boolean>
                    classNamePrefix="eq"
                    isSearchable
                    value={methods.values.cafmProviderType}
                    isDisabled={true}
                    options={cafmProviderOptions}
                    onChange={(option) => {
                      methods.setFieldValue("type", option);
                      methods.setFieldTouched("type");
                    }}
                    placeholder="Select provider type"
                    aria-label="site-client-select"
                    className={`react-select${
                      methods.errors.cafmProviderType != null
                        ? " is-invalid"
                        : ""
                    }`}
                  />
                  <Form.Text className="text-muted">
                    You can select one provider type to use with this connection
                  </Form.Text>
                  {methods.errors.cafmProviderType &&
                    methods.touched.cafmProviderType && (
                      <Form.Control.Feedback type="invalid">
                        {methods.errors.cafmProviderType.toString()}
                      </Form.Control.Feedback>
                    )}
                </Col>
              </Form.Group>
              <TextFormGroup
                title={"End point"}
                error={methods.errors.endpoint}
                subText={"Enter the end point"}
                name={"endpoint"}
              />
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.Simpro && (
                <TextFormGroup
                  title={"Simpro Site Id"}
                  error={methods.errors.simproSiteId}
                  subText={"Enter Simpro Site Id"}
                  name={"simproSiteId"}
                />
              )}
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.ConceptEvolution && (
                <>
                  <TextFormGroup
                    title={"Create method name"}
                    error={methods.errors?.conceptEvolutionCreateMethodName}
                    subText={"Enter Concept Evolution create method name"}
                    name={"conceptEvolutionCreateMethodName"}
                  />
                  ReqMgtCafmConfigurationType.ConceptEvolution && (
                  <TextFormGroup
                    title={"Update method name"}
                    error={methods.errors?.conceptEvolutionUpdateMethodName}
                    subText={"Enter Concept Evolution update method name"}
                    name={"conceptEvolutionUpdateMethodName"}
                  />
                </>
              )}
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.Maximo && (
                <>
                  <TextFormGroup
                    title={"Create method name"}
                    error={methods.errors?.maximoCreateMethodName}
                    subText={"Enter Maximo create method name"}
                    name={"maximoCreateMethodName"}
                  />
                  <TextFormGroup
                    title={"Update method name"}
                    error={methods.errors?.maximoUpdateMethodName}
                    subText={"Enter Maximo update method name"}
                    name={"maximoUpdateMethodName"}
                  />
                </>
              )}
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.Elogbooks && (
                <>
                  <TextFormGroup
                    title={"Service provider name"}
                    error={methods.errors?.elogbooksServiceProviderName}
                    subText={"Enter Elogbooks service provider name"}
                    name={"elogbooksServiceProviderName"}
                  />
                  <TextFormGroup
                    title={"Site name"}
                    error={methods.errors?.elogbooksSiteName}
                    subText={"Enter Elogbooks site name"}
                    name={"elogbooksSiteName"}
                  />
                  <TextFormGroup
                    title={"Priority name"}
                    error={methods.errors?.elogbooksPriorityName}
                    subText={"Enter Elogbooks priority name"}
                    name={"elogbooksPriorityName"}
                  />
                </>
              )}
              <TextFormGroup
                title={"Username"}
                name={"username"}
                copyFieldName={"Username"}
                value={formData.username}
              />
              <Form.Group as={Row}>
                <Form.Label column md="3" lg="3">
                  Password
                </Form.Label>
                <Col md="9" lg="6">
                  <Password
                    placeholder={
                      methods.values.isPasswordSet ? "**********" : undefined
                    }
                    name="password"
                    onPasswordChange={(e) => {
                      void methods.setFieldValue("password", e.target.value);
                    }}
                    loading={false}
                  />
                </Col>
              </Form.Group>
            </div>
            {formData.isCallbackConnectionSet ? (
              <div className="pb-1">
                <div className="mt-3 alert alert-danger">
                  Cafm callback connection secret have already been generated.
                </div>
              </div>
            ) : null}
            <div className="form-inline justify-content-end mt-1">
              <Button
                name="updateConfig"
                variant="outline-primary"
                size="sm"
                className="align-self-center m-2"
                onClick={() => methods.handleSubmit()}
                disabled={isLoading || !methods.isValid || !methods.dirty}
              >
                {loadingUpsert ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Update connection"
                )}
              </Button>
              <Button
                name="createCredentials"
                variant="outline-primary"
                size="sm"
                className="align-self-center m-2"
                onClick={() =>
                  generateCredentials(methods.values.uuid, siteUuid)
                }
                disabled={isLoading}
              >
                {loadingCreds ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : formData.isCallbackConnectionSet ? (
                  "Re-create callback secret"
                ) : (
                  "Create callback secret"
                )}
              </Button>
              <Button
                name="deleteConnection"
                variant="danger"
                size="sm"
                className="align-self-center"
                onClick={() => del(methods.values.uuid, siteUuid)}
                disabled={isLoading}
              >
                {loadingDel ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Delete connection"
                )}
              </Button>
            </div>
          </CardAccordion>
        )}
      </Formik>
    </div>
  );
};
