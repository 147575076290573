import React from "react";
import { PageHeading } from "../../../components/PageHeading";
import { Navigation } from "../Navigation";
import { Container, Col, Row } from "react-bootstrap";
import { IndustryStoreProvider } from "./hook/useIndustryStore";
import { Divider } from "../../../components/Divider";
import { SubTitle } from "../../../components";
import { IndustryList } from "./list-view/IndustryList";
import { AddIndustryForm } from "./AddIndustryForm";

/**
 * Industries home.
 */
export const IndustriesHome: React.FC = () => {
  return (
    <IndustryStoreProvider>
      <PageHeading title="Industries" subHeading={<Navigation />}></PageHeading>
      <Container>
        <Row>
          <Col className="mt-n2">
            Industries are assigned to companies and can only be created by an
            administrator.
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col>
            <SubTitle>Manage Industries</SubTitle>
            <IndustryList />
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col className="pb-5">
            <SubTitle>Add Industries</SubTitle>
            <AddIndustryForm />
          </Col>
        </Row>
      </Container>
    </IndustryStoreProvider>
  );
};
