import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { SiteDetailsQuery } from "../../../../generated/admin";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { SiteStatusFormGroup } from "../fields/SiteStatusFormGroup";
import { ClientNameFormGroup } from "../fields/ClientNameFormGroup";
import { TextFormGroup } from "../fields/TextFormGroup";
import { UserManagement } from "./UserManagement";
import { DefaultIrisAuthorFormGroup } from "../fields/DefaultIrisAuthorFormGroup";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { FormikContextType } from "formik";
import { SocialLinks } from "./SocialLinks";
import { GeneralSettingsBranding } from "./GeneralSettingsBranding";
import { TermsAndConditions } from "./TermsAndConditions";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { CookiePolicy } from "./CookiePolicy";
import { ThirdPartyServiceProvider } from "./ThirdPartyServiceProvider";
import { NewsletterFooter } from "./NewsletterFooter";
import { AddressInput } from "../fields/address/AddressInput";
import { SiteLocale } from "../fields/SiteLocale";
import { InactiveUser } from "./InactiveUser";
import { useUser } from "../../../user/UserContext";
import { BuildingInfoCategories } from "./BuildingInfoCategories";
import { FormikInputWithCharCount } from "../../../../components/FormikInputWithCharCount";
import { GeneralSettingsRegistration } from "./GeneralSettingsRegistration";
import { useConfig } from "../../../../providers/ConfigProvider";
import { HistoryToggleContainer } from "./History";
import { Marketplace } from "./Marketplace";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { colors } from "../../../../components/colors";
import { DefaultIrisAssigneeFormGroup } from "../fields/DefaultIrisAssigneeFormGroup";
import { SiteTierLevelFormGroup } from "../fields/SiteTierLevelFormGroup";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
  methods: FormikContextType<SiteConfigFormData>;
}

export const GeneralSettings: React.FC<Props> = ({ dest, methods }) => {
  const user = useUser();
  const config = useConfig();

  return (
    <Container className="mb-5">
      <ToggleContainer className="lg" title="Operational Details">
        {user.isAdminOrRegionalManager &&
        config.featureToggles.equiemEssentials ? (
          <SiteTierLevelFormGroup methods={methods} />
        ) : null}
        <SiteStatusFormGroup methods={methods} />
        <ClientNameFormGroup
          disabled={user.activeDestination != null}
          methods={methods}
        />
        <TextFormGroup
          title={"Site name"}
          name={"siteName"}
          disabled
          subText={
            "The site name is used across systems and reporting to identify the site. Take care when entering this name as it cannot be edited once created."
          }
          handleChange={methods.handleChange}
          value={methods.values.siteName}
          error={methods.errors.siteName}
        />
        <AddressInput methods={methods} />
        {user.isAdminOrRegionalManager && (
          <SiteLocale
            methods={methods}
            showDebugLanguages={!config.isProduction}
          />
        )}
        {config.featureToggles.fromAddress && (
          <TextFormGroup
            title={"From email address"}
            subText={
              "System generated emails will be sent from this email address. DNS records will automatically be added in the ‘Web Settings > DNS Management’ table which you will need to add to your DNS records before this change will take effect."
            }
            name={"fromEmail"}
            append={
              methods.values.fromEmailActive ? (
                <FaCheckCircle size={"1em"} color={colors.green} />
              ) : (
                <FaClock size={"1em"} color={colors.orange} />
              )
            }
            handleChange={methods.handleChange}
            value={methods.values.fromEmail}
            error={methods.errors.fromEmail}
          />
        )}
        <TextFormGroup
          title={"Reply-to email address"}
          subText={
            "Replies to any system generated emails will be sent to this email address."
          }
          name={"replyToEmail"}
          handleChange={methods.handleChange}
          value={methods.values.replyToEmail}
          error={methods.errors.replyToEmail}
        />
        <TextFormGroup
          title={"Contact email address"}
          subText={
            'This email address serves as the contact point for the "Contact us" link displayed in the menus of both the web and mobile apps. Without a specified email address, these links will not be visible. Additionally, it is utilised for operational services, such as Access Control, in the event that a user encounters an error.'
          }
          name={"email"}
          handleChange={methods.handleChange}
          value={methods.values.email}
          error={methods.errors.email}
        />
        <DefaultIrisAuthorFormGroup
          methods={methods}
          error={methods.errors.defaultIrisAuthor}
        />
        <DefaultIrisAssigneeFormGroup
          methods={methods}
          error={methods.errors.defaultIrisAssignee}
        />
        {user.inSiteContext ? null : (
          <Form.Group as={Row} controlId="enableReporting">
            <Form.Label column md="3">
              Enable reporting
            </Form.Label>
            <Col md="9" lg="6">
              <Form.Check
                className="mb-3"
                style={{ zIndex: "unset" }}
                type="switch"
                label=""
                disabled={dest.reportingEnabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setFieldValue("enableReporting", e.target.checked);
                }}
                defaultChecked={methods.values.enableReporting}
              />
              <Form.Text className="text-muted">
                Once enabled, site metrics will start sending to the reporting
                dashboards. This feature cannot be turned off once enabled.
              </Form.Text>
              {methods.errors.enableReporting && (
                <Form.Control.Feedback type="invalid">
                  {methods.errors.enableReporting}
                </Form.Control.Feedback>
              )}
            </Col>
          </Form.Group>
        )}
      </ToggleContainer>

      {methods.values.managedBy !== "PORTAL" && (
        <ToggleContainer className="lg" title="Registration">
          <GeneralSettingsRegistration dest={dest} methods={methods} />
        </ToggleContainer>
      )}

      <ToggleContainer className="lg" title="Branding">
        <GeneralSettingsBranding dest={dest} methods={methods} />
      </ToggleContainer>

      <ToggleContainer className="lg" title="Navigation">
        <Form.Group as={Row} controlId="buildingInfoTitle">
          <Form.Label column md="3">
            Building page title
          </Form.Label>
          <Col md="9" lg="6">
            <FormikInputWithCharCount name="buildingInfoTitle" maxLength={20} />
            <Form.Text className="text-muted">
              This title is shown on both the web and mobile apps.
            </Form.Text>
          </Col>
        </Form.Group>
        <BuildingInfoCategories methods={methods} />
      </ToggleContainer>

      <Marketplace methods={methods} />

      <ToggleContainer className="lg" title="Social Links">
        <SocialLinks methods={methods} />
      </ToggleContainer>
      {config.region !== "eu" && (
        <ToggleContainer className="lg" title="External API Keys">
          <TextFormGroup
            title="Tawk live chat"
            name="liveChatPK"
            handleChange={methods.handleChange}
            value={methods.values.liveChatPK ?? ""}
            error={methods.errors.liveChatPK}
            subText="Enter the Direct Chat Link from your Tawk account to enable live chat on your site. Eg. https://tawk.to/chat/123a45678b9c012d34e56fa7/1abc234d5"
          />
        </ToggleContainer>
      )}

      <ToggleContainer className="lg" title="Legal">
        <TermsAndConditions dest={dest} />
        <PrivacyPolicy dest={dest} />
        <CookiePolicy dest={dest} />
        <ThirdPartyServiceProvider methods={methods} />
        <NewsletterFooter methods={methods} />
      </ToggleContainer>

      <ToggleContainer
        className="lg"
        title="User Management"
        testId={"site-user-management"}
      >
        <InactiveUser methods={methods} />
        <UserManagement uuid={dest.uuid} />
      </ToggleContainer>
      <HistoryToggleContainer uuid={dest.uuid} />
    </Container>
  );
};
