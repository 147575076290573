import React from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage, Field, FormikProps } from "formik";
import { renderErrorMessage } from "./renderErrorMessage";

interface Values {
  businessNumber?: string;
}

export const BusinessNumberField: React.FC<
  Pick<FormikProps<Values>, "touched" | "errors">
> = ({ touched, errors }) => {
  const name: keyof Values = "businessNumber";

  return (
    <Form.Group>
      <Form.Label>Business number</Form.Label>
      <Field name={name}>
        {({ field }: any) => (
          <Form.Control
            placeholder="Business number"
            isInvalid={touched[name] ? errors[name] : undefined}
            {...field}
          ></Form.Control>
        )}
      </Field>
      <ErrorMessage name={name} render={renderErrorMessage} />
    </Form.Group>
  );
};
