import { useMemo } from "react";
import { useAddCompanyV2DestinationMutation } from "../../../../generated/admin";
import {
  EqMessageSuccess,
  EqMessageError,
  MessageFn
} from "../../../message/EqMessage";
import { RootRoutes } from "../../../../RouterOutlet";

/**
 * Hook for company suggestion.
 */
export function useCompanySuggestion(
  redirect: (path: string) => void,
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
) {
  const [
    mutation,
    { loading: addingDestination }
  ] = useAddCompanyV2DestinationMutation();

  return useMemo(
    () => ({
      addCompany: async (companyUuid: string, siteUuid: string) => {
        if (addingDestination) {
          return;
        }

        const result = await mutation({
          variables: {
            companies: [companyUuid],
            destination: siteUuid
          }
        });
        if (
          result.data?.addCompanyV2Destination.__typename === "SuccessResponse"
        ) {
          successMsgFn({ text: "Companies successfully added." });
          redirect(RootRoutes.COMPANIES);
        }
        if (
          result.data?.addCompanyV2Destination.__typename === "FailureResponse"
        ) {
          errorMsgFn({ text: result.data?.addCompanyV2Destination.reason });
        }
      },
      addingDestination
    }),
    [addingDestination, errorMsgFn, mutation, redirect, successMsgFn]
  );
}
