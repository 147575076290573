import React, {
  useState,
  useMemo,
  createContext,
  useContext,
  useEffect
} from "react";
import {
  CompanyListQuery,
  useCompanyListQuery
} from "../../../../generated/admin";
import { UserContext } from "../../../user/UserContext";

interface DeletedCompaniesStore {
  readonly deleted: string[];
  deleteCompanies(companyUuids: string[]): void;
  deleteCompany(uuid: string): void;
}

function useDeleteStoreFactory(): DeletedCompaniesStore {
  const user = useContext(UserContext);
  const destinationUuid = user?.activeDestination?.uuid ?? undefined;
  const [deleted, setDeleted] = useState<
    { uuid: string; destinationUuid?: string }[]
  >([]);

  return useMemo(
    () => ({
      deleted: deleted.flatMap((d) =>
        d.destinationUuid === destinationUuid ? [d.uuid] : []
      ),
      deleteCompanies: (companyUuids: string[]) => {
        setDeleted((deleted) => [
          ...deleted,
          ...companyUuids.map((uuid) => ({ uuid, destinationUuid }))
        ]);
      },
      deleteCompany: (uuid: string) => {
        setDeleted((deleted) => [{ uuid, destinationUuid }, ...deleted]);
      }
    }),
    [deleted, destinationUuid]
  );
}

const DeletedCompanyContext = createContext<DeletedCompaniesStore | null>(null);

export function useDeletedCompanyStore(): DeletedCompaniesStore {
  const store = useContext(DeletedCompanyContext);
  if (store == null) {
    throw new Error("deleted company store not initialized");
  }
  return store;
}

export const DeletedCompaniesStoreProvider: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  const store = useDeleteStoreFactory();
  return (
    <DeletedCompanyContext.Provider value={store}>
      {children}
    </DeletedCompanyContext.Provider>
  );
};

interface State {
  readonly searchTerm: string;
  readonly companies: CompanyListQuery | null;
  readonly loading: boolean;
  readonly options: Array<{ value: string; label: string }>;
}

interface IOptionStore extends State {
  setSearchTerm(searchTerm: string): void;
}

export function useOptionStore(): IOptionStore {
  const deletedCompaniesStore = useDeletedCompanyStore();
  const [state, setState] = useState<State>({
    searchTerm: "",
    companies: null,
    loading: true,
    options: []
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      options:
        state.companies?.companiesV2.edges.flatMap((edge) =>
          edge.node == null ||
          deletedCompaniesStore.deleted.includes(edge.node.uuid)
            ? []
            : [
                {
                  value: edge.node.uuid,
                  label: edge.node.name
                }
              ]
        ) ?? []
    }));
  }, [state.companies, deletedCompaniesStore.deleted]);

  const result = useCompanyListQuery({
    variables: {
      first: 40,
      search: state.searchTerm
    }
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: result.loading,
      companies: result.data ?? null
    }));
  }, [result]);

  function setSearchTerm(searchTerm: string) {
    setState((state) => ({
      ...state,
      searchTerm
    }));
  }

  return useMemo(
    () => ({
      ...state,
      setSearchTerm
    }),
    [state]
  );
}
