import { CapsuleButton } from "../../../components/CapsuleButton";
import {
  Modal,
  Button,
  Row,
  Col,
  Form as BootstrapForm,
} from "react-bootstrap";
import React, { useState } from "react";
import { FaFilter, FaUndo } from "react-icons/fa";
import { TextFormGroup } from "../../sites/site-details/fields/TextFormGroup";
import { SelectGroup } from "../../../components/formik/SelectGroup";
import { Formik, Form } from "formik";
import { DateSelectGroup } from "../../../components/formik/DateSelectGroup";
import {
  UserFilter,
  useUserSiteAttributesQuery,
  useSiteGroupsQuery,
  ProfileRegistrationType,
  useSiteDetailsQuery,
} from "../../../generated/admin";
import { CompaniesDropdown } from "../../../components/formik/CompaniesDropdown";
import { OptionType } from "../../../model/OptionType";
import { useUser } from "../UserContext";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";

type Filters = UserFilter & { company?: OptionType; emailInput?: string };

export const UserFilters = (props: {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<UserFilter>>;
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const filterCount = getFilterCount(props);
  const user = useUser();
  const attributesData = useUserSiteAttributesQuery({
    variables: {
      destinationUuid: user.activeDestination?.uuid,
    },
    skip: user.activeDestination == null,
  });
  const groupsData = useSiteGroupsQuery({
    variables: {
      destinationUuid: user.activeDestination?.uuid,
    },
    skip: user.activeDestination == null,
  });
  const siteData = useSiteDetailsQuery({
    variables: {
      uuid: user.activeDestination?.uuid,
    },
    skip: user.activeDestination == null,
  });

  const userTypeOptions = () => {
    let options = [];
    if (
      siteData.data?.destination.settings.registration.commercialSignupEnabled
    ) {
      options.push({
        label: "Commercial",
        value: ProfileRegistrationType.Commercial,
      });
    }
    if (siteData.data?.destination.settings.registration.visitorSignupEnabled) {
      options.push({
        label: "Visitor",
        value: ProfileRegistrationType.Visitor,
      });
    }
    if (
      siteData.data?.destination.settings.registration.residentialSignupEnabled
    ) {
      options.push({
        label: "Residential",
        value: ProfileRegistrationType.Residential,
      });
    }

    return options;
  };

  return (
    <Formik<Filters>
      initialValues={props.filters}
      onSubmit={(values) => {
        props.setFilters({
          ...values,
          companyUuid: values.company?.value,
        });
        handleClose();
      }}
    >
      {(methods) => (
        <>
          <CapsuleButton onClick={handleShow}>
            <FaFilter />
            <span>
              &nbsp;Filters{" "}
              {filterCount > 0 ? (
                <>
                  | <b style={{ color: "#000" }}>{filterCount}</b>
                </>
              ) : null}
            </span>
          </CapsuleButton>
          <Modal
            dialogClassName="w-80"
            size="xl"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Row className="w-100">
                <Col xs="10">
                  <Modal.Title className="pl-3">Filter</Modal.Title>
                </Col>
                <Col xs="2">
                  <Button
                    style={{
                      float: "right",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      methods.setValues({});
                    }}
                    className="p-0 mr-3 text-dark font-weight-bold"
                    variant="link"
                  >
                    <FaUndo /> Reset
                  </Button>
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Body className="mb-2">
              <Form className="p-3">
                <TextFormGroup fullWidth={true} title={"Name"} name={"name"} />
                <BootstrapForm.Group as={Row} controlId="emailInput">
                  <BootstrapForm.Label column xs="3">
                    Email
                  </BootstrapForm.Label>
                  <Col xs="9">
                    <BootstrapForm.Control
                      as="textarea"
                      rows={3}
                      name="emailInput"
                      isInvalid={methods.errors.emailInput != null}
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        methods.setFieldValue("emailInput", val);
                        if (val == null) {
                          methods.setFieldValue("email", undefined);
                          methods.setFieldValue("emails", undefined);
                          return;
                        }
                        const emails = val
                          .split(",")
                          .map((v) => v.trim())
                          .filter((v) => v !== "");
                        if (emails.length === 1) {
                          methods.setFieldValue("email", emails[0]);
                          methods.setFieldValue("emails", undefined);
                        } else {
                          methods.setFieldValue("email", undefined);
                          methods.setFieldValue("emails", emails);
                        }
                      }}
                      value={methods.values.emailInput!}
                    />
                    <BootstrapForm.Text className="text-muted">
                      Enter a single email address or add multiple email
                      addresses separated by a comma.
                    </BootstrapForm.Text>
                    {methods.errors.emailInput && (
                      <BootstrapForm.Control.Feedback type="invalid">
                        {methods.errors.emailInput}
                      </BootstrapForm.Control.Feedback>
                    )}
                  </Col>
                </BootstrapForm.Group>
                {user.activeDestination != null ? (
                  <LoadingOrErrorDisplay
                    loading={siteData.loading}
                    error={siteData.error}
                  >
                    <SelectGroup
                      title={"User type"}
                      name={"registrationType"}
                      placeholder={"Select a user type"}
                      options={userTypeOptions()}
                    />
                  </LoadingOrErrorDisplay>
                ) : null}
                <SelectGroup
                  title={"Verified email"}
                  name={"verified"}
                  placeholder={"Select a status"}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
                <CompaniesDropdown title={"Company"} name={"company"} />
                <SelectGroup
                  title={"Status"}
                  name={"status"}
                  placeholder={"Select a status"}
                  options={[
                    {
                      label: (
                        <span className="text-warning">Pending approval</span>
                      ),
                      value: "pending_approval",
                    },
                    {
                      label: <span className="text-success">Active</span>,
                      value: "active",
                    },
                    {
                      label: <span className="text-danger">Deactivated</span>,
                      value: "deactivated",
                    },
                  ]}
                />
                <DateSelectGroup title={"Last seen"} name={"lastSeen"} />
                <DateSelectGroup title={"Account age"} name={"accountAge"} />
                {user.activeDestination != null ? (
                  <>
                    <SelectGroup
                      title={"Email subscription"}
                      name={"subscribedToEmails"}
                      placeholder={"Select a status"}
                      options={[
                        { label: "On", value: true },
                        { label: "Off", value: false },
                      ]}
                    />
                    <LoadingOrErrorDisplay
                      loading={groupsData.loading}
                      error={groupsData.error}
                    >
                      <SelectGroup
                        title={"Groups"}
                        name={"groups"}
                        isMulti={true}
                        placeholder={"Select groups"}
                        options={groupsData.data?.siteGroups.map((a) => {
                          return {
                            value: a.uuid,
                            label: a.name,
                          };
                        })}
                      />
                    </LoadingOrErrorDisplay>
                    <LoadingOrErrorDisplay
                      loading={attributesData.loading}
                      error={attributesData.error}
                    >
                      <SelectGroup
                        title={"Attributes"}
                        name={"attributes"}
                        isMulti={true}
                        placeholder={"Select attributes"}
                        options={attributesData.data?.userSiteAttributes.map(
                          (a) => {
                            return {
                              value: a.uuid,
                              label: a.name,
                            };
                          }
                        )}
                      />
                    </LoadingOrErrorDisplay>
                  </>
                ) : (
                  <SelectGroup
                    title={"System permissions"}
                    name={"permissions"}
                    isMulti={true}
                    placeholder={"Select permissions"}
                    options={[
                      { label: "Admin (VMS)", value: "store_admin" },
                      {
                        label: "Access attendee reports (Checkin)",
                        value: "AccessAttendeeReport",
                      },
                    ]}
                  />
                )}

                <Row className="justify-content-md-center mt-4">
                  <Button
                    type="submit"
                    className="mr-2"
                    variant="primary"
                    data-testid="btnInviteUser"
                  >
                    Apply
                  </Button>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
        </>
      )}
    </Formik>
  );
};

function getFilterCount(props: {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<UserFilter>>;
}) {
  const keys = Object.keys(props.filters) as Array<keyof Filters>;
  const filterCount = keys.filter((k) => {
    return (
      k !== "company" &&
      k !== "emailInput" &&
      props.filters[k] != null &&
      props.filters[k] !== ""
    );
  }).length;
  return filterCount;
}
