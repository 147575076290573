import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import { SiteList } from "./site-list/SiteList";
import { SiteSettingsModule } from "./site-details/SiteSettingsModule";
import { useUser } from "../user/UserContext";
import { RootRoutes } from "../../RouterOutlet";
import { useSiteContextHelper } from "../../hooks/useSiteContextHelper";
import { CreateSite } from "./site-create/CreateSite";
import { PlatformType } from "../../generated/admin";
import { HomeUIWidgetModule } from "./home-uiwidget/HomeUIWidgetModule";
import { IntegrationsModule } from "./integrations";

const RootComponent = () => {
  const siteCtxHelper = useSiteContextHelper();
  const user = useUser();

  useEffect(() => {
    if (user.activeDestination != null) {
      siteCtxHelper.redirect(
        RootRoutes.SITES + "/" + user.activeDestination?.uuid
      );
    }
  }, [user.activeDestination, siteCtxHelper])

  return <SiteList />;
}

export const SitesModule = () => {
  return (
    <Routes>
      <Route path={`/create`} element={<CreateSite />} />
      <Route path={`/:uuid/web-homeuiwidget`} element={<HomeUIWidgetModule platform={PlatformType.Web} />} />
      <Route path={`/:uuid/mobile-homeuiwidget`} element={<HomeUIWidgetModule platform={PlatformType.Mobile} />} />
      <Route path={`/:uuid/integrations`} element={<IntegrationsModule />} />
      <Route path={`/:uuid/*`} element={<SiteSettingsModule />} />
      <Route path="*" element={<RootComponent />} />
    </Routes>
  );
};
