import React, { useContext, useEffect, useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { UserFormContext } from "./context/UserFormContext";
import { UserFormData, userFormNameCheck } from "./model/UserFormData";
import Select from "react-select";
import { OptionType } from "../../../model/OptionType";
import { UserContext } from "../UserContext";

interface UserFormSiteSelectorProps {
  setActiveSite: (uuid: string | null) => void;
}

export const UserFormSiteSelector: React.FC<UserFormSiteSelectorProps> = (
  { setActiveSite }
) => {
  const formName = userFormNameCheck("activeSite");
  const ctx = useContext(UserFormContext);
  const systemUser = useContext(UserContext);
  const methods = useFormContext<UserFormData>();

  const options: OptionType[] = useMemo(() => {
    const opts = systemUser?.activeDestination != null
      ? [
          {
            value: systemUser.activeDestination.uuid,
            label: systemUser.activeDestination.name
          }
        ]
      : ctx.user?.destinationsV2?.edges.flatMap((e) => {
          return e.node == null
            ? []
            : [
                {
                  value: e.node?.destination.uuid,
                  label: e.node?.destination.name ?? "none"
                }
              ];
        }) ?? [];

    return opts;
  }, [ctx.user?.destinationsV2, systemUser?.activeDestination])

  const total = options.length ?? 0;

  // Set the active site as default when site only 1.
  useEffect(() => {
    if (options.length === 1) {
      setActiveSite(options[0].value);
    }
  }, [options, setActiveSite])

  if (total === 0) {
    return <></>;
  }

  const onlyOneSite = options.length === 1;

  return (
    <div className="border-2 border-top border-light mt-3 mb-4 py-3 user-site-selector">
      <Controller
        render={({field: { value, onChange }}) =>
          <Select<OptionType>
            classNamePrefix="eq"
            options={options}
            isClearable={!onlyOneSite}
            placeholder="Select a site to view their profile"
            className={`react-select w-50`}
            isDisabled={onlyOneSite}
            onChange={(event: any) => {
              setActiveSite(event?.value ?? null);
              onChange(event);
            }}
            value={value as OptionType}
          />
        }
        control={methods.control}
        name={formName}
        defaultValue={onlyOneSite ? options[0] : undefined}
      />
    </div>
  );
};
