import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useField, FieldAttributes } from "formik";
import Select from "react-select";
import { OptionType } from "../../model/OptionType";

interface Props {
  error?: string;
  title: string;
  subText?: string;
  maxLength?: number;
  asTextarea?: boolean;
  options: OptionType[];
  isMulti?: boolean;
}

export interface DateFilter {
  interval: string;
  amount?: number;
  comparator: string;
}

export const DateSelectGroup = ({
  error,
  title,
  subText,
  maxLength,
  asTextarea = false,
  ...props
}: FieldAttributes<any> & Props) => {
  const [field, , helpers] = useField(props);
  const { interval, amount, comparator }: DateFilter = field.value ?? {
    interval: "days",
    amount: null,
    comparator: "gt"
  };
  const intervals = [
    { label: "Days", value: "days" },
    { label: "Months", value: "months" }
  ];
  const comparators = [
    { label: "Greater than", value: "gt" },
    { label: "Less than", value: "lt" }
  ];

  return (
    <Form.Group as={Row} controlId={field.name}>
      <Form.Label column md="3">
        {title}
      </Form.Label>
      <Col md="3">
        <Select<OptionType>
          classNamePrefix="eq"
          className="react-select"
          value={comparators.find((c) => c.value === comparator)}
          options={comparators}
          onChange={(selectedOption: any) =>
            helpers.setValue({
              interval,
              amount,
              comparator: selectedOption?.value ?? "gt"
            })
          }
        />
      </Col>
      <Col md="3">
        <Form.Control
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            helpers.setValue({
              interval,
              amount: Number(e.target.value),
              comparator
            })
          }
          value={amount?.toString() ?? ""}
        />
      </Col>
      <Col md="3">
        <Select<OptionType>
          classNamePrefix="eq"
          className="react-select"
          value={intervals.find((c) => c.value === interval)}
          options={intervals}
          onChange={(selectedOption: any) =>
            helpers.setValue({
              interval: selectedOption?.value ?? "days",
              amount,
              comparator
            })
          }
        />
      </Col>
    </Form.Group>
  );
};
