import React, { useState, useEffect, useMemo, useContext } from "react";
import { GroupContext } from "../GroupContext";
import {
  useGroupsQuery,
  useUpsertGroupsMutation,
  useDeleteGroupMutation,
  useUpsertGroupMutation,
  GroupsDocument,
} from "../../../../generated/admin";
import {
  EqMessageError,
  EqMessageSuccess,
  MessageFn,
} from "../../../message/EqMessage";
import { Group } from "../model/Group";
import { GroupStore } from "../model/GroupStore";
import { GroupState } from "../model/GroupState";

export const groupsSort = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });

export function useGroupsStoreFactory(
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
): GroupStore {
  const [state, setState] = useState<GroupState>({
    groups: [],
    saveErrors: [],
    loading: false,
    newGroupName: "",
    groupsToAdd: [],
    saving: false,
    deleting: "",
    sortedGroups: [],
  });

  const results = useGroupsQuery({ fetchPolicy: "network-only" });
  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: results.loading,
      initalLoadError: results.error,
      groups: results.data?.groups ?? [],
    }));
  }, [results]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      sortedGroups: [...state.groups].sort(groupsSort),
    }));
  }, [state.groups]);

  const [deleteMutation] = useDeleteGroupMutation();

  const [groupsUpsertMutation, upsertGroupsResult] = useUpsertGroupsMutation();
  useEffect(() => {
    setState((state) => ({
      ...state,
      saving: upsertGroupsResult.loading,
    }));
  }, [upsertGroupsResult.loading]);

  const [groupUpsertMutation, upsertGroupResult] = useUpsertGroupMutation();
  useEffect(() => {
    setState((state) => ({
      ...state,
      saving: upsertGroupResult.loading,
    }));
  }, [upsertGroupResult.loading]);

  return useMemo(() => {
    const refetchQueries = [
      {
        query: GroupsDocument,
      },
    ];

    const updateGroup = (uuid: string, name: string) => {
      setState((state) => ({
        ...state,
        groups: state.groups.map((l) => {
          if (l.uuid === uuid) {
            return {
              ...l,
              name,
            }
          }
          return l;
        }),
      }));
    };

    return {
      ...state,
      groupsSort,
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const newGroupName = event.target.value;
        setState((state) => ({
          ...state,
          newGroupName,
        }));
      },
      addGroup: () => {
        if (state.newGroupName === "") {
          errorMsgFn({ text: "Please click on add before saving." });
          return;
        }
        if (state.groupsToAdd.map((l) => l.name).includes(state.newGroupName)) {
          errorMsgFn({ text: "Cannot create duplicate group name." });
          return;
        }
        setState((state) => ({
          ...state,
          groupsToAdd: [...state.groupsToAdd, { name: state.newGroupName }],
          newGroupName: "",
        }));
      },
      addGroups: (newGroups: Group[]) => {
        setState((state) => ({
          ...state,
          groups: [...state.groups, ...newGroups],
        }));
      },
      saveGroup: async (uuid: string, name: string): Promise<boolean> => {
        const result = await groupUpsertMutation({
          variables: { uuid, name },
        });

        if (result.data?.upsertGroup.__typename === "GroupSuccessResponse") {
          updateGroup(uuid, name);
          await EqMessageSuccess({ text: "Group name updated." });
        } else {
          await EqMessageError({
            text:
              result.data?.upsertGroup.__typename === "FailureResponse"
                ? result.data.upsertGroup.reason
                : "There was an error updating the group.",
          });
        }

        return result.data?.upsertGroup.__typename === "GroupSuccessResponse";
      },
      saveGroups: async () => {
        if (state.groupsToAdd.length === 0) {
          errorMsgFn({
            text: "Please click on add before saving.",
          });
          return;
        }
        const result = await groupsUpsertMutation({
          variables: {
            input: state.groupsToAdd,
          },
          refetchQueries,
        });

        const addedGroups: Group[] =
          result.data?.upsertGroups.flatMap((attr) => {
            if (attr.__typename === "GroupSuccessResponse") {
              return [
                {
                  uuid: attr.group.uuid,
                  name: attr.group.name,
                },
              ];
            }

            return [];
          }) ?? [];

        const errors =
          result.data?.upsertGroups.flatMap((attr) => {
            if (attr.__typename === "FailureResponse") {
              return [attr.reason];
            }
            return [];
          }) ?? [];

        setState((state) => ({
          ...state,
          groups: [...state.groups, ...addedGroups],
          saveErrors: errors.map((message) => ({
            message,
          })),
          groupsToAdd: [],
        }));

        if (errors.length > 0) {
          errorMsgFn({
            html: errors.join("<br/>"),
          });
        }
        if (addedGroups.length > 0) {
          const label = addedGroups.length > 1 ? "Groups" : "Group";
          successMsgFn({ text: `${label} created.` });
        }
      },
      deleteGroup: async (uuid: string) => {
        setState((state) => ({ ...state, deleting: uuid }));

        const result = await deleteMutation({
          variables: { uuid },
          refetchQueries,
        });
        setState((state) => ({ ...state, deleting: "" }));

        if (result.data == null) {
          errorMsgFn({
            text: "Something went wrong, please refresh the page and try again.",
          });
          return;
        }

        if (result.data.deleteGroup.__typename === "FailureResponse") {
          errorMsgFn({ text: result.data.deleteGroup.reason });
          return;
        }

        successMsgFn({ text: "Group has been deleted!" });
        setState((state) => ({
          ...state,
          groups: state.groups.filter((l) => l.uuid !== uuid),
        }));
      },
      removeGroup: (name: string) => {
        setState((state) => ({
          ...state,
          groupsToAdd: state.groupsToAdd.filter((l) => l.name !== name),
        }));
      },
      updateGroup,
      updateLocalGroup:
        (original: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
          setState((state) => ({
            ...state,
            groupsToAdd: state.groupsToAdd.map((l) =>
              l.name === original ? { name: e.target.value } : l
            ),
          }));
        },
    };
  }, [
    state,
    errorMsgFn,
    groupUpsertMutation,
    groupsUpsertMutation,
    successMsgFn,
    deleteMutation,
  ]);
}

export function useGroupsStore(): GroupStore {
  const store = useContext(GroupContext);
  if (store == null) {
    throw Error("Groups store context not initialized.");
  }
  return store;
}

export const GroupsStoreProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const store = useGroupsStoreFactory();

  return (
    <GroupContext.Provider value={store}>{children}</GroupContext.Provider>
  );
};
