import React from "react";
import { Form, Row, Col, Badge } from "react-bootstrap";
import Select from "react-select";
import { FormikContextType } from "formik";

import { EqOne } from "../../../../svgs/EqOne";

interface Props {
  methods: Pick<
    FormikContextType<{ locale: string }>,
    "values" | "setFieldValue" | "touched" | "handleChange"
  >;
  showDebugLanguages?: boolean;
}

interface LanguageOption {
  value: string;
  label: string;
}

const languages: LanguageOption[] = [
  { value: "en-US", label: "English (US)" },
  { value: "en-AU", label: "English (AU)" },
  { value: "en-CA", label: "English (CA)" },
  { value: "en-GB", label: "English (UK)" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "pl", label: "Polish" },
  { value: "es", label: "Spanish" },
  { value: "hu", label: "Hungarian" },
  { value: "nl", label: "Dutch" },
  { value: "sk", label: "Slovak" },
  { value: "cs", label: "Czech" },
  { value: "pt-PT", label: "Português" },
];

const debugLanguages: LanguageOption[] = [
  { value: "ja", label: "Japanese" },
  { value: "en", label: "English (Base)" },
  { value: "en-US-debug", label: "English (Debug)" },
  { value: "en-US-debug-longer", label: "English (Debug +25%)" },
];

export const SiteLocale: React.FC<Props> = ({
  methods,
  showDebugLanguages = false,
}) => {
  const options = showDebugLanguages
    ? [...languages, ...debugLanguages]
    : [...languages];

  const value =
    methods.values.locale === "en" && !showDebugLanguages
      ? options.find((option) => option.value === "en-US")
      : options.find((option) => option.value === methods.values.locale);

  return (
    <Form.Group className="locale" as={Row} controlId="locale">
      <Form.Label column md="3">
        Language
        <br />
        <Badge variant="primary">
          <EqOne />
        </Badge>
      </Form.Label>
      <Col md="9" lg="6">
        <Select<LanguageOption>
          className={`react-select${value == null ? " is-invalid" : ""}`}
          classNamePrefix="eq"
          name="locale"
          aria-label="locale"
          options={options.sort((a, b) => a.label.localeCompare(b.label))}
          value={value}
          onChange={(option) =>
            methods.setFieldValue("locale", option?.value ?? "en")
          }
        />
        <Form.Text className="text-muted">
          This will determine the language and date and time formats used in
          Equiem One.
        </Form.Text>
      </Col>
    </Form.Group>
  );
};
