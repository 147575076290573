import React from "react";
import { PageNavigationButton } from "../../../components/NavigationButton";
import { RootRoutes } from "../../../RouterOutlet";
import { useParams } from "react-router-dom";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { useUser } from "../../user/UserContext";

/**
 * Navigation within taxonomy.
 */
export const CompanyDetailsNavigation: React.FC<{
  showIntegrations: boolean;
}> = ({ showIntegrations }) => {
  const { uuid } = useParams<{ uuid: string }>();
  const user = useUser();
  const siteCtxHelper = useSiteContextHelper();
  const currentSiteHasIntegrations =
    user.integrations.evolutionEnabled || user.integrations.yardiEnabled;

  return (
    <>
      <div className="p-3">
        <PageNavigationButton
          to={siteCtxHelper.appendUrl(`${RootRoutes.COMPANIES}/edit/${uuid}`)}
          btnClasses="px-5"
          title="General"
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
        />
        {showIntegrations && currentSiteHasIntegrations ? (
          <PageNavigationButton
            to={siteCtxHelper.appendUrl(
              `${RootRoutes.COMPANIES}/edit/${uuid}/integrations`
            )}
            btnClasses="px-5"
            title="Integration"
            className="d-block d-sm-inline-block"
          />
        ) : null}
      </div>
      <hr className="my-0" />
    </>
  );
};
