import Swal, { SweetAlertResult, SweetAlertOptions } from "sweetalert2";

export type MessageFn = (
  options: SweetAlertOptions
) => Promise<SweetAlertResult>;

/**
 * Eq message.
 */
const base = () => {
  return Swal.mixin({
    buttonsStyling: false,
    customClass: {
      confirmButton: `btn btn-primary mx-2 shadow-none`,
      cancelButton: "btn btn-secondary"
    }
  });
};

export const toast = (title: string) =>
  Swal.fire({
    toast: true,
    timer: 1000,
    showConfirmButton: false,
    position: "center-right",
    html: `
      <div class="toast-body font-weight-light p-0 m-0">
        ${title}
      </div>
    `
  });

export const EqMessage: MessageFn = (options: SweetAlertOptions) =>
  base().fire({
    ...options
  });
export const EqDangerMessage: MessageFn = (options: SweetAlertOptions) =>
  base().fire({
    ...options
  });
export const EqMessageError: MessageFn = (options: SweetAlertOptions) =>
  base().fire({
    ...options,
    icon: "error"
  });
export const EqMessageSuccess: MessageFn = (options: SweetAlertOptions) =>
  base().fire({
    ...options,
    icon: "success"
  });
export const EqMessageWarning: MessageFn = (options: SweetAlertOptions) =>
  base().fire({
    ...options,
    icon: "warning"
  });
export const EqMessageQuestion: MessageFn = (options: SweetAlertOptions) =>
  base().fire({
    ...options,
    icon: "question"
  });
export const EqMessageInfo: MessageFn = (options: SweetAlertOptions) =>
  base().fire({
    ...options,
    icon: "info"
  });
