import React, { useCallback, useState } from "react";
import { JoditTextEditor } from "../../../../components/JoditTextEditor";
import { useFormikContext } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import {
  SiteDetailsQuery,
  useUpdateDestinationLegalMutation,
} from "../../../../generated/admin";
import { CardAccordion } from "../../../../components/SingleEqAccordion";
import { CookiePolicyLogs } from "./CookiePolicyLogs";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { CreateButton } from "../../../../components/CreateButton";
import { Spinner } from "react-bootstrap";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const CookiePolicy: React.FC<Props> = ({ dest }) => {
  const fm = useFormikContext<SiteConfigFormData>();
  const [value, setValue] = useState(dest.cookiePolicyRaw);
  const [save, { loading }] = useUpdateDestinationLegalMutation();
  const sendIt = useCallback(async () => {
    const { data } = await save({
      variables: {
        input: {
          uuid: dest.uuid,
          cookiePolicy: value,
          cookiePolicyChangeReason: stringNotEmpty(
            fm.values.cookiePolicyChangeReason
          )
            ? fm.values.cookiePolicyChangeReason
            : undefined,
        },
      },
    });

    if (data?.adminUpdateDestinationLegal?.__typename === "SuccessResponse") {
      EqMessageSuccess({ text: "Successfully saved cookie policy." });
      return;
    }
    if (data?.adminUpdateDestinationLegal?.__typename === "FailureResponse") {
      EqMessageError({ text: data.adminUpdateDestinationLegal.reason });
    } else {
      EqMessageError({ text: "An unexpected error occurred" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dest.uuid, fm.values.cookiePolicyChangeReason, value]);

  return (
    <CardAccordion title="Cookie Policy">
      <JoditTextEditor
        placeholder="Enter Cookie Policy..."
        content={value}
        finishCallback={setValue}
      />
      <div className="pt-3 text-right">
        <CreateButton disabled={loading} onClick={sendIt}>
          <span>
            {loading ? (
              <>
                <Spinner animation="grow" size="sm" /> Saving...
              </>
            ) : (
              "Save Cookie Policy"
            )}
          </span>
        </CreateButton>
      </div>
      <CookiePolicyLogs dest={dest} />
    </CardAccordion>
  );
};
