import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { OptionType } from "../../../../model/OptionType";
import { useClientsQuery } from "../../../../generated/admin";
import { FormikContextType } from "formik";
import { useConfig } from "../../../../providers/ConfigProvider";
import { mapRegionToClientRegion } from "../../../../util/regionEnumMappers";

interface Props {
  methods: Pick<
    FormikContextType<{ client?: string }>,
    "setFieldValue" | "values" | "errors" | "touched"
  >;
  disabled?: boolean;
  label?: string;
  children?: React.ReactNode;
}

export const ClientNameFormGroup = ({
  methods,
  disabled = false,
  label,
  children,
}: Props) => {
  const config = useConfig();
  const region = mapRegionToClientRegion(config.region);
  const { data, loading } = useClientsQuery({
    variables: {
      region,
    },
  });
  const options: OptionType[] =
    data?.clients.map((c) => ({
      value: c.uuid,
      label: `${c.name} (${c.region})`,
    })) ?? [];
  return (
    <Form.Group as={Row} controlId="client">
      <Form.Label column md="3">
        {label ?? "Client name"}
      </Form.Label>
      <Col md="9" lg="6" className="site-client-select">
        <Select<OptionType>
          classNamePrefix="eq"
          isLoading={loading}
          isDisabled={disabled}
          options={options}
          value={options?.find(
            (option) => option.value === methods.values.client
          )}
          onChange={(option: any) => {
            methods.setFieldValue("client", option.value);
            methods.setFieldValue("clientName", option.label);
          }}
          placeholder="Client name"
          aria-label="site-client-select"
          className={`react-select${
            methods.errors.client != null ? " is-invalid" : ""
          }`}
        />
        {methods.errors.client && methods.touched.client && (
          <Form.Control.Feedback type="invalid">
            {methods.errors.client}
          </Form.Control.Feedback>
        )}

        {children ?? (
          <Form.Text className="text-muted">
            The client name is used across systems to identify which client a
            site belongs to. New clients can be added on the Taxonomy page.
          </Form.Text>
        )}
      </Col>
    </Form.Group>
  );
};
