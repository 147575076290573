import { useState, useCallback, ReactNode } from "react";
import { SubTitle } from "../../../components";
import { Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import { CreateButton } from "../../../components/CreateButton";
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
} from "react-hook-form";
import {
  CompanyFragment,
  Destination,
  CompanyAttribute,
  CompanyIndustry,
  useCreateOrUpdateCompanyMutation,
} from "../../../generated/admin";
import { EqMessageError, EqMessageSuccess } from "../../message/EqMessage";
import { CompanyValidationValues } from "./CompanyValidationValues";
import { CompanyLevels } from "./levels/CompanyLevels";
import { useCurrentSiteLevels } from "./levels/useCurrentSiteLevels";
import { DestinationsCheckbox } from "../../../components/DestinationsCheckbox";
import { Divider } from "../../../components/Divider";
import Select from "react-select";
import { OptionType } from "../../../model/OptionType";
import { CompanyFormData } from "./model/CompanyFormData";
import { useCompanyDetailsForm } from "./hook/useCompanyDetailsForm";
import { companyValidationOptions } from "./companyValidationOptions";
import useDebounce from "../../../hooks/useDebounce";
import { CompanySuggestion } from "./suggestion/CompanySuggestion";
import { BuildingWithLevels } from "../../../model/BuildingWithLevels";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICompanyDetailsForm {
  company?: CompanyFragment | null;
  activeDestination?: Maybe<string>;
  activeDestinationBuildings: BuildingWithLevels[];
  destinations: Pick<Destination, "uuid" | "name">[];
  industries: Pick<CompanyIndustry, "uuid" | "name">[];
  attributes: Pick<CompanyAttribute, "uuid" | "name">[];
}

/**
 * Company details form.
 */
export const CompanyDetailsForm: React.FC<ICompanyDetailsForm> = ({
  company,
  activeDestination,
  activeDestinationBuildings,
  destinations,
  industries,
  attributes,
}) => {
  const [search, setSearch] = useState<string>("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const resetSearch = useCallback(() => setSearch(""), [setSearch]);
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearch(e.currentTarget.value),
    [setSearch]
  );

  const methods = useForm<CompanyFormData>({
    defaultValues: {
      name: company?.name,
      validationValues: company?.validation?.values.map((x) => {
        return {
          value: x.value,
          rule: companyValidationOptions.find((o) => o.value === x.rule),
        };
      }),
    },
  });
  const {
    fields: validationValuesFields,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: "validationValues",
  });

  const siteLevels = useCurrentSiteLevels(
    activeDestination
      ? {
          uuid: activeDestination,
          buildings: activeDestinationBuildings,
        }
      : undefined,
    company
  );

  const [mutation, { loading: saving }] = useCreateOrUpdateCompanyMutation();
  const { onSubmit, disableFields } = useCompanyDetailsForm({
    mutationFn: mutation,
    successMsgFn: EqMessageSuccess,
    errorMsgFn: EqMessageError,
    buildingLevelUuids: siteLevels.state.buildingLevelUuids,
    companyUuid: company?.uuid,
    activeDestination,
    validationMessage: company?.validation?.message,
  });

  const attributeOptions: OptionType[] = attributes.map((a) => ({
    value: a.uuid,
    label: a.name,
  }));

  const industryOptions: OptionType[] = industries.map((i) => ({
    value: i.uuid,
    label: i.name,
  }));

  return (
    <FormProvider {...methods}>
      <Form
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
        className="gray-disable-form"
      >
        <Form.Group as={Row} controlId="companyName">
          <Form.Label column md="3" lg="2">
            Company name
          </Form.Label>
          <Col md="9" lg="6">
            <Form.Control
              type="text"
              disabled={disableFields}
              placeholder="e.g. Equiem"
              {...methods.register("name", { required: "Required." })}
              onChange={handleSearchChange}
            />
            {methods.formState.errors.name && (
              <Form.Control.Feedback type="invalid">
                {methods.formState.errors.name.message}
              </Form.Control.Feedback>
            )}
            {activeDestination && (
              <CompanySuggestion
                siteUuid={activeDestination}
                search={debouncedSearchTerm}
                hideFn={resetSearch}
              />
            )}
            <Form.Text className="text-muted">
              The company name will be displayed in a user’s profile and
              throughout other Equiem systems. These are unique and cannot be
              duplicated.
            </Form.Text>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="companyIndustry">
          <Form.Label column md="3" lg="2">
            Industry
          </Form.Label>
          <Col md="9" lg="6">
            <Controller
              render={(props) => (
                <Select<OptionType>
                  isDisabled={disableFields}
                  classNamePrefix="eq"
                  options={industryOptions}
                  placeholder="Select industry"
                  className={`react-select${
                    methods.formState.errors.industry ? " is-invalid" : ""
                  }`}
                  value={props.field.value}
                  onChange={props.field.onChange}
                />
              )}
              rules={{
                required: "Required.",
              }}
              control={methods.control}
              name="industry"
              defaultValue={
                company?.industry?.uuid != null
                  ? industryOptions.find(
                      (x) => x.value === company?.industry?.uuid
                    )
                  : undefined
              }
            />
            {methods.formState.errors.industry && (
              <Form.Control type="invalid">
                {methods.formState.errors.industry.message as ReactNode}
              </Form.Control>
            )}
            <Form.Text className="text-muted">
              Select the appropriate industry for this company. This list has
              been defined by an administrator. If a new type is required
              contact an administrator.
            </Form.Text>
          </Col>
        </Form.Group>

        {activeDestination && (
          <Form.Group as={Row} controlId="companyAttribute">
            <Form.Label column md="3" lg="2">
              Attributes
            </Form.Label>
            <Col md="9" lg="6">
              <Controller
                render={(props) => (
                  <Select<OptionType, boolean>
                    isMulti
                    classNamePrefix="eq"
                    options={attributeOptions}
                    placeholder="Select attributes"
                    className="react-select"
                    value={props.field.value}
                    onChange={props.field.onChange}
                  />
                )}
                control={methods.control}
                name="attributes"
                defaultValue={
                  company?.companyDestinations.flatMap((cd) => {
                    if (cd.destinationUuid !== activeDestination) {
                      return [];
                    }

                    return cd.attributes.map((a) => ({
                      value: a.uuid,
                      label: a.name,
                    }));
                  }) ?? undefined
                }
              />

              <Form.Text className="text-muted">
                Attributes are additional tags that you can assign to the
                company to help define it further. These may be used throughout
                other Equiem systems for segmentation purposes. Attributes can
                be managed in the attributes settings page.
              </Form.Text>
            </Col>
          </Form.Group>
        )}

        <Divider />

        <SubTitle>Validation Values</SubTitle>
        <p className="mb-5">
          A user who works in a building, must select the company they are
          associated with during registration and their email domain must be an
          exact match of any of the values from the below table. Otherwise, they
          will need to be manually approved. If no values are set, the user will
          be approved automatically irrespective of the email address they use
          to register with.
        </p>

        <Form.Group as={Row} controlId="validationValues">
          <Form.Label column md="3" lg="2">
            Validation values
          </Form.Label>
          <Col md="9" lg="10" className="pb-3">
            <CompanyValidationValues
              append={append as any}
              remove={remove}
              fields={validationValuesFields}
              disableEdit={disableFields}
            />

            <Form.Text className="text-muted mt-3">
              The validation value is what the user must match in order to be
              automatically approved during registration. Multiple values can
              exist. Include everything after the @ symbol in your value.
            </Form.Text>
          </Col>
        </Form.Group>

        {activeDestination == null && (
          <>
            <Divider />
            <SubTitle>Sites</SubTitle>
            {company != null ? (
              <>
                <p>
                  Sites enabled below are the current sites using this company,
                  not the sites that it is shared with. By default, a company is
                  available for all sites to use. Enabling a site in this list
                  will add it to the selected site whereas disabling a site will
                  remove it.
                </p>

                {company?.flexOperator != null && (
                  <Alert variant="warning">
                    This company is a Flex Operator. Changing the site
                    associations will affect their Flex Tenants as well.
                  </Alert>
                )}

                <DestinationsCheckbox
                  destinations={destinations}
                  selectedUuids={(company?.companyDestinations ?? []).map(
                    (d) => d.destinationUuid
                  )}
                />
              </>
            ) : (
              <p>
                Companies created by an administrator are automatically
                available for all sites to use. When editing a company, you will
                be able to see which sites it is used at.
              </p>
            )}
          </>
        )}

        <CompanyLevels
          {...siteLevels}
          isFlex={company?.flexOperator != null}
          flexTenants={company?.flexTenants}
        />

        <div className="mt-5 text-right">
          <CreateButton disabled={saving} type="submit">
            {saving ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Save"
            )}
          </CreateButton>
        </div>
      </Form>
    </FormProvider>
  );
};
