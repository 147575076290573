import { useUpdatedUserStore, UpdatedUserStore } from "./UpdatedUserStore";
import { useState, useEffect, useMemo, useContext } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import {
  useUserListQuery,
  UserListQuery,
  UserSort,
  UserSortField,
  UserFilter,
  ProfileStatus,
} from "../../../../generated/admin";
import { UserContext } from "../../UserContext";
import { useTableSearch } from "../../../../hooks/useTableSearch";

interface State {
  users: UserStoreUser[];
  loading: boolean;
  error?: { message: string };
  endCursor?: string | null;
  totalCount: number;
}

export function transformResult(
  result: Pick<
    ReturnType<typeof useUserListQuery>,
    "data" | "loading" | "error"
  >,
  updatedStore: UpdatedUserStore
) {
  return {
    users: transformUsers(updatedStore, result.data),
    loading: result.loading,
    error: result.error,
    endCursor: result.data?.users.pageInfo.endCursor,
    totalCount: result.data?.users.totalCount ?? 0,
  };
}

export function transformUsers(
  updatedStore: UpdatedUserStore,
  data?: UserListQuery
): UserStoreUser[] {
  return (
    data?.users.edges.flatMap((e) => {
      const profile = e.node?.profile;
      if (profile == null || e.node == null) {
        return [];
      }
      const status =
        updatedStore.state[profile.uuid] != null
          ? updatedStore.state[profile.uuid].status
          : e.node.active
          ? ProfileStatus.Active
          : profile.status === ProfileStatus.PendingApproval
          ? ProfileStatus.PendingApproval
          : ProfileStatus.Deactivated;
      return [
        {
          uuid: profile.uuid,
          email: profile.email,
          unverifiedEmail: profile.unverifiedEmail,
          name: `${profile.firstName} ${profile.lastName}`,
          status,
          lastActivityTime: profile.lastActivityTime,
          companyName: profile.companyV2?.name,
          emailVerified: profile.emailVerified,
        },
      ];
    }) ?? []
  );
}

export function mergeResults(
  prev: UserListQuery,
  fetchMoreResult?: UserListQuery
) {
  if (!fetchMoreResult) return prev;

  const edges = [...prev.users.edges, ...fetchMoreResult.users.edges];
  const edgeMap = edges.reduce((carry, item) => {
    if (item.node == null) return carry;
    carry[item.node.uuid] = item;
    return carry;
  }, {} as { [key: string]: UserListQuery["users"]["edges"][number] });
  const updated = {
    ...prev,
    users: {
      ...prev.users,
      pageInfo: fetchMoreResult.users.pageInfo,
      edges: Object.values(edgeMap),
    },
  };
  return updated;
}

export interface UserStoreUser {
  uuid: string;
  email: string;
  unverifiedEmail: string;
  name: string;
  status: ProfileStatus;
  lastActivityTime: number;
  companyName?: string;
  emailVerified: boolean;
}

export const initialState = {
  users: [],
  loading: false,
  totalCount: 0,
};

export type UserStore = ReturnType<typeof useUserStore>;

function sortKeyToDestKey(key: string): UserSortField {
  switch (key) {
    case "name":
      return UserSortField.Name;
    case "email":
      return UserSortField.Email;
    case "companyName":
      return UserSortField.Company;
    case "isActive":
      return UserSortField.Status;
    case "lastActivityTime":
      return UserSortField.LastSeen;
    default:
      return UserSortField.Name;
  }
}

function sortTransform(
  sort: {
    field: string;
    asc: boolean;
  } | null
): UserSort | undefined {
  return sort != null
    ? {
        asc: sort.asc,
        field: sortKeyToDestKey(sort.field),
      }
    : undefined;
}

const first = 50;

export function useUserStore(
  localSort: {
    field: string;
    asc: boolean;
  } | null,
  companyUuid?: string
) {
  const sort = useMemo(() => {
    return sortTransform(localSort);
  }, [localSort]);

  const { searchText } = useTableSearch({ defaultSortFieldName: "name" });

  const updatedStore = useUpdatedUserStore();
  const userCtx = useContext(UserContext);
  const [loadingMore, setLoadingMore] = useState(false);
  const [filters, setFilters] = useState<UserFilter>({});
  const [search, setSearch] = useState<string | undefined>(searchText);
  const [state, setState] = useState<State>(initialState);
  const debouncedSearchTerm = useDebounce(search, 500);
  const email =
    debouncedSearchTerm != null && debouncedSearchTerm !== ""
      ? debouncedSearchTerm
      : filters.email !== ""
      ? filters.email
      : undefined;
  const name =
    debouncedSearchTerm != null && debouncedSearchTerm !== ""
      ? debouncedSearchTerm
      : filters.name !== ""
      ? filters.name
      : undefined;
  const filter = {
    verified: filters.verified,
    subscribedToEmails: filters.subscribedToEmails,
    status: filters.status,
    lastSeen: filters.lastSeen,
    accountAge: filters.accountAge,
    permissions: filters.permissions,
    groups: filters.groups,
    attributes: filters.attributes,
    emails: (filters.emails ?? []).length > 0 ? filters.emails : undefined,
    email: email?.trim(),
    name: name?.trim(),
    companyUuid: companyUuid ?? filters.companyUuid,
    registrationType: filters.registrationType,
  };
  const result = useUserListQuery({
    fetchPolicy: "network-only",
    variables: {
      page: { first },
      filter,
      sort,
      destinationUuid: userCtx?.activeDestination?.uuid ?? null,
    },
    errorPolicy: "all",
  });

  const fetchMore = async () => {
    if (loadingMore) {
      return;
    }
    setLoadingMore(true);

    try {
      await result.fetchMore({
        variables: {
          filter,
          page: {
            first,
            after: result.data?.users.pageInfo.endCursor,
          },
          sort,
        },
        updateQuery: (prev: UserListQuery, { fetchMoreResult }) => {
          setLoadingMore(false);
          return mergeResults(prev, fetchMoreResult);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const refetch = () => result.refetch();

  useEffect(
    () => setState((state) => transformResult(result, updatedStore)),
    [setState, result, updatedStore]
  );

  return {
    ...state,
    loadingMore,
    fetchMore,
    setSearch,
    search,
    refetch,
    hasNextPage: result.data?.users.pageInfo.hasNextPage ?? false,
    filters,
    setFilters,
  };
}
