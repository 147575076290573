import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { SiteDetailsQuery } from "../../../../generated/admin";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { FormikContextType } from "formik";
import { TextFormGroup } from "../fields/TextFormGroup";
import { useUser } from "../../../user/UserContext";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
  methods: FormikContextType<SiteConfigFormData>;
}

export const GeneralSettingsRegistration: React.FC<Props> = ({
  dest,
  methods,
}) => {
  const user = useUser();
  const multipleEnabled = () => {
    if (
      methods.values.commercialSignupEnabled === true &&
      methods.values.residentialSignupEnabled === true
    ) {
      return true;
    }

    if (
      methods.values.visitorSignupEnabled === true &&
      methods.values.residentialSignupEnabled === true
    ) {
      return true;
    }

    if (
      methods.values.commercialSignupEnabled === true &&
      methods.values.visitorSignupEnabled === true
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <h5 className="mb-4">Email Verification</h5>

      <Form.Group as={Row} controlId={"emailVerificationRequired"}>
        <Col md="9" lg="6">
          <Form.Check
            type="switch"
            name={"emailVerificationRequired"}
            label="Require email verification for site access"
            onChange={methods.handleChange}
            defaultChecked={methods.values.emailVerificationRequired ?? false}
            disabled={user.inSiteContext}
          />
        </Col>
      </Form.Group>

      <h5 className="mb-4">Supported User Types</h5>
      <p>
        If more than one user type is supported, an additional page will be
        added to the registration process for users to select the type of user
        they are.
      </p>

      <Form.Group as={Row} controlId={"commercialSignupEnabled"}>
        <Col md="9" lg="6">
          <Form.Check
            type="switch"
            name={"commercialSignupEnabled"}
            label="Enable commercial users to register"
            onChange={methods.handleChange}
            defaultChecked={methods.values.commercialSignupEnabled ?? false}
            disabled={
              !multipleEnabled() &&
              methods.values.commercialSignupEnabled === true
            }
          />
        </Col>
      </Form.Group>

      {methods.values.commercialSignupEnabled === true && multipleEnabled() && (
        <TextFormGroup
          title={"Registration button title"}
          subText={
            "This title is shown on the button for users to select their user type."
          }
          name={"commercialSignupCTA"}
          maxLength={20}
          handleChange={methods.handleChange}
          value={methods.values.commercialSignupCTA}
          error={methods.errors.commercialSignupCTA}
        />
      )}

      <Form.Group as={Row} controlId={"residentialSignupEnabled"}>
        <Col md="9" lg="6">
          <Form.Check
            type="switch"
            name={"residentialSignupEnabled"}
            label="Enable residential users to register"
            onChange={methods.handleChange}
            defaultChecked={methods.values.residentialSignupEnabled ?? false}
            disabled={
              !multipleEnabled() &&
              methods.values.residentialSignupEnabled === true
            }
          />
        </Col>
      </Form.Group>

      {methods.values.residentialSignupEnabled === true &&
        multipleEnabled() && (
          <TextFormGroup
            title={"Registration button title"}
            subText={
              "This title is shown on the button for users to select their user type."
            }
            name={"residentialSignupCTA"}
            maxLength={20}
            handleChange={methods.handleChange}
            value={methods.values.residentialSignupCTA}
            error={methods.errors.residentialSignupCTA}
          />
        )}

      <Form.Group as={Row} controlId={"visitorSignupEnabled"}>
        <Col md="9" lg="6">
          <Form.Check
            type="switch"
            name={"visitorSignupEnabled"}
            label="Enable visitors to register"
            onChange={methods.handleChange}
            defaultChecked={methods.values.visitorSignupEnabled ?? false}
            disabled={
              !multipleEnabled() && methods.values.visitorSignupEnabled === true
            }
          />
        </Col>
      </Form.Group>

      {methods.values.visitorSignupEnabled === true && multipleEnabled() && (
        <TextFormGroup
          title={"Registration button title"}
          subText={
            "This title is shown on the button for users to select their user type."
          }
          name={"visitorSignupCTA"}
          maxLength={20}
          handleChange={methods.handleChange}
          value={methods.values.visitorSignupCTA}
          error={methods.errors.visitorSignupCTA}
        />
      )}
      {methods.values.visitorSignupEnabled === true && (
        <Form.Group as={Row} controlId={"visitorCanSubscribeToEmails"}>
          <Form.Label column md="3">
            Receive newsletters
          </Form.Label>
          <Col md="9" lg="6">
            <Form.Check
              type="switch"
              name={"visitorCanSubscribeToEmails"}
              label=""
              onChange={methods.handleChange}
              defaultChecked={
                methods.values.visitorCanSubscribeToEmails ?? false
              }
            />
            <Form.Text className="text-muted">
              If enabled, visitors can receive newsletters and are able to
              manage their email subscription settings in their profile.
            </Form.Text>
          </Col>
        </Form.Group>
      )}
      {methods.values.visitorSignupEnabled === true && (
        <Form.Group as={Row} controlId={"visitorCanSubscribeToNotifications"}>
          <Form.Label column md="3">
            Receive push notifications
          </Form.Label>
          <Col md="9" lg="6">
            <Form.Check
              type="switch"
              name={"visitorCanSubscribeToNotifications"}
              label=""
              onChange={methods.handleChange}
              defaultChecked={
                methods.values.visitorCanSubscribeToNotifications ?? false
              }
            />
            <Form.Text className="text-muted">
              If enabled, visitors can receive push notifications and are able
              to manage their notification subscription settings in their
              profile.
            </Form.Text>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
