import React from "react";
import { WhitelabelFormData } from "./WhitelabelFormData";
import { Form } from "react-bootstrap";
import { FormikContextType } from "formik";
import { InputRow } from "./components/InputRow";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { TextFormGroup } from "../../sites/site-details/fields/TextFormGroup";
import { AppStoreCategory } from "./components/AppStoreCategory";
import { AppStoreLocale } from "./components/AppStoreLocale";
import { WhitelabelImageUpload } from "./WhitelabelImageUpload";
import { stringNotEmpty } from "../../../util/stringNotEmpty";

interface Props {
  methods: FormikContextType<WhitelabelFormData>;
  expanded: boolean,
}

export const AppStoreListing: React.FC<Props> = ({ methods, expanded }) => {
  return (
    <ToggleContainer className="lg" title="Store Listing" show={expanded}>
      <InputRow title="Name">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="name"
            isInvalid={methods.errors.name != null}
            onChange={methods.handleChange}
            value={methods.values.name ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.name) && (
          <div className="invalid-feedback d-block">
            {methods.errors.name}
          </div>
        )}
        <Form.Text className="text-muted">
          The name of your app as it will appear on the App Store. This can't be longer than 30 characters.
          It must be available in the Google Play and Apple App stores. We recommended searching the stores beforehand to
          check for availability.
        </Form.Text>
      </InputRow>
      
      <InputRow title="Apple developer account">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="appleAccountName"
            isInvalid={methods.errors.appleAccountName != null}
            onChange={methods.handleChange}
            value={methods.values.appleAccountName ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.appleAccountName) && (
          <div className="invalid-feedback d-block">
            {methods.errors.appleAccountName}
          </div>
        )}
        <Form.Text className="text-muted">
          The name of the Apple developer account.
        </Form.Text>
      </InputRow>

      <InputRow title="Apple company name">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="appleCompanyName"
            isInvalid={methods.errors.appleCompanyName != null}
            onChange={methods.handleChange}
            value={methods.values.appleCompanyName ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.appleCompanyName) && (
          <div className="invalid-feedback d-block">
            {methods.errors.appleCompanyName}
          </div>
        )}
        <Form.Text className="text-muted">
          This will appear under your app name on your App Store product page.
          If you have a trade name, DBA, or fictitious business name, enter it here.
          It can't be changed later.
        </Form.Text>
      </InputRow>

      <InputRow title="Google Play account">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="googleAccountName"
            isInvalid={methods.errors.googleAccountName != null}
            onChange={methods.handleChange}
            value={methods.values.googleAccountName ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.googleAccountName) && (
          <div className="invalid-feedback d-block">
            {methods.errors.googleAccountName}
          </div>
        )}
        <Form.Text className="text-muted">
        The name of the Google Play account.
        </Form.Text>
      </InputRow>

      <TextFormGroup
        title={"Description"}
        name="description"
        subText="A description of your app, and its features, which will appear on the respective app stores. 4000 characters maximum."
        asTextarea={true}
      />

      <InputRow title="Subtitle / short description">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="subtitle"
            isInvalid={methods.errors.subtitle != null}
            onChange={methods.handleChange}
            value={methods.values.subtitle ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.subtitle) && (
          <div className="invalid-feedback d-block">
            {methods.errors.subtitle}
          </div>
        )}
        <Form.Text className="text-muted">
          A subtitle for your app which will appear on the respective app
          stores. 30 characters maximum.
        </Form.Text>
      </InputRow>

      <InputRow title="Keywords">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="keywords"
            isInvalid={methods.errors.keywords != null}
            onChange={methods.handleChange}
            value={methods.values.keywords ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.keywords) && (
          <div className="invalid-feedback d-block">
            {methods.errors.keywords}
          </div>
        )}
        <Form.Text className="text-muted">
          Add keywords for which end-users can search and discover your app in
          the respective app stores. Separate keywords with a comma and exclude
          any spaces between commas. 80 characters maximum.
        </Form.Text>
      </InputRow>

      <AppStoreCategory methods={methods} />

      <InputRow title="Privacy policy">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="privacyPolicyUrl"
            isInvalid={methods.errors.privacyPolicyUrl != null}
            onChange={methods.handleChange}
            value={methods.values.privacyPolicyUrl ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.privacyPolicyUrl) && (
          <div className="invalid-feedback d-block">
            {methods.errors.privacyPolicyUrl}
          </div>
        )}
        <Form.Text className="text-muted">
          A URL that links to the privacy policy.
        </Form.Text>
      </InputRow>

      <InputRow title="Support URL">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="supportUrl"
            isInvalid={methods.errors.supportUrl != null}
            onChange={methods.handleChange}
            value={methods.values.supportUrl ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.supportUrl) && (
          <div className="invalid-feedback d-block">
            {methods.errors.supportUrl}
          </div>
        )}
        <Form.Text className="text-muted">
        A URL with support and contact information for your app. This URL will be visible on the App Store.
        </Form.Text>
      </InputRow>

      <InputRow title="Contact email">
        <div className="border-bottom d-flex align-items-center">
          <Form.Control
            type="text"
            name="contactEmail"
            isInvalid={methods.errors.contactEmail != null}
            onChange={methods.handleChange}
            value={methods.values.contactEmail ?? ""}
          />
        </div>
        {stringNotEmpty(methods.errors.contactEmail) && (
          <div className="invalid-feedback d-block">
            {methods.errors.contactEmail}
          </div>
        )}
        <Form.Text className="text-muted">
          A contact email which will appear on the respective app stores.
        </Form.Text>
      </InputRow>

      <AppStoreLocale methods={methods} />

      <TextFormGroup
        title={"Release notes"}
        name="releaseNotes"
        subText="The release notes shown above will be used for the initial release only. 4000 characters maximum."
        asTextarea={true}
      />

      <WhitelabelImageUpload
        title="Google Play feature graphic"
        height={500}
        width={1024}
        existingImage={methods.values.android.featureGraphic}
        fieldName="android.featureGraphic"
        methods={methods}
        hint={"Feature graphic must be a PNG or JPEG, up to 15 MB and "}
      />

    </ToggleContainer>
  );
};
