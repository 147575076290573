import React from "react";
import { Formik, FormikErrors } from "formik";
import { Container, Col, Row, Spinner, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CreateButton } from "../../../components/CreateButton";
import { PageHeading } from "../../../components/PageHeading";
import {
  useDestinationIntegrationsQuery,
  useUpdateDestinationIntegrationsMutation,
} from "../../../generated/admin";
import { useUser } from "../../user/UserContext";
import { Navigation } from "../site-details/Navigation";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { EqMessage } from "../../message/EqMessage";

export const IntegrationsModule: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const user = useUser();
  const [mutate] = useUpdateDestinationIntegrationsMutation();

  const { data: destinationDetails, loading } = useDestinationIntegrationsQuery(
    {
      fetchPolicy: "network-only",
      variables: { uuid },
      skip: user.activeAtDifferentDestination(uuid!),
    }
  );

  if (loading) {
    return <Spinner animation="grow" size="sm" />;
  }

  type IntegrationsFormValues = {
    evolutionEnabled: boolean;
    bluepointEnabled: boolean;
    bluepointNewCredential: boolean;
    bluepointNewClientId: string;
    bluepointNewClientSecret: string;
    bluepointNewAsId: string;
  };
  const dest = destinationDetails!.destination;

  const initialValues: IntegrationsFormValues = {
    evolutionEnabled: dest.integrations?.evolution.enabled ?? false,
    bluepointEnabled: dest.integrations?.bluepoint.enabled ?? false,
    bluepointNewCredential: null == dest.integrations?.bluepoint.clientId,
    bluepointNewClientId: dest.integrations?.bluepoint.clientId ?? "",
    bluepointNewClientSecret: "",
    bluepointNewAsId:
      null == dest.integrations?.bluepoint.asId
        ? ""
        : `${dest.integrations.bluepoint.asId}`,
  };

  return (
    <Formik<IntegrationsFormValues>
      initialValues={initialValues}
      validate={(values) => {
        const errors: FormikErrors<IntegrationsFormValues> = {};
        if (values.bluepointEnabled && values.bluepointNewCredential) {
          if (values.bluepointNewClientId.trim().length < 1) {
            errors.bluepointNewClientId = "required";
          }
          if (values.bluepointNewClientSecret.trim().length < 1) {
            errors.bluepointNewClientSecret = "required";
          }
          const newAsId = values.bluepointNewAsId.trim();
          if (newAsId.length < 1) {
            errors.bluepointNewAsId = "required";
          } else if (!/^[0-9 ]+$/.test(newAsId)) {
            errors.bluepointNewAsId = "user id must be a number";
          }
        }
        return errors;
      }}
      onSubmit={async (values) => {
        const newClientId = values.bluepointNewCredential
          ? values.bluepointNewClientId
          : undefined;
        const newClientSecret = values.bluepointNewCredential
          ? values.bluepointNewClientSecret
          : undefined;
        const newAsId = values.bluepointNewCredential
          ? parseInt(values.bluepointNewAsId)
          : undefined;
        const result = await mutate({
          variables: {
            integrations: {
              destinationUuid: uuid,
              evolution: {
                enabled: values.evolutionEnabled,
              },
              bluepoint: {
                newClientId,
                newClientSecret,
                newAsId,
                enabled: values.bluepointEnabled,
              },
            },
          },
        });
        const icon = result.data?.updateDestinationIntegrations.success
          ? "success"
          : "error";
        EqMessage({
          icon,
          text: result.data?.updateDestinationIntegrations.message ?? icon,
        });
      }}
    >
      {(methods) => (
        <>
          <PageHeading
            isSticky
            title={
              <Container className="pl-0" fluid>
                <Row className="justify-content-between">
                  <Col md="6">
                    {"Integration Settings"}{" "}
                    <span className="text-muted">{dest.name ?? ""}</span>{" "}
                  </Col>
                  <Col md="6">
                    <CreateButton
                      className="mr-4 float-right"
                      disabled={methods.isSubmitting}
                      onClick={methods.handleSubmit}
                    >
                      {methods.isSubmitting ? (
                        <span>
                          <Spinner animation="grow" size="sm" /> Saving...
                        </span>
                      ) : (
                        "Save"
                      )}
                    </CreateButton>
                  </Col>
                </Row>
              </Container>
            }
            subHeading={<Navigation />}
          ></PageHeading>
          <div className="pt-5">
            <Container className="mb-5">
              <ToggleContainer
                className="lg"
                title="JLL Evolution"
                show={initialValues.evolutionEnabled}
              >
                <Form.Group as={Row} controlId="evolutionEnabled">
                  <Form.Label column md="3">
                    Enable JLL Evolution
                  </Form.Label>
                  <Col md="9" lg="6">
                    <Form.Check
                      type="switch"
                      label=""
                      id="evolutionEnabled"
                      onChange={methods.handleChange}
                      defaultChecked={methods.values.evolutionEnabled ?? false}
                    />

                    {methods.errors.evolutionEnabled && (
                      <Form.Control.Feedback type="invalid">
                        {methods.errors.evolutionEnabled}
                      </Form.Control.Feedback>
                    )}
                    <Form.Text className="text-muted">
                      Once enabled, this will enable the Evolution tenant code
                      to be stored against companies to allow approved Evolution
                      users to raise requests for their site. Navigate to the
                      company page to set the Evolution tenant code.
                    </Form.Text>
                  </Col>
                </Form.Group>
              </ToggleContainer>
            </Container>
            <Container className="mb-5">
              <ToggleContainer
                className="lg"
                title="Bluepoint"
                show={initialValues.bluepointEnabled}
              >
                <Form.Group as={Row} controlId="bluepointEnabled">
                  <Form.Label column md="3">
                    Enable Bluepoint
                  </Form.Label>
                  <Col md="9" lg="6">
                    <Form.Check
                      type="switch"
                      label=""
                      onChange={methods.handleChange}
                      defaultChecked={initialValues.bluepointEnabled}
                    />
                    <Form.Text className="text-muted">
                      Once enabled, allows tenants to access Bluepoint visitor
                      management via the Equiem platform.
                    </Form.Text>
                  </Col>
                </Form.Group>

                {methods.values.bluepointEnabled ? (
                  <>
                    <Form.Group as={Row} controlId="bluepointNewCredential">
                      <Form.Label column md="3">
                        New credential
                      </Form.Label>
                      <Col md="9" lg="6">
                        <Form.Check
                          type="switch"
                          label=""
                          onChange={methods.handleChange}
                          defaultChecked={initialValues.bluepointNewCredential}
                          disabled={
                            initialValues.bluepointNewCredential &&
                            null == dest.integrations?.bluepoint.clientId
                          }
                        />
                        <Form.Text className="text-muted">
                          New credentials are required when enabling the
                          Bluepoint integration for the first time.
                        </Form.Text>
                      </Col>
                    </Form.Group>
                    <Form.Group controlId="bluepointNewClientId">
                      <Form.Label>Client id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="client-id"
                        required={true}
                        onChange={methods.handleChange}
                        onBlur={methods.handleBlur}
                        defaultValue={initialValues.bluepointNewClientId}
                        disabled={!methods.values.bluepointNewCredential}
                        isInvalid={
                          methods.touched.bluepointNewClientId &&
                          methods.errors.bluepointNewClientId !== undefined
                        }
                      />
                      {methods.errors.bluepointNewClientId ? (
                        <Form.Control.Feedback type="invalid">
                          {methods.errors.bluepointNewClientId}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="bluepointNewClientSecret">
                      <Form.Label>Client secret</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="secret"
                        required={true}
                        onChange={methods.handleChange}
                        onBlur={methods.handleBlur}
                        disabled={!methods.values.bluepointNewCredential}
                        isInvalid={
                          methods.touched.bluepointNewClientSecret &&
                          methods.errors.bluepointNewClientSecret !== undefined
                        }
                      />
                      {methods.errors.bluepointNewClientSecret ? (
                        <Form.Control.Feedback type="invalid">
                          {methods.errors.bluepointNewClientSecret}
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Text className="text-muted">
                          An existing client secret is never shown here. It will
                          be replaced if a new client secret is entered.
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group controlId="bluepointNewAsId">
                      <Form.Label>Bluepoint API/system user id</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="55699"
                        required={true}
                        onChange={methods.handleChange}
                        onBlur={methods.handleBlur}
                        defaultValue={initialValues.bluepointNewAsId}
                        disabled={!methods.values.bluepointNewCredential}
                        isInvalid={
                          methods.touched.bluepointNewAsId &&
                          methods.errors.bluepointNewAsId !== undefined
                        }
                      />
                      {methods.errors.bluepointNewAsId ? (
                        <Form.Control.Feedback type="invalid">
                          {methods.errors.bluepointNewAsId}
                        </Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </>
                ) : null}
              </ToggleContainer>
            </Container>
          </div>
        </>
      )}
    </Formik>
  );
};
