import React, { useContext } from "react";
import { RouterOutlet } from "./RouterOutlet";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { SitePicker } from "./modules/sites/site-picker/SitePicker";
import { InternalServiceProvider } from "./providers/InternalServiceProvider";
import { TopMenu } from "./admin-components/TopMenu";
import { Sidebar } from "./admin-components/Sidebar";
import { AppPicker } from "./admin-components/AppPicker";
import { UserProvider } from "./modules/user/UserProvider";
import { UserContext } from "./modules/user/UserContext";
import { RoleCheck } from "./modules/user/RoleCheck";
import { LastLocationProvider } from "react-router-dom-last-location";
import { useAuth0 } from "./providers/Auth0Provider";
import { useSiteContextHelper } from "./hooks/useSiteContextHelper";
import { useConfig } from "./providers/ConfigProvider";
import { sidebarItems } from "./sidebarIcons";

const AppDefault: React.FC = () => {
  const user = useContext(UserContext);
  const auth0 = useAuth0();
  const ctxHelper = useSiteContextHelper();

  if (user?.autoDeployPicker()) {
    return <SitePicker pathName={window.location.pathname} />;
  }

  const iconSize = "16px";

  return (
    <div className="App">
      <div className="row">
        <div className="col-auto">
          <Sidebar expanded={true} items={sidebarItems(iconSize, user)} />
        </div>
        <div className="col">
          <div className="ml-0 pl-0 mr-5">
            <TopMenu
              email={user?.email ?? ""}
              name={user?.name ?? ""}
              avatar={user?.avatar}
              currentDestinationName={user?.activeDestination?.name}
              logout={auth0.logout}
              switchSite={() => ctxHelper.redirect("/site-picker")}
              switchApp={() => ctxHelper.redirect("/app-picker")}
            />
            <RouterOutlet user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <UserProvider>
        <LastLocationProvider>
          <RoleCheck>
            <InternalServiceProvider>
              <Routes>
                <Route path={`/site-picker`} element={<SitePicker />} />
                <Route path={`/app-picker`} element={<AppPickerWithData />} />
                <Route path="*" element={<AppDefault />} />
              </Routes>
            </InternalServiceProvider>
          </RoleCheck>
        </LastLocationProvider>
      </UserProvider>
    </Router>
  );
};

const AppPickerWithData = () => {
  const ctx = useSiteContextHelper();
  const config = useConfig();
  return <AppPicker close={() => ctx.goBack()} region={config.region} />;
};

export default App;
