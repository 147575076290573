import { useState, useContext, useMemo } from "react";
import {
  useMergeCompaniesMutation,
  CompanyListDocument
} from "../../../../generated/admin";
import { DeleteCheckContext } from "../../../../components/deleteCheck";
import { Option } from "./Option";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { useDeletedCompanyStore } from "./OptionStore";

export function useMergeCompaniesState(companySearchTerm: string) {
  const deletedStore = useDeletedCompanyStore();
  const deleteCheck = useContext(DeleteCheckContext);
  const [input, setInput] = useState<{
    master: Option | null;
    merge: Array<Option | null>;
  }>({
    master: null,
    merge: []
  });
  const [mutation, { loading }] = useMergeCompaniesMutation();

  return useMemo(
    () => ({
      addMergeRow: (option: Option) => {
        setInput((input) => {
          const mergeUuids = input.merge.flatMap((op) =>
            op == null ? [] : [op.value]
          );
          if (mergeUuids.includes(option.value)) {
            setTimeout(
              () =>
                EqMessageError({
                  text: "Company can only be added once."
                }),
              20
            );
            return input;
          }
          if (mergeUuids.length > 9) {
            setTimeout(
              () =>
                EqMessageError({
                  text: "Maximum of 10 companies can be merged."
                }),
              20
            );
            return input;
          }
          return {
            ...input,
            merge: [...input.merge, option]
          };
        });
      },
      updateMergeRow: (index: number) => (option?: Option) => {
        input.merge[index] = option ?? null;
        setInput((input) => ({ ...input }));
      },
      removeMergeRow: (index: number) => () => {
        input.merge[index] = null;
        const mergeUuids = input.merge.filter((uuid) => uuid != null);
        setInput((input) => ({ ...input, merge: mergeUuids }));
      },
      setMaster: (option?: Option) =>
        setInput((input) => ({
          ...input,
          master: option ?? null
        })),
      merge: async () => {
        const masterUuid = input.master?.value;
        const mergeUuids = input.merge.flatMap((m) =>
          m != null ? [m.value] : []
        );
        if (masterUuid == null) {
          EqMessageError({
            text: "Please select a master company."
          });
          return;
        }
        if (input.merge.length === 0) {
          EqMessageError({
            text: "Please select which companies need to be merged."
          });
          return;
        }
        await deleteCheck(
          async () => {
            const result = await mutation({
              variables: {
                masterUuid,
                mergeUuids
              },
              refetchQueries: [
                {
                  query: CompanyListDocument,
                  variables: {
                    search: companySearchTerm,
                    first: 100
                  }
                }
              ]
            });
            if (result.errors != null) {
              EqMessageError({
                text: "An unknown error occurred."
              });
              return;
            }
            if (
              result.data?.mergeCompanies.__typename === "MergeCompaniesFailure"
            ) {
              EqMessageError({
                text: result.data.mergeCompanies.reason
              });
              return;
            }
            if (
              result.data?.mergeCompanies.__typename === "MergeCompaniesSuccess"
            ) {
              deletedStore.deleteCompanies(mergeUuids);
              setInput({
                master: null,
                merge: []
              });
              EqMessageSuccess({
                text: "Companies have been merged."
              });
            }
          },
          {
            confirmButtonText: "Merge companies",
            text:
              "Are you sure you want to merge these companies? Once merged, all instances will form a single entity."
          }
        );
      },
      loading,
      input,
      selected: [
        input.master?.value,
        ...input.merge.map((m) => m?.value)
      ].flatMap((v) => (v != null ? [v] : []))
    }),
    [input, companySearchTerm, deleteCheck, deletedStore, loading, mutation]
  );
}
