import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const Navigation: React.FC<Props> = ({ methods }) => (
  <ToggleContainer className="lg" title="Navigation">
    <Form.Group as={Row} controlId={"newsAndEventsDisabled"}>
      <Form.Label column md="3">
        News &amp; event pages
      </Form.Label>
      <Col md="9" lg="6" className="pt-1">
        <Form.Check
          type="checkbox"
          name={"newsAndEventsDisabled"}
          onChange={methods.handleChange}
          checked={methods.values.newsAndEventsDisabled}
          label="Tick this box to disable the news &amp; event pages on the mobile app"
        />
        {methods.errors.newsAndEventsDisabled != null && (
          <div className="invalid-feedback d-block">
            {methods.errors.newsAndEventsDisabled}
          </div>
        )}
      </Col>
    </Form.Group>
  </ToggleContainer>
);
