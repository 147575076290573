import React, { useContext, useCallback, useMemo, useState } from "react";
import { SubTitle } from "../../../components";
import { Container, Row, Col } from "react-bootstrap";
import { SearchBar } from "../../../admin-components/Searchbar";
import { Link } from "react-router-dom";
import { CreateButton } from "../../../components/CreateButton";
import { RootRoutes } from "../../../RouterOutlet";
import {
  CompanyListQuery,
  useCompanyListQuery,
  ExportCompaniesCsvQuery,
  ExportCompaniesCsvQueryVariables,
  ExportCompaniesCsvDocument,
  CompanyV2SortField,
  CompanyV2Sort,
} from "../../../generated/admin";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import useDebounce from "../../../hooks/useDebounce";
import { Sorter } from "../../../components/Sorter";
import { MergeCompanies } from "./merge-companies/MergeCompanies";
import { CompanyDelete } from "./CompanyDelete";
import { useDeletedCompanyStore } from "./merge-companies/OptionStore";
import { PageHeading } from "../../../components/PageHeading";
import { UserContext } from "../../user/UserContext";
import { EqTable } from "../../../components/Table";
import { CompaniesCatalogueLanding } from "../catalogue/CompaniesCatalogueLanding";
import { EqMessageError } from "../../message/EqMessage";
import { saveAs } from "file-saver";
import { CapsuleButton } from "../../../components/CapsuleButton";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { useTableSearch } from "../../../hooks/useTableSearch";
import { useApolloClient } from "@apollo/client";

const first = 10;

interface Sort {
  field: string;
  asc: boolean;
}

function sortKeyToDestKey(key: string): CompanyV2SortField {
  switch (key) {
    case "name":
      return CompanyV2SortField.CompanyName;
    case "industry.name":
      return CompanyV2SortField.Industry;
    case "destinationCount":
      return CompanyV2SortField.SiteCount;
    default:
      return CompanyV2SortField.CompanyName;
  }
}

function sortTransform(
  sort: {
    field: string;
    asc: boolean;
  } | null
): CompanyV2Sort | undefined {
  return sort != null
    ? {
        asc: sort.asc,
        field: sortKeyToDestKey(sort.field),
      }
    : undefined;
}

const useExportCompanyCsv = (variables: ExportCompaniesCsvQueryVariables) => {
  const client = useApolloClient();
  const func = useCallback(async () => {
    const result = await client.query<
      ExportCompaniesCsvQuery,
      ExportCompaniesCsvQueryVariables
    >({
      query: ExportCompaniesCsvDocument,
      variables,
    });
    if (result.data?.companiesV2CSV == null) {
      await EqMessageError({ text: "Unable to export companies to csv" });
      return;
    }
    saveAs(new Blob([result.data.companiesV2CSV]), "data.csv", true);
  }, [variables, client]);

  return func;
};

/**
 * Company list.
 */
export const CompanyList: React.FC = () => {
  const siteCtxHelper = useSiteContextHelper();
  const userCtx = useContext(UserContext);
  const deletedStore = useDeletedCompanyStore();

  const { defaultSortQuery, searchText, onTableSort, onTextSearch } =
    useTableSearch({ defaultSortFieldName: "name" });

  const [search, setSearch] = React.useState<string>(searchText);
  const [sort, setSort] = React.useState<Sort | null>(defaultSortQuery);
  const debouncedSearchTerm = useDebounce(search, 500);

  const activeDestinationUuid = userCtx?.activeDestination?.uuid ?? null;
  const withDestination = activeDestinationUuid == null;
  const countField = useMemo(() => withDestination
    ? { key: "destinationCount", label: "No. of Sites" }
    : {
        key: "companyDestination.profiles.totalCount",
        label: "No. of Users",
      }, [withDestination]);

  const sorter = useMemo(() => new Sorter(
    [
      { key: "name", label: "Company" },
      { key: "industry.name", label: "Industry" },
      countField,
    ],
    sort,
    setSort,
    onTableSort
  ), [sort, setSort, onTableSort, countField]);

  const { loading, error, data, fetchMore, refetch } = useCompanyListQuery({
    variables: {
      search: debouncedSearchTerm,
      first,
      sort: sortTransform(sort),
      destinationUuid: activeDestinationUuid,
    },
    fetchPolicy: "network-only",
  });

  const callbackFn = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const [loadingMore, setLoadingMore] = useState(false);

  const more = useCallback(async () => {
    if (loadingMore) {
      return;
    }
    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          search: debouncedSearchTerm,
          first,
          after: data?.companiesV2.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }): CompanyListQuery => {
          const edges = [
            ...prev.companiesV2?.edges,
            ...(fetchMoreResult?.companiesV2?.edges ?? []),
          ];
          const edgeMap = edges.reduce((carry, item) => {
            if (item.node == null) return carry;
            carry[item.node.uuid] = item;
            return carry;
          }, {} as { [key: string]: CompanyListQuery["companiesV2"]["edges"][number] });
          return {
            ...prev,
            companiesV2: {
              ...prev.companiesV2,
              edges: Object.values(edgeMap),
              pageInfo:
                fetchMoreResult?.companiesV2.pageInfo ??
                prev.companiesV2.pageInfo,
            },
          };
        },
      });
      setLoadingMore(false);
    } catch (e) {
      console.error(e);
    }
  }, [fetchMore, data, debouncedSearchTerm, loadingMore]);

  const sorted = sorter.sortItems(
    data?.companiesV2.edges.flatMap((e) =>
      e.node != null && !deletedStore.deleted.includes(e.node.uuid)
        ? [e.node]
        : []
    ) ?? []
  );

  const serverLoading = useMemo(
    () => ({
      total: data?.companiesV2.totalCount ?? 0,
      loadMore: more,
      hasNextPage: data?.companiesV2.pageInfo.hasNextPage ?? false,
      loading: loadingMore,
    }),
    [data, loadingMore, more]
  );

  const exportCsv = useExportCompanyCsv({
    destinationUuid: userCtx?.activeDestination?.uuid ?? null,
  });

  const topLeftContent = <CapsuleButton onClick={exportCsv}>Csv</CapsuleButton>;

  const clear = useCallback(() => setSearch(""), [setSearch]);
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
      onTextSearch(e.currentTarget.value);
    },
    [setSearch, onTextSearch]
  );

  const operations = useMemo(
    () => [
      () => ({
        actionLabel: "...editing",
        label: "Edit",
        isInAction: false,
        operation: (company: { uuid: string }) => {
          siteCtxHelper.redirect(RootRoutes.COMPANIES + "/edit/" + company.uuid);
        },
      }),
      (company: any) => ({
        render: () => (
          <CompanyDelete
            allowDelete={
              company.destinationCount > 0 && activeDestinationUuid == null
            }
            activeDestination={activeDestinationUuid}
            companyUuid={company.uuid}
            searchTerm={debouncedSearchTerm}
          />
        ),
      }),
    ],
    [siteCtxHelper, activeDestinationUuid, debouncedSearchTerm]
  );

  return (
    <>
      <PageHeading title="Companies"></PageHeading>
      <Container className="pb-5">
        <Row>
          <Col xs="12" className="border-bottom pb-5">
            Companies are entities that a user profile is attached to. They are
            associated with an industry and can have other defining attributes.
            A company can have validation values, enabling the user to be
            automatically approved if their email domain matches any of the
            values during registration. If a user doesn’t match the validation,
            their account will require manual approval.
          </Col>
        </Row>
        {activeDestinationUuid && (
          <Row>
            <Col xs="12" className="border-bottom py-5">
              <CompaniesCatalogueLanding
                callback={callbackFn}
                destinationUuid={activeDestinationUuid}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs="12" className="pt-5 pb-3">
            <SubTitle>
              {activeDestinationUuid ? "My Companies" : "Manage Companies"}
            </SubTitle>
            {activeDestinationUuid
              ? `
              Companies displayed below are the companies
              that are enabled for your site. Before creating a new company,
              search the company catalogue above to check if it already exists and
              then enable it for your site. If you enable a company from the
              catalogue, you must update certain values so they are specific to
              your site.
            `
              : `
              Companies displayed below are a catalogue of companies available for
              selection across all sites. New companies can be created by an
              admininstrator and also at a site level, all of which will be stored
              in the below table.
            `}
          </Col>
        </Row>
        <Row className="justify-content-between pt-3 pb-4">
          <Col md="7" lg="4">
            <SearchBar
              clear={clear}
              value={search}
              onChange={handleSearchChange}
              placeholder={"Search for companies or industries..."}
            />
          </Col>
          <Col className="py-3 py-md-0" md="4" lg="3">
            <Link to={siteCtxHelper.appendUrl(RootRoutes.COMPANIES + "/create")}>
              <CreateButton>Create New Company</CreateButton>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="pb-5">
            <LoadingOrErrorDisplay loading={loading} error={error}>
              <EqTable
                items={sorted}
                sorter={sorter}
                serverLoading={serverLoading}
                operations={operations}
                topLeftContent={topLeftContent}
                urlSearchParams={true}
              />
            </LoadingOrErrorDisplay>
          </Col>
        </Row>
        {!activeDestinationUuid && (
          <Row>
            <Col xs="12" className="py-5 border-top">
              <MergeCompanies companySearchTerm={debouncedSearchTerm} />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
