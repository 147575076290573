import React from "react";
import { EqDangerMessage } from "../modules/message/EqMessage";
import { SweetAlertOptions } from "sweetalert2";

/**
 * @todo: base on the usage, this should be confirmation in general not specific to delete.
 */
export const deleteCheck = async <T>(
  callback: () => T,
  options?: SweetAlertOptions
): Promise<T | undefined> => {
  const result = await EqDangerMessage({
    title: "Are you sure?",
    text: "Once deleted, you cannot retrieve this record.",
    confirmButtonText: "Delete",
    icon: "warning",
    showCancelButton: true,
    ...options
  });
  if (result.value != null) {
    return callback();
  }
};

export const DeleteCheckContext = React.createContext(deleteCheck);
