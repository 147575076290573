import React, { useContext } from "react";
import { User } from "./User";

export const UserContext = React.createContext<User | null>(null);

export function useUser(): User {
  const user = useContext(UserContext);
  if (user == null) {
    throw new Error("User context not initialized");
  }

  return user;
}
