import React, { useContext, useCallback, useMemo } from "react";
import { Table, Spinner } from "react-bootstrap";
import { UserFormHistoryForm } from "./UserFormHistoryForm";
import {
  ProfileEventLogAction,
  UserHistoryQuery,
  useUserHistoryQuery,
} from "../../../generated/admin";
import { UserFormContext } from "./context/UserFormContext";
import { TimeFormat } from "../../../components/TimeFormat";

/**
 * User form history form component.
 */
export const UserFormHistoryTable: React.FC = () => {
  const ctx = useContext(UserFormContext);
  const variables = useMemo(
    () => ({
      uuid: ctx.user?.uuid,
      destinationUuid: ctx.activeSite,
      page: {
        first: 100,
      },
    }),
    [ctx]
  );
  const { data, loading, refetch } = useUserHistoryQuery({
    variables,
    fetchPolicy: "network-only",
  });

  const finishCallback = useCallback(() => refetch(variables), [
    variables,
    refetch,
  ]);

  return (
    <Table responsive size="sm" className="mt-3">
      <thead>
        <tr>
          <th className="border-top-0 border-bottom border-dark w-65">
            Message
          </th>
          <th className="border-top-0 border-bottom border-dark">Changed by</th>
          <th className="border-top-0 border-bottom border-dark">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={3} className="py-0 pr-0">
            <UserFormHistoryForm
              hidden={loading}
              finishCallback={finishCallback}
            />
          </td>
        </tr>
        {loading && (
          <tr>
            <td colSpan={3}>
              <Spinner size="sm" animation="grow" /> Loading...
            </td>
          </tr>
        )}
        {data?.user?.profile?.history.edges.map((h) => (
          <tr key={h.node?.uuid}>
            <td>{formatHistoryItem(h, data)}</td>
            <td>{h.node?.triggeredBy?.displayName ?? ""}</td>
            <td>
              <TimeFormat timestamp={h.node?.timestamp} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
function formatHistoryItem(
  h: NonNullable<
    NonNullable<NonNullable<UserHistoryQuery["user"]>["profile"]>["history"]
  >["edges"][number],
  data: UserHistoryQuery
): string {
  if (h.node?.message == null) {
    return "";
  }
  if (
    h.node.actionType !== ProfileEventLogAction.SiteProfileAdded &&
    h.node.actionType !== ProfileEventLogAction.SiteProfileRemoved
  ) {
    return h.node.message;
  }
  if (
    h.node?.triggeredBy?.uuid != null &&
    h.node?.triggeredBy?.uuid !== data.user?.uuid
  ) {
    return `${h.node.message} (manual)`;
  }
  return h.node.message;
}
