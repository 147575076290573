import React, { useContext, useCallback, useMemo, useState } from "react";
import { SubTitle } from "../../../components";
import { Container, Row, Col } from "react-bootstrap";
import { SearchBar } from "../../../admin-components/Searchbar";
import { Link } from "react-router-dom";
import { CreateButton } from "../../../components/CreateButton";
import { RootRoutes } from "../../../RouterOutlet";
import {
  ApartmentListQuery,
  useApartmentListQuery,
} from "../../../generated/admin";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import useDebounce from "../../../hooks/useDebounce";
import { Sorter } from "../../../components/Sorter";
import { PageHeading } from "../../../components/PageHeading";
import { UserContext } from "../../user/UserContext";
import { EqTable } from "../../../components/Table";
import { ApartmentsCatalogueLanding } from "../catalogue/ApartmentsCatalogueLanding";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { ApartmentDelete } from "./ApartmentDelete";
import { useTableSearch } from "../../../hooks/useTableSearch";

const first = 10;

/**
 * Apartment list.
 */
export const ApartmentList: React.FC = () => {
  const siteCtxHelper = useSiteContextHelper();
  const userCtx = useContext(UserContext);

  const { defaultSortQuery, searchText, onTableSort, onTextSearch } =
    useTableSearch({ isDefaultSortNull: true });

  const [search, setSearch] = React.useState<string>(searchText);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [sort, setSort] = React.useState<{
    field: string;
    asc: boolean;
  } | null>(defaultSortQuery);

  const activeDestinationUuid = userCtx?.activeDestination?.uuid ?? null;
  const sorter = useMemo(() => {
    if (activeDestinationUuid == null) {
      return new Sorter(
        [
          { key: "name", label: "Apartment" },
          { key: "destinationCount", label: "No. of Sites" },
        ],
        sort,
        setSort,
        onTableSort
      )
    } else {
      return new Sorter(
        [{ key: "name", label: "Apartment" }],
        sort,
        setSort,
        onTableSort
      );
    }
    
  }, [activeDestinationUuid, sort, setSort, onTableSort]);

  const { loading, error, data, fetchMore, refetch } = useApartmentListQuery({
    variables: {
      search: debouncedSearchTerm,
      first,
      destinationUuid: activeDestinationUuid,
    },
    fetchPolicy: "network-only",
  });

  const callbackFn = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const [loadingMore, setLoadingMore] = useState(false);

  const more = useCallback(async () => {
    if (loadingMore) {
      return;
    }
    setLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          search: debouncedSearchTerm,
          first,
          after: data?.apartments.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }): ApartmentListQuery => {
          setLoadingMore(false);
          const edges = [
            ...prev.apartments?.edges,
            ...(fetchMoreResult?.apartments?.edges ?? []),
          ];
          const edgeMap = edges.reduce((carry, item) => {
            if (item.node == null) return carry;
            carry[item.node.uuid] = item;
            return carry;
          }, {} as { [key: string]: ApartmentListQuery["apartments"]["edges"][number] });
          return {
            ...prev,
            apartments: {
              ...prev.apartments,
              edges: Object.values(edgeMap),
              pageInfo:
                fetchMoreResult?.apartments.pageInfo ??
                prev.apartments.pageInfo,
            },
          };
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [fetchMore, data, debouncedSearchTerm, loadingMore]);

  const serverLoading = useMemo(
    () => ({
      total: data?.apartments.totalCount ?? 0,
      loadMore: more,
      hasNextPage: data?.apartments.pageInfo.hasNextPage ?? false,
      loading: loadingMore,
    }),
    [data, loadingMore, more]
  );

  const clear = useCallback(() => {
    setSearch("");
    onTextSearch("");
  }, [setSearch, onTextSearch]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      setSearch(val);
      onTextSearch(val);
    },
    [setSearch, onTextSearch]
  );

  const operations = useMemo(
    () => [
      () => ({
        actionLabel: "...editing",
        label: "Edit",
        isInAction: false,
        operation: (apartment: { uuid: string }) => {
          siteCtxHelper.redirect(RootRoutes.APARTMENTS + "/edit/" + apartment.uuid);
        },
      }),
      (apartment: any) => ({
        render: () => (
          <ApartmentDelete
            allowDelete={
              apartment.destinationCount > 0 && activeDestinationUuid == null
            }
            activeDestination={activeDestinationUuid}
            apartmentUuid={apartment.uuid}
            searchTerm={debouncedSearchTerm}
          />
        ),
      }),
    ],
    [siteCtxHelper, activeDestinationUuid, debouncedSearchTerm]
  );

  const sorted = sorter.sortItems(
    data?.apartments.edges.flatMap((e) => (e.node != null ? [e.node] : [])) ??
      []
  );

  return (
    <>
      <PageHeading title="Apartments"></PageHeading>
      <Container className="pb-5">
        <Row>
          <Col xs="12" className="border-bottom pb-5">
            Apartments are entities that a residential user profile is attached
            to. All residential users will select an apartment during
            registration and will require manual approval before they can access
            the site. An apartment can only be associated with a single user
            account.
          </Col>
        </Row>
        {activeDestinationUuid && (
          <Row>
            <Col xs="12" className="border-bottom py-5">
              <ApartmentsCatalogueLanding
                callback={callbackFn}
                destinationUuid={activeDestinationUuid}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs="12" className="pt-5 pb-3">
            <SubTitle>
              {activeDestinationUuid ? "My Apartments" : "Manage Apartments"}
            </SubTitle>
            {activeDestinationUuid
              ? `
              Apartments displayed below are the apartments
              that are enabled for your site. Before creating a new apartment,
              search the apartment catalogue above to check if it already exists and
              then enable it for your site. If you enable an apartment from the
              catalogue, you must update certain values so they are specific to
              your site.
            `
              : `
              Apartments displayed below are a catalogue of apartments available for
              selection across all sites. New apartments can be created by an
              admininstrator and also at a site level, all of which will be stored
              in the below table.
            `}
          </Col>
        </Row>
        <Row className="justify-content-between pt-3 pb-4">
          <Col md="7" lg="4">
            <SearchBar
              clear={clear}
              value={search}
              onChange={handleSearchChange}
              placeholder={"Search for apartments"}
            />
          </Col>
          <Col className="py-3 py-md-0" md="4" lg="3">
            <Link to={siteCtxHelper.appendUrl(RootRoutes.APARTMENTS + "/create")}>
              <CreateButton>Create New Apartment</CreateButton>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="pb-5">
            <LoadingOrErrorDisplay loading={loading} error={error}>
              <EqTable
                items={sorted}
                sorter={sorter}
                serverLoading={serverLoading}
                operations={operations}
                urlSearchParams={true}
              />
            </LoadingOrErrorDisplay>
          </Col>
        </Row>
      </Container>
    </>
  );
};
