import React from "react";
import { RootRoutes } from "./RouterOutlet";
import { SiteConfig } from "./svgs/SiteConfig";
import {
  FaLayerGroup,
  FaList,
  FaMobileAlt,
  FaRegBuilding,
  FaRegQuestionCircle,
  FaWarehouse,
} from "react-icons/fa";
import { UserManagement } from "./svgs/UserManagement";
import { User } from "./modules/user/User";
import { SidebarItem } from "./admin-components/SidebarItem";

export const sidebarItems = (
  iconSize: string,
  user: User | null
): SidebarItem[] => {
  let items = [
    {
      title: "Site Configuration",
      icon: <SiteConfig size={iconSize} />,
      link: RootRoutes.SITES,
      current: true,
    },
    {
      title: "Buildings",
      icon: <FaRegBuilding size={iconSize} />,
      link: RootRoutes.BUILDINGS,
      current: true,
    },
    {
      title: "Apartments",
      icon: <FaWarehouse size={iconSize} />,
      link: RootRoutes.APARTMENTS,
      current: true,
    },
    {
      title: "Companies",
      icon: <FaLayerGroup data-cy={"companies-link"} size={iconSize} />,
      link: RootRoutes.COMPANIES,
      current: true,
      children:
        user?.activeDestination == null
          ? undefined
          : [
              {
                title: "Companies",
                link: RootRoutes.COMPANIES,
              },
              {
                title: "Leasing",
                link: RootRoutes.LEASING,
              },
            ],
    },
    {
      title: "User Management",
      icon: <UserManagement size={iconSize} />,
      link: RootRoutes.USERS,
      current: true,
      children:
        user?.activeDestination == null
          ? undefined
          : [
              {
                title: "User Management",
                link: RootRoutes.USERS,
              },
              {
                title: "Invited Users",
                link: RootRoutes.INVITES,
              },
            ],
    },
    {
      title: "Taxonomy",
      icon: <FaList size={iconSize} />,
      link: RootRoutes.TAXONOMY,
      current: true,
    },
    {
      title: "White Label Apps",
      icon: <FaMobileAlt size={iconSize} />,
      link: RootRoutes.WHITELABEL,
      current: true,
    },
    {
      title: "Help",
      icon: <FaRegQuestionCircle size={iconSize} />,
      link: RootRoutes.HELP,
      current: true,
    },
  ];

  if (
    user?.activeDestinationSettings &&
    user.activeDestinationSettings.registration?.residentialSignupEnabled !==
      true
  ) {
    items = items.filter((item) => item.title !== "Apartments");
  }
  if (!user?.isAdminOrRegionalManager) {
    items = items.filter((item) => item.title !== "White Label Apps");
  }

  return items as SidebarItem[];
};
