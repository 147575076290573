import React from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage, Field, FormikProps } from "formik";
import { renderErrorMessage } from "./renderErrorMessage";
import { validateRequired } from "./validateRequired";
import { useConfig } from "../../../providers/ConfigProvider";

interface Values {
  leaseSize?: string;
}

export const LeaseSizeField: React.FC<
  Pick<FormikProps<Values>, "touched" | "errors"> & {
    initialSize?: number;
  }
> = ({ touched, errors, initialSize }) => {
  const { areaUnits } = useConfig();
  const name: keyof Values = "leaseSize";

  return (
    <Form.Group>
      <Form.Label>Size of lease ({areaUnits}) * </Form.Label>
      <Field name={name} validate={validateRequired}>
        {({ field }: any) => (
          <Form.Control
            type="number"
            min="0"
            step="10"
            placeholder="Size of lease"
            isInvalid={touched[name] ? errors[name] : undefined}
            {...field}
          ></Form.Control>
        )}
      </Field>
      {initialSize != null ? (
        <small className="form-text text-muted">Currently {initialSize}</small>
      ) : null}
      <ErrorMessage name={name} render={renderErrorMessage} />
    </Form.Group>
  );
};
