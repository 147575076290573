import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { Accordion, Card } from "react-bootstrap";

interface ToggleContainerProps {
  title: string;
  // This will remove the overflow for dropdown e.g.
  allowVisibility?: boolean;
  cardClassName?: string;
  bodyClassName?: string;
  collapse?: boolean;
  children?: React.ReactNode;
}

export const CardAccordion: React.FC<ToggleContainerProps> = ({
  title,
  children,
  cardClassName,
  collapse,
}) => {
  const [collapseBody, setCollapseBody] = useState(collapse ?? true);
  return (
    <div className="eq-accordion">
      <Card className={cardClassName}>
        <Card.Header
          className="accordion-header"
          onClick={() => setCollapseBody(!collapseBody)}
        >
          <span>{title}</span>
          <FaAngleDown size="15px" />
        </Card.Header>
        {collapseBody ? null : (
          <Card.Body className="accordion-body">{children}</Card.Body>
        )}
      </Card>
    </div>
  );
};

/**
 * Single accordion with arrow.
 */
export const SingleEqAccordion: React.FC<ToggleContainerProps> = ({
  title,
  children,
  allowVisibility = false,
  bodyClassName = "",
  collapse = true,
}) => {
  return (
    <Accordion
      className={`eq-accordion ${allowVisibility ? "visible-card" : ""}`}
      defaultActiveKey={collapse ? undefined : "0"}
    >
      <Card>
        <Accordion.Collapse eventKey="0" className="accordion-body">
          <Card.Body className={bodyClassName}>{children}</Card.Body>
        </Accordion.Collapse>

        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          className="accordion-header"
        >
          <span>{title}</span>
          <FaAngleDown size="15px" />
        </Accordion.Toggle>
      </Card>
    </Accordion>
  );
};
