import React from "react";
import { WhitelabelFormData } from "./WhitelabelFormData";
import { Form } from "react-bootstrap";
import { FormikContextType } from "formik";
import { InputRow } from "./components/InputRow";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { FileUpload } from "./components/FileUpload";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
interface Props {
  methods: FormikContextType<WhitelabelFormData>;
}

export const ApiKeys: React.FC<Props> = ({ methods }) => {
  return (
    <ToggleContainer className="lg" title="Store API Keys" show={false}>
      <ToggleContainer className="lg" title="Apple Connect" show={false}>
        <InputRow title="API key">
          <FileUpload
            defaultValue={methods.values.appleKey.id}
            handleUpload={(val) => {
              if (val == null) {
                methods.setFieldValue("appleKey", {});
              }
              methods.setFieldValue("appleKey.key", val);
            }}
          ></FileUpload>
          {stringNotEmpty(methods.errors.appleKey?.key) && (
            <div className="invalid-feedback d-block">
              {methods.errors.appleKey?.key}
            </div>
          )}
          <Form.Text className="text-muted">
            Upload the client's Apple Connect API key.
          </Form.Text>
        </InputRow>
        <InputRow title="Key ID">
          <div className="border-bottom d-flex align-items-center">
            <Form.Control
              type="text"
              name="appleKey.keyId"
              isInvalid={methods.errors.appleKey != null}
              onChange={methods.handleChange}
              value={methods.values.appleKey?.keyId ?? ""}
            />
          </div>
          {stringNotEmpty(methods.errors.appleKey?.keyId) && (
            <div className="invalid-feedback d-block">
              {methods.errors.appleKey?.keyId}
            </div>
          )}
          <Form.Text className="text-muted">
            The key id for the Apple Connect API key.
          </Form.Text>
        </InputRow>
        <InputRow title="Issuer ID">
          <div className="border-bottom d-flex align-items-center">
            <Form.Control
              type="text"
              name="appleKey.issuerId"
              isInvalid={methods.errors.appleKey != null}
              onChange={methods.handleChange}
              value={methods.values.appleKey?.issuerId ?? ""}
            />
          </div>
          {stringNotEmpty(methods.errors.appleKey?.issuerId) && (
            <div className="invalid-feedback d-block">
              {methods.errors.appleKey?.issuerId}
            </div>
          )}
          <Form.Text className="text-muted">
            The issuer id for the Apple Connect API key.
          </Form.Text>
        </InputRow>
      </ToggleContainer>
      <ToggleContainer className="lg" title="Google Play" show={false}>
        <InputRow title="API key">
          <FileUpload
            defaultValue={methods.values.androidKey.id}
            handleUpload={(val) => {
              if (val == null) {
                methods.setFieldValue("androidKey", {});
              }
              methods.setFieldValue("androidKey.json", val);
            }}
          ></FileUpload>
          <Form.Text className="text-muted">
            Upload the client's Google Play Store API key.
          </Form.Text>
        </InputRow>
      </ToggleContainer>
    </ToggleContainer>
  );
};
