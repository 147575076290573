import React, { useState, useMemo } from "react";
import { Option } from "./Option";
import Select from "react-select";
import { Form, Col, Row } from "react-bootstrap";
import { DropdownIndicator } from "./DropdownIndicator";
import { useOptionStore } from "./OptionStore";
import { AddButton } from "../../../../components/AddButton";

export const CompanyAdd = ({
  addInput,
  selectedOptions
}: {
  addInput: (option: Option) => void;
  selectedOptions: string[];
}) => {
  const store = useOptionStore();
  if (store == null) {
    throw new Error("option store not initialized");
  }
  const [selected, setSelected] = useState<Option | null>(null);

  const options = useMemo(
    () => [
      ...store.options.filter((o) => !selectedOptions.includes(o.value)),
      ...(selected != null ? [selected] : [])
    ],
    [selected, store.options, selectedOptions]
  );

  return (
    <Row
      className="border"
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && selected != null) {
          addInput(selected);
          setSelected(null);
        }
      }}
    >
      <Col className="py-2 px-3 font-weight-lighter" xs="7">
        <Select
          className="react-select"
          classNamePrefix="eq"
          isLoading={store.loading}
          options={options}
          onChange={(e) => {
            setSelected(e as Option);
          }}
          isClearable={true}
          components={{ DropdownIndicator }}
          value={selected}
          onInputChange={(input) => store.setSearchTerm(input)}
          placeholder="Search for a company to merge..."
        />
      </Col>

      <Col className="py-2 px-3 font-weight-lighter" xs="4">
        <Form.Control
          className="border-bottom"
          value={selected?.label ?? ""}
          onChange={() => {}}
        />
      </Col>

      <Col xs="1" className="d-flex justify-content-end align-items-center">
        <AddButton
          onClick={() => {
            if (selected == null) {
              return;
            }
            addInput(selected);
            setSelected(null);
          }}
        />
      </Col>
    </Row>
  );
};
