import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { TextFormGroup } from "../fields/TextFormGroup";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const StatsProvider: React.FC<Props> = ({ methods }) => {
  return (
    <div className="py-3">
      <div className="d-flex pt-3 pb-2 justify-content-between border-bottom">
        <h4 className="font-weight-normal">Stats Provider</h4>
      </div>

      <div className="pt-4">
        <Form.Group controlId="statsProviderEnableMenu" as={Row}>
          <Form.Label column md="3" lg="2">
            Enable Facilio Integration
          </Form.Label>
          <Col className="pt-1">
            <div className="d-flex align-items-center">
              <Form.Check
                name="statsProviderConfiguration.enableMenu"
                type="switch"
                label=""
                id="statsProviderConfiguration.enableMenu"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  methods.setFieldValue(
                    "statsProviderConfiguration.enabled",
                    e.target.checked
                  );
                }}
                defaultChecked={
                  methods.values.statsProviderConfiguration?.enabled
                }
              />
            </div>
            <Form.Text className="text-muted">
              Enables stats provider integration.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group controlId="tatsProviderConfiguration.url" as={Row}>
          <Form.Label column md="3" lg="2">
            Stats provider SQS URL
          </Form.Label>
          <Col className="pt-1">
            <TextFormGroup
              hideLabel={true}
              name={"statsProviderConfiguration.url"}
              handleChange={methods.handleChange}
              value={methods.values.statsProviderConfiguration?.url}
              disabled={
                methods.values.statsProviderConfiguration?.enabled !== true
              }
            />
          </Col>
        </Form.Group>
      </div>
    </div>
  );
};
