import React from "react";
import { SearchBar } from "../../admin-components/Searchbar";
import { CatalogueItem } from "./CatalogueItem";
import { CatalogueBox } from "./CatalogueBox";
import { Item } from "./model/Item";
import InfiniteScroll from "react-infinite-scroller";
import { Spinner } from "react-bootstrap";
import { useCatalogueContext } from "./hook/useCatalogueContext";

// Reason we are not using the default loading or error is due to
// InfiniteScroll is very fusy with the parent element, if you wrap
// The children with any other element, it will break it.
const LoadingOrError: React.FC<{
  loading: boolean;
  error?: { message: string } | undefined;
  children?: React.ReactNode;
}> = ({ loading, error, children }) => {
  if (loading) {
    return (
      <div>
        <Spinner size="sm" animation="grow" /> Loading...
      </div>
    );
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  return <>{children}</>;
};

/**
 * Catalogue.
 */
export const CatalogueItems: React.FC<{ title: string }> = ({ title }) => {
  const { selectorStore, catalogueItemsStore } = useCatalogueContext();
  const catalogueOnClick = (c: Item) => () => selectorStore.selectItem(c);

  return (
    <CatalogueBox title={title}>
      {catalogueItemsStore.searchEnabled && (
        <div className="pb-3 px-3">
          <SearchBar
            disabled={catalogueItemsStore.loading}
            clear={catalogueItemsStore.clearSearch}
            value={catalogueItemsStore.searchKeyword}
            onChange={catalogueItemsStore.handleSearchChange}
            placeholder={catalogueItemsStore.searchPlaceholderText}
          />
        </div>
      )}
      <div className="overflow-y-auto max-h-60">
        <LoadingOrError
          error={catalogueItemsStore.error}
          loading={catalogueItemsStore.loading}
        >
          {catalogueItemsStore.items && (
            <InfiniteScroll
              pageStart={0}
              loadMore={catalogueItemsStore.loadMore}
              hasMore={catalogueItemsStore.hasMore}
              loader={
                <div key={0}>
                  <Spinner size="sm" animation="grow" /> Loading...
                </div>
              }
              useWindow={false}
            >
              {catalogueItemsStore.items.length === 0 && (
                <div className="bg-light py-2 px-3 text-secondary">
                  Search retrieved no results.
                </div>
              )}
              {catalogueItemsStore.items.map((c) => (
                <CatalogueItem
                  onClick={catalogueOnClick({
                    uuid: c.uuid,
                    name: c.name ?? "Unknown",
                  })}
                  key={c.uuid}
                  className={`${
                    selectorStore.itemsSelected[c.uuid] != null
                      ? selectorStore.itemsSelected[c.uuid].status ===
                        "selected"
                        ? "selected"
                        : "active"
                      : ""
                  }`}
                >
                  {c.name}
                </CatalogueItem>
              ))}
            </InfiniteScroll>
          )}
        </LoadingOrError>
      </div>
    </CatalogueBox>
  );
};
