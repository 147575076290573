import React, { useCallback, useMemo, useState } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { DestinationType, DnsCheckStatus, SiteDetailsDocument, useMigrateDestinationToEquiemOneMutation } from "../../../../generated/admin";
import { FormikProps, FieldArray, Field, FieldArrayRenderProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { RemoveButton } from "../../../../components/RemoveButton";
import { AddButton } from "../../../../components/AddButton";
import { FaCheckCircle, FaClock, FaExclamation } from "react-icons/fa";
import { EqTable } from "../../../../components/Table";
import { Sorter } from "../../../../components/Sorter";
import { useUser } from "../../../user/UserContext";
import { colors } from "../../../../components/colors";
import { CopyField } from "../../../../components/CopyField";
import { useConfig } from "../../../../providers/ConfigProvider";
import { EqDangerMessage, EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

const MAX_SUBDOMAINS = 3;

export const DomainSettings: React.FC<Props> = ({ methods }) => {
  const user = useUser();
  const config = useConfig();
  const sorter = useMemo(
    () =>
      new Sorter([
        { label: "Type", key: "type", sortable: false },
        { label: "Name", key: "hostname", sortable: false },
        { label: "Value", key: "value", sortable: false },
        { label: "Status", key: "status", sortable: false }
      ]),
    []
  );
  const [migrationSuccessful, setMigrationSuccesful] = useState(false);
  const [mutate, { loading }] = useMigrateDestinationToEquiemOneMutation();
  const onMigrationButtonClick = useCallback(async () => {
    const confirm = await EqDangerMessage({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Complete Migration",
      title: "Are you sure?",
      html: "Migrating from Web NG to Essentials is an irreversible action.",
    });
    if (confirm.value !== true) {
      return;
    }

    const result = await mutate({
      variables: {
        uuid: methods.values.destinationUuid
      },
      refetchQueries: [{
        query: SiteDetailsDocument,
        variables: { uuid: methods.values.destinationUuid },
      }]
    });

    if (result.errors == null && result.data?.adminMigrateDestinationToEquiemOne.uuid != null) {
      setMigrationSuccesful(true);

      await EqMessageSuccess({
        text: "Site successfully migrated to Equiem One",
      });
    } else {
      await EqMessageError({
        text: result.errors?.[0].message ?? "Something went wrong while migrating site to Equiem One",
      });
    }


  }, [methods.values.destinationUuid, mutate]);

  const showEquiemOneDomain = !user.inSiteContext
    && methods.values.infrastructure?.equiemOneDomain?.hostname != null
    && methods.values.tierLevel != null
    && config.featureToggles.equiemEssentials;

  const showMigrationButton = showEquiemOneDomain
    && methods.values.type !== DestinationType.EquiemOne

  return (
    <ToggleContainer className="lg" title="Domains">
      {user.inSiteContext ? null : (
        <>
          <h5 className="mb-4">Testing Domain</h5>
          <p>
            The testing domain is automatically generated when creating a new
            site and cannot be changed. This domain can be used for testing the
            site before the primary domain is added.
          </p>
          <Container fluid className="p-0 mb-5">
            <Row className="justify-content-between">
              <Col>
                <Container fluid>
                  <Row
                    className="py-1 border"
                    style={{ background: "#f2f2f2" }}
                  >
                    <Col xs="4">
                      <Form.Control
                        onClick={(
                          event: React.MouseEvent<HTMLInputElement>
                        ) => {
                          event.currentTarget.select();
                        }}
                        readOnly
                        style={{ background: "#f2f2f2" }}
                        value={
                          methods.values.infrastructure?.referenceDomain
                            .hostname
                        }
                      />
                    </Col>
                    <Col xs="4">
                      <Form.Group
                        className="d-flex justify-content-center align-items-center my-1"
                        controlId={`infrastructure.referenceDomainIsPrimary`}
                      >
                        <div className="form-check">
                          <input
                            type="radio"
                            name="infrastructure.referenceDomainIsPrimary"
                            id="infrastructure.referenceDomainIsPrimary"
                            checked={
                              methods.values.infrastructure
                                ?.referenceDomainIsPrimary || false
                            }
                            onChange={(e) => {
                              const checked = e.currentTarget.checked;
                              methods.setFieldValue(
                                "infrastructure.referenceDomainIsPrimary",
                                checked
                              );
                              if (checked) {
                                methods.values.infrastructure?.webDomains.forEach(
                                  (_, index) => {
                                    methods.setFieldValue(
                                      `infrastructure.webDomains.${index}.primary`,
                                      false
                                    );
                                  }
                                );
                              }
                            }}
                            className="form-check-input mb-2"
                          />
                          <label
                            title=""
                            style={{ whiteSpace: "nowrap" }}
                            htmlFor={`infrastructure.referenceDomainIsPrimary`}
                            className="form-check-label"
                          >
                            Primary domain
                          </label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  { showEquiemOneDomain ?
                    <Row
                      className="py-1 border"
                      style={{ background: "#f2f2f2" }}
                    >
                      <Col xs="4">
                        <Form.Control
                          className="equiem-one-testing-domain"
                          onClick={(
                            event: React.MouseEvent<HTMLInputElement>
                          ) => {
                            event.currentTarget.select();
                          }}
                          readOnly
                          style={{ background: "#f2f2f2" }}
                          value={
                            methods.values.infrastructure?.equiemOneDomain?.hostname
                          }
                        />
                      </Col>
                      {showMigrationButton && !migrationSuccessful && (
                        <Col
                          xs="3"
                          className="d-flex justify-content-end align-items-center"
                          style={{ marginLeft: "auto" }}
                        >
                          <Button
                            disabled={loading}
                            variant="outline-primary"
                            onClick={onMigrationButtonClick}
                        >
                            { loading ? <><Spinner size="sm" animation="grow" /> Loading...</> : "Complete Migration" }
                          </Button>
                        </Col>
                      )}
                    </Row>
                  : null}
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}

      <h5 className="mb-4">Site Domains</h5>
      <p>
        Users will access the site at the domains listed in the below table.
        Enter the primary and any alias domains that you want to associate with
        this site. DNS entries will automatically be provided in the DNS
        Management table once a domain has been added.
      </p>
      <Container fluid className="p-0 mb-5">
        <FieldArray name="infrastructure.webDomains">
          {(
            arrayHelpers: Omit<FieldArrayRenderProps, "form"> & {
              form: FormikProps<SiteConfigFormData>;
            }
          ) => (
            <Row className="justify-content-between">
              <Col>
                <Container fluid>
                  {arrayHelpers.form.values.infrastructure?.webDomains?.map(
                    (webDomain, i) => (
                      <Row
                        className={`pt-1 border ${
                          i === 0 ? "" : "border-top-0"
                        }`}
                        key={`list-${i}`}
                      >
                        <Col xs="4">
                          <Field
                            name={`infrastructure.webDomains.${i}.domain.hostname`}
                            as={Form.Control}
                          />
                        </Col>
                        <Col xs="4">
                          <Form.Group
                            className="d-flex justify-content-center align-items-center my-1"
                            controlId={`infrastructure.webDomains.${i}.primary`}
                          >
                            <div className="form-check">
                              <input
                                type="radio"
                                id={`infrastructure.webDomains.${i}.primary`}
                                data-testid={`${webDomain.domain.hostname}_primary`}
                                className="form-check-input mb-2"
                                checked={webDomain.primary}
                                onChange={() => {
                                  methods.setFieldValue(
                                    "infrastructure.referenceDomainIsPrimary",
                                    false
                                  );
                                  arrayHelpers.form.values.infrastructure?.webDomains.forEach(
                                    (wd, index) => {
                                      const currentDomain =
                                        wd.domain === webDomain.domain;
                                      methods.setFieldValue(
                                        `infrastructure.webDomains.${index}.primary`,
                                        currentDomain
                                      );
                                      if (currentDomain) {
                                        methods.setFieldValue(
                                          `infrastructure.webDomains.${index}.redirect`,
                                          false
                                        );
                                      }
                                    }
                                  );
                                }}
                              />
                              <label
                                title=""
                                style={{ whiteSpace: "nowrap" }}
                                htmlFor={`infrastructure.webDomains.${i}.primary`}
                                className="form-check-label"
                              >
                                Primary domain
                              </label>
                            </div>
                          </Form.Group>
                        </Col>

                        <Col xs="3">
                          {webDomain.primary ? null : (
                            <Form.Group
                              className="d-flex justify-content-center align-items-center my-1"
                              controlId={`infrastructure.webDomains.${i}.redirect`}
                            >
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  id={`infrastructure.webDomains.${i}.redirect`}
                                  data-testid={`${webDomain.domain.hostname}_redirect`}
                                  className="form-check-input mb-2"
                                  checked={webDomain.redirect}
                                  onChange={(e) => {
                                    methods.setFieldValue(
                                      `infrastructure.webDomains.${i}.redirect`,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <label
                                  title=""
                                  htmlFor={`infrastructure.webDomains.${i}.redirect`}
                                  className="form-check-label"
                                >
                                  Redirect to primary domain
                                </label>
                              </div>
                            </Form.Group>
                          )}
                        </Col>
                        <Col
                          xs="1"
                          className="d-flex justify-content-end align-items-center"
                        >
                          <RemoveButton
                            onClick={() => arrayHelpers.remove(i)}
                          />
                        </Col>
                      </Row>
                    )
                  )}

                  {(arrayHelpers.form.values.infrastructure?.webDomains ?? [])
                    .length <= MAX_SUBDOMAINS ? (
                    <Row className="border">
                      <Col className="py-1" xs="4">
                        <Field
                          name={"addWebDomain"}
                          placeholder={"Add a domain"}
                          as={Form.Control}
                        />
                      </Col>
                      <Col
                        xs="2"
                        className="d-flex offset-6 justify-content-end align-items-center"
                      >
                        <AddButton
                          data-testid="add-webdomains-btn"
                          onClick={() => {
                            arrayHelpers.push({
                              primary: false,
                              domain: {
                                hostname: arrayHelpers.form.values.addWebDomain
                              }
                            });
                            methods.setFieldValue("addWebDomain", "");
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Container>
              </Col>
            </Row>
          )}
        </FieldArray>
      </Container>
      <h5 className="mb-4">Account Management Domain</h5>
      <p>
        Users will be redirected to the following subdomain as part of
        registering a new account, verifying their email and resetting their
        password. This subdomain will automatically be created once a primary
        domain has been added. The value can be edited in the below field if it
        conflicts with an existing subdomain in use.
      </p>
      <Container fluid className="p-0 mb-5">
        <Row className="justify-content-between">
          <Col>
            <Container fluid>
              <Row className="py-1 border">
                <Col xs="5" lg="5">
                  <Field
                    name={`infrastructure.accountDomain.hostname`}
                    data-testid="account-domain"
                    as={Form.Control}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <h5 className="mb-4">DNS Management</h5>
      <p>
        This table shows you the DNS entries needed to connect all your domains.
        The status column indicates if the entry has been added and fully
        provisioned. Log in to your domain provider to manage these settings and
        ensure all domains listed above include the settings shown below.
      </p>
      <Container fluid className="p-0 mb-5">
        <EqTable
          flushRight={false}
          sorter={sorter}
          pagination={false}
          positionfixed={true}
          items={(methods.values.infrastructure?.dnsChecks ?? []).map(
            (row, i) => {
              return {
                uuid: i.toString(),
                ...row,
                hostname: (
                  <CopyField
                    showValue
                    value={row.hostname}
                    fieldName="Hostname"
                  />
                ),
                value: (
                  <CopyField showValue value={row.value} fieldName="Value" />
                ),
                type: (
                  <a
                    href={row.helpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.type}
                  </a>
                ),
                status: (() => {
                  switch (row.status) {
                    case DnsCheckStatus.Created:
                      return <FaClock size={"1.5em"} color={colors.orange} />;
                    case DnsCheckStatus.Provisioned:
                      return (
                        <FaCheckCircle size={"1.5em"} color={colors.green} />
                      );
                    case DnsCheckStatus.Deprovisioned:
                      return (
                        <FaExclamation size={"1.5em"} color={colors.danger} />
                      );
                    case DnsCheckStatus.NotCreated:
                      return (
                        <FaExclamation size={"1.5em"} color={colors.danger} />
                      );
                  }
                })()
              };
            }
          )}
        />
      </Container>
    </ToggleContainer>
  );
};
