import styled from "styled-components";

export const SortHeader = styled.th`
  &:hover {
    cursor: pointer;
  }
  &&&& {
    border-bottom: 1px solid #000000;
    border-top: 1px solid #000000;
  }
`;
