import { createContext, useContext } from "react";
import { CatalogueSelectorStore } from "../model/CatalogueSelectorStore";
import { CatalogueItemsStore } from "../model/CatalogueItemsStore";

export const CatalogueContext = createContext<{
  selectorStore: CatalogueSelectorStore;
  catalogueItemsStore: CatalogueItemsStore;
} | null>(null);

export function useCatalogueContext() {
  const store = useContext(CatalogueContext);
  if (store == null) {
    throw Error("Catalogue context not initialized.");
  }
  return store;
}
