import React from "react";
import { useField, FieldAttributes } from "formik";
import Select from "react-select";
import { OptionType } from "../../model/OptionType";

export const FormikSelect: React.FC<FieldAttributes<any> & {
  options: OptionType[];
}> = ({ options, ...props }) => {
  const [field, , helpers] = useField(props); // can pass 'props' into useField also, if 'props' contains a name attribute

  return (
    <Select<OptionType>
      classNamePrefix="eq"
      className="react-select"
      value={
        (Array.isArray(field.value)
          ? options.filter((op: OptionType) => field.value.includes(op.value))
          : options.find((op: OptionType) => op.value === field.value)) ?? null
      }
      onChange={(selected) =>
        helpers.setValue(
          Array.isArray(selected)
            ? selected.flatMap((s) => (s != null ? [s.value] : []))
            : (selected as OptionType)?.value
        )
      }
      options={options}
      onBlur={field.onBlur}
      {...props}
    />
  );
};
