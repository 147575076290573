import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";

import {
  useSiteListQuery,
  useBuildingDetailsQuery,
  useCreateOrUpdateBuildingMutation,
  BuildingDetailsQuery,
  SyncBuildingInput,
  CreateOrUpdateBuildingMutationResult,
} from "../../../generated/admin";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { CreateButton } from "../../../components/CreateButton";
import { useConfig } from "../../../providers/ConfigProvider";
import { User } from "../../user/User";
import { UserContext } from "../../user/UserContext";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { useLevelStore } from "../list-view/LevelsContext";
import { useBuildingStore } from "../list-view/BuildingStore";
import { SyncBuildingInputTouched } from "./SyncBuildingInputTouched";
import { buildingFormErrors } from "./buildingFormErrors";
import { createOrUpdateBuildingFactory } from "./createOrUpdateBuildingFactory";
import { BuildingDetailsTabProps } from "./BuildingDetailsTabProps";
import {
  BuildingDetailsTabContainer,
  BuildingDetailsTab,
} from "./BuildingDetailsTabContainer";
import { BuildingDetailsGeneral } from "./BuildingDetailsGeneral";
import { BuildingDetailsTaxRates } from "./BuildingDetailsTaxRates";
import { BuildingDetailsIntegration } from "./BuildingDetailsIntegration";

const getStateFromBuilding = (
  building: NonNullable<BuildingDetailsQuery["building"]> | null,
  user: User | null
): SyncBuildingInput => ({
  uuid: building?.uuid ?? undefined,
  name: building?.name ?? "",
  area: building?.area ?? 0,
  destinationUuid:
    building?.destination?.uuid ?? user?.activeDestination?.uuid ?? "",
  occupants: building?.occupants ?? 0,
  units: building?.units ?? "square feet",
  levels: building?.buildingLevels.map((l) => l.name) ?? [],
  buildingAddress: building?.hasBuildingAddress
    ? {
        address: building.address,
        streetName: building.streetName,
        city: building.city,
        postcode: building.postcode,
        state: building.state,
        country: building.country,
        latitude: building.latitude,
        longitude: building.longitude,
        timezone: building.timezone,
      }
    : null,
  taxes: building?.taxes
    ? {
        taxInclusive: building.taxes.taxInclusive,
        currency: building.taxes.currency.code,
        taxRates: building.taxes.taxRates.map(({ label, rate }) => ({
          label,
          rate,
        })),
      }
    : null,
  integrations: building?.integrations
    ? {
        yardi: {
          propertyCode: building.integrations.yardi?.propertyCode,
          accountId: building.integrations.yardi?.accountId,
          accrualId: building.integrations.yardi?.accrualId,
          offsetId: building.integrations.yardi?.offsetId,
          checknumId: building.integrations.yardi?.checknumId,
        },
      }
    : null,
});

const getSaveMessage = (r: CreateOrUpdateBuildingMutationResult) => {
  if (r.error != null) {
    return r.error.message;
  }
  if (r.data?.createOrUpdateBuilding.__typename === "BuildingSyncFailure") {
    return r.data.createOrUpdateBuilding.reason;
  }
  return null;
};

export const BuildingDetails: React.FC = () => {
  const { uuid } = useParams<{ uuid?: string }>();
  const config = useConfig();
  const user = useContext(UserContext);

  const sitesResult = useSiteListQuery({
    variables: { name: "", installation: config.installation },
  });
  const levelsStore = useLevelStore();
  const buildingResult = useBuildingDetailsQuery({
    variables: { uuid },
    skip: uuid == null,
    fetchPolicy: "network-only",
  });

  const building = buildingResult?.data?.building ?? null;

  const [tab, setTab] = useState(BuildingDetailsTab.GENERAL);
  const [state, setState] = useState<SyncBuildingInput>(
    getStateFromBuilding(building, user)
  );
  const [touched, setTouched] = useState<SyncBuildingInputTouched>({
    name: false,
    destinationUuid: false,
    area: false,
    occupants: false,
    units: false,
    levels: false,
    buildingAddress: false,
    taxes: false,
    integrations: false,
  });

  useEffect(() => {
    setState(getStateFromBuilding(building, user));
  }, [building, user]);

  const dataLoading =
    sitesResult.loading || levelsStore.loading || buildingResult.loading;
  const dataError =
    sitesResult.error ?? levelsStore.initalLoadError ?? buildingResult.error;

  const isTaxAdmin =
    user != null &&
    (user.roles.includes("SUPER_ADMIN") ||
      user.roles.includes("ADMIN") ||
      user.roles.includes("REGIONAL_MANAGER"));
  const hasIntegrations =
    building?.destination?.settings.booking.yardiEnabled ?? false;

  const [save, saveResult] = useCreateOrUpdateBuildingMutation();
  const formErrors = buildingFormErrors(state);
  const siteCtxHelper = useSiteContextHelper();
  const buildingStore = useBuildingStore();
  const createOrUpdateBuilding = createOrUpdateBuildingFactory({
    errors: formErrors,
    save,
    state,
    siteCtxHelper,
    touched,
    setTouched,
    buildingStore,
    isTaxAdmin,
  });

  const tabProps: BuildingDetailsTabProps = {
    sites: sitesResult.data?.searchDestinations ?? [],
    levels: levelsStore.sortedLevels,
    building,
    state,
    setState,
    touched,
    setTouched,
    errors: formErrors,
  };

  return (
    <>
      <div className="mt-n3">
        <div className="my-3 pl-3">
          <h1 className="text-primary font-weight-light m-0">
            {uuid != null ? "Edit Building" : "Create New Building"}
          </h1>
        </div>
        <hr className="mb-0 bg-secondary" />
      </div>
      <BuildingDetailsTabContainer
        tab={tab}
        isTaxAdmin={isTaxAdmin}
        hasIntegrations={hasIntegrations}
        setTab={setTab}
      >
        <LoadingOrErrorDisplay loading={dataLoading} error={dataError}>
          {getSaveMessage(saveResult)}
          <Form noValidate autoComplete="off">
            {tab === BuildingDetailsTab.GENERAL && (
              <BuildingDetailsGeneral {...tabProps} />
            )}
            {tab === BuildingDetailsTab.TAX_RATES && (
              <BuildingDetailsTaxRates {...tabProps} />
            )}
            {tab === BuildingDetailsTab.INTEGRATION && (
              <BuildingDetailsIntegration {...tabProps} />
            )}
            <div className="mt-4 text-right">
              <CreateButton
                disabled={saveResult.loading}
                onClick={createOrUpdateBuilding}
              >
                {saveResult.loading ? (
                  <span>
                    <Spinner animation="grow" size="sm" /> Saving...
                  </span>
                ) : (
                  <span>Save</span>
                )}
              </CreateButton>
            </div>
          </Form>
        </LoadingOrErrorDisplay>
      </BuildingDetailsTabContainer>
    </>
  );
};
