import React from "react";
import { PageHeading } from "../../../components/PageHeading";
import { Navigation } from "../Navigation";
import { Container, Col, Row } from "react-bootstrap";
import { ClientStoreProvider } from "./hook/useClientStore";
import { Divider } from "../../../components/Divider";
import { SubTitle } from "../../../components";
import { List } from "./list-view/List";
import { AddForm } from "./AddForm";

/**
 * Clients home.
 */
export const ClientsHome: React.FC = () => {
  return (
    <ClientStoreProvider>
      <PageHeading title="Clients" subHeading={<Navigation />}></PageHeading>
      <Container>
        <Row>
          <Col className="mt-n2">
            All sites must have a client associated with it. This is used
            throughout other applications to distinguish which client the site
            belongs to.
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col>
            <SubTitle>Manage Clients</SubTitle>
            <List />
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col className="pb-5">
            <SubTitle>Add Clients</SubTitle>
            <AddForm />
          </Col>
        </Row>
      </Container>
    </ClientStoreProvider>
  );
};
