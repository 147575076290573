import React, { useCallback, useEffect, useMemo } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  DestinationStatus,
  SiteListQuery,
  useSiteListLazyQuery,
} from "../../../generated/admin";
import { RootRoutes } from "../../../RouterOutlet";
import useDebounce from "../../../hooks/useDebounce";
import { SearchBar } from "../../../admin-components/Searchbar";
import { useConfig } from "../../../providers/ConfigProvider";
import { CreateButton } from "../../../components/CreateButton";
import { Sorter } from "../../../components/Sorter";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { PageHeading } from "../../../components/PageHeading";
import { EqTable } from "../../../components/Table";
import styled from "styled-components";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { SubTitle } from "../../../components";
import { FaExternalLinkAlt } from "react-icons/fa";
import { CopyField } from "../../../components/CopyField";
import { useTableSearch } from "../../../hooks/useTableSearch";

const StyledSiteList = styled.div`
  table td:first-child {
    width: 99%;
  }
  table td:nth-child(3) {
    white-space: nowrap;
    padding: 0.75rem 3rem 0.75rem 0.75rem;
  }
`;

interface SortData {
  field: string;
  asc: boolean;
}

export const SiteList = () => {
  const { defaultSortQuery, searchText, onTableSort, onTextSearch } =
    useTableSearch({ defaultSortFieldName: "name" });

  const config = useConfig();
  const [search, setSearch] = React.useState<string>(searchText);
  const [sort, setSort] = React.useState<SortData | null>(defaultSortQuery);
  const [query, { data, loading, error }] = useSiteListLazyQuery();

  const sorter = useMemo(() => new Sorter(
    [
      { key: "name", label: "Site" },
      { key: "siteType", label: "Site type" },
      { key: "client.name", label: "Client" },
      { key: "status", label: "Status" },
    ],
    sort,
    setSort,
    onTableSort
  ), [onTableSort, sort]);

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    query({
      variables: {
        name: debouncedSearchTerm,
        installation: config.installation,
      }
    })
  }, [debouncedSearchTerm, config.installation, query])

  const clear = useCallback(() => {
    setSearch("");
    onTextSearch("");
  }, [setSearch, onTextSearch]);

  const handleSearch = useCallback(
    (e: { currentTarget: { value: any } }) => {
      const val = e.currentTarget.value;
      setSearch(val);
      onTextSearch(val);
    },
    [setSearch, onTextSearch]
  );

  const siteCtxHelper = useSiteContextHelper();
  const clickHandler = useCallback(
    (dest: { uuid: string }) =>
      siteCtxHelper.redirect(RootRoutes.SITES + "/" + dest.uuid),
    [siteCtxHelper]
  );

  return (
    <>
      <PageHeading title="Site Configuration"></PageHeading>
      <Container>
        <Row>
          <Col>
            <SubTitle>Manage Sites</SubTitle>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="7" lg="4">
            <SearchBar
              clear={clear}
              value={search}
              onChange={handleSearch}
              placeholder={"Search for sites or clients..."}
            />
          </Col>
          <Col className="py-3 py-md-0" md="4" lg="3">
            <CreateButton
              onClick={() => siteCtxHelper.redirect(RootRoutes.SITES + "/create")}
            >
              Create New Site
            </CreateButton>
          </Col>
        </Row>
        <br />
        <LoadingOrErrorDisplay loading={loading} error={error}>
          <StyledSiteList>
            <EqTable<
              { status: JSX.Element } & Omit<
                SiteListQuery["searchDestinations"][0],
                "status"
              >
            >
              pageSize={50}
              sorter={sorter}
              clickable={true}
              clickHandler={clickHandler}
              flushRight={true}
              urlSearchParams={true}
              operations={[
                (dest) => ({
                  render: () => (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={"goto" + dest.uuid}>
                          Go to site
                        </Tooltip>
                      }
                    >
                      <a
                        target="_blank"
                        className="btn btn-link pt-0 align-top"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                        href={dest.webAppUrl.replace(
                          "/api/v3/graphql",
                          ""
                        )}
                      >
                        <FaExternalLinkAlt />
                      </a>
                    </OverlayTrigger>
                  ),
                }),
                (data) => ({
                  render: () => (
                    <CopyField value={data.uuid} fieldName="Site UUID" />
                  ),
                }),
              ]}
              items={(data?.searchDestinations ?? []).map((d) => ({
                ...d,
                siteType: d.tierLevel == null ? "Web NG" : d.tierLevel,
                client:
                  d.client != null
                    ? {
                        ...d.client,
                        name: `${d.client.name} (${d.client.region})`,
                      }
                    : undefined,
                status:
                  d.status === DestinationStatus.Live ? (
                    <span className="text-lime">Live</span>
                  ) : d.status === DestinationStatus.Deactivated ? (
                    <span className="text-danger">Deactivated</span>
                  ) : (
                    <span>Hidden</span>
                  ),
              }))}
            />
          </StyledSiteList>
        </LoadingOrErrorDisplay>
      </Container>
    </>
  );
};
