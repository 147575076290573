import React, { useContext } from "react";
import { Form, Row, Col, Container, Spinner } from "react-bootstrap";
import { useUserFormHistoryActions } from "./hook/useUserFormHistoryActions";
import { UserFormContext } from "./context/UserFormContext";
import { AddButton } from "../../../components/AddButton";

interface UserFormHistoryFormProps {
  hidden?: boolean;
  finishCallback?: () => void;
}

/**
 * User form history form component.
 */
export const UserFormHistoryForm: React.FC<UserFormHistoryFormProps> = ({
  hidden = false,
  finishCallback
}) => {
  const ctx = useContext(UserFormContext);
  const result = useUserFormHistoryActions(
    ctx.user?.profile?.uuid,
    ctx.activeSite ?? undefined,
    finishCallback
  );

  if (hidden) {
    return <></>;
  }

  return (
    <Container fluid>
      <Row>
        <Col xs="8" className="pl-0">
          <Form.Control
            placeholder="Add message"
            className="p-0 m-0 border-0"
            onChange={result.onChangeInput}
            value={result.message}
          />
        </Col>

        <Col
          xs="3"
          className="offset-1 justify-content-end align-items-center pr-0 d-flex"
        >
          {result.loading ? (
            <span>
              <Spinner animation="grow" size="sm" /> Loading...
            </span>
          ) : (
            <AddButton onClick={result.onSubmit} />
          )}
        </Col>
      </Row>
    </Container>
  );
};
