import { stringNotEmpty } from "./stringNotEmpty";

export interface AddressInfo {
  address: string;
  streetName?: string | null;
  city?: string | null;
  postcode?: string | null;
  state?: string | null;
  country?: string | null;
  latitude: number;
  longitude: number;
  timezone: string;
}

const findAddressComponent =
  (desiredTypes: string[]) =>
  (components: google.maps.GeocoderAddressComponent[]): string | undefined => {
    const match = components.find((component) =>
      desiredTypes.some((desiredType) => component.types.includes(desiredType))
    );
    return match?.long_name;
  };

const getCity = findAddressComponent(["locality", "postal_town"]);
const getPostcode = findAddressComponent(["postal_code"]);
const getCountry = findAddressComponent(["country"]);
const getState = findAddressComponent(["administrative_area_level_1"]);
const getStreetName = findAddressComponent(["route"]);
const getTimezone = async ({
  latitude,
  longitude,
}: Pick<AddressInfo, "latitude" | "longitude">) => {
  try {
    const coordinates = latitude + "," + longitude;
    const res = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${coordinates}&timestamp=0&key=${googleMapsApiKey}`
    );
    const body = await res.json();
    return body.timeZoneId || null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export async function formatPlaceResult(
  place: google.maps.places.PlaceResult,
  defaults: AddressInfo
): Promise<AddressInfo> {
  const addressArray = place.address_components;
  if (addressArray == null) {
    throw new Error("failed to parse address");
  }

  const latitude = place.geometry?.location?.lat() ?? defaults.latitude;
  const longitude = place.geometry?.location?.lng() ?? defaults.longitude;

  return {
    address: place.formatted_address ?? defaults.address,
    streetName: getStreetName(addressArray) ?? defaults.streetName,
    city: getCity(addressArray) ?? defaults.city,
    postcode: getPostcode(addressArray) ?? defaults.postcode,
    state: getState(addressArray) ?? defaults.state,
    country: getCountry(addressArray) ?? defaults.country,
    latitude,
    longitude,
    timezone: (await getTimezone({ latitude, longitude })) ?? defaults.timezone,
  };
}

export function validateAddress(address: AddressInfo): boolean {
  return (
    stringNotEmpty(address.address) &&
    Number.isFinite(address.latitude) &&
    Number.isFinite(address.longitude) &&
    stringNotEmpty(address.timezone)
  );
}
