import React, { useState } from "react";

const linkStyle = {
  color: "#0d6efd",
  textDecoration: "underline",
  cursor: "pointer",
};

interface Props {
  content: string;
  limit: number;
}

export const LongText: React.FC<Props> = ({
  content,
  limit,
}: {
  content: string;
  limit: number;
}) => {
  const [showAll, setShowAll] = useState(false);
  const showMore = () => setShowAll(true);
  const showLess = () => setShowAll(false);
  if (content.length <= limit) {
    return <div>{content}</div>;
  }
  if (showAll) {
    return (
      <div>
        {content}{" "}
        <span style={linkStyle} className="link-primary" onClick={showLess}>
          less
        </span>
      </div>
    );
  }
  const toShow = content.substring(0, limit) + "...";
  return (
    <div>
      {toShow}
      <span style={linkStyle} className="link-primary" onClick={showMore}>
        more
      </span>
    </div>
  );
};
