import { useContext, useCallback } from "react";
import { UserContext } from "../modules/user/UserContext";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { LastLocationContextType, useLastLocation } from "react-router-dom-last-location";
import { RootRoutes } from "../RouterOutlet";

/**
 * Use site context.
 */
export function useSiteContextHelper() {
  const user = useContext(UserContext);
  const lastLocation = useLastLocation();
  const history = useNavigate();

  const buildUrl = useCallback((path: string, site: string) => {
    return queryString.stringifyUrl({
      url: path,
      query: { site },
    });
  }, []);

  const calculateBackPath = useCallback(
    (
      lastPath: Partial<LastLocationContextType>,
      pathToGo?: string,
      goBackToAllSitesView?: boolean
    ) => {
      if (pathToGo != null) {
        return pathToGo;
      }

      const pathWithSiteContext =
        lastPath != null ? `${lastPath.lastLocation?.pathname}${lastPath.lastLocation?.search}` : null;

      const pathWithoutSiteContext =
        lastPath != null ? lastPath.lastLocation?.pathname : null;

      if (goBackToAllSitesView === true) {
        return pathWithoutSiteContext ?? RootRoutes.COMPANIES;
      }

      return pathWithSiteContext ?? RootRoutes.COMPANIES;
    },
    []
  );

  return {
    redirect: (path: string) => {
      if (user?.activeDestination?.uuid == null) {
        history(path);
      } else {
        history(buildUrl(path, user.activeDestination.uuid));
      }
    },
    appendUrl: (path: string) => {
      if (user?.activeDestination?.uuid == null) {
        return path;
      } else {
        return buildUrl(path, user.activeDestination.uuid);
      }
    },
    goBack: (
      destination?: string,
      pathName?: string,
      goBackToAllSitesView?: boolean
    ) => {
      const path = calculateBackPath(
        lastLocation,
        pathName,
        goBackToAllSitesView
      );

      if (destination != null && destination.length > 0) {
        history(buildUrl(path, destination));
      } else {
        history(path);
      }
    },
  };
}

export type SiteContextHelper = ReturnType<typeof useSiteContextHelper>;
