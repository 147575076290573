import React from "react";
import { Container, Form } from "react-bootstrap";
import {
  WhitelabelFormData,
  whitelabelFormValidation,
} from "./WhitelabelFormData";
import { Formik } from "formik";
import {
  WhitelabelStatus,
  useUpsertWhitelabelAppMutation,
} from "../../../generated/admin";
import { ClientNameFormGroup } from "../../sites/site-details/fields/ClientNameFormGroup";
import { useParams } from "react-router-dom";
import { EqMessageError, EqMessageSuccess } from "../../message/EqMessage";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { AppDetails } from "./AppDetails";
import { AppStoreListing } from "./AppStoreListing";
import { ApiKeys } from "./ApiKeys";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { DetailsPageHeading } from "./components/DetailsPageHeading";
import { useUser } from "../../user/UserContext";
import { WhitelabelGeneralView } from "./WhitelabelGeneralView";

export const CreateWhitelabelApp: React.FC = () => {
  const user = useUser();
  const siteCtxHelper = useSiteContextHelper();
  const [mutation] = useUpsertWhitelabelAppMutation();
  const { uuid } = useParams<{ uuid: string }>();
  let formData: WhitelabelFormData = {
    android: {},
    ios: {},
    name: "",
    appName: "",
    client: "",
    clientName: "",
    appleAccountName: "",
    appleCompanyName: "",
    googleAccountName: "",
    color: "#000000",
    language: "en-AU",
    categories: [""],
    appleKey: {},
    apnsKey: {},
    androidKey: {},
    firebaseServiceKey: {},
    betaUsers: [],
    sites: [],
    betaUserInput: { type: "", value: "" },
    bundleId: "",
    packageName: "",
    appleTeamId: "",
    appleITCTeamId: "",
    appleAppStoreId: "",
    applePayMerchantId: "",
    appleCodeSigningIdentity: "",
    editionId: "",
    firebaseAndroidAppId: "",
    androidFlavor: "",
    matchGitBranch: "",
    dynamicLinkUrl: "",
    firebaseProjectId: "",
    firebaseWebApiKey: "",
    appleReviewEmail: "",
    googleReviewEmail: "",
    equiemOwner: {
      value: user.uuid,
      label: `${user.name} (${user.email})`,
    },
    status: WhitelabelStatus.Draft,
  };

  return (
    <Formik<WhitelabelFormData>
      initialValues={formData}
      validate={whitelabelFormValidation}
      onSubmit={(ev) => {
        const betaUsers = ev.betaUsers.map((a) => {
          return { emailAddress: a.value, operatingSystem: a.type };
        });
        if (ev.betaUserInput.type !== "" && ev.betaUserInput.value !== "") {
          betaUsers.push({
            emailAddress: ev.betaUserInput.value,
            operatingSystem: ev.betaUserInput.type,
          });
        }
        mutation({
          variables: {
            input: {
              androidImages: ev.android,
              iosImages: ev.ios,
              clientUuid: ev.client,
              clientName: ev.clientName,
              name: ev.name ?? `${ev.clientName.trim()} App`,
              appName: ev.appName ?? `${ev.clientName.trim()} App`,
              appleAccountName: ev.appleAccountName,
              appleCompanyName: ev.appleCompanyName,
              googleAccountName: ev.googleAccountName,
              uuid: ev.uuid,
              categories: ev.categories,
              color: ev.color,
              description: ev.description,
              keywords: [ev.keywords ?? ""],
              subtitle: ev.subtitle,
              appleStoreKey:
                stringNotEmpty(ev.appleKey.key)
                  ? {
                    certificate: ev.appleKey.key ?? "",
                    issuerId: ev.appleKey.issuerId ?? "",
                    keyId: ev.appleKey.keyId ?? "",
                  }
                  : undefined,
              apnsKey: 
                stringNotEmpty(ev.apnsKey.key)
                  ? {
                    certificate: ev.apnsKey.key ?? "",
                    keyId: ev.apnsKey.keyId ?? "",
                  }
                  : undefined,
              googlePlayKey:
                stringNotEmpty(ev.androidKey.json)
                  ? JSON.parse(ev.androidKey.json)
                  : undefined,
              firebaseServiceKey:
                stringNotEmpty(ev.firebaseServiceKey.json)
                    ? JSON.parse(ev.firebaseServiceKey.json)
                    : undefined,
              betaUsers: betaUsers,
              siteUuids: ev.sites,
              privacyUrl: ev.privacyPolicyUrl,
              supportUrl: ev.supportUrl,
              contactEmail: ev.contactEmail,
              releaseNotes: ev.releaseNotes,
              language: ev.language,
              distributionRegions: ["All"],
              bundleId: ev.bundleId,
              packageName: ev.packageName,
              appleTeamId: ev.appleTeamId,
              appleITCTeamId: ev.appleITCTeamId,
              appleAppStoreId: ev.appleAppStoreId,
              applePayMerchantId: ev.applePayMerchantId,
              appleCodeSigningIdentity: ev.appleCodeSigningIdentity,
              editionId: ev.editionId,
              firebaseAndroidAppId: ev.firebaseAndroidAppId,
              androidFlavor: ev.androidFlavor,
              matchGitBranch: ev.matchGitBranch,
              dynamicLinkUrl: ev.dynamicLinkUrl,
              firebaseProjectId: ev.firebaseProjectId,
              firebaseWebApiKey: ev.firebaseWebApiKey,
              appleReviewEmail: ev.appleReviewEmail,
              googleReviewEmail: ev.googleReviewEmail,
              equiemOwner: ev.equiemOwner?.value,
              status: ev.status,
            },
          },
        })
          .then((a) => {
            const results = a.data?.upsertWhitelabelApp;
            if (results === undefined) {
              EqMessageError({
                text: "There was a problem creating white label app.",
              });
            }
            if (results?.uuid !== uuid) {
              EqMessageSuccess({
                text: `Successfully created ${results?.name}.`,
              });
            } else {
              EqMessageSuccess({
                text: `${results?.name} details edited successfully.`,
              });
            }
            siteCtxHelper.goBack();
          })
          .catch((err) => {
            EqMessageError({ text: err });
          });
      }}
    >
      {(methods) => (
        <>
          <DetailsPageHeading title={"Create New White Label App"} methods={methods} />
          <Container>
            <>
              <br />
              <ClientNameFormGroup methods={methods} label="Select client">
                <Form.Text className="text-muted">
                  The client associated with the White Label app.
                </Form.Text>
              </ClientNameFormGroup>
              <WhitelabelGeneralView methods={methods} />
              <AppStoreListing methods={methods} expanded={true} />
              <ApiKeys methods={methods} />
              <AppDetails methods={methods} />
            </>
          </Container>
        </>
      )}
    </Formik>
  );
};
