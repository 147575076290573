import React, { useCallback } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { CatalogueItems } from "./CatalogueItems";
import { MyCatalogueItems } from "./MyCatalogueItems";
import { FaLongArrowAltRight, FaLongArrowAltLeft } from "react-icons/fa";
import { CreateButton } from "../../components/CreateButton";
import { useCatalogueSelectorFactory } from "./hook/useCatalogueSelector";
import { CatalogueItemsStore } from "./model/CatalogueItemsStore";
import { CatalogueContext } from "./hook/useCatalogueContext";

interface Props {
  catalogueItemsStore: CatalogueItemsStore;
  text: {
    itemsTitle: string;
    selectedItemsTitle: string;
    saveBtnTxt: string;
  };
}

/**
 * Catalogue.
 */
export const Catalogue: React.FC<Props> = ({ catalogueItemsStore, text }) => {
  const result = useCatalogueSelectorFactory();
  const saveFn = useCallback(
    () => catalogueItemsStore.save.fn(result.addedItems()),
    [catalogueItemsStore.save, result]
  );

  return (
    <CatalogueContext.Provider
      value={{ selectorStore: result, catalogueItemsStore }}
    >
      <Container fluid>
        <Row>
          <Col xs="5" className="min-h-70">
            <CatalogueItems title={text.itemsTitle} />
          </Col>
          <Col
            xs="2"
            className="d-flex align-items-center flex-column justify-content-center"
          >
            <div className="mb-3">
              <Button
                variant="outline-primary"
                className="border-radius-10-px py-1"
                data-testid="add-items"
                onClick={result.addItems}
              >
                <FaLongArrowAltRight size={20} />
              </Button>
            </div>
            <div>
              <Button
                variant="outline-primary"
                className="border-radius-10-px py-1"
                data-testid="remove-items"
                onClick={result.removeItems}
              >
                <FaLongArrowAltLeft size={20} />
              </Button>
            </div>
          </Col>
          <Col xs="5" className="min-h-70">
            <MyCatalogueItems title={text.selectedItemsTitle} />
          </Col>
        </Row>
        <Row>
          <Col className="py-3 text-right">
            <CreateButton
              data-testid="save-items"
              disabled={catalogueItemsStore.save.loading}
              onClick={saveFn}
            >
              {catalogueItemsStore.save.loading ? (
                <span>
                  <Spinner animation="grow" size="sm" /> Saving...
                </span>
              ) : (
                text.saveBtnTxt
              )}
            </CreateButton>
          </Col>
        </Row>
      </Container>
    </CatalogueContext.Provider>
  );
};
