import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { CompanyAttribute } from "../model/CompanyAttribute";
import { TableButton, ActionButton } from "../../../../components/TableButton";
import { useUpsertCompanyAttributeMutation } from "../../../../generated/admin";
import { CompanyAttributeStore } from "../hook/useCompanyAttributesStore";
import { EqMessageSuccess, EqMessageError } from "../../../message/EqMessage";

interface Props {
  attribute: CompanyAttribute;
  store: CompanyAttributeStore;
  setEditing: (edit: boolean) => void;
}

/**
 * Edit row.
 */
export const EditRow: React.FC<Props> = ({ attribute, store, setEditing }) => {
  const [name, setName] = React.useState(attribute.name);
  const handleName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setName(e.target.value.slice(0, 50)),
    [setName]
  );
  const cancelEdit = useCallback(() => setEditing(false), [setEditing]);
  const [save, { loading }] = useUpsertCompanyAttributeMutation();

  const handleSave = useCallback(async () => {
    if (name === "") {
      return;
    }
    const result = await save({
      variables: { uuid: attribute.uuid, name, siteUuid: store.siteUuid }
    });
    if (
      result.data?.upsertCompanyAttribute.__typename ===
      "CompanyAttributeSuccessResponse"
    ) {
      setEditing(false);
      store.updateAttribute(attribute.uuid, name);
      await EqMessageSuccess({ text: "Company attribute name updated." });
    } else {
      await EqMessageError({
        text:
          result.data?.upsertCompanyAttribute.__typename === "FailureResponse"
            ? result.data?.upsertCompanyAttribute.reason
            : "There was an error updating the company attribute."
      });
    }
  }, [save, attribute, name, setEditing, store]);
  return (
    <tr>
      <td className="py-1">
        <Form.Control value={name} onChange={handleName} />
      </td>
      <td>
        {!loading && (
          <>
            <TableButton onClick={cancelEdit}>Cancel</TableButton> |&nbsp;
          </>
        )}
        <ActionButton
          onClick={handleSave}
          actionLabel="Saving..."
          restLabel="Save"
          isInAction={loading}
        />
      </td>
    </tr>
  );
};
