import React from "react";
import {
  CompanyV2SortField,
  CompanyListDocument,
  CompanyListQueryVariables,
  CompanyListQuery
} from "../../generated/admin";
import AsyncSelect from "react-select/async";
import { OptionType } from "../../model/OptionType";
import debounce from "debounce-promise";
import { useField, FieldAttributes } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import { useApolloClient } from "@apollo/client";

interface Props {
  error?: string;
  title: string;
  subText?: string;
  maxLength?: number;
  asTextarea?: boolean;
  options: OptionType[];
  isMulti?: boolean;
  fullWidth?: true;
}

/**
 * Companies dropdown.
 * @todo: need to seperate the logic from ui.
 */
export const CompaniesDropdown: React.FC<FieldAttributes<any> & Props> = ({
  error,
  title,
  subText,
  maxLength,
  asTextarea = false,
  fullWidth = true,
  ...props
}) => {
  const [field, , helpers] = useField(props);
  const client = useApolloClient();

  const loadOptions = async (search: string) => {
    if (search.length < 1) {
      return [];
    }

    const result = await client.query<
      CompanyListQuery,
      CompanyListQueryVariables
    >({
      query: CompanyListDocument,
      variables: {
        first: 100,
        search,
        sort: {
          asc: true,
          field: CompanyV2SortField.CompanyName
        }
      }
    });

    return result.data.companiesV2.edges.flatMap((c) => {
      return c.node != null && c.node.name != null
        ? [
            {
              value: c.node.uuid,
              label: c.node.name
            }
          ]
        : [];
    });
  };
  // Loading debounce: https://github.com/JedWatson/react-select/issues/3075.
  const debouncedLoadOptions = debounce(loadOptions, 700, {
    leading: true
  });

  return (
    <Form.Group as={Row} controlId={field.name}>
      <Form.Label column md="3">
        {title}
      </Form.Label>
      <Col md="9" lg={fullWidth ? "9" : "6"}>
        <AsyncSelect<OptionType>
          loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
          isSearchable
          classNamePrefix="eq"
          noOptionsMessage={(obj: { inputValue: string }) => {
            return obj.inputValue.length < 1
              ? "Search for a company"
              : "No options";
          }}
          placeholder="Search for a company"
          className={`react-select`}
          onChange={(selected: any) => helpers.setValue(selected)}
          value={field.value ?? null}
          name={props.name}
        />
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
        {subText != null ? (
          <Form.Text className="text-muted">{subText}</Form.Text>
        ) : null}
      </Col>
    </Form.Group>
  );
};
