import React, { useMemo, useState } from "react";
import { useClientStore } from "../hook/useClientStore";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { ArrayPagination } from "../../../../components/Pagination";
import { Sorter } from "../../../../components/Sorter";
import { FlushRightTable } from "../../../../components/FlushRightTable";
import { LazyExportToCsv } from "../../../../components/export/LazyExportToCsv";
import styled from "styled-components";
import { Client } from "../../../../generated/admin";

const StyledUserList = styled.div`
  table th:nth-child(1) {
    width: 70%;
  }
`;

const exportConfig = [
  { column: "name", label: "Client" },
  { column: "destinationCount", label: "No. of sites" },
];

const transforms = [
  (item: Client) => {
    return { ...item };
  },
];

/**
 * Client list.
 */
export const List: React.FC = () => {
  const store = useClientStore();
  const [sort, setSort] = useState<{
    field: string;
    asc: boolean;
  }>({ field: "name", asc: true });
  const sorter = useMemo(() => new Sorter(
    [
      { key: "name", label: "Client" },
      { key: "region", label: "Region" },
      {
        key: "destinationCount",
        label: "No. of sites",
        style: { textAlign: "right" },
      },
    ],
    sort,
    setSort
  ), [sort]);
  const sorted = sorter.sortItems(store.clients.slice());

  return (
    <LoadingOrErrorDisplay
      loading={store.loading}
      error={store.initalLoadError}
    >
      <StyledUserList>
        <ArrayPagination
          topLeftContent={
            <>
              <LazyExportToCsv
                items={sorted}
                config={exportConfig}
                transforms={transforms}
              />
            </>
          }
          items={sorted}
        >
          {(items) => (
            <FlushRightTable>
              <sorter.Heading />
              <tbody>
                {items.map((client) => (
                  <tr>
                    <td>{client.name}</td>
                    <td>{client.region}</td>
                    <td>{client.destinationCount}</td>
                  </tr>
                ))}
              </tbody>
            </FlushRightTable>
          )}
        </ArrayPagination>
      </StyledUserList>
    </LoadingOrErrorDisplay>
  );
};
