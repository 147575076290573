import Select from "react-select";
import React from "react";
import { Form } from "react-bootstrap";
import { InputRow } from "./InputRow";
import { OptionType } from "../../../../model/OptionType";
import { FormikContextType } from "formik";
import { WhitelabelFormData } from "../WhitelabelFormData";
interface Props {
  siteOptions: {
    value: any;
    label: string;
  }[];
  methods: FormikContextType<WhitelabelFormData>;
}

export const SiteSelector: React.FC<Props> = ({ siteOptions, methods }) => {
  return (
    <InputRow title="Sites accessible by the app">
      {siteOptions.length > 0 && (
        <Select<OptionType, boolean>
          classNamePrefix="eq"
          aria-label="site-list"
          isMulti={true}
          isSearchable={true}
          onChange={(newValue) => {
            const vals = newValue as Array<OptionType>;
            methods.setFieldValue(
              "sites",
              vals.map((a) => a.value)
            );
          }}
          required
          options={siteOptions}
          defaultValue={siteOptions.filter((x) =>
            methods.values.sites.includes(x.value)
          )}
        />
      )}
      <Form.Text className="text-muted">
        Select the sites that should be associated with the app.
      </Form.Text>
    </InputRow>
  );
};
