import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { ImageUpload } from "../fields/ImageUpload";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { TextFormGroup } from "../fields/TextFormGroup";
import { ColorPallet } from "../../../../components/ColorPallet";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const WebBranding: React.FC<Props> = ({ methods }) => (
  <ToggleContainer className="lg" title="Branding">
    <h5 className="mb-4">Colours</h5>
    <Form.Group as={Row} controlId="primaryColor">
      <Form.Label column md="3">
        Primary colour
      </Form.Label>
      <Col md="9" lg="6">
        <div className="border-bottom d-flex align-items-center">
          {methods.values.primaryColor && (
            <ColorPallet bgcolor={methods.values.primaryColor} />
          )}

          <Form.Control
            type="text"
            name="primaryColor"
            className="border-0"
            isInvalid={methods.errors.primaryColor != null}
            onChange={methods.handleChange}
            value={methods.values.primaryColor ?? ""}
          />
        </div>
        {methods.errors.primaryColor && (
          <div className="invalid-feedback d-block">
            {methods.errors.primaryColor}
          </div>
        )}
        <Form.Text className="text-muted">
          The primary colour is used throughout the web and on all automated 
          email notifications. A hex code must be entered in the format #000000. 
          Ensure that a similar colour is used for the 'Primary colour' 
          field in the 'Mobile App &gt; Branding' section of configuration.
        </Form.Text>
      </Col>
    </Form.Group>
    <h5 className="mb-4">Image Assets</h5>
    <Form.Group as={Row} controlId="webWelcomePageImage">
      <Form.Label column md="3" className="pt-0">
        Welcome page
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload<SiteConfigFormData>
          fieldName="webWelcomePageImage"
          methods={methods}
          existingImage={methods.values.webWelcomePageImage}
          allowedExtension={["JPG", "PNG", "GIF"]}
        />
        <Form.Text muted>
          If no image is uploaded the background will default to the primary
          colour. Supported formats are PNG, JPG or GIF. Maximum file size is
          5MB and the recommended width and height is 1800px by 1200px.
        </Form.Text>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="webHomePageHeaderImage">
      <Form.Label column md="3" className="pt-0">
        Home page header
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload<SiteConfigFormData>
          fieldName="webHomePageHeaderImage"
          methods={methods}
          existingImage={methods.values.webHomePageHeaderImage}
          allowedExtension={["JPG", "PNG", "GIF"]}
        />
        <Form.Text muted>
          Supported formats are PNG, JPG or GIF. Maximum file size is 5MB and
          the recommended width and height is 1800px by 382px.
        </Form.Text>
        <div className="pt-1 pb-2">
          <Form.Check
            type="checkbox"
            name="textOverHomePageImage"
            onChange={methods.handleChange}
            checked={methods.values.textOverHomePageImage ?? false}
            label="Tick this box to show the site name text overlay on the header image"
          />
        </div>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="webBuildingPageHeaderImage">
      <Form.Label column md="3" className="pt-0">
        Building page header
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload<SiteConfigFormData>
          fieldName="webBuildingPageHeaderImage"
          methods={methods}
          existingImage={methods.values.webBuildingPageHeaderImage}
          allowedExtension={["JPG", "PNG", "GIF"]}
        />
        <Form.Text muted>
          Supported formats are PNG, JPG or GIF. Maximum file size is 5MB and
          the recommended width and height is 1800px by 382px.
        </Form.Text>
        <div className="pt-1 pb-2">
          <Form.Check
            type="checkbox"
            name="textOverBuildingPageImage"
            onChange={methods.handleChange}
            checked={methods.values.textOverBuildingPageImage ?? false}
            label="Tick this box to show the site name text overlay on the header image"
          />
        </div>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="webSiteLogoImage">
      <Form.Label column md="3" className="pt-0">
        Site logo
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload<SiteConfigFormData>
          fieldName="webSiteLogoImage"
          methods={methods}
          existingImage={methods.values.webSiteLogoImage}
          allowedExtension={["JPG", "PNG", "GIF"]}
          sizeDropdown={{
            options: [
              { label: "Small", value: "s" },
              { label: "Medium", value: "m" },
              { label: "Large", value: "l" }
            ],
            selectedValue: methods.values.webSiteLogoImageSize ?? "s",
            onChange: (option: any) => {
              methods.setFieldValue("webSiteLogoImageSize", option.value);
            }
          }}
        />
        <Form.Text muted>
          Supported formats are PNG, JPG or GIF. Maximum file size is 5MB. Once
          uploaded you can select from 3 available sizes. Increasing the size
          will increase the available width to contain the logo. The maximum
          height of a logo is fixed at 128px.
        </Form.Text>
        <div className="pt-1 pb-2">
          <Form.Check
            type="checkbox"
            name="showLogoOnWhiteBackground"
            onChange={methods.handleChange}
            checked={methods.values.showLogoOnWhiteBackground ?? false}
            label="Tick this box to use white as the logo background colour"
          />
        </div>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="webFooterLogoImage">
      <Form.Label column md="3" className="pt-0">
        Footer logo
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload<SiteConfigFormData>
          fieldName="webFooterLogoImage"
          methods={methods}
          existingImage={methods.values.webFooterLogoImage}
          allowedExtension={["JPG", "PNG", "GIF"]}
        />
        <Form.Text muted>
          Supported formats are PNG, JPG or GIF. Maximum file size is 5MB and
          the recommended width and height is 150px by 70px.
        </Form.Text>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="webBrowserIconImage">
      <Form.Label column md="3" className="pt-0">
        Browser icon
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload<SiteConfigFormData>
          fieldName="webBrowserIconImage"
          methods={methods}
          existingImage={methods.values.webBrowserIconImage}
          allowedExtension={["PNG", "ICO"]}
        />
        <Form.Text muted>
          Supported formats are PNG or ICO. Maximum file size is 5MB. The icon
          will be resized by the browser for display in your URL bar.
        </Form.Text>
      </Col>
    </Form.Group>

    <h5 className="mb-4">Welcome Page</h5>
    <TextFormGroup
      title={"Heading"}
      name="welcomePageHeading"
      subText="This heading is shown on the welcome page of the site."
    />
    <TextFormGroup
      title={"Subheading"}
      name="welcomePageSubHeading"
      subText="This subheading is shown on the welcome page of the site."
      asTextarea={true}
    />
  </ToggleContainer>
);
