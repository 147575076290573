import React, { useContext } from "react";
import { UserFormContext } from "./context/UserFormContext";
import { useFormContext } from "react-hook-form";
import { UserFormData, userFormNameCheck } from "./model/UserFormData";
import { Spinner, ProgressBar } from "react-bootstrap";
import { useUserFormImageActions } from "./hook/useUserFormImageActions";
import axios from "axios";

/**
 * User form image component.
 */
export const UserFormImage: React.FC = () => {
  const ctx = useContext(UserFormContext);
  const methods = useFormContext<UserFormData>();
  const noImg = `${process.env.PUBLIC_URL}/images/no-user.jpg`;
  const {
    uploading,
    avatar,
    setDisplayPic,
    displayPic,
    uploadProgress,
    onChange,
  } = useUserFormImageActions(
    ctx.user?.profile?.avatar ? ctx.user?.profile?.avatar : noImg,
    axios
  );

  return (
    <div>
      <div className="d-inline-block border border-dark">
        <img
          onError={() => {
            setDisplayPic(noImg)
          }}
          className="w-280-px h-280-px w-xl-350-px h-xl-350-px object-fit-cover"
          alt={ctx.user?.profile?.displayName}
          src={displayPic}
        />
      </div>
      <div className="pt-3">
        {!uploading && (
          <input style={{
            color: "transparent",
            overflow: "hidden",
            borderBottom: "none",
          }} className="eq-img-upload" type="file" onChange={(e) => onChange(e.target.files as any)} accept="image/*" multiple={false} />
        )}
        {uploading && (
          <div className="text-center">
            {uploadProgress != null ? (
              <ProgressBar
                className="mb-2"
                now={uploadProgress}
                label={`${uploadProgress}%`}
              />
            ) : (
              <>
                <Spinner size="sm" animation="grow" /> Loading...
              </>
            )}
          </div>
        )}
        {avatar && (
          <input
            type="hidden"
            value={avatar}
            {...methods.register(userFormNameCheck("avatar"))}
          />
        )}
      </div>
    </div>
  );
};
