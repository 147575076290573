import React, { useEffect } from "react";
import { ApartmentFragment } from "../../../../generated/admin";
import { BuildingWithLevels } from "../../../../model/BuildingWithLevels";

export function useCurrentSiteLevels(
  activeDestinationWithBuildings?: {
    uuid: string;
    buildings: BuildingWithLevels[];
  },
  apartment?: ApartmentFragment | null
) {
  const [state, setState] = React.useState<{
    buildingLevelUuids: string[];
    buildings: BuildingWithLevels[];
  }>({
    buildings: activeDestinationWithBuildings?.buildings ?? [],
    buildingLevelUuids: []
  });
  const activeUuid = activeDestinationWithBuildings?.uuid;

  useEffect(() => {
    if (apartment == null) {
      return;
    }
    const cd = apartment.apartmentDestinations
      .filter((cd) => cd.destination?.uuid === activeUuid)
      .pop();
    if (cd == null) {
      return;
    }

    setState((curState) => ({
      ...curState,
      buildingLevelUuids: cd.buildingLevels.map((bl) => bl.uuid)
    }));
  }, [apartment, activeUuid]);

  const setBuildingLevels = (buildingLevelUuids: string[]) =>
    setState((curState) => ({
      ...curState,
      buildingLevelUuids
    }));

  return { state, setBuildingLevels };
}
