import {
  AssociationValidationRule,
  CreateOrUpdateCompanyMutationFn,
} from "../../../../generated/admin";
import { useMemo } from "react";
import { CompanyFormData } from "../model/CompanyFormData";
import { MessageFn } from "../../../message/EqMessage";
import { useSiteContextHelper } from "../../../../hooks/useSiteContextHelper";
import { Maybe } from "graphql/jsutils/Maybe";

interface Param {
  mutationFn: CreateOrUpdateCompanyMutationFn;
  successMsgFn: MessageFn;
  errorMsgFn: MessageFn;
  companyUuid?: Maybe<string>;
  buildingLevelUuids?: Maybe<string[]>;
  activeDestination?: Maybe<string>;
  validationMessage?: Maybe<string>;
}

/**
 * Company details form hook.
 */
export function useCompanyDetailsForm({
  mutationFn,
  successMsgFn,
  errorMsgFn,
  companyUuid,
  buildingLevelUuids,
  activeDestination,
  validationMessage,
}: Param) {
  const siteCtxHelper = useSiteContextHelper();

  return useMemo(
    () => ({
      disableFields: companyUuid != null && activeDestination != null,
      onSubmit: async (data: CompanyFormData) => {
        // Validate the validation input.
        const containsAtSymbol = (
          data.validationValues?.filter((v) => {
            const rule = v.rule.value as AssociationValidationRule;

            return (
              rule === AssociationValidationRule.ExactEmailDomain &&
              v.value.indexOf("@") !== -1
            );
          }) ?? []
        ).map((x) => x.value);

        if (containsAtSymbol.length > 0) {
          errorMsgFn({
            text: `The validation value ${containsAtSymbol.join(
              ", "
            )} cannot include the @ symbol.`,
          });
          return;
        }

        let destinations: string[] | undefined = undefined;
        if (companyUuid == null && activeDestination != null) {
          destinations = [activeDestination];
        } else if (companyUuid != null && data.destinations != null) {
          destinations = data.destinations;
        }

        const result = await mutationFn({
          variables: {
            input: {
              uuid: companyUuid,
              name: data.name,
              industry: data.industry.value,
              destinations,
              validation: {
                message: validationMessage,
                values:
                  data.validationValues?.map((x) => ({
                    value: x.value,
                    rule: x.rule.value as AssociationValidationRule,
                  })) ?? [],
              },
              companyDestination:
                activeDestination != null
                  ? {
                      destinationUuid: activeDestination,
                      buildingLevels: buildingLevelUuids ?? undefined,
                      companyAttributes:
                        data.attributes?.map((x) => x.value) ?? undefined,
                    }
                  : undefined,
            },
          },
        });

        if (result.errors?.length) {
          errorMsgFn({
            text: result.errors.join(", "),
          });

          return;
        }

        if (
          result.data?.createOrUpdateCompany.__typename ===
          "CompanyV2SyncFailure"
        ) {
          errorMsgFn({
            text: result.data?.createOrUpdateCompany.reason,
          });

          return;
        }

        successMsgFn({
          text: `Company ${
            companyUuid != null ? "details edited" : "created"
          } successfully.`,
        });

        siteCtxHelper.goBack();
      },
    }),
    [
      companyUuid,
      activeDestination,
      mutationFn,
      buildingLevelUuids,
      successMsgFn,
      siteCtxHelper,
      errorMsgFn,
      validationMessage,
    ]
  );
}
