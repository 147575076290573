import React, { useState, createContext, useContext, FC } from "react";
import { useUserTracking } from "../../../../components/UserTracking";
import { ProfileStatus } from "../../../../generated/admin";

export type UpdatedUserStore = ReturnType<typeof useUpdatedUserStoreFactory>;

export function useUpdatedUserStoreFactory() {
  const [state, setState] = useState<{
    [uuid: string]: { status: ProfileStatus };
  }>({});
  function updateUser(uuid: string, status: ProfileStatus) {
    setState((state) => {
      const updated = { ...state };
      updated[uuid] = { status };
      return updated;
    });
  }
  return {
    state,
    updateUser,
  };
}

const UpdatedUserContext = createContext<UpdatedUserStore | null>(null);

export function useUpdatedUserStore() {
  const store = useContext(UpdatedUserContext);
  if (store == null) {
    throw new Error("updated user store not initialized");
  }
  return store;
}

export const UpdatedUserStoreProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
  const store = useUpdatedUserStoreFactory();
  useUserTracking();
  return (
    <UpdatedUserContext.Provider value={store}>
      {children}
    </UpdatedUserContext.Provider>
  );
};
