import React from "react";
import { Container } from "react-bootstrap";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { General } from "./General";
import { Branding } from "./Branding";
import { Navigation } from "./Navigation";
import { AccessControl } from "./AccessControl";
import { Parking } from "./Parking";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { SiteDetailsQuery } from "../../../../generated/admin";
import { RootRoutes } from "../../../../RouterOutlet";
interface Props {
  methods: FormikProps<SiteConfigFormData>;
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const MobileSettings: React.FC<Props> = ({ methods, dest }) => (
  <Container className="mb-5">
    <General methods={methods} />
    <Branding methods={methods} />
    <Navigation methods={methods} />
    <ToggleContainer
      title="Home Screen Configuration"
      className="lg"
      link={`${RootRoutes.SITES}/${dest.uuid}/mobile-homeuiwidget`}
    />
    <AccessControl methods={methods} dest={dest} />
    <Parking methods={methods} />
  </Container>
);
