import React from "react";
import { Form, Row, Spinner, Col } from "react-bootstrap";
import { CreateButton } from "../../../../components/CreateButton";
import {
  DestinationLevelsQuery,
  SyncAreaInput,
  useCreateOrUpdateAreaMutation,
  DestinationLevelsDocument,
  DestinationLevelsQueryVariables,
  CreateOrUpdateAreaMutation,
  CreateOrUpdateAreaMutationVariables,
} from "../../../../generated/admin";
import { Area } from "../BuildingDetailsGeneral";
import { EqMessageSuccess, EqMessageError } from "../../../message/EqMessage";
import { LevelsSelection } from "../../../../components/LevelsSelection";
import { BuildingWithLevels } from "../../../../model/BuildingWithLevels";
import { Maybe } from "graphql/jsutils/Maybe";
import { MutationHookOptions } from "@apollo/client";

type Destination = {
  uuid: string;
  buildings?: Maybe<BuildingWithLevels[]>;
};

export const AreaForm = ({
  destination,
  area,
  submitCallback,
}: {
  destination: Destination;
  area?: Area;
  submitCallback?: () => void;
}) => {
  const [state, setState] = React.useState<SyncAreaInput & { dirty: boolean }>({
    uuid: area?.uuid,
    name: area?.name ?? "",
    destinationUuid: destination.uuid,
    buildingLevelUuids: area?.buildingLevels.map((bl) => bl.uuid) ?? [],
    dirty: false,
  });
  const [createOrUpdateArea, { loading }] = useCreateOrUpdateAreaMutation();

  const createOrUpdate = async () => {
    if (state.name.length === 0) {
      return;
    }

    const result = await createOrUpdateArea({
      variables: {
        input: {
          destinationUuid: destination.uuid,
          name: state.name,
          uuid: state.uuid,
          buildingLevelUuids: state.buildingLevelUuids,
        },
      },
      update: update(state),
    });
    if (result.data?.createOrUpdateArea.__typename === "AreaSyncSuccess") {
      if (state.uuid == null) {
        setState({
          ...state,
          name: "",
          buildingLevelUuids: [],
          dirty: false,
        });
      }
      await EqMessageSuccess({
        text: "Area successfully saved.",
      });
    } else if (
      result.data?.createOrUpdateArea.__typename === "AreaSyncFailure"
    ) {
      const message =
        result.data?.createOrUpdateArea.reason ?? "Unable to save Area";
      await EqMessageError({
        text: message,
      });
    }
    submitCallback && submitCallback();
  };

  return (
    <>
      {area == null && (
        <p>
          Specify the name and then select the levels you want to form the new
          area using the table below. If your site is comprised of multiple
          buildings, use the links at the top of this table to toggle between
          buildings.
        </p>
      )}
      <Form.Group controlId="name" as={Row}>
        <Form.Label column sm="2">
          {area?.uuid ? "Area name" : "New area name"}
        </Form.Label>

        <Col sm="6">
          <Form.Control
            required
            value={state.name}
            isInvalid={state.dirty && state.name.length === 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setState({ ...state, name: e.target.value, dirty: true })
            }
            onBlur={() => setState({ ...state, dirty: true })}
            placeholder="e.g. Low-rise"
            name="name"
          />

          {state.dirty && state.name.length === 0 && (
            <Form.Control.Feedback type="invalid">
              Required.
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>
      <LevelsSelection
        buildings={destination.buildings}
        state={state}
        setState={(buildingLevelUuids: string[]) =>
          setState((state) => ({ ...state, buildingLevelUuids }))
        }
      />
      <br />
      <br />
      <Row className="flex-row-reverse">
        <Col>
          <CreateButton
            disabled={loading}
            onClick={createOrUpdate}
            className="mr-1 float-right"
            style={{ maxWidth: "260px" }}
          >
            {loading ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Save Area"
            )}
          </CreateButton>
        </Col>
      </Row>
    </>
  );
};

const update =
  (
    state: SyncAreaInput
  ): MutationHookOptions<
    CreateOrUpdateAreaMutation,
    CreateOrUpdateAreaMutationVariables
  >["update"] =>
  (cache, { data: mutationResult }) => {
    if (mutationResult?.createOrUpdateArea.__typename !== "AreaSyncSuccess") {
      return;
    }
    const existing = cache.readQuery<
      DestinationLevelsQuery,
      DestinationLevelsQueryVariables
    >({
      query: DestinationLevelsDocument,
      variables: { uuid: state.destinationUuid },
    });
    if (existing == null) {
      return;
    }
    const areaToPush = {
      __typename: "Area" as const,
      ...state,
      uuid: mutationResult?.createOrUpdateArea.area.uuid,
      buildingLevels: state.buildingLevelUuids.map((uuid) => ({
        __typename: "BuildingLevel" as const,
        uuid,
      })),
    };
    const updated = {
      ...existing,
      destination: {
        ...existing.destination,
        areas: [
          ...(existing.destination.areas?.filter(
            (a) => a.uuid !== areaToPush.uuid
          ) ?? []),
          areaToPush,
        ],
      },
    };
    cache.writeQuery<DestinationLevelsQuery, DestinationLevelsQueryVariables>({
      query: DestinationLevelsDocument,
      data: updated,
    });
  };
