import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { FormikContextType, getIn } from "formik";
import { WhitelabelFormData } from "./WhitelabelFormData";
import { ImageUpload } from "../../sites/site-details/fields/ImageUpload";

type AllowedExtension = "PNG";
interface Props {
  title: string;
  fieldName: string;
  existingImage: string | null | undefined;
  methods: FormikContextType<WhitelabelFormData>;
  allowedExtension?: AllowedExtension[];
  height: number;
  width: number;
  alphaChannel?: boolean;
  hint?: string;
}

export const WhitelabelImageUpload: React.FC<Props> = ({
  title,
  height,
  width,
  fieldName,
  existingImage,
  methods,
  allowedExtension,
  alphaChannel,
  hint,
}) => {
  const error = getIn(methods.errors, fieldName);
  return (
    <Form.Group as={Row} controlId={fieldName}>
      <Form.Label column md="3" className="pt-0">
        {title}
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload<WhitelabelFormData>
          allowedExtension={allowedExtension}
          restrictions={{
            height: height,
            width: width,
            alphaChannel: alphaChannel,
          }}
          existingImage={existingImage}
          fieldName={fieldName}
          methods={methods}
        />
        <Form.Text muted>
        {hint != null ? `${hint}` : ""} {width}px by {height}px
        </Form.Text>
        {error == null ? <></> : <p className="text-danger">{error}</p>}
      </Col>
    </Form.Group>
  );
};
