import React, { useMemo, useState } from "react";
import { useIndustryStore } from "../hook/useIndustryStore";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { ArrayPagination } from "../../../../components/Pagination";
import { Sorter } from "../../../../components/Sorter";
import { FlushRightTable } from "../../../../components/FlushRightTable";
import { ToggleEdit } from "./ToggleEdit";
import { LazyExportToCsv } from "../../../../components/export/LazyExportToCsv";
import { Industry } from "../model/Industry";
import styled from "styled-components";

const StyledUserList = styled.div`
  table th:nth-child(1) {
    width: 70%;
  }
`;

const exportConfig = [
  { column: "name", label: "Industry" },
  { column: "companyCount", label: "No. of companies" }
];

const transforms = [
  (item: Industry) => {
    return { ...item };
  }
];

/**
 * Industry list.
 */
export const IndustryList: React.FC = () => {
  const store = useIndustryStore();
  const [sort, setSort] = useState<{
    field: string;
    asc: boolean;
  }>({ field: "name", asc: true });
  const sorter = useMemo(() => new Sorter(
    [
      { key: "name", label: "Industry" },
      { key: "companyCount", label: "No. of companies" }
    ],
    sort,
    setSort
  ), [sort]);
  const sorted = sorter.sortItems(store.industries.slice());

  return (
    <LoadingOrErrorDisplay
      loading={store.loading}
      error={store.initalLoadError}
    >
      <StyledUserList>
        <ArrayPagination
          topLeftContent={
            <>
              <LazyExportToCsv
                items={sorted}
                config={exportConfig}
                transforms={transforms}
              />
            </>
          }
          items={sorted}
        >
          {(items) => (
            <FlushRightTable>
              <sorter.Heading>
                <th className="w-200-px text-right">Operations</th>
              </sorter.Heading>
              <tbody>
                {items.map((industry) => (
                  <ToggleEdit
                    key={`row-${industry.uuid}`}
                    industry={industry}
                    store={store}
                  />
                ))}
              </tbody>
            </FlushRightTable>
          )}
        </ArrayPagination>
      </StyledUserList>
    </LoadingOrErrorDisplay>
  );
};
