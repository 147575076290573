export type QueryParam =  string | string[] | null

export const toBoolParam = (value: QueryParam): boolean | null => {
    return typeof value === 'string' && ["false", "true"].includes(value)
    ? JSON.parse(value)
    : null
  }

export const toIntParam = (value: QueryParam): number | null => {
    if (typeof value !== 'string') return null
    return parseInt(value)
}