import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { SiteConfigFormData } from "./SiteConfigFormData";
import { EqMessageWarning } from "../../message/EqMessage";

type errorKey = keyof SiteConfigFormData;

const split = (target: string) =>
  target.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();

export const SiteConfigErrors: React.FC = () => {
  const fm = useFormikContext<SiteConfigFormData>();

  useEffect(() => {
    if (
      !fm.isSubmitting ||
      fm.isValid ||
      fm.isValidating ||
      Object.keys(fm.errors).length === 0
    ) {
      return;
    }

    const errors = Object.keys(fm.errors).map(
      (ekey) => `${fm.errors[ekey as errorKey]} [${split(ekey)}]`
    );

    EqMessageWarning({
      title: "Attention",
      html: `Check the following errors:<br/> ${errors.join("<br />")}`,
    });
  }, [fm.errors, fm.isSubmitting, fm.isValid, fm.isValidating]);

  return null;
};
