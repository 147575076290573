import React, { useState, useCallback } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { SubTitle } from "../../../components";
import { CreateButton } from "../../../components/CreateButton";
import { useGroupsCatalogue } from "./store/useGroupsCatalogue";
import { GroupStore } from "./model/GroupStore";
import { Catalogue } from "../../catalogue/Catalogue";

interface Props {
  store: GroupStore;
}

const text = {
  selectedItemsTitle: "My Groups",
  itemsTitle: "Groups Catalogue",
  saveBtnTxt: "Add Groups",
};

/**
 * Groups catalogue landing.
 */
export const GroupsCatalogue: React.FC<Props> = ({ store }) => {
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => setShow(false), [setShow]);
  const handleShow = useCallback(() => setShow(true), [setShow]);
  const result = useGroupsCatalogue({
    store,
    closeModalFn: handleClose,
  });

  return (
    <>
      <SubTitle>Groups Catalogue</SubTitle>
      <p>
        Choose from a catalogue of existing groups to add to your site. These
        groups will be unique to your site once added and will become available
        in the table below.
      </p>
      <Row className="justify-content-end">
        <Col md="4" lg="3">
          <CreateButton onClick={handleShow}>Open Catalogue</CreateButton>
        </Col>
      </Row>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header
          className="border-bottom-0 pb-0 pt-2"
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Catalogue catalogueItemsStore={result} text={text} />
        </Modal.Body>
      </Modal>
    </>
  );
};
