export const UserManagement = (_: { uuid: string }) => {
  return (
    <div>
      <h5 className="mb-4">Site Managers</h5>
      <p>
        The Site Manager role has now been replaced by the newer Property
        Manager role. To make a user a Property Manager, visit the Roles tab of
        the user's profile in Equiem ONE.
      </p>
    </div>
  );
};
