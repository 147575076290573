import React from "react";
import { UserListActionButton } from "./UserListActions";
import { Button } from "react-bootstrap";
import { FatListIcon } from "../../../components/FatListIcon";

export const FatListButton: React.FC<UserListActionButton> = (props) => (
  <Button
    onClick={props.onclick}
    variant={"link"}
    size="sm"
    className="p-0 ml-1"
  >
    <FatListIcon />
  </Button>
);
