import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useField, FieldAttributes } from "formik";
import Select from "react-select";
import { OptionType } from "../../model/OptionType";

interface Props {
  error?: string;
  title: string;
  subText?: string;
  maxLength?: number;
  asTextarea?: boolean;
  options: OptionType[];
  isMulti?: boolean;
  fullWidth?: true;
}

export const SelectGroup = ({
  error,
  title,
  subText,
  maxLength,
  asTextarea = false,
  fullWidth = true,
  ...props
}: FieldAttributes<any> & Props) => {
  const [field, , helpers] = useField(props);
  return (
    <Form.Group as={Row} controlId={field.name}>
      <Form.Label column md="3">
        {title}
      </Form.Label>
      <Col md="9" lg={fullWidth ? "9" : "6"}>
        <Select<OptionType>
          classNamePrefix="eq"
          className="react-select"
          aria-label={`react-select-${field.name}`}
          value={
            (Array.isArray(field.value)
              ? props.options.filter((op: OptionType) =>
                  field.value.includes(op.value)
                )
              : props.options.find(
                  (op: OptionType) => op.value === field.value
                )) ?? null
          }
          onChange={(selected) =>
            helpers.setValue(
              Array.isArray(selected)
                ? selected.flatMap((s) => (s != null ? [s.value] : []))
                : (selected as OptionType)?.value
            )
          }
          onBlur={field.onBlur}
          {...props}
        />
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
        {subText != null ? (
          <Form.Text className="text-muted">{subText}</Form.Text>
        ) : null}
      </Col>
    </Form.Group>
  );
};
