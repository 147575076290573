import React from "react";
import { useSiteGroupsQuery } from "../../../../generated/admin";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { FormikSelect } from "../../../../components/formik/FormikSelect";

export const GroupsSelector: React.FC<{
  destinationUuid: string;
  name: string;
}> = ({ destinationUuid, name }) => {
  const { data, loading, error } = useSiteGroupsQuery({
    variables: { destinationUuid },
  });
  const options =
    data?.siteGroups.map((a) => {
      return {
        value: a.uuid,
        label: a.name,
      };
    }) ?? [];
  return (
    <LoadingOrErrorDisplay loading={loading} error={error}>
      <FormikSelect
        placeholder="Select groups..."
        aria-label={name}
        name={name}
        options={options}
        isMulti
      />
    </LoadingOrErrorDisplay>
  );
};
