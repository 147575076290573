import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { SiteDetailsQuery } from "../../../../generated/admin";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { FormikContextType } from "formik";
import { ImageUpload } from "../fields/ImageUpload";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
  methods: FormikContextType<SiteConfigFormData>;
}

export const GeneralSettingsBranding: React.FC<Props> = ({ dest, methods }) => {
  return (
    <>
      <h5 className="mb-4">Image Assets</h5>
      <Form.Group as={Row} controlId="emailHeaderImage">
        <Form.Label column md="3" className="pt-0">
          Email header
        </Form.Label>
        <Col md="9" lg="6">
          <ImageUpload<SiteConfigFormData>
            fieldName="emailHeaderImage"
            methods={methods}
            existingImage={methods.values.emailHeaderImage}
            allowedExtension={["JPG", "PNG"]}
          />
          <Form.Text muted>
            The header image will be shown at the top of newsletters and system 
            generated emails. Supported formats are PNG and JPG. 
            Maximum file size is 5MB and the recommended width is 600px.
          </Form.Text>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="emailFooterImage">
        <Form.Label column md="3" className="pt-0">
          Email footer
        </Form.Label>
        <Col md="9" lg="6">
          <ImageUpload<SiteConfigFormData>
            fieldName="emailFooterImage"
            methods={methods}
            existingImage={methods.values.emailFooterImage}
            allowedExtension={["JPG", "PNG", "GIF"]}
          />
          <Form.Text muted>
            The footer image will be shown at the bottom of newsletters and 
            system generated emails. Supported formats are PNG and JPG. 
            Maximum file size is 5MB and the recommended width is 600px.
          </Form.Text>
        </Col>
      </Form.Group>
    </>
  );
};
