import React, { useMemo } from "react";
import {
  SyncBuildingInput,
  useBuildingDetailsQuery
} from "../../../generated/admin";
import { FlushRightTable } from "../../../components/FlushRightTable";
import { ArrayPagination } from "../../../components/Pagination";
import { Sorter } from "../../../components/Sorter";
import { useShiftKeyDown } from "./useShiftKeyDown";
import { selectRange } from "../../../components/selectRange";
import { Form } from "react-bootstrap";

export const BuildingLevelsTable = ({
  levels,
  state,
  setState,
  building
}: {
  levels: { name: string }[];
  state: SyncBuildingInput;
  setState: React.Dispatch<React.SetStateAction<SyncBuildingInput>>;
  building?: NonNullable<
    ReturnType<typeof useBuildingDetailsQuery>["data"]
  >["building"];
}) => {
  const [lastSelected, setLastSelected] = React.useState<string | null>(null);
  const shiftKeyDown = useShiftKeyDown();
  const [sort, setSort] = React.useState<{
    field: string;
    asc: boolean;
  }>({ field: "enabled", asc: true });

  const sorter = useMemo(() => {
    return new Sorter(
    [{ key: "name", label: "Level" },
    { key: "enabled", label: "Status" }],
    sort,
    setSort
  )}, [sort]);

  return (
    <ArrayPagination
      items={sorter.sortItems(
        levels.map((level) => {
          const checked = state.levels.includes(level.name);
          const enabled =
            building?.buildingLevels.map((l) => l.name).includes(level.name) ??
            false;
          return { ...level, checked, enabled };
        })
      )}
    >
      {(items) => {
        const currentPageUuids = items.map((lvl) => lvl.name);
        const allLevelsSelected =
          currentPageUuids.filter((cl) => state.levels.includes(cl)).length ===
          currentPageUuids.length;

        return (
          <FlushRightTable hover>
            <sorter.Heading prepend={true}>
              <th style={{ width: "50px" }}>
                <Form.Check
                  type="checkbox"
                  checked={allLevelsSelected}
                  onChange={() => {
                    const lvls = allLevelsSelected
                      ? state.levels.filter(
                          (l) => !currentPageUuids.includes(l)
                        )
                      : new Set([...state.levels, ...currentPageUuids]);
                    return setState({
                      ...state,
                      levels: Array.from(lvls)
                    });
                  }}
                />
              </th>
            </sorter.Heading>
            <tbody>
              {items.map((level) => {
                const selectLevel = selectLevelHandlerFactory({
                  shiftKeyDown,
                  lastSelected,
                  levels,
                  level,
                  state,
                  setState,
                  setLastSelected
                });
                return (
                  <tr
                    onClick={selectLevel}
                    style={{ cursor: "pointer", userSelect: "none" }}
                    key={"building-detail-levels" + level.name}
                  >
                    <td>
                      <Form.Check
                        type="checkbox"
                        readOnly
                        checked={level.checked}
                      />
                    </td>
                    <td>{level.name}</td>
                    <td
                      style={{
                        color: level.enabled ? "green" : "grey"
                      }}
                    >
                      {level.enabled ? "Enabled" : "Disabled"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </FlushRightTable>
        );
      }}
    </ArrayPagination>
  );
};

function selectLevelHandlerFactory({
  shiftKeyDown,
  lastSelected,
  levels,
  level,
  state,
  setState,
  setLastSelected
}: {
  shiftKeyDown: boolean;
  lastSelected: string | null;
  levels: { name: string }[];
  level: { checked: boolean; enabled: boolean; name: string };
  state: SyncBuildingInput;
  setState: React.Dispatch<React.SetStateAction<SyncBuildingInput>>;
  setLastSelected: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  return () => {
    if (shiftKeyDown && lastSelected != null) {
      const lvls = selectRange(
        levels.map((l) => l.name),
        level.name,
        lastSelected,
        level.checked,
        state.levels
      );
      setState({
        ...state,
        levels: lvls
      });
    } else {
      setState({
        ...state,
        levels: level.checked
          ? state.levels.filter((name) => name !== level.name)
          : [...state.levels, level.name]
      });
    }
    setLastSelected(level.name);
  };
}
