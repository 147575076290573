import React, { useMemo, useState } from "react";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { ArrayPagination } from "../../../../components/Pagination";
import { Sorter } from "../../../../components/Sorter";
import { FlushRightTable } from "../../../../components/FlushRightTable";
import { ToggleEdit } from "./ToggleEdit";
import { LazyExportToCsv } from "../../../../components/export/LazyExportToCsv";
import { UserAttribute } from "../model/UserAttribute";
import { UserAttributeStore } from "../model/UserAttributeStore";

interface Props {
  store: UserAttributeStore;
}

const exportConfig = [{ column: "name", label: "User attribute" }];
const transforms = [
  (item: UserAttribute) => {
    return { ...item };
  },
];

/**
 * Attribute list.
 */
export const AttributeList: React.FC<Props> = ({ store }) => {
  const [sort, setSort] = useState<{
    field: string;
    asc: boolean;
  }>({ field: "name", asc: true });
  const sorter = useMemo(() => {
    return new Sorter(
    [{ key: "name", label: "User attribute" }],
    sort,
    setSort
  )}, [sort]);

  const sorted = useMemo(() => {
    return sorter.sortItems(
      store.attributes.slice(),
      store.userAttributesSort
    );
  }, [store.attributes, store.userAttributesSort, sorter]);

  const topLeftContent =
    store.activeDestination || sorted.length === 0 ? (
      <></>
    ) : (
      <LazyExportToCsv
        items={sorted}
        config={exportConfig}
        transforms={transforms}
      />
    );

  return (
    <LoadingOrErrorDisplay
      loading={store.loading}
      error={store.initalLoadError}
    >
      <ArrayPagination
        urlSearchParams={true}
        topLeftContent={topLeftContent}
        items={sorted}
      >
        {(items) => (
          <FlushRightTable>
            <sorter.Heading>
              <th className="w-200-px text-right">Operations</th>
            </sorter.Heading>
            <tbody>
              {items.map((attribute) => (
                <ToggleEdit
                  key={`row-${attribute.uuid}`}
                  attribute={attribute}
                  store={store}
                />
              ))}
            </tbody>
          </FlushRightTable>
        )}
      </ArrayPagination>
    </LoadingOrErrorDisplay>
  );
};
