import React, { useCallback } from "react";
import { Formik, FormikHelpers, FieldArray } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { CardAccordion } from "../../../../../components/SingleEqAccordion";
import { TextFormGroup } from "../../fields/TextFormGroup";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";
import {
  UpdateIntegrationInput,
  useDeleteAc1IntegrationMutation,
  useUpdateAc1IntegrationMutation,
} from "../../../../../generated/admin";
import { trimmedOrUndefined } from "../../../../../util/trimmedOrUndefined";
import { stringNotEmpty } from "../../../../../util/stringNotEmpty";
import type { Building, Integration } from "./useAc1Data";
import { useAc1Data } from "./useAc1Data";
import { OptionType } from "../../../../../model/OptionType";
import { AddButton } from "../../../../../components/AddButton";
import { RemoveButton } from "../../../../../components/RemoveButton";

export type FormValues = Omit<
  UpdateIntegrationInput,
  "uuid" | "buildingIntegrations"
> & {
  uuid: string;
  provider: string;
  buildings: Building[];
};

interface Props {
  integration: Integration;
  siteUuid: string;
}

export const IntegrationConfiguration: React.FC<Props> = ({
  integration,
  siteUuid,
}) => {
  const { siteBuildingOptions } = useAc1Data(siteUuid);
  const [updateMutation, { loading: updateInProgress }] =
    useUpdateAc1IntegrationMutation();
  const [deleteMutation, { loading: deleteInProgress }] =
    useDeleteAc1IntegrationMutation();
  const initialValues: FormValues = {
    ...integration,
    buildings: !!integration?.buildings?.length
      ? integration.buildings
      : [
          {
            visitorsEnabled: true,
            employeesEnabled: true,
            building: {
              value: "",
              label: "",
            },
          },
        ],
  };

  const handleUpdate = useCallback(
    async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        if (updateInProgress) {
          return;
        }

        const result = await updateMutation({
          variables: {
            input: {
              uuid: values.uuid,
              name: values.name.trim(),
              buildingIntegrations: values.buildings.map(
                ({ building, visitorsEnabled, employeesEnabled }) => ({
                  buildingUuid: building.value,
                  visitorsEnabled,
                  employeesEnabled,
                })
              ),
              ...(stringNotEmpty(values.accessPassName)
                ? { accessPassName: values.accessPassName.trim() }
                : {}),
              externalApiBaseUrl: values.externalApiBaseUrl.trim(),
              externalApiDataUrl: trimmedOrUndefined(values.externalApiDataUrl),
              externalApiKey: trimmedOrUndefined(values.externalApiKey),
              externalApiSuffix: trimmedOrUndefined(values.externalApiSuffix),
              externalApiTokenSuffix: trimmedOrUndefined(
                values.externalApiTokenSuffix
              ),
              externalClientId: trimmedOrUndefined(values.externalClientId),
              externalClientPassword: trimmedOrUndefined(
                values.externalClientPassword
              ),
              externalClientScope: trimmedOrUndefined(
                values.externalClientScope
              ),
              externalClientSecret: trimmedOrUndefined(
                values.externalClientSecret
              ),
              externalClientUsername: trimmedOrUndefined(
                values.externalClientUsername
              ),
              connectionAndKeyPassword: trimmedOrUndefined(
                values.connectionAndKeyPassword
              ),
              connectionCertificate: stringNotEmpty(
                values.connectionCertificate
              )
                ? values.connectionCertificate
                : undefined,
              connectionKey: stringNotEmpty(values.connectionKey)
                ? values.connectionKey
                : undefined,
              config: stringNotEmpty(values.config) ? values.config : undefined,
            },
          },
          refetchQueries: ["Ac1Integrations"],
        });

        if (result.errors != null) {
          console.error(result.errors);
          if (result.errors.length === 0) {
            throw new Error("Unknown error response from server.");
          }
          throw result.errors[0];
        }

        resetForm();
        EqMessageSuccess({
          text: "Successfully updated integration.",
        });
      } catch (e: unknown) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [updateInProgress, updateMutation]
  );

  const deleteWithConfirmation = useCallback(
    async (uuid: string, name: string) => {
      const confirm = await EqDangerMessage({
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        title: "Are you sure?",
        html: `Once "${name}" is deleted, you cannot retrieve this integration.`,
      });

      if (confirm.value !== true || deleteInProgress) {
        return;
      }

      try {
        const result = await deleteMutation({
          variables: { uuid },
          refetchQueries: ["Ac1Integrations"],
        });

        if (result.errors != null) {
          console.error(result.errors);
          if (result.errors.length === 0) {
            throw new Error("Unknown error response from server.");
          }
          throw result.errors[0];
        }

        EqMessageSuccess({ text: `${name} deleted. ` });
      } catch (e: unknown) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [deleteMutation, deleteInProgress]
  );

  const getFilteredOptions = (
    selectedBuildings: Building[],
    currentIndex: number
  ) => {
    const selectedValues = selectedBuildings
      .map((b, index) => (index !== currentIndex ? b.building?.value : null))
      .filter(Boolean);
    return siteBuildingOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const findSelectedOption = (value: string) =>
    siteBuildingOptions.find((option) => option.value === value) || null;

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "auto",
      minWidth: "400px",
    }),
  };

  return (
    <div className="container-main">
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdate}
        enableReinitialize
      >
        {(methods) => (
          <CardAccordion cardClassName="flex-nowrap" title={integration.name}>
            <Form>
              <Form.Group as={Row} controlId="buildings">
                <Form.Label column md="3">
                  {"Buildings"}
                </Form.Label>
                <Col md="9" lg="6">
                  <FieldArray
                    name="buildings"
                    render={(arrayHelpers) => (
                      <div>
                        {methods.values.buildings.map(
                          (buildingEntity, index) => {
                            return (
                              <Form.Group
                                as={Row}
                                controlId={`buildings-${index}`}
                                key={index}
                              >
                                <Col
                                  className="site-client-select d-flex align-items-center"
                                  style={{ gap: "16px" }}
                                >
                                  <div
                                    data-eq-test={`building-select-${index}`}
                                  >
                                    <Row className="mb-2">
                                      <Col className="pr-0">
                                        <Select<OptionType, false>
                                          classNamePrefix="eq"
                                          styles={customStyles}
                                          isSearchable
                                          value={findSelectedOption(
                                            buildingEntity?.building?.value
                                          )}
                                          options={getFilteredOptions(
                                            methods.values.buildings,
                                            index
                                          )}
                                          onChange={(selectedOption) => {
                                            if (!selectedOption) return;

                                            const updatedBuilding = {
                                              ...buildingEntity,
                                              building: {
                                                value: selectedOption.value,
                                                label: selectedOption.label,
                                              },
                                            };
                                            arrayHelpers.replace(
                                              index,
                                              updatedBuilding
                                            );
                                            methods.setFieldValue(
                                              `buildings[${index}]`,
                                              updatedBuilding
                                            );
                                          }}
                                          placeholder="Select..."
                                          aria-label="site-client-select"
                                        />
                                      </Col>
                                      <Col
                                        className="pl-1 pt-2 pb-0 pr-0"
                                        style={{ width: "30px" }}
                                      >
                                        {methods.values.buildings.length > 1 &&
                                          index <
                                            methods.values.buildings.length -
                                              1 && (
                                            <button
                                              type="button"
                                              data-eq-test={`bin-${index}`}
                                              style={{
                                                border: "none",
                                                background: "white",
                                              }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <RemoveButton />
                                            </button>
                                          )}
                                        {index ===
                                          methods.values.buildings.length - 1 &&
                                          methods.values.buildings.length <
                                            siteBuildingOptions.length && (
                                            <button
                                              type="button"
                                              data-eq-test={`plus-${index}`}
                                              style={{
                                                border: "none",
                                                background: "white",
                                              }}
                                              onClick={() =>
                                                arrayHelpers.push({
                                                  value: "",
                                                  label: "",
                                                  visitorsEnabled: true,
                                                  employeesEnabled: true,
                                                })
                                              }
                                            >
                                              <AddButton />
                                            </button>
                                          )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="pr-0">
                                        <Form.Check
                                          name={`buildings[${index}].visitorsEnabled`}
                                          type="checkbox"
                                          label="Visitors access passes"
                                          data-eq-test={`visitors-enabled-${index}`}
                                          onChange={(e) => {
                                            const updatedBuilding = {
                                              ...buildingEntity,
                                              visitorsEnabled: e.target.checked,
                                            };
                                            arrayHelpers.replace(
                                              index,
                                              updatedBuilding
                                            );
                                            methods.setFieldValue(
                                              `buildings[${index}].visitorsEnabled`,
                                              e.target.checked
                                            );
                                          }}
                                          checked={
                                            buildingEntity.visitorsEnabled
                                          }
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Check
                                          name={`buildings[${index}].employeesEnabled`}
                                          type="checkbox"
                                          label="Employees access passes"
                                          data-eq-test={`employees-enabled-${index}`}
                                          onChange={(e) => {
                                            const updatedBuilding = {
                                              ...buildingEntity,
                                              employeesEnabled:
                                                e.target.checked,
                                            };
                                            arrayHelpers.replace(
                                              index,
                                              updatedBuilding
                                            );
                                            methods.setFieldValue(
                                              `buildings[${index}].employeesEnabled`,
                                              e.target.checked
                                            );
                                          }}
                                          checked={
                                            buildingEntity.employeesEnabled
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Form.Group>
                            );
                          }
                        )}
                      </div>
                    )}
                  />
                </Col>
              </Form.Group>
              <TextFormGroup
                title="Name"
                subText="The unique name for this integration configuration."
                name="name"
              />
              <TextFormGroup title="Provider" name="provider" disabled />
              <TextFormGroup
                title="Pass Name"
                subText="The unique name that the user will see in the mobile app."
                name="accessPassName"
              />
              <TextFormGroup
                title="Provider API Base URL"
                subText="The base URL for the provider API."
                name="externalApiBaseUrl"
              />
              <TextFormGroup
                title="Provider API Data URL"
                name="externalApiDataUrl"
              />
              <TextFormGroup
                title="Provider API Key"
                name="externalApiKey"
                placeholder="Enter new API Key or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider API Suffix"
                name="externalApiSuffix"
              />
              <TextFormGroup
                title="Provider API Token Suffix"
                name="externalApiTokenSuffix"
              />
              <TextFormGroup
                title="Provider Client ID"
                name="externalClientId"
                placeholder="Enter new Client ID or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Password"
                name="externalClientPassword"
                placeholder="Enter new Client Password or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Scope"
                name="externalClientScope"
                placeholder="Enter new Client Scope or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Secret"
                name="externalClientSecret"
                placeholder="Enter new Client Secret or leave blank to keep existing"
              />
              <TextFormGroup
                title="Provider Client Username"
                name="externalClientUsername"
                placeholder="Enter new Client Username or leave blank to keep existing"
              />
              <TextFormGroup
                title="Connection and Key Password"
                name="connectionAndKeyPassword"
                placeholder="Enter new Connection and Key Password or leave blank to keep existing"
              />
              <TextFormGroup
                title="Connection Certificate"
                name="connectionCertificate"
                rows={12}
                placeholder="Enter new Connection Certificate or leave blank to keep existing"
                asTextarea
              />
              <TextFormGroup
                title="Connection Key"
                name="connectionKey"
                rows={12}
                placeholder="Enter new Connection Key or leave blank to keep existing"
                asTextarea
              />
              <TextFormGroup
                title="Misc Configuration"
                subText="Any other extra configuration in JSON"
                name="config"
                rows={12}
                asTextarea
              />
              <div className="form-inline justify-content-end mt-1">
                <Button
                  name="updateIntegration"
                  variant="outline-primary"
                  size="sm"
                  className="align-self-center m-2"
                  onClick={() => methods.handleSubmit()}
                  disabled={updateInProgress}
                >
                  {updateInProgress ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Update integration"
                  )}
                </Button>
                <Button
                  name="deleteIntegration"
                  variant="outline-danger"
                  size="sm"
                  className="align-self-center"
                  onClick={() =>
                    deleteWithConfirmation(
                      methods.values.uuid,
                      methods.values.name
                    )
                  }
                  disabled={deleteInProgress}
                >
                  {deleteInProgress ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Delete integration"
                  )}
                </Button>
              </div>
            </Form>
          </CardAccordion>
        )}
      </Formik>
    </div>
  );
};
