import React, { useState, useCallback } from "react";
import { SubTitle } from "../../../components";
import { Row, Col, Form, Spinner } from "react-bootstrap";
import { CreateButton } from "../../../components/CreateButton";
import { FormProvider, useForm } from "react-hook-form";
import {
  ApartmentFragment,
  Destination,
  useCreateOrUpdateApartmentMutation
} from "../../../generated/admin";
import { EqMessageError, EqMessageSuccess } from "../../message/EqMessage";
import { DestinationsCheckbox } from "../../../components/DestinationsCheckbox";
import { Divider } from "../../../components/Divider";
import { BuildingWithLevels } from "../../../model/BuildingWithLevels";
import { ApartmentFormData } from "./model/ApartmentFormData";
import { useApartmentDetailsForm } from "./hooks/useApartmentDetailsForm";
import { ApartmentSuggestion } from "./suggestion/ApartmentSuggestion";
import useDebounce from "../../../hooks/useDebounce";
import { ApartmentLevels } from "./levels/ApartmentLevels";
import { useCurrentSiteLevels } from "./levels/useCurrentSiteLevels";
import { Maybe } from "graphql/jsutils/Maybe";

interface IApartmentDetailsForm {
  apartment?: ApartmentFragment | null;
  activeDestination?: Maybe<string>;
  activeDestinationBuildings: BuildingWithLevels[];
  destinations: Pick<Destination, "uuid" | "name">[];
}

/**
 * Apartment details form.
 */
export const ApartmentDetailsForm: React.FC<IApartmentDetailsForm> = ({
  apartment,
  activeDestination,
  activeDestinationBuildings,
  destinations
}) => {
  const [search, setSearch] = useState<string>("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const resetSearch = useCallback(() => setSearch(""), [setSearch]);
  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearch(e.currentTarget.value),
    [setSearch]
  );

  const methods = useForm<ApartmentFormData>({
    defaultValues: {
      name: apartment?.name,
    }
  });

  const siteLevels = useCurrentSiteLevels(
    activeDestination
      ? {
          uuid: activeDestination,
          buildings: activeDestinationBuildings
        }
      : undefined,
    apartment
  );

  const [mutation, { loading: saving }] = useCreateOrUpdateApartmentMutation();
  const { onSubmit, disableFields } = useApartmentDetailsForm({
    mutationFn: mutation,
    successMsgFn: EqMessageSuccess,
    errorMsgFn: EqMessageError,
    apartmentUuid: apartment?.uuid,
    activeDestination,
    buildingLevelUuids: siteLevels.state.buildingLevelUuids
  });

  return (
    <FormProvider {...methods}>
      <Form
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
        className="gray-disable-form"
      >
        <Form.Group as={Row} controlId="apartmentName">
          <Form.Label column md="3" lg="2">
            Apartment name
          </Form.Label>
          <Col md="9" lg="6">
            <Form.Control
              type="text"
              disabled={disableFields}
              placeholder="e.g. 101"
              {...methods.register("name", { required: "Required." })}
              onChange={handleSearchChange}
            />
            {methods.formState.errors.name && (
              <Form.Control.Feedback type="invalid">
                {methods.formState.errors.name.message}
              </Form.Control.Feedback>
            )}
            {activeDestination && (
              <ApartmentSuggestion
                siteUuid={activeDestination}
                search={debouncedSearchTerm}
                hideFn={resetSearch}
              />
            )}
            <Form.Text className="text-muted">
              The apartment name will be displayed in a user’s profile and
              throughout other Equiem systems. These are unique and cannot be
              duplicated.
            </Form.Text>
          </Col>
        </Form.Group>

        {activeDestination == null && (
          <>
            <Divider />
            <SubTitle>Sites</SubTitle>
            {apartment != null ? (
              <>
                <p>
                  Sites enabled below are the current sites using this
                  apartment, not the sites that it is shared with. By default,
                  an apartment is available for all sites to use. Enabling a
                  site in this list will add it to the selected site whereas
                  disabling a site will remove it.
                </p>

                <DestinationsCheckbox
                  destinations={destinations}
                  selectedUuids={(apartment?.apartmentDestinations ?? []).map(
                    (d) => d.destinationUuid
                  )}
                />
              </>
            ) : (
              <p>
                Apartments created by an administrator are automatically
                available for all sites to use. When editing an apartment, you
                will be able to see which sites it is used at.
              </p>
            )}
          </>
        )}

        <ApartmentLevels {...siteLevels} />

        <div className="mt-5 text-right">
          <CreateButton disabled={saving} type="submit">
            {saving ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Save"
            )}
          </CreateButton>
        </div>
      </Form>
    </FormProvider>
  );
};
