import { useState, useEffect, useMemo, useContext } from "react";
import {
  useGetLevelsQuery,
  useDeleteLevelMutation,
  useAddLevelsMutation
} from "../../../generated/admin";
import { LevelStore, State } from "./LevelStore";
import { UserContext } from "../../user/UserContext";

export const levelsSort = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base"
  });

export const initialState = {
  levels: [],
  saveErrors: [],
  loading: false,
  newLevelName: "",
  levelsToAdd: [],
  addingLevel: false,
  deleting: "",
  sortedLevels: []
};

function useDeleteMutation(
  destinationUuid?: string
): ReturnType<typeof useDeleteLevelMutation>[0] {
  const [deleteMutation] = useDeleteLevelMutation();
  return (args) =>
    deleteMutation({
      ...args,
      variables: {
        uuid: args?.variables?.uuid,
        destinationUuid
      }
    });
}

function useAddMutation(
  destinationUuid?: string
): ReturnType<typeof useAddLevelsMutation> {
  const [addLevelsMutation, addResult] = useAddLevelsMutation();
  const withDest: ReturnType<typeof useAddLevelsMutation>[0] = (args) => {
    const input = args?.variables?.input;
    return addLevelsMutation({
      ...args,
      variables: {
        input: (Array.isArray(input) ? input : []).map((i) => ({
          ...i,
          destinationUuid
        }))
      }
    });
  };
  return [withDest, addResult];
}

export function useLevelStoreFactory(): LevelStore {
  const user = useContext(UserContext);
  const destinationUuid = user?.activeDestination?.uuid;
  const [state, setState] = useState<State>(initialState);

  const deleteMutation = useDeleteMutation(destinationUuid);

  const [addLevelsMutation, addResult] = useAddMutation(destinationUuid);

  const results = useGetLevelsQuery({
    variables: { destinationUuid: user?.activeDestination?.uuid },
    fetchPolicy: "network-only"
  });

  const store = useMemo(
    () => new LevelStore(state, setState, addLevelsMutation, deleteMutation),
    [state, addLevelsMutation, deleteMutation]
  );

  const set = (update: Partial<State>) =>
    setState((state) => ({ ...state, ...update }));

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: results.loading,
      initalLoadError: results.error,
      levels: results.data?.levels ?? []
    }));
  }, [results]);

  // Something about this useEffect which is re-rendering all the time...
  useEffect(() => {
    set({
      addingLevel: addResult.loading
    });
  }, [addResult.loading]);

  return store;
}
