import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  title: string | JSX.Element;
  className?: string;
  dataTestId?: string;
}

interface LinkProps extends Props {
  to: string;
  btnClasses?: string;
}

interface ButtonProps extends Props {
  active: boolean;
  onClick(): void;
}

const Sublink = styled(NavLink)`
  &.active {
    button.btn-secondary {
      box-shadow: none;
      background: var(--secondary);
      color: var(--primary);
    }
  }
`;

const StyledButton = styled(Button)`
  &.btn-secondary {
    border-color: var(--primary);
    background: #fff;
    color: var(--primary);
    min-width: 150px;
    font-weight: 600;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
        rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
      background: var(--secondary);
    }

    &.active-tab {
      box-shadow: none;
      background: var(--secondary);
      color: var(--primary);
    }
  }
`;

/**
 * Navigation style button.
 */
export const PageNavigationButton: React.FC<LinkProps> = ({
  to,
  title,
  className,
  btnClasses,
  dataTestId,
}) => {
  return (
    <Sublink
      className={className ?? ""}
      to={to}
      data-testid={dataTestId}
      end
    >
      <StyledButton
        variant="secondary"
        className={`text-uppercase ${btnClasses ?? ""}`}
      >
        {title}
      </StyledButton>
    </Sublink>
  );
};

export const TabNavigationButton: React.FC<ButtonProps> = ({
  active,
  title,
  className,
  dataTestId,
  onClick,
}) => {
  return (
    <StyledButton
      variant="secondary"
      className={`text-uppercase ${className ?? ""} ${
        active ? "active-tab" : ""
      }`}
      onClick={() => onClick()}
      data-testid={dataTestId}
    >
      {title}
    </StyledButton>
  );
};
