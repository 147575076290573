import React, { useState } from "react";
import {
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { kebabCase } from "lodash";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useClearSearchParams } from "../hooks/useTableSearch";
import { SidebarItem } from "./SidebarItem";
import { useSiteContextHelper } from "../hooks/useSiteContextHelper";

const renderTooltip =
  (title: string) => (props: Omit<TooltipProps, "id"> & { show: boolean }) => {
    return (
      <Tooltip {...props} id={title}>
        {" "}
        {title}
      </Tooltip>
    );
  };

export const Sidebar: React.FC<{ items: SidebarItem[]; expanded: boolean }> = (
  props
) => {
  const [isOpen, setIsOpen] = useState(props.expanded);

  return (
    <div className="px-3 sticky-top bg-secondary text-primary mx-1 mx-xl-5 mt-4 mb-5 shadow rounded">
      <div className="vh-100">
        <aside className="d-flex flex-column ">
          <ul className="nav flex-column border-0 pt-1 pb-2" role="tablist">
            <li className="nav-item mt-3 mr-n4 text-primary text-right">
              {isOpen ? (
                <FaChevronCircleLeft
                  className="d-none d-xl-inline"
                  size={"1.4rem"}
                  onClick={() => setIsOpen(!isOpen)}
                />
              ) : (
                <FaChevronCircleRight
                  className="d-none d-xl-inline"
                  size={"1.4rem"}
                  onClick={() => setIsOpen(!isOpen)}
                />
              )}
            </li>
            {props.items.map((item, index) => (
              <Item item={item} key={index} expanded={isOpen} />
            ))}
          </ul>
        </aside>
      </div>
    </div>
  );
};

export const NavSpan = styled.span`
  color: #2600aa;
  text-decoration: none;
  background-color: transparent;
`;

function Item({ item, expanded }: { item: SidebarItem; expanded: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const { appendUrl, redirect } = useSiteContextHelper();
  const { clearTableSearchParams } = useClearSearchParams();

  return (
    <li className={`nav-item ${kebabCase(item.title)}`}>
      {item.children == null ? (
        <OverlayTrigger placement="right" overlay={renderTooltip(item.title)}>
          <NavLink
            className={({ isActive }) => isActive ? "current nav-link" : "nav-link"}
            to={appendUrl(item.link)}
            onClick={() => {
              clearTableSearchParams();
            }}
          >
            <span className="text-center">{item.icon}</span>
            {expanded ? (
              <span className="m-2 d-none d-xl-inline">{item.title}</span>
            ) : null}
          </NavLink>
        </OverlayTrigger>
      ) : (
        <NavDropdown
          drop={"right"}
          id="sidebar-dropdown"
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          show={isOpen}
          className={item.current ? "current" : ""}
          title={
            <span onClick={() => redirect(item.link)}>
              <span className="text-center">{item.icon}</span>
              {expanded ? (
                <span className="ml-2 d-none d-xl-inline">{item.title}</span>
              ) : null}
            </span>
          }
        >
          {item.children.map((child, i) => (
            <React.Fragment key={i}>
              <NavDropdown.Item>
                <NavSpan
                  onClick={() => {
                    clearTableSearchParams();
                    redirect(child.link);
                  }}
                >
                  {child.title}
                </NavSpan>
              </NavDropdown.Item>
              {i === item.children!.length - 1 ? null : <NavDropdown.Divider />}
            </React.Fragment>
          ))}
        </NavDropdown>
      )}
    </li>
  );
}
