import { Formik } from "formik";
import { EmployeeCountField } from "../form-fields/EmployeeCountField";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  LeaseFragmentFragment,
  useLeaseCreateMutation,
} from "../../../generated/admin";
import { EqMessageError } from "../../message/EqMessage";
import { UserContext } from "../../user/UserContext";
import { LeaseSizeField } from "../form-fields/LeaseSizeField";
import { LeaseStartDateField } from "../form-fields/LeaseStartDateField";
import { LeaseExpiryDateField } from "../form-fields/LeaseExpiryDateField";
import { LeaseBreakDateField } from "../form-fields/LeaseBreakDateField";
import { LeaseNotificationDateField } from "../form-fields/LeaseNotificationDateField";
import { LeaseNameField } from "../form-fields/LeaseNameField";
import { BusinessNumberField } from "../form-fields/BusinessNumberField";
import { BusinessNumberTypeField } from "../form-fields/BusinessNumberTypeField";
import { stringNotEmpty } from "../../../util/stringNotEmpty";

interface Props {
  onHide: () => void;
  company: {
    uuid: string;
    name: string;
  };
  onCreate?: (newLease: LeaseFragmentFragment) => void;
}

interface FormValue {
  businessNumber?: string | null;
  businessNumberType?: string | null;
  name: string;
  startDate: string;
  breakDate: string;
  expiryDate: string;
  notificationDate: string;
  leaseSize: number;
  employeeCount?: number;
}

/**
 * LeaseCreate modal
 */
export const LeaseCreate: React.FC<Props> = ({ onHide, onCreate, company }) => {
  const user = useContext(UserContext);
  const [createLease] = useLeaseCreateMutation();
  const nameInputRef = useRef<HTMLInputElement>();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Select value in name input field automatically on load.
    nameInputRef.current?.select();
  }, []);

  return (
    <Modal size="xl" centered scrollable={false} show={true} onHide={onHide}>
      <Formik<FormValue>
        initialValues={{
          businessNumber: "",
          businessNumberType: "",
          name: company.name,
          startDate: "",
          breakDate: "",
          expiryDate: "",
          notificationDate: "",
          leaseSize: 0,
          employeeCount: 0,
        }}
        validateOnChange={submitted}
        validateOnBlur={submitted}
        onSubmit={async (values) => {
          setSubmitted(true);

          if (user?.activeDestination?.uuid == null) {
            throw new Error(
              "Can't create lease without an active site context."
            );
          }

          const leaseResult = await createLease({
            variables: {
              input: {
                companyUuid: company.uuid,
                destinationUuid: user?.activeDestination?.uuid,
                businessNumber: stringNotEmpty(values.businessNumber)
                  ? values.businessNumber
                  : null,
                businessNumberType: stringNotEmpty(values.businessNumberType)
                  ? values.businessNumberType
                  : null,
                expiryDate: new Date(values.expiryDate).getTime(),
                startDate: new Date(values.startDate).getTime(),
                breakDate: new Date(values.breakDate).getTime(),
                notificationDate: new Date(values.notificationDate).getTime(),
                name: values.name,
                size: values.leaseSize,
                employeeCount: values.employeeCount,
              },
            },
          });

          if (leaseResult.data?.createLease == null) {
            EqMessageError({
              text: "Failed to create lease. Please try again.",
            });
          } else {
            onHide();
            if (onCreate != null) {
              onCreate(leaseResult.data?.createLease);
            }
          }
        }}
      >
        {(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;

          return (
            <>
              <Modal.Header>
                <Modal.Title>Add Lease for {company.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col md={7}>
                      <LeaseNameField {...formikProps} ref={nameInputRef} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7} lg={4}>
                      <BusinessNumberField {...formikProps} />
                    </Col>
                    <Col md={5} lg={3}>
                      <BusinessNumberTypeField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                      <EmployeeCountField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                      <LeaseSizeField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <LeaseStartDateField {...formikProps} />
                        </Col>
                        <Col>
                          <LeaseBreakDateField {...formikProps} />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <LeaseNotificationDateField {...formikProps} />
                        </Col>
                        <Col>
                          <LeaseExpiryDateField {...formikProps} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner animation="grow" size="sm" />
                      <span className="ml-1">Creating...</span>
                    </>
                  ) : (
                    "Create"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  onClick={onHide}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
