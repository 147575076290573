import React from "react";

export const EqOne = () => (
  <svg
    width="30"
    height="6"
    viewBox="0 0 120 29"
    fill="none"
    style={{ margin: "2px" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.515625V27.9047H19.6583V22.6284H5.6556V17.0298H18.0592V11.7533H5.6556V5.79197H19.6583V0.515625H0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.6224 22.9455C32.9113 22.9455 29.0921 19.0347 29.0921 14.2105C29.0921 9.38639 32.9113 5.47557 37.6224 5.47557C42.3334 5.47557 46.1526 9.38639 46.1526 14.2105H51.4274C51.4274 6.40323 45.2467 0.0742188 37.6224 0.0742188C29.9981 0.0742188 23.8174 6.40323 23.8174 14.2105C23.8174 22.0178 29.9981 28.3468 37.6224 28.3468V22.9455Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.4646 28.4211V22.9919C46.7535 22.9729 42.94 19.0492 42.94 14.2119L37.6475 14.2119C37.6475 22.0478 43.8304 28.4022 51.4646 28.4211Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.6348 12.9869C70.6348 4.84981 75.4021 -3.41055e-05 82.6696 -3.34702e-05C89.8983 -3.28382e-05 94.626 4.84981 94.626 12.9869L94.626 27.8574L88.9994 27.8574L88.9994 12.9467C88.9994 8.4172 86.4597 5.41103 82.6696 5.41103C78.8014 5.41103 76.2614 8.4172 76.2614 12.9467L76.2614 27.8574L70.6348 27.8574L70.6348 12.9869Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.973 11.9262H118.398V17.1771H105.973V22.7486H120V27.9992H100.307V0.743164H120V5.99374H105.973V11.9262Z"
      fill="white"
    />
    <path
      d="M60.0561 14.2112C60.0561 9.36218 56.2241 5.43118 51.4972 5.43118L51.4972 0.00195312C59.1472 0.00195484 65.3486 6.36364 65.3486 14.2112C65.3486 22.0588 59.1471 28.4205 51.4972 28.4205C51.4858 28.4205 51.4743 28.4205 51.4629 28.4204V22.9912C51.4743 22.9912 51.4858 22.9912 51.4972 22.9912C56.2241 22.9912 60.0561 19.0602 60.0561 14.2112Z"
      fill="white"
    />
  </svg>
);
