import React from "react";
import { OptionType } from "../../../model/OptionType";
import { StylesConfig } from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { UserFormData, userFormNameCheck } from "./model/UserFormData";
import {
  ProfileRegistrationType,
  SiteDetailsDocument,
  SiteDetailsQuery,
  SiteDetailsQueryVariables,
} from "../../../generated/admin";
import AsyncSelect from "react-select/async";
import { EqMessageWarning } from "../../message/EqMessage";
import { useApolloClient } from "@apollo/client";

interface Props {
  defaultValue: ProfileRegistrationType;
  siteUuid: string;
  setRegistrationType: (registrationType: ProfileRegistrationType) => void;
}

const options = [
  {
    value: ProfileRegistrationType.Commercial,
    label: "Commercial",
  },
  {
    value: ProfileRegistrationType.Visitor,
    label: "Visitor",
  },
  {
    value: ProfileRegistrationType.Residential,
    label: "Residential",
  },
];

const styles: StylesConfig<OptionType> = {
  option: (styles, { data, isSelected, isFocused }) => {
    return {
      ...styles,
      color: data.value,
      fontWeight: 400,
      backgroundColor: isSelected ? "#ddd" : isFocused ? "#f2f2f2" : undefined,
    };
  },
  singleValue: (styles, { data }) => {
    return { ...styles, color: data.value, fontWeight: 400 };
  },
};

/**
 * User form user type dropdown.
 */
export const UserFormUserTypeDropdown: React.FC<Props> = ({
  defaultValue,
  siteUuid,
  setRegistrationType,
}) => {
  const methods = useFormContext<UserFormData>();
  const client = useApolloClient();

  const loadOptions = async () => {
    const result = await client.query<
      SiteDetailsQuery,
      SiteDetailsQueryVariables
    >({
      query: SiteDetailsDocument,
      variables: { uuid: siteUuid },
    });

    if (result.errors) {
      return [];
    }

    let filteredOptions = options;
    const registration = result.data.destination.settings.registration;
    if (!registration.commercialSignupEnabled) {
      filteredOptions = filteredOptions.filter(
        (opt) => opt.value !== ProfileRegistrationType.Commercial
      );
    }
    if (!registration.visitorSignupEnabled) {
      filteredOptions = filteredOptions.filter(
        (opt) => opt.value !== ProfileRegistrationType.Visitor
      );
    }
    if (!registration.residentialSignupEnabled) {
      filteredOptions = filteredOptions.filter(
        (opt) => opt.value !== ProfileRegistrationType.Residential
      );
    }

    return filteredOptions;
  };

  const changeValue = (selected: any) => {
    setRegistrationType(selected.value);
    if (selected.value === ProfileRegistrationType.Commercial) {
      EqMessageWarning({
        title: "Attention",
        text: `Ensure a valid company is set for this user.`,
      });
    }

    return selected;
  };

  return (
    <div className="user-type-selector">
      <Controller
        render={() =>
          <AsyncSelect<OptionType, boolean>
            classNamePrefix="eq"
            loadOptions={loadOptions}
            isClearable={false}
            placeholder="Select registration type"
            className={`react-select`}
            styles={styles}
            defaultOptions
            defaultValue={options.find((opt) => opt.value === defaultValue)}
            onChange={changeValue}
          />
        }
        control={methods.control}
        name={userFormNameCheck("registrationType")}
      />
    </div>
  );
};
