import { CreateOrUpdateApartmentMutationFn } from "../../../../generated/admin";
import { useMemo } from "react";
import { ApartmentFormData } from "../model/ApartmentFormData";
import { MessageFn } from "../../../message/EqMessage";
import { useSiteContextHelper } from "../../../../hooks/useSiteContextHelper";
import { Maybe } from "graphql/jsutils/Maybe";

interface Param {
  mutationFn: CreateOrUpdateApartmentMutationFn;
  successMsgFn: MessageFn;
  errorMsgFn: MessageFn;
  apartmentUuid?: Maybe<string>;
  activeDestination?: Maybe<string>;
  buildingLevelUuids?: Maybe<string[]>;
}

/**
 * Apartment details form hook.
 */
export function useApartmentDetailsForm({
  mutationFn,
  successMsgFn,
  errorMsgFn,
  apartmentUuid,
  buildingLevelUuids,
  activeDestination
}: Param) {
  const siteCtxHelper = useSiteContextHelper();

  return useMemo(
    () => ({
      disableFields: apartmentUuid != null && activeDestination != null,
      onSubmit: async (data: ApartmentFormData) => {
        let destinations: string[] | undefined = undefined;
        if (apartmentUuid == null && activeDestination != null) {
          destinations = [activeDestination];
        } else if (apartmentUuid != null && data.destinations != null) {
          destinations = data.destinations;
        }
        try {
          const result = await mutationFn({
            variables: {
              input: {
                uuid: apartmentUuid,
                name: data.name,
                destinations,
                apartmentDestination:
                  activeDestination != null
                    ? {
                        destinationUuid: activeDestination,
                        buildingLevels:
                          buildingLevelUuids != null &&
                          buildingLevelUuids?.length > 0
                            ? buildingLevelUuids
                            : undefined
                      }
                    : undefined
              }
            }
          });

          if (
            result.data?.createOrUpdateApartment.__typename ===
            "ApartmentFailure"
          ) {
            errorMsgFn({
              text: result.data?.createOrUpdateApartment.reason
            });

            return;
          }

          successMsgFn({
            text: `Apartment ${
              apartmentUuid != null ? "details edited" : "created"
            } successfully.`
          });

          siteCtxHelper.goBack();
        } catch (e: any) {
          console.log(e);
          errorMsgFn({
            text: e?.message
          });
        }
      }
    }),
    [
      apartmentUuid,
      activeDestination,
      mutationFn,
      successMsgFn,
      siteCtxHelper,
      errorMsgFn,
      buildingLevelUuids
    ]
  );
}
