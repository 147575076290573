import {
  SyncBuildingInput,
  useCreateOrUpdateBuildingMutation,
  DestinationLevelsQuery,
  DestinationLevelsQueryVariables,
  DestinationLevelsDocument,
} from "../../../generated/admin";
import { SyncBuildingInputTouched } from "./SyncBuildingInputTouched";
import { EqMessageError, EqMessageSuccess } from "../../message/EqMessage";
import { BuildingStore } from "../list-view/BuildingStore";
import { SiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { BuildingFormErrors } from "./buildingFormErrors";

interface Params {
  state: SyncBuildingInput;
  save: ReturnType<typeof useCreateOrUpdateBuildingMutation>[0];
  siteCtxHelper: SiteContextHelper;
  errors: BuildingFormErrors;
  touched: SyncBuildingInputTouched;
  setTouched: (touched: SyncBuildingInputTouched) => void;
  buildingStore: BuildingStore;
  isTaxAdmin: boolean;
}

export const createOrUpdateBuildingFactory =
  ({
    state,
    save,
    siteCtxHelper,
    errors,
    touched,
    setTouched,
    buildingStore,
    isTaxAdmin,
  }: Params) =>
  async () => {
    const params = {
      ...state,
      name: state.name.trim(),
      taxes: isTaxAdmin ? state.taxes : null,
    };
    setTouched(
      Object.keys(touched).reduce((carry, item) => {
        carry[item as keyof SyncBuildingInputTouched] = true;
        return carry;
      }, {} as SyncBuildingInputTouched)
    );
    const errorValues = Object.values(errors).flatMap((e) =>
      e != null ? [e] : []
    );
    if (errorValues.length > 0) {
      return;
    }

    const result = await save({
      variables: { input: params },
      update: (cache, data) => {
        const current = cache.readQuery<
          DestinationLevelsQuery,
          DestinationLevelsQueryVariables
        >({ query: DestinationLevelsDocument });
        if (current == null) {
          return;
        }
        if (
          data.data?.createOrUpdateBuilding.__typename !== "BuildingSyncSuccess"
        ) {
          return;
        }
        const building = data.data?.createOrUpdateBuilding.building;

        cache.writeQuery<
          DestinationLevelsQuery,
          DestinationLevelsQueryVariables
        >({
          query: DestinationLevelsDocument,
          data: {
            ...current,
            destination: {
              ...current.destination,
              buildings:
                current.destination.buildings?.map(
                  (
                    b
                  ): NonNullable<
                    DestinationLevelsQuery["destination"]["buildings"]
                  >[0] => {
                    if (b.uuid !== building.uuid) {
                      return b;
                    }
                    return {
                      uuid: building.uuid,
                      name: building.name,
                      buildingLevels: building.buildingLevels,
                    };
                  }
                ) ?? null,
            },
          },
        });
      },
    });
    if (
      result.data?.createOrUpdateBuilding.__typename === "BuildingSyncFailure"
    ) {
      EqMessageError({
        text: result.data?.createOrUpdateBuilding.reason,
      });
      return;
    } else if (
      result.data?.createOrUpdateBuilding.__typename === "BuildingSyncSuccess"
    ) {
      const uuid = result.data?.createOrUpdateBuilding.building.uuid;
      buildingStore.addNode(result.data.createOrUpdateBuilding.building);
      if (state.uuid !== uuid) {
        EqMessageSuccess({
          text: `Successfully created building ${params.name}`,
        });
      } else {
        EqMessageSuccess({
          text: "Building details edited successfully",
        });
      }
      siteCtxHelper.goBack();
    }
  };
