import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { GeneralSettings } from "./general";
import {
  useSiteDetailsQuery,
  SiteDetailsQuery,
  useStatsProviderConfigurationQuery,
  StatsProviderConfigurationQuery,
} from "../../../generated/admin";
import { useParams } from "react-router-dom";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { SiteConfigFormData } from "./SiteConfigFormData";
import { EqMessageWarning } from "../../message/EqMessage";
import { PageHeading } from "../../../components/PageHeading";
import { Row, Col, Spinner, Container } from "react-bootstrap";
import { Navigation } from "./Navigation";
import { CreateButton } from "../../../components/CreateButton";
import { MobileSettings } from "./mobile";
import { WebSettings } from "./web";
import { Formik } from "formik";
import { RootRoutes } from "../../../RouterOutlet";
import { useUser } from "../../user/UserContext";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { DeleteDestinationButton } from "./DeleteDestinationButton";
import {
  transformInitialValues,
  useOnSubmit,
  siteConfigFormValidation,
} from "./siteConfigFormFunctions";
import { ModulesSettings } from "./modules";
import { SiteConfigErrors } from "./siteConfigErrors";

export interface SiteSettingsQueryData {
  destination: NonNullable<SiteDetailsQuery>["destination"];
  statsProviderConfiguration?: StatsProviderConfigurationQuery["statsProviderConfiguration"] | null;
}

export const SiteSettingsModule = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const user = useUser();
  const siteCtxHelper = useSiteContextHelper();

  if (uuid == null) {
    throw new Error("No uuid parameter supplied for page");
  }

  const {
    data: destinationDetails,
    loading,
    error,
  } = useSiteDetailsQuery({
    fetchPolicy: "network-only",
    variables: { uuid },
    skip: user.activeAtDifferentDestination(uuid),
  });

  const { data: statsProviderConfigurationQuery } =
    useStatsProviderConfigurationQuery({
      variables: {
        siteUuid: uuid,
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (user.activeAtDifferentDestination(uuid)) {
      siteCtxHelper.redirect(
        RootRoutes.SITES + "/" + user.activeDestination?.uuid
      );
    }

    if (!loading && !destinationDetails?.destination.managedByUCM) {
      const inSiteContext = user.activeDestination != null;
      if (inSiteContext) {
        EqMessageWarning({
          title: "Attention",
          html: inSiteContext
            ? /* html */ `[${
                destinationDetails?.destination.name
              }] is not currently managed by Admin Panel. Contact <a href="mailto:support@getequiem.com?subject=${encodeURIComponent(
                `[${destinationDetails?.destination.name}] requires enabling in Admin Panel.`
              )}" target="_blank" rel="noopener noreferrer">Support</a> to have this actioned, at which point you will be able to manage the site settings.`
            : `[${destinationDetails?.destination.name}] is not currently managed by Admin Panel. Select the checkbox ‘Managed by Admin Panel’ in the ‘General Settings’ > ‘Operational Details’ section, in order to save changes to the settings.`,
        });
        siteCtxHelper.redirect(RootRoutes.HELP);
      }
    }
  }, [
    destinationDetails?.destination,
    loading,
    user.activeDestination,
    siteCtxHelper,
    user,
    uuid,
  ]);

  if (destinationDetails == null) {
    return <LoadingOrErrorDisplay loading={loading} error={error} />;
  }

  const dest = destinationDetails.destination;

  return (
    <SmInner
      destination={dest}
      statsProviderConfiguration={statsProviderConfigurationQuery?.statsProviderConfiguration}
    />
  );
};

const SmInner: React.FC<SiteSettingsQueryData> = (data) => {
  const onSubmit = useOnSubmit(data);

  const primaryDomainSet =
    data.destination.managedByUCM &&
    ((data.destination.infrastructure?.webDomains.some(
      (wd) => wd.primary === true
    ) ||
      data.destination.infrastructure?.referenceDomainIsPrimary) ??
      false);

  return (
    <Formik<SiteConfigFormData>
      initialValues={transformInitialValues(data)}
      validate={siteConfigFormValidation(data)}
      validateOnMount={true}
      onSubmit={onSubmit}
    >
      {(methods) => (
        <>
          <SiteConfigErrors />
          <PageHeading
            isSticky
            title={
              <Container className="pl-0" fluid>
                <Row className="justify-content-between">
                  <Col md="6">
                    {"General Settings"}{" "}
                    <span className="text-muted">
                      {data.destination.name ?? ""}
                    </span>{" "}
                  </Col>
                  <Col md="6">
                    <CreateButton
                      className="mr-4 float-right"
                      disabled={methods.isSubmitting}
                      onClick={methods.handleSubmit}
                    >
                      {methods.isSubmitting ? (
                        <span>
                          <Spinner animation="grow" size="sm" /> Saving...
                        </span>
                      ) : (
                        "Save"
                      )}
                    </CreateButton>
                    <DeleteDestinationButton dest={data.destination} />
                  </Col>
                </Row>
              </Container>
            }
            subHeading={<Navigation />}
          ></PageHeading>
          {data.destination.reportingEnabled ? null : (
            <div className="mx-3 mt-3 alert alert-danger">
              Reporting is currently disabled. Enable reporting at launch.
            </div>
          )}
          {!primaryDomainSet && (
            <div className="mx-3 mt-3 alert alert-danger">
              A primary domain has not been set for this site. Ensure you set
              one prior to launching the site.
            </div>
          )}
          {data.destination.domainErrors && (
            <div className="mx-3 mt-3 alert alert-danger">
              {data.destination.domainErrors}
            </div>
          )}
          <div className="pt-5">
            <Routes>
              <Route
                path={`/web`}
                element={
                  <WebSettings methods={methods} dest={data.destination} />
                }
              />
              <Route
                path={`/mobile`}
                element={
                  <MobileSettings methods={methods} dest={data.destination} />
                }
              />
              <Route
                path={`/modules`}
                element={
                  <ModulesSettings methods={methods} dest={data.destination} />
                }
              />
              <Route
                path="*"
                element={
                  <GeneralSettings dest={data.destination} methods={methods} />
                }
              />
            </Routes>
          </div>
        </>
      )}
    </Formik>
  );
};
