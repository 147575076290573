import React, { useMemo } from "react";
import { Option } from "./Option";
import Select from "react-select";
import { Row, Col, Form } from "react-bootstrap";
import { DropdownIndicator } from "./DropdownIndicator";
import { useOptionStore } from "./OptionStore";
import { RemoveButton } from "../../../../components/RemoveButton";

export const CompanySearch = ({
  addInput,
  remove,
  input = null,
  selected
}: {
  addInput: (option?: Option) => void;
  remove?: () => void;
  input?: Option | null;
  selected: string[];
}) => {
  const store = useOptionStore();
  if (store == null) {
    throw new Error("option store not initialized");
  }

  const options = useMemo(
    () => [
      ...store.options.filter((o) => !selected.includes(o.value)),
      ...(input != null ? [input] : [])
    ],
    [input, store.options, selected]
  );

  return (
    <Row className="border border-bottom-0 font-weight-lighter">
      <Col xs={7} className="py-2 px-3">
        <Select
          isLoading={store.loading}
          isClearable={true}
          options={options}
          onChange={(e) => {
            addInput(e as Option);
          }}
          className="react-select"
          classNamePrefix="eq"
          components={{ DropdownIndicator }}
          value={input}
          onInputChange={(input) => store.setSearchTerm(input)}
          placeholder="Select your master company..."
        />
      </Col>
      <Col xs={4} className="py-2 px-3">
        <Form.Control defaultValue={input?.label ?? ""} />
      </Col>
      <Col xs="1" className="justify-content-end d-flex align-items-center">
        {remove != null ? <RemoveButton onClick={remove} /> : null}
      </Col>
    </Row>
  );
};
