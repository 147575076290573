import React, { useCallback } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { AddButton } from "../../../components/AddButton";
import { CreateButton } from "../../../components/CreateButton";
import { GroupStore } from "./model/GroupStore";
import { RemoveButton } from "../../../components/RemoveButton";

interface Props {
  store: GroupStore;
}

/**
 * Add Group form.
 */
export const AddGroupForm: React.FC<Props> = ({ store }) => {
  const handleRemoveGroup = useCallback(
    (group: { name: string }) => () => store.removeGroup(group.name),
    [store]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        store.addGroup();
      }
    },
    [store]
  );

  return (
    <Container fluid className="p-0">
      <Row className="justify-content-between">
        <Col md="7" lg="8">
          <Container fluid>
            {store.groupsToAdd.map((group, i) => (
              <Row key={`list-${i}`}>
                <Col
                  className="py-1 px-3 border border-bottom-0 border-right-0"
                  xs="10"
                  lg="11"
                >
                  <Form.Control
                    className="w-50"
                    required
                    value={group.name}
                    onChange={store.updateLocalGroup(group.name)}
                  />
                </Col>
                <Col
                  lg="1"
                  xs="2"
                  className="justify-content-end d-flex align-items-center border border-bottom-0 border-left-0"
                >
                  <RemoveButton onClick={handleRemoveGroup(group)} />
                </Col>
              </Row>
            ))}

            <Row onKeyDown={handleKeyDown}>
              <Col className="border border-right-0 px-3" xs="10" lg="11">
                <Form.Control
                  required
                  className="border-bottom-0"
                  placeholder="Group Name"
                  value={store.newGroupName}
                  onChange={store.handleChange}
                />
              </Col>
              <Col
                lg="1"
                xs="2"
                className="border border-left-0 d-flex justify-content-end align-items-center"
              >
                <AddButton
                  data-testid="add-group-btn"
                  onClick={store.addGroup}
                />
              </Col>
            </Row>
          </Container>
        </Col>

        <Col md="4" lg="3" className="mt-3 mt-md-0 text-right">
          <CreateButton disabled={store.loading} onClick={store.saveGroups}>
            {store.saving ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Add Groups"
            )}
          </CreateButton>
        </Col>
      </Row>
    </Container>
  );
};
