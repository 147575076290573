import React, { Suspense, lazy } from "react";
import { ExportProps } from "./ExportToCsv";
import { CapsuleButton } from "../CapsuleButton";

const ExportToCsv = lazy(() => import("./ExportToCsv"));

export function LazyExportToCsv(props: ExportProps) {
  return (
    <Suspense
      fallback={<CapsuleButton onClick={() => null}>...Loading</CapsuleButton>}
    >
      <ExportToCsv {...props} />
    </Suspense>
  );
}
