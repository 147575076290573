import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

export const DeleteButton = styled((props) => (
  <Button {...props} variant="outline-danger">
    {props.children}
  </Button>
))`
  border-radius: 3px;
  width: 100%;
  max-width: 300px;
`;
