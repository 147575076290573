import { Formik } from "formik";
import { useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { CardAccordion } from "../../../../../components/SingleEqAccordion";
import {
  BarrierControlConfigKeysDocument,
  BarrierControlConfigsDocument,
  useBarrierControlConfigKeysQuery,
  useCreateBarrierControlConfigCredentialsMutation,
  useDeleteBarrierControlConfigMutation,
  useUpdateBarrierControlConfigMutation,
} from "../../../../../generated/admin";
import { OptionType } from "../../../../../model/OptionType";
import {
  EqDangerMessage,
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";
import { TextFormGroup } from "../../fields/TextFormGroup";
import { AccessControlConnectionFormData } from "./AccessControlConnectionFormData";

interface Props {
  data: AccessControlConnectionFormData;
}

export const AccessControlConnection: React.FC<Props> = ({ data }) => {
  const barcodeOptions: OptionType[] = [
    { value: "CODE128", label: "Barcode" },
    { value: "QR", label: "QR" },
    { value: "None", label: "None" }
  ];

  const { data: keysData } = useBarrierControlConfigKeysQuery({
    variables: {
      uuid: data.uuid,
    },
  });

  const [createCreds, { loading: loadingCreds }] =
    useCreateBarrierControlConfigCredentialsMutation();

  const [deleteConfig, { loading: loadingDel }] =
    useDeleteBarrierControlConfigMutation();

  const [updateConfig, { loading: loadingUpdateConfig }] =
    useUpdateBarrierControlConfigMutation();

  const create = useCallback(
    async (uuid: string) => {
      try {
        const result = await createCreds({
          variables: {
            uuid: uuid,
          },
          refetchQueries: [
            {
              query: BarrierControlConfigKeysDocument,
              variables: {
                uuid: uuid,
              },
            },
          ],
        });
        if (!result.data?.createBarrierControlConfigCredentials.keyId) {
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          html: `
          <div class="d-flex flex-column overflow-hidden">
            <div class="mb-3 row">
              <label for="keyId" class="form-label col-md-3">Access key</label>
              <div class="col-lg-6 col-md-9">
                <input name="keyId" type="text" id="keyId" class="form-control" value=${result.data.createBarrierControlConfigCredentials.keyId}>
              </div>
            </div>
            <div class="mb-3 row">
              <label for="keySecret" class="form-label col-md-3">Secret key</label>
              <div class="col-lg-6 col-md-9">
                <input name="keySecret" type="text" id="keySecret" class="form-control" value=${result.data.createBarrierControlConfigCredentials.keySecret}>
              </div>
            </div>
          </div>
          `,
        });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [createCreds]
  );

  const del = useCallback(
    async (uuid: string, siteUuid: string) => {
      const confirm = await EqDangerMessage({
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete connection",
        title: "Are you sure?",
        html: "Once deleted, you cannot retrieve this connection.",
      });
      if (confirm.value !== true || loadingDel) {
        return;
      }

      try {
        const result = await deleteConfig({
          variables: {
            uuid: uuid,
          },
          refetchQueries: [
            {
              query: BarrierControlConfigsDocument,
              variables: {
                siteUuid: siteUuid,
              },
            },
          ],
        });
        if (!result.data?.deleteBarrierControlConfig.success) {
          throw new Error(
            result.data?.deleteBarrierControlConfig.message ??
              "Unknown error response from server."
          );
        }

        EqMessageSuccess({ text: "Access control connection deleted. " });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [deleteConfig, loadingDel]
  );

  const update = useCallback(
    async (values: AccessControlConnectionFormData, siteUuid: string) => {
      try {
        const result = await updateConfig({
          variables: {
            uuid: values.uuid!,
            input: {
              buildingUuids: values.buildings.map((x) => x.value),
              siteUuid: siteUuid,
              codeType: values.codeType!,
              name: values.name!,
            },
          },
          refetchQueries: [
            {
              query: BarrierControlConfigsDocument,
              variables: {
                siteUuid: siteUuid,
              },
            },
          ],
        });

        if (!result.data?.updateBarrierControlConfig.uuid) {
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          text: "Successfully updated access control connection.",
        });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [updateConfig]
  );

  return (
    <div className="container-main">
      <Formik<AccessControlConnectionFormData>
        initialValues={data}
        onSubmit={(values) => update(values, data.destinationUuid)}
        key={data.uuid}
      >
        {(methods) => (
          <CardAccordion
            cardClassName="flex-nowrap"
            title={methods.values.name}
          >
            <div>
              <Form.Group as={Row} controlId="buildingUuids">
                <Form.Label column md="3">
                  {"Buildings"}
                </Form.Label>
                <Col md="9" lg="6" className="site-client-select">
                  <Select<OptionType, boolean>
                    classNamePrefix="eq"
                    isMulti={true}
                    isSearchable
                    value={methods.values.buildings}
                    options={data.allBuildings ?? []}
                    onChange={(options) => {
                      methods.setFieldValue("buildings", options);
                      methods.setFieldTouched("buildings");
                    }}
                    placeholder="Select..."
                    aria-label="site-client-select"
                    className={`react-select${
                      methods.errors.buildings != null ? " is-invalid" : ""
                    }`}
                  />
                  <Form.Text className="text-muted">
                    Select the buildings that can utilise this access control
                    connection.
                  </Form.Text>
                  {methods.errors.buildings && methods.touched.buildings && (
                    <Form.Control.Feedback type="invalid">
                      {methods.errors.buildings.toString()}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>
              <TextFormGroup
                title={"Name"}
                value={methods.values.name}
                subText={"The unique name for the access control connection."}
                name={"name"}
              />
              <Form.Group as={Row} controlId="codeType" className="pb-3">
                <Form.Label column md="3">
                  {"Format"}
                </Form.Label>
                <Col md="9" lg="6" className="site-client-select">
                  <Select<OptionType, boolean>
                    classNamePrefix="eq"
                    isSearchable
                    options={barcodeOptions}
                    value={barcodeOptions.find(
                      (o: OptionType) => o.value === methods.values.codeType
                    )}
                    onChange={(option: any) => {
                      methods.setFieldValue("codeType", option.value);
                      methods.setFieldTouched("codeType");
                    }}
                    placeholder="Select..."
                    aria-label="site-client-select"
                    className={`react-select${
                      methods.errors.codeType != null ? " is-invalid" : ""
                    }`}
                  />
                  <Form.Text className="text-muted">
                    The format can either be Barcode or QR code.
                  </Form.Text>
                  {methods.errors.codeType && methods.touched.codeType && (
                    <Form.Control.Feedback type="invalid">
                      {methods.errors.codeType}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>
              <TextFormGroup
                title={"AWS Region"}
                copyField={true}
                disabled={true}
                subText={
                  "The AWS region in which this connection's infrastructure is provisioned."
                }
                name={"awsRegion"}
                copyFieldName={"AWS Region"}
              />
              <TextFormGroup
                title={"Queue URL"}
                copyField={true}
                value={methods.values.sqsUrl}
                disabled={true}
                subText={"The queue URL for the access control connection."}
                name={"sqsUrl"}
                copyFieldName={"Queue URL"}
              />
              <TextFormGroup
                title={"Topic ARN"}
                copyField={true}
                value={methods.values.snsTopicArn}
                disabled={true}
                subText={"The topic ARN for the access control connection."}
                name={"snsTopicArn"}
                copyFieldName={"Topic ARN"}
              />
              <TextFormGroup
                title={"Alarm Name"}
                copyField={true}
                disabled={true}
                subText={
                  "The alarm which is monitoring the queue of the access control connection."
                }
                name={"alarmName"}
                copyFieldName={"Alarm Name"}
              />
              <TextFormGroup
                title={"Log Group Name"}
                copyField={true}
                disabled={true}
                subText={
                  "The log group into which the access control connection's user account is allowed to create log streams and put log events."
                }
                name={"logGroupName"}
                copyFieldName={"Log Group Name"}
              />
            </div>
            {keysData?.barrierControlConfigKeys !== undefined &&
            keysData.barrierControlConfigKeys.count > 0 ? (
              <div className="pb-1">
                <div className="mt-3 alert alert-danger">
                  Access keys have already been generated. Delete the connection
                  if new keys are required.
                </div>
              </div>
            ) : null}
            <div className="form-inline justify-content-end mt-1">
              <Button
                name="updateConfig"
                variant="outline-primary"
                size="sm"
                className="align-self-center m-2"
                onClick={() => methods.handleSubmit()}
                disabled={loadingUpdateConfig}
              >
                {loadingUpdateConfig ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Update connection"
                )}
              </Button>
              {keysData?.barrierControlConfigKeys !== undefined &&
              keysData.barrierControlConfigKeys.count > 0 ? null : (
                <Button
                  name="createCredentials"
                  variant="outline-primary"
                  size="sm"
                  className="align-self-center m-2"
                  onClick={() => create(methods.values.uuid)}
                  disabled={
                    loadingCreds ||
                    (keysData?.barrierControlConfigKeys !== undefined &&
                      keysData.barrierControlConfigKeys.count > 0)
                  }
                >
                  {loadingCreds ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Create keys"
                  )}
                </Button>
              )}

              <Button
                name="deleteConnection"
                variant="danger"
                size="sm"
                className="align-self-center"
                onClick={() => del(methods.values.uuid, data.destinationUuid)}
                disabled={loadingDel}
              >
                {loadingDel ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Delete connection"
                )}
              </Button>
            </div>
          </CardAccordion>
        )}
      </Formik>
    </div>
  );
};
