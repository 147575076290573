import React, { useCallback, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AppleStoreStatus,
  GoogleStoreStatus,
  WhitelabelStatus,
  useWhitelabelAppsQuery,
} from "../../../generated/admin";
import { RootRoutes } from "../../../RouterOutlet";
import useDebounce from "../../../hooks/useDebounce";
import { SearchBar } from "../../../admin-components/Searchbar";
import { CreateButton } from "../../../components/CreateButton";
import { Sorter } from "../../../components/Sorter";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { PageHeading } from "../../../components/PageHeading";
import { EqTable } from "../../../components/Table";
import styled from "styled-components";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { SubTitle } from "../../../components";
import { useTableSearch } from "../../../hooks/useTableSearch";
import startCase from "lodash/startCase";

const StyledList = styled.div`
  table td:first-child {
    width: 99%;
  }
  table td:nth-child(2) {
    white-space: nowrap;
    padding: 0.75rem 3rem 0.75rem 0.75rem;
  }
`;

interface SortData {
  field: string;
  asc: boolean;
}

export const WhitelabelList = () => {
  const { defaultSortQuery, searchText, onTableSort, onTextSearch } =
    useTableSearch({ defaultSortFieldName: "name" });

  const [search, setSearch] = React.useState<string>(searchText);
  const [sort, setSort] = React.useState<SortData | null>(defaultSortQuery);

  const sorter = useMemo(() => new Sorter(
    [
      { key: "name", label: "Name" },
      { key: "client.name", label: "Client" },
      { key: "status", label: "Status" },
      { key: "appleStoreStatus", label: "Apple Store Status" },
      { key: "googleStoreStatus", label: "Google Store Status" },
    ],
    sort,
    setSort,
    onTableSort
  ), [onTableSort, sort]);

  const debouncedSearchTerm = useDebounce(search, 500);

  const WhitelabelAppsVariables = useMemo(
    () => ({
      first: 100,
      search: debouncedSearchTerm,
    }),
    [debouncedSearchTerm]
  );

  const { loading, error, data } = useWhitelabelAppsQuery({
    variables: WhitelabelAppsVariables,
    fetchPolicy: "network-only",
  });

  const clear = useCallback(() => {
    setSearch("");
    onTextSearch("");
  }, [setSearch, onTextSearch]);

  const handleSearch = useCallback(
    (e: { currentTarget: { value: any } }) => {
      const val = e.currentTarget.value;
      setSearch(val);
      onTextSearch(val);
    },
    [setSearch, onTextSearch]
  );

  const siteCtxHelper = useSiteContextHelper();

  const clickHandler = useCallback(
    (dest: { uuid: string }) => {
      siteCtxHelper.redirect(RootRoutes.WHITELABEL + "/edit/" + dest.uuid);
    },
    [siteCtxHelper]
  );

  return (
    <>
      <PageHeading title="White Label Apps"></PageHeading>
      <Container>
        <Row>
          <Col>
            <SubTitle>Manage Apps</SubTitle>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col md="7" lg="4">
            <SearchBar
              clear={clear}
              value={search}
              onChange={handleSearch}
              placeholder={"Search for apps..."}
            />
          </Col>
          <Col className="py-3 py-md-0" md="4" lg="3">
            <CreateButton
              onClick={() =>
                siteCtxHelper.redirect(RootRoutes.WHITELABEL + "/create")
              }
            >
              Create New App
            </CreateButton>
          </Col>
        </Row>
        <br />
        <LoadingOrErrorDisplay loading={loading} error={error}>
          <StyledList>
            <EqTable
              pageSize={20}
              operations={[]}
              sorter={sorter}
              clickable={true}
              clickHandler={clickHandler}
              items={(data?.whitelabelApps.edges ?? []).flatMap((a) => {
                return (
                  [
                    {
                      uuid: a.node?.uuid,
                      name: a.node?.name,
                      client:
                        a.node?.client != null
                          ? {
                              ...a.node.client,
                              name: `${a.node.client.name} (${a.node.client.region})`,
                            }
                          : undefined,
                      appleStoreStatus:
                        a.node?.appleStoreStatus ===
                          AppleStoreStatus.ApiNotAuthorized ? (
                          <span>
                            {startCase(a.node?.appleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.InReview ? (
                          <span>
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleEditVersion}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.InvalidBinary ? (
                            <span className="text-danger">
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleEditVersion}
                          </span>
                        ) : a.node?.appleStoreStatus === AppleStoreStatus.Live ? (
                          <span className="text-lime">
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleVersion}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.MembershipExpires ? (
                          <span>
                            {startCase(a.node?.appleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.MembershipExpired ? (
                          <span className="text-danger">
                            {startCase(a.node?.appleStoreStatus.toLowerCase())}
                          </span>
                        )  : a.node?.appleStoreStatus ===
                          AppleStoreStatus.MetadataRejected ? (
                          <span className="text-danger">
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleEditVersion}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.PendingAgreement ? (
                          <span className="text-danger">
                            {startCase(a.node?.appleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.PendingRelease ? (
                          <span>
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleEditVersion}
                          </span>
                        )  : a.node?.appleStoreStatus ===
                          AppleStoreStatus.PrepareSubmission ? (
                          <span>
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleEditVersion}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.StoreRejected ? (
                          <span className="text-danger">
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleEditVersion}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.Unknown ? (
                          <span className="text-danger">
                            {startCase(a.node?.appleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.appleStoreStatus ===
                          AppleStoreStatus.WaitingForReview ? (
                          <span>
                            {startCase(a.node?.appleStoreStatus.toLowerCase())} {a.node?.appleEditVersion}
                          </span>
                        ) : (
                          <span>N/A</span>
                        ),
                      googleStoreStatus:
                        a.node?.googleStoreStatus ===
                        GoogleStoreStatus.AccountNotVerified ? (
                          <span className="text-danger">
                            {startCase(a.node?.googleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.googleStoreStatus ===
                          GoogleStoreStatus.Live ? (
                          <span className="text-lime">
                            {startCase(a.node?.googleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.googleStoreStatus ===
                          GoogleStoreStatus.PendingRelease ? (
                          <span>
                            {startCase(a.node?.googleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.googleStoreStatus ===
                          GoogleStoreStatus.StoreRejected ? (
                          <span className="text-danger">
                            {startCase(a.node?.googleStoreStatus.toLowerCase())}
                          </span>
                        ) : a.node?.googleStoreStatus ===
                          GoogleStoreStatus.WaitingForReview ? (
                          <span>
                            {startCase(a.node?.googleStoreStatus.toLowerCase())}
                          </span>
                        ) : (
                          <span>N/A</span>
                        ),
                      status:
                        a.node?.status === WhitelabelStatus.AwaitingApproval ? (
                          <span>{startCase(a.node?.status.toLowerCase())}</span>
                        ) : a.node?.status === WhitelabelStatus.Developed ? (
                          <span>{startCase(a.node?.status.toLowerCase())}</span>
                        ) : a.node?.status === WhitelabelStatus.Development ? (
                          <span>{startCase(a.node?.status.toLowerCase())}</span>
                        ) : a.node?.status ===
                          WhitelabelStatus.DevelopmentBlocked ? (
                          <span className="text-danger">
                            {startCase(a.node?.status.toLowerCase())}
                          </span>
                        ) : a.node?.status === WhitelabelStatus.Live ? (
                          <span className="text-lime">
                            {startCase(a.node?.status.toLowerCase())}
                          </span>
                        ) : a.node?.status ===
                          WhitelabelStatus.ReadyForDevelopment ? (
                          <span>{startCase(a.node?.status.toLowerCase())}</span>
                        ) : a.node?.status === WhitelabelStatus.Retired ? (
                          <span className="text-danger">
                            {startCase(a.node?.status.toLowerCase())}
                          </span>
                        ) : (
                          <span>{startCase(a.node?.status.toLowerCase())}</span>
                        ),
                    },
                  ]
                );
              })}
            />
          </StyledList>
        </LoadingOrErrorDisplay>
      </Container>
    </>
  );
};
