import { FormikProps } from "formik";
import {
  BarrierControlConfigsQuery,
  BuildingEdge,
  BuildingListQuery,
  useBarrierControlConfigsQuery,
  useBuildingListQuery,
} from "../../../../../generated/admin";
import { SiteConfigFormData } from "../../SiteConfigFormData";
import { AccessControlConnection } from "./AccessControlConnection";
import { AccessControlConnectionFormData } from "./AccessControlConnectionFormData";
import { useContext } from "react";
import { ConfigContext } from "../../../../../providers/ConfigProvider";

interface Props {
  siteFormData: FormikProps<SiteConfigFormData>;
}

export const AccessControlConnectionList: React.FC<Props> = ({
  siteFormData,
}) => {
  const config = useContext(ConfigContext);
  const { data } = useBarrierControlConfigsQuery({
    variables: {
      siteUuid: siteFormData.values.destinationUuid,
    },
  });

  const { data: buildingsData } = useBuildingListQuery({
    variables: {
      first: 100,
      destinationUuid: siteFormData.values.destinationUuid,
    },
  });

  if (data?.barrierControlConfigs?.length === 0) {
    return null;
  }

  const mapToFormData = (
    configsQuery: BarrierControlConfigsQuery | undefined,
    buildingsQuery: BuildingListQuery | undefined
  ): AccessControlConnectionFormData[] => {
    return configsQuery?.barrierControlConfigs?.map((x) => {
      return {
        uuid: x.uuid,
        destinationUuid: siteFormData.values.destinationUuid,
        sqsUrl: x.sqsUrl,
        snsTopicArn: x.snsTopicArn,
        name: x.name,
        codeType: x.codeType,
        awsRegion: config.reportingRegion,
        alarmName: x.alarmName,
        logGroupName: x.logGroupName,
        allBuildings: (
          (buildingsQuery?.buildings?.edges as BuildingEdge[]) ?? []
        ).map((e) => ({
          value: e?.node?.uuid as string,
          label: e?.node?.name,
        })),
        buildings: x.buildings.map((b) => {
          return {
            value: b?.uuid,
            label: b?.name,
          };
        }),
      };
    }) as AccessControlConnectionFormData[];
  };

  return (
    <div className="container-main">
      <h5>Existing connections</h5>
      {mapToFormData(data, buildingsData)?.map((x) => (
        <AccessControlConnection
          key={x.uuid}
          data={x}
        ></AccessControlConnection>
      ))}
    </div>
  );
};
