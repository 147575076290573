import React, { CSSProperties } from "react";
import { SortHeader } from "./SortHeader";
import { FaSortDown, FaSortUp, FaSort } from "react-icons/fa";
import get from "lodash/get";

interface SortData {
  field: string;
  asc: boolean;
}

export class Sorter {
  constructor(
    public fields: {
      key: string;
      label: string;
      style?: CSSProperties;
      sortable?: boolean;
      sortKey?: string;
    }[],
    private sort: SortData | null = null,
    private setSort: (sort: SortData) => void = () => {},
    private onSort: (sort: SortData) => void = () => {}
  ) {}

  sortListener = (field: string) => () => {
    const sortData = {
      field,
      asc: this.sort?.field !== field || !this.sort.asc,
    };

    this.setSort(sortData);
    this.onSort(sortData);
  };

  sortIcon = (field: string) =>
    this.sort?.field === field ? (
      this.sort.asc ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      )
    ) : (
      <FaSort />
    );

  Heading: React.FC<{
    prepend?: boolean;
    before?: JSX.Element;
    after?: JSX.Element;
    wrap?: boolean;
    children?: React.ReactNode;
  }> = ({ children, before, after, prepend = false, wrap = false }) => (
    <thead>
      <tr>
        {before}
        {prepend ? children : null}
        {this.fields.map((field) => (
          <SortHeader
            style={{ whiteSpace: wrap ? undefined : "nowrap", ...field.style }}
            key={field.key}
            onClick={
              field.sortable ?? true
                ? this.sortListener(field.sortKey ?? field.key)
                : () => {}
            }
          >
            {field.label}{" "}
            {field.sortable ?? true
              ? this.sortIcon(field.sortKey ?? field.key)
              : null}
          </SortHeader>
        ))}
        {prepend ? null : children}
        {after}
      </tr>
    </thead>
  );

  sortItems = <T extends {}>(
    items: T[],
    sortFunc?: (a: T, b: T) => number
  ): T[] => {
    if (this.sort == null) {
      return items;
    }
    const defaultFunc = (a: T, b: T) => {
      if (this.sort == null) {
        return 0;
      }
      const aField = get(a, this.sort.field);
      const bField = get(b, this.sort.field);
      switch (typeof aField) {
        case "string": {
          return aField.localeCompare(bField);
        }
        case "number": {
          return aField - bField;
        }
        case "boolean": {
          return aField === bField ? 0 : aField ? -1 : 1;
        }
        default:
          return 0;
      }
    };
    const sorted = items.sort(sortFunc ?? defaultFunc);

    return this.sort.asc ? sorted : sorted.reverse();
  };
}
