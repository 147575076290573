import { useProfileDetailsQuery } from "../../../../generated/admin";
import { useUpdatedUserStore } from "./UpdatedUserStore";
import { useEffect, useState } from "react";
import { useConfig } from "../../../../providers/ConfigProvider";

export function useUserDetailsStore(uuid: string) {
  const updatedStore = useUpdatedUserStore();
  const config = useConfig();
  const { data, error, loading } = useProfileDetailsQuery({
    variables: {
      uuid,
      installation: config.installation,
    },
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const [state, setState] = useState(data);

  useEffect(() => {
    if (data == null) {
      return;
    }
    const updated = { 
      ...data,
      user: {
        ...data.user,
        profile: {
          ...data.user?.profile,
          status: updatedStore.state[uuid]?.status ?? data.user?.profile?.status,
        }
      },
    };
    setState(updated as any);
  }, [data, updatedStore.state, uuid]);

  return {
    data: state,
    error,
    loading,
  };
}
