import React, { useState, useMemo, useCallback } from "react";
import { Container, Row, Modal } from "react-bootstrap";
import { SearchBar } from "../../../admin-components/Searchbar";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { EqTable } from "../../../components/Table";
import { Sorter } from "../../../components/Sorter";
import { UserListActions } from "./UserListActions";
import { CompactListButton } from "./CompactListButton";
import { FatListButton } from "./FatListButton";
import { UserDetails } from "../user-details/UserDetails";
import styled from "styled-components";
import { TimeFormat } from "../../../components/TimeFormat";
import { FaCheckCircle } from "react-icons/fa";
import { PageHeading } from "../../../components/PageHeading";
import { useUserStore } from "../user-details/user-store/UserStore";
import { useParams } from "react-router-dom";
import { UserOperations } from "../bulk-user-actions/UserOperations";
import { UserFilters } from "./UserFilters";
import { ProfileStatus } from "../../../generated/admin";
import { useTableSearch } from "../../../hooks/useTableSearch";
import { HideForRegionalManagerInAllSitesContext } from "../../../components/HideForRegionalManagerInAllSitesContext";

const StyledUserList = styled.div`
  table td:nth-child(6) {
    width: 100px;
  }
`;

const ButtonVerticalDivider = styled.span`
  margin-right: 10px;
  margin-left: 10px;
  border-left: 1.25px solid #000;
`;

export const UserList = () => {
  const { defaultSortQuery, onTableSort, onTextSearch } = useTableSearch({
    defaultSortFieldName: "name",
  });

  const { companyUuid } = useParams<{ companyUuid: string }>();
  const [sort, setSort] = useState<{
    field: string;
    asc: boolean;
  } | null>(defaultSortQuery);
  const [compact, setCompact] = useState<boolean>(false);
  const { setExpanded, setCollapsed } = useMemo(
    () => ({
      setExpanded: () => setCompact(false),
      setCollapsed: () => setCompact(true),
    }),
    [setCompact]
  );

  const store = useUserStore(sort, companyUuid);
  const { fetchMore, setSearch, filters, setFilters, search } = store;

  const [currentUserUuid, setCurrentUserUuid] = useState<string | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const selectable = useMemo(
    () => ({
      selected,
      setSelected,
    }),
    [selected, setSelected]
  );
  const sorter = useMemo(
    () =>
      new Sorter(
        [
          { key: "name", label: "Name" },
          { key: "email", label: "Email" },
          { key: "companyName", label: "Company" },
          { key: "active", label: "Status", sortKey: "isActive" },
          {
            key: "lastActivity",
            label: "Last seen",
            sortKey: "lastActivityTime",
          },
        ],
        sort,
        setSort,
        onTableSort
      ),
    [sort, setSort, onTableSort]
  );

  const clear = useCallback(() => {
    setSearch("");
    onTextSearch("");
  }, [setSearch, onTextSearch]);

  const onSearchChange = useCallback(
    (e: { currentTarget: { value: any } }) => {
      const val = e.currentTarget.value;
      setSearch(val);
      onTextSearch(val);
    },
    [setSearch, onTextSearch]
  );
  const rowClickHandler = useCallback(
    (item: { uuid: React.SetStateAction<string | null> }) =>
      setCurrentUserUuid(item.uuid),
    [setCurrentUserUuid]
  );

  const serverLoading = useMemo(
    () => ({
      total: store.totalCount,
      loadMore: fetchMore,
      hasNextPage: store.hasNextPage,
      loading: store.loadingMore,
    }),
    [store, fetchMore]
  );

  const closeModal = useCallback(
    () => setCurrentUserUuid(null),
    [setCurrentUserUuid]
  );

  return (
    <>
      <PageHeading title="User Management"></PageHeading>
      <Container>
        <Row className="mb-4">
          <div className="col-5">
            <SearchBar
              clear={clear}
              value={search}
              onChange={onSearchChange}
              placeholder={"Search for a user..."}
            />
          </div>
        </Row>

        <LoadingOrErrorDisplay loading={store.loading}>
          <StyledUserList>
            <EqTable
              urlSearchParams={true}
              topLeftContent={
                <UserListActions>
                  <UserFilters filters={filters} setFilters={setFilters} />
                  <ButtonVerticalDivider />
                  <HideForRegionalManagerInAllSitesContext>
                    <UserOperations
                      selected={selected}
                      setSelected={setSelected}
                      store={store}
                    />
                    <ButtonVerticalDivider />
                  </HideForRegionalManagerInAllSitesContext>
                  <CompactListButton onclick={setCollapsed} />
                  <FatListButton onclick={setExpanded} />
                </UserListActions>
              }
              pageSize={50}
              compact={compact}
              sorter={sorter}
              selectable={selectable}
              clickable={true}
              clickHandler={rowClickHandler}
              items={store.users.map((user) => {
                return {
                  ...user,
                  email: (
                    <>
                      {!user.emailVerified ? (
                        user.email
                      ) : (
                        <>
                          {user.email}{" "}
                          <FaCheckCircle
                            className="text-primary ml-2"
                            data-testid="email-verified"
                          />
                        </>
                      )}
                      {user.unverifiedEmail != null ? (
                        <small className="d-block text-muted">
                          Verifying {user.unverifiedEmail}
                        </small>
                      ) : null}
                    </>
                  ),
                  isActive: user.status === ProfileStatus.Active,
                  active:
                    user.status === ProfileStatus.Active ? (
                      <span className="text-success">Active</span>
                    ) : user.status === ProfileStatus.Deactivated ? (
                      <span className="text-danger">Deactivated</span>
                    ) : (
                      <span className="text-warning">Pending approval</span>
                    ),
                  lastActivity: (
                    <TimeFormat timestamp={user.lastActivityTime} />
                  ),
                };
              })}
              serverLoading={serverLoading}
            />

            <Modal
              show={currentUserUuid != null}
              onHide={closeModal}
              dialogClassName="side-modal right"
            >
              <Modal.Body className="p-0">
                {currentUserUuid && (
                  <UserDetails uuid={currentUserUuid!} closeFn={closeModal} />
                )}
              </Modal.Body>
            </Modal>
          </StyledUserList>
        </LoadingOrErrorDisplay>
      </Container>
    </>
  );
};
