import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { ImageUpload } from "../fields/ImageUpload";
import { ColorPallet } from "../../../../components/ColorPallet";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const Branding: React.FC<Props> = ({ methods }) => (
  <ToggleContainer className="lg" title="Branding">
    <h5 className="mb-4">Colours</h5>
    <Form.Group as={Row} controlId="mobileColor">
      <Form.Label column md="3">
        Primary colour
      </Form.Label>
      <Col md="9" lg="6">
        <div className="border-bottom d-flex align-items-center">
          {methods.values.mobileColor && (
            <ColorPallet bgcolor={methods.values.mobileColor} />
          )}

          <Form.Control
            type="text"
            name="mobileColor"
            className="border-0"
            isInvalid={methods.errors.mobileColor != null}
            onChange={methods.handleChange}
            value={methods.values.mobileColor ?? ""}
          />
        </div>
        {methods.errors.mobileColor && (
          <div className="invalid-feedback d-block">
            {methods.errors.mobileColor}
          </div>
        )}
        <Form.Text className="text-muted">
        The primary colour is used throughout the mobile app. 
        Ensure that a similar colour is used for the 'Primary colour' 
        field in the 'Web &gt; Branding' section of configuration.
        </Form.Text>
      </Col>
    </Form.Group>
    <h5 className="mb-4">Image Assets</h5>

    <Form.Group as={Row} className="mb-0">
      <Form.Label column md="3" className="pt-0">
        Home page header
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload
          fieldName="appHeroImage"
          methods={methods}
          existingImage={methods.values.appHeroImage}
          allowedExtension={["JPG", "PNG"]}
        />
        {methods.errors.appHeroImage && (
          <div className="invalid-feedback d-block">
            {methods.errors.appHeroImage}
          </div>
        )}
        <Form.Text muted>
          Supported formats are PNG or JPG. Maximum file size is 5MB and we
          recommend uploading a high resolution image.
        </Form.Text>
      </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="textOverAppHeroImage">
      <Col md="9" lg="6" className="offset-md-3 pt-2">
        <Form.Check
          type="checkbox"
          name="textOverAppHeroImage"
          onChange={methods.handleChange}
          checked={methods.values.textOverAppHeroImage ?? false}
          label="Tick this box to show the site name text overlay on the header image"
        />
      </Col>
      {methods.errors.textOverAppHeroImage && (
        <div className="invalid-feedback d-block">
          {methods.errors.textOverAppHeroImage}
        </div>
      )}
    </Form.Group>

    <Form.Group as={Row} controlId="buildingInfoHeaderImage">
      <Form.Label column md="3" className="pt-0">
        Building page header
      </Form.Label>
      <Col md="9" lg="6">
        <ImageUpload
          fieldName="buildingInfoHeaderImage"
          methods={methods}
          existingImage={methods.values.buildingInfoHeaderImage}
          allowedExtension={["JPG", "PNG"]}
        />
        {methods.errors.buildingInfoHeaderImage && (
          <div className="invalid-feedback d-block">
            {methods.errors.buildingInfoHeaderImage}
          </div>
        )}
        <Form.Text muted>
          Supported formats are PNG or JPG. Maximum file size is 5MB and we
          recommend uploading a high resolution image.
        </Form.Text>
      </Col>
    </Form.Group>
  </ToggleContainer>
);
