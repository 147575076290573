import React from "react";
import { FormikContextType } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { CardAccordion } from "../../../../components/SingleEqAccordion";
import { FormikTextarea } from "../fields/FormikTextarea";

interface Props {
  methods: FormikContextType<SiteConfigFormData>;
}

export const NewsletterFooter: React.FC<Props> = ({ methods }) => {
  return (
    <CardAccordion title="Email Footer">
      <FormikTextarea
        name={`newsletterFooter`}
        placeholder="Enter Email Footer..."
        content={methods.values.newsletterFooter}
      />
      <div className="text-muted pt-2">
        <small>This text will be inserted into the footer of most emails. Formatting is not supported and will be centered in the footer of the email.</small>
      </div>
    </CardAccordion>
  );
};
