import React from "react";
import {
  SiteDetailsQuery,
  DestinationStatus,
  SiteListDocument,
  useDeleteDestinationMutation,
} from "../../../generated/admin";
import { EqMessageSuccess, EqMessageError } from "../../message/EqMessage";
import { RootRoutes } from "../../../RouterOutlet";
import { useUser } from "../../user/UserContext";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { DeleteButton } from "../../../components/DeleteButton";
import { deleteCheck } from "../../../components/deleteCheck";
import { useConfig } from "../../../providers/ConfigProvider";
import { Spinner } from "react-bootstrap";

export const DeleteDestinationButton = ({
  dest,
}: {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}) => {
  const user = useUser();
  const config = useConfig();
  const siteCtxHelper = useSiteContextHelper();
  const [deleteMutation, { loading }] = useDeleteDestinationMutation();

  return !user.inSiteContext &&
    dest.status === DestinationStatus.Deactivated ? (
    <DeleteButton
      disabled={loading}
      onClick={async () => {
        await deleteCheck(
          async () => {
            const result = await deleteMutation({
              variables: { uuid: dest.uuid },
              refetchQueries: [
                {
                  query: SiteListDocument,
                  variables: {
                    name: "",
                    installation: config.installation,
                  },
                },
              ],
            });
            if (
              result.data?.adminDeleteDestination?.__typename ===
              "SuccessResponse"
            ) {
              EqMessageSuccess({
                text: "Successfully deleted site.",
              });
              siteCtxHelper.redirect(RootRoutes.SITES);
            } else if (
              result.data?.adminDeleteDestination?.__typename ===
              "FailureResponse"
            ) {
              EqMessageError({
                text: result.data.adminDeleteDestination.reason,
              });
            } else {
              EqMessageError({
                text: "An unexpected error occurred",
              });
            }
          },
          {
            confirmButtonText: "Delete site",
            text: "Only delete a site if it was created by accident, for testing purposes, or it has been deactivated for a prolonged period of time and should be removed from the list of all sites. Note that reporting metrics will not be deleted as part of this.",
          }
        );
      }}
      className="mr-4 float-right"
    >
      {loading ? (
        <span>
          <Spinner animation="grow" size="sm" /> Deleting...
        </span>
      ) : (
        "Delete Site"
      )}
    </DeleteButton>
  ) : null;
};
