import React, { useMemo, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { FormikContextType } from "formik";

import { DestinationTierLevel } from "../../../../generated/admin";

interface Props {
  methods: Pick<
    FormikContextType<{ tierLevel?: DestinationTierLevel | null }>,
    "values" | "setFieldValue" | "touched" | "handleChange" | "errors"
  >;
}

interface SiteTierLevelOptions {
  value: DestinationTierLevel | "None";
  label: string;
}

const options: SiteTierLevelOptions[] = [
  { value: "None", label: "Web NG" },
  { value: DestinationTierLevel.Essentials, label: "Essentials" },
];

export const SiteTierLevelFormGroup: React.FC<Props> = ({ methods }) => {
  const [justSelected, setJustSelected] = useState(false);
  const isDisabled = useMemo(
    () => methods.values.tierLevel != null && !justSelected,
    [methods.values.tierLevel, justSelected]
  );
  const value = options.find(
    (o) => o.value === (methods.values.tierLevel ?? "None")
  );

  return (
    <Form.Group className="tier-level" as={Row} controlId="tierLevel">
      <Form.Label column md="3">
        Site type
      </Form.Label>
      <Col md="9" lg="6">
        <Select<SiteTierLevelOptions>
          className={`react-select${
            methods.errors.tierLevel != null ? " is-invalid" : ""
          }`}
          classNamePrefix="eq"
          name="tierLevel"
          aria-label="tierLevel"
          options={options}
          isDisabled={isDisabled}
          value={value}
          onChange={(option) => {
            setJustSelected(true);
            return methods.setFieldValue(
              "tierLevel",
              option?.value === "None" ? null : option?.value ?? null
            );
          }}
        />
        <Form.Text className="text-muted">
          Select whether the site is a Web NG or Essentials site.
        </Form.Text>
      </Col>
    </Form.Group>
  );
};
