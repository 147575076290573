import React from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDestinationNameQuery } from "../../generated/admin";
import { useSiteContextHelper } from "../../hooks/useSiteContextHelper";
import { RootRoutes } from "../../RouterOutlet";

interface Props {
  siteUuid: string;
  paths?: JSX.Element[];
  className?: string;
}

export const SiteBreadCrumb: React.FC<Props> = ({
  siteUuid,
  paths = [],
  className = "",
}) => {
  const { loading, data } = useDestinationNameQuery({
    variables: { uuid: siteUuid },
  });
  const siteCtxHelper = useSiteContextHelper();

  if (loading) {
    return <Spinner animation="grow" size="sm" />;
  }

  if (data?.destination.name == null) {
    return null;
  }

  return (
    <div className={className}>
      <Link to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${siteUuid}`)}>
        General Settings {data?.destination.name}
      </Link>
      {paths.map((path, i) => (
        <React.Fragment key={i}>
          <span className="mx-1">&gt;</span>
          {path}
        </React.Fragment>
      ))}
    </div>
  );
};
