export function selectRange(
  uuids: string[],
  uuid: string,
  lastSelected: string,
  checked: boolean,
  selected: string[]
) {
  const [start, end] = [uuids.indexOf(uuid), uuids.indexOf(lastSelected)].sort(
    (a, b) => a - b
  );
  const toSelect = uuids.slice(start, end + 1);
  const lvls = checked
    ? selected.filter((l) => !toSelect.includes(l))
    : Array.from(new Set([...selected, ...toSelect]));
  return lvls;
}
