import React, { useState, useContext } from "react";
import { UserContext } from "../../UserContext";
import { Row, Col, Button, Spinner, Form } from "react-bootstrap";
import { Heading1 } from "../../../../components/text";
import {
  useBulkUpdateUsersStatusMutation,
  BulkUpdateUsersStatusMutation,
  BulkUpdateUsersStatusMutationVariables,
  useBulkDeleteUsersMutation,
  BulkDeleteUsersMutation,
} from "../../../../generated/admin";
import { ExecutionResult } from "graphql";
import { EqMessageSuccess, EqMessageWarning } from "../../../message/EqMessage";
import { UserStore } from "../../user-details/user-store/UserStore";
import { Divider } from "../../../../components/Divider";
import { OperationSelector, Operation } from "./OperationSelector";
import { UserStatusSelector } from "./UserStatusSelector";
import { GroupsSelector } from "./GroupsSelector";
import { AttributeSelector } from "./AttributeSelector";
import { Formik } from "formik";

const SelectedUserText: React.FC<{ count: number }> = ({ count }) => (
  <Heading1 className="text-center bg-secondary p-2 text-primary">
    You’ve selected {count} {count > 1 ? "users" : "user"} to update.
  </Heading1>
);

const UpdatingFeedback = () => (
  <>
    <Spinner animation="grow" size="sm" /> Updating...
  </>
);

function chunkArray<T>(array: T[], size: number): T[][] {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
}

const showUpdatedResultMessage = (
  updated: ExecutionResult<BulkUpdateUsersStatusMutation>
) => {
  const result = updated.data?.updateUsersForDestination!;
  const updatedMsg = () => {
    if (result.updatedCount === 0) {
      return "No user accounts were successfully updated.";
    }

    return `<strong>${result.updatedCount}</strong> ${
      result.updatedCount > 1 ? "user accounts have" : "user account has"
    } been updated.
    `;
  };

  if (result != null && result.errors?.length === 0) {
    EqMessageSuccess({
      html: updatedMsg(),
    });
  } else {
    EqMessageWarning({
      html: `
        <div>${updatedMsg()}</div>
        <div class="mt-4">There were issues updating the following users:</div>
        <div class="mt-4">
          ${result.errors
            ?.map((error) => `<div>${error.email ?? error.userUuid}</div>`)
            .join(" ")}
        </div>
      `,
    });
  }
};

const showDeleteResultMessage = (
  updated: ExecutionResult<BulkDeleteUsersMutation>
) => {
  const result = updated.data?.bulkDeleteUsers!;
  const updatedMsg = () => {
    return `<strong>${result.updatedCount}</strong> ${
      result.updatedCount > 1 ? "user accounts have" : "user account has"
    } been deleted.
    `;
  };

  if (result.success) {
    EqMessageSuccess({
      html: updatedMsg(),
    });
  } else {
    EqMessageWarning({
      html: `
        <div>${updatedMsg()}</div>
        <div class="mt-4">There were issues updating the following users:</div>
        <div class="mt-4">
          ${result.errors
            ?.map(
              (error) =>
                `<div>${error.email ?? error.userUuid} (${error.reason})</div>`
            )
            .join(" ")}
        </div>
      `,
    });
  }
};

export const SelectedUserForm: React.FC<{
  selected: string[];
  onCancel: () => void;
  store: UserStore;
  onSuccess: () => void;
}> = ({ selected, onCancel, store, onSuccess }) => {
  const [bulkUpdateStatus, { loading: updating }] =
    useBulkUpdateUsersStatusMutation();
  const [bulkDelete, { loading: deleting }] = useBulkDeleteUsersMutation();
  const [operation, setOperation] = useState<Operation>("status");
  const ctx = useContext(UserContext);
  const loading = updating || deleting;

  return (
    <div>
      <Formik<BulkUpdateUsersStatusMutationVariables>
        initialValues={{
          destinationUuid: ctx?.activeDestination?.uuid!,
          userUuids: selected,
          groupUuidsToAdd: null,
        }}
        onSubmit={async (variables) => {
          if (selected.length === 0) {
            EqMessageWarning({
              text: "No users have been selected. You must first select the user checkboxes before performing this operation.",
            });
            return;
          }
          switch (operation) {
            case "delete": {
              const users = chunkArray(
                typeof variables.userUuids === "string"
                  ? [variables.userUuids]
                  : variables.userUuids,
                10
              );
              const [first, ...remainder] = await Promise.all(
                users.map(
                  async (userUuids) =>
                    await bulkDelete({
                      variables: {
                        destinationUuid: variables.destinationUuid,
                        userUuids: userUuids,
                      },
                    })
                )
              );

              showDeleteResultMessage(
                remainder.reduce((carry, item) => {
                  const a = carry.data?.bulkDeleteUsers!;
                  const b = item.data?.bulkDeleteUsers!;
                  return {
                    data: {
                      bulkDeleteUsers: {
                        success: a.success && b.success,
                        updatedCount: a.updatedCount + b.updatedCount,
                        errors: [...a.errors, ...b.errors],
                      },
                    },
                  };
                }, first)
              );
              break;
            }
            default: {
              const updated = await bulkUpdateStatus({
                variables,
              });
              showUpdatedResultMessage(updated);
              break;
            }
          }
          onSuccess();
          store.refetch();
          onCancel();
        }}
      >
        {({ handleSubmit }) => (
          <form className="p-3" onSubmit={handleSubmit}>
            <SelectedUserText count={selected.length} />
            <Row className="mt-4">
              <Col md="3">
                <Form.Label>Operation</Form.Label>
              </Col>
              <Col md="9">
                <OperationSelector
                  operation={operation}
                  onSelectOperation={(op) => {
                    setOperation(op);
                  }}
                />
              </Col>
            </Row>
            <Divider />
            {operation === "status" ? (
              <Row className="mt-4">
                <Col md="3">
                  <Form.Label>Status</Form.Label>
                </Col>
                <Col md="9">
                  <UserStatusSelector />
                </Col>
              </Row>
            ) : null}
            {operation === "groups" ? (
              <>
                <Row className="mt-4">
                  <Col md="3">
                    <Form.Label>Add group</Form.Label>
                  </Col>
                  <Col md="9">
                    <GroupsSelector
                      name={"groupUuidsToAdd"}
                      destinationUuid={ctx?.activeDestination?.uuid!}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="3">
                    <Form.Label>Remove group</Form.Label>
                  </Col>
                  <Col md="9">
                    <GroupsSelector
                      name={"groupUuidsToRemove"}
                      destinationUuid={ctx?.activeDestination?.uuid!}
                    />
                  </Col>
                </Row>
              </>
            ) : null}
            {operation === "attributes" ? (
              <>
                <Row className="mt-4">
                  <Col md="3">
                    <Form.Label>Add attribute</Form.Label>
                  </Col>
                  <Col md="9">
                    <AttributeSelector
                      name={"attributeUuidsToAdd"}
                      destinationUuid={ctx?.activeDestination?.uuid!}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="3">
                    <Form.Label>Remove attribute</Form.Label>
                  </Col>
                  <Col md="9">
                    <AttributeSelector
                      name={"attributeUuidsToRemove"}
                      destinationUuid={ctx?.activeDestination?.uuid!}
                    />
                  </Col>
                </Row>
              </>
            ) : null}
            <Row className="justify-content-center mt-4">
              <Button
                className="mr-2"
                variant="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <UpdatingFeedback />
                ) : operation === "delete" ? (
                  "Delete users"
                ) : (
                  `Update ${operation ?? "status"}`
                )}
              </Button>
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </div>
  );
};
