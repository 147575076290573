import React from "react";
import { Button } from "react-bootstrap";

/**
 * Capsule button.
 */
export const CapsuleButton: React.FC<any> = ({ children, ...props }) => {
  const variant = props.variant ?? "outline-primary";
  return (
    <Button size="sm" variant={variant} {...props}>
      {children}
    </Button>
  );
};
