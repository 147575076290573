import React from "react";
import { Formik } from "formik";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import { CreateButton } from "../../../components/CreateButton";
import {
  AdminPlatformType,
  AdminUiWidgetType,
  PlatformType,
  UiWidgetType,
  useHomeUiWidgetsV2Query,
  useUpsertHomeUiWidgetsMutation,
} from "../../../generated/admin";
import { UIWidgets } from "./UIWidgets";
import { UIWidgetFormValue } from "./UIWidgetFormValue";
import { EqMessageError, EqMessageSuccess } from "../../message/EqMessage";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { RootRoutes } from "../../../RouterOutlet";

// only get widgets of these types from the server
// we don't do much in the admin panel so feel free to add new types as they are added to the backend
const widgetTypes = [
  UiWidgetType.Booking,
  UiWidgetType.Curated,
  UiWidgetType.Events,
  UiWidgetType.Featured,
  UiWidgetType.Forme,
  UiWidgetType.News,
  UiWidgetType.Popular,
  UiWidgetType.Quicklinks,
  UiWidgetType.Reorder,
  UiWidgetType.Transport,
  UiWidgetType.Trending,
  UiWidgetType.Discount,
];

interface Props {
  uuid: string;
  platform: PlatformType;
  className?: string;
  showLogo?: boolean;
  showFooter?: boolean;
}

export const HomeUIWidgetForm: React.FC<Props> = ({
  uuid,
  platform,
  className,
  showLogo = true,
  showFooter = true,
}) => {
  const result = useHomeUiWidgetsV2Query({
    variables: { uuid, platform, widgetTypes },
    fetchPolicy: "network-only",
  });
  const siteCtxHelper = useSiteContextHelper();
  const [mutate] = useUpsertHomeUiWidgetsMutation();

  if (result.loading) {
    return <Spinner animation="grow" size="sm" />;
  }

  const initialValues: UIWidgetFormValue = {
    widgets: (result.data?.destination.homeUIWidgetsV2 ?? []).map(
      ({ uuid, type, enabled, name }) => ({
        uuid,
        type: type as unknown as AdminUiWidgetType,
        enabled,
        name,
      })
    ),
  };

  return (
    <div className={className}>
      <Formik<UIWidgetFormValue>
        initialValues={initialValues}
        onSubmit={async (values, helper) => {
          if (values.widgets == null) {
            helper.setSubmitting(false);
            return;
          }

          try {
            const mresult = await mutate({
              variables: {
                destinationUuid: uuid,
                platform:
                  platform === PlatformType.Web
                    ? AdminPlatformType.Web
                    : AdminPlatformType.Mobile,
                input: values.widgets.map(({ uuid, type, enabled }) => ({
                  uuid,
                  type,
                  enabled: enabled ?? true,
                })),
              },
            });

            if (
              mresult.data?.adminUpsertHomeUIWidgets?.__typename ===
              "SuccessResponse"
            ) {
              EqMessageSuccess({
                text: "Successfully saved site details.",
              });
            }
          } catch (e) {
            EqMessageError({
              text:
                e instanceof Error
                  ? e.message
                  : "Something went wrong, please try again later.",
            });
          }

          helper.setSubmitting(false);
          siteCtxHelper.redirect(RootRoutes.SITES);
          return;
        }}
      >
        {(methods) => {
          return (
            <form onSubmit={methods.handleSubmit}>
              <Container fluid>
                <Row>
                  <Col className="text-right p-0 pb-4">
                    {methods.values.widgets.length === 0 ? null : (
                      <CreateButton
                        disabled={methods.isSubmitting}
                        onClick={methods.submitForm}
                      >
                        {methods.isSubmitting ? (
                          <span>
                            <Spinner animation="grow" size="sm" /> Saving...
                          </span>
                        ) : (
                          "Save"
                        )}
                      </CreateButton>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="border p-0 rounded">
                    <div
                      className="p-2 border-bottom"
                      style={{ minHeight: "50px" }}
                    >
                      {showLogo ? (
                        <div
                          style={{ width: "100px", height: "50px" }}
                          className="border rounded d-flex justify-content-center align-items-center"
                        >
                          Logo
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="ui-widgets p-3"
                      style={{ background: "#f7f7f7" }}
                    >
                      <UIWidgets methods={methods} />
                    </div>
                    {showFooter ? (
                      <div
                        className="p-2 d-flex justify-content-between align-items-center font-weight-bold"
                        style={{ height: "100px" }}
                      >
                        <div>&nbsp;</div>
                        <div>Footer</div>
                        <div>&nbsp;</div>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
