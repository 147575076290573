import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import {
  useUpsertClientsMutation,
  useClientsQuery,
  Client,
} from "../../../../generated/admin";
import { useConfig } from "../../../../providers/ConfigProvider";
import { mapRegionToAdminClientRegion } from "../../../../util/regionEnumMappers";
import {
  EqMessageError,
  EqMessageSuccess,
  MessageFn,
} from "../../../message/EqMessage";

interface State {
  readonly clients: Client[];
  readonly initalLoadError?: { message: string };
  readonly saveErrors: Array<{ message: string }>;
  readonly loading: boolean;
  readonly newClientName: string;
  readonly clientsToAdd: Array<{ name: string }>;
  readonly saving: boolean;
  readonly deleting: string;
}

export interface ClientStore extends State {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addClient: () => void;
  updateLocalClient: (
    original: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeClient: (name: string) => void;
  updateClient: (uuid: string, name: string) => void;
  addClients: () => Promise<void>;
}

export const clientsSort = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });

export function useClientStoreFactory(
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
): ClientStore {
  const config = useConfig();
  const clientRegion = mapRegionToAdminClientRegion(config.region);

  const [state, setState] = useState<State>({
    clients: [],
    saveErrors: [],
    loading: false,
    newClientName: "",
    clientsToAdd: [],
    saving: false,
    deleting: "",
  });

  const set = (update: Partial<State>) =>
    setState((state) => ({ ...state, ...update }));

  const results = useClientsQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: results.loading,
      initalLoadError: results.error,
      clients: results.data?.clients ?? [],
    }));
  }, [results]);

  const [clientUpsertMutation, addResult] = useUpsertClientsMutation();
  useEffect(() => {
    set({ saving: addResult.loading });
  }, [addResult.loading]);

  return useMemo(
    () => ({
      ...state,
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((state) => ({
          ...state,
          newClientName: event.target.value,
        }));
      },
      addClient: () => {
        if (state.newClientName === "") {
          errorMsgFn({ text: "Please click on add before saving." });
          return;
        }
        if (
          state.clientsToAdd.map((l) => l.name).includes(state.newClientName)
        ) {
          errorMsgFn({ text: "Cannot create duplicate client name." });
          return;
        }
        setState((state) => ({
          ...state,
          clientsToAdd: [...state.clientsToAdd, { name: state.newClientName }],
          newClientName: "",
        }));
      },
      addClients: async () => {
        if (state.clientsToAdd.length === 0) {
          errorMsgFn({
            text: "Please click on add before saving.",
          });
          return;
        }

        const result = await clientUpsertMutation({
          variables: {
            input: state.clientsToAdd.map((clientInput) => ({
              ...clientInput,
              region: clientRegion,
            })),
          },
        });

        const added: Client[] =
          result.data?.upsertClients.flatMap((item) => {
            if (item.__typename === "ClientSuccessResponse") {
              return [
                {
                  uuid: item.client.uuid,
                  name: item.client.name,
                  destinationCount: item.client.destinationCount,
                  region: item.client.region,
                },
              ];
            }

            return [];
          }) ?? [];

        const errors =
          result.data?.upsertClients.flatMap((item) => {
            if (item.__typename === "FailureResponse") {
              return [item.reason];
            }
            return [];
          }) ?? [];

        setState((state) => ({
          ...state,
          clients: [...state.clients, ...added],
          saveErrors: errors.map((message) => ({
            message,
          })),
          clientsToAdd: [],
        }));

        if (errors.length > 0) {
          errorMsgFn({
            html: errors.join("<br/>"),
          });
        }
        if (added.length > 0) {
          successMsgFn({
            text: `${added.length > 1 ? "Clients" : "Client"} created.`,
          });
        }
      },
      removeClient: (name: string) => {
        setState((state) => ({
          ...state,
          clientsToAdd: state.clientsToAdd.filter((l) => l.name !== name),
        }));
      },
      updateClient: (uuid: string, name: string) => {
        setState((state) => ({
          ...state,
          clients: state.clients.map((l) => {
            if (l.uuid === uuid) {
              return {
                ...l,
                name,
              }
            }
            return l;
          }),
        }));
      },
      updateLocalClient:
        (original: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
          setState((state) => ({
            ...state,
            clientsToAdd: state.clientsToAdd.map((l) =>
              l.name === original ? { name: e.target.value } : l
            ),
          }));
        },
    }),
    [state, errorMsgFn, clientUpsertMutation, successMsgFn, clientRegion]
  );
}

export const ClientContext = createContext<ClientStore | null>(null);

export function useClientStore(): ClientStore {
  const store = useContext(ClientContext);
  if (store == null) {
    throw Error("Client store context not initialized.");
  }
  return store;
}

export const ClientStoreProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const store = useClientStoreFactory();

  return (
    <ClientContext.Provider value={store}>{children}</ClientContext.Provider>
  );
};
