import React from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { AdminParkingProvider } from "../../../../generated/admin";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { useUser } from "../../../user/UserContext";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const Parking: React.FC<Props> = ({ methods }) => {
  const user = useUser();
  if (user.inSiteContext) {
    return null;
  }
  return (
    <ToggleContainer className="lg" title="Parking">
      <p>Select the appropriate system to enable parking via the mobile app.</p>
      <Container fluid>
        <Row className="py-2 border justify-content-between">
          <Col>UbiPark</Col>
          <Col xs="auto">
            <Form.Check
              name="ubiPark"
              type="switch"
              label=""
              id="ubiPark"
              onChange={() =>
                methods.setFieldValue(
                  "parkingProvider",
                  methods.values.parkingProvider ===
                    AdminParkingProvider.Ubipark
                    ? AdminParkingProvider.None
                    : AdminParkingProvider.Ubipark
                )
              }
              defaultChecked={
                methods.values.parkingProvider === AdminParkingProvider.Ubipark
              }
            />
          </Col>
        </Row>
      </Container>
    </ToggleContainer>
  );
};
