import {
  useCompanyListQuery,
  CompanyListQuery,
  useAddCompanyV2DestinationMutation,
} from "../../../generated/admin";
import { useState, useMemo, useEffect, useCallback } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { CatalogueItemsStore } from "../../catalogue/model/CatalogueItemsStore";
import { CatalogueItemsState } from "../../catalogue/model/CatalogueItemsState";
import {
  EqMessageSuccess,
  MessageFn,
  EqMessageError,
} from "../../message/EqMessage";
import { ItemSelector } from "../../catalogue/model/ItemSelector";

interface Props {
  first: number;
  searchEnabled?: boolean;
  destinationUuid: string;
  saveSuccessCallback: () => Promise<void>;
  successMsgFn?: MessageFn;
  errorMsgFn?: MessageFn;
}

/**
 * Company catalogue load hook.
 */
export function useCompanyCatalogueFactory({
  first,
  destinationUuid,
  saveSuccessCallback,
  searchEnabled = true,
  successMsgFn = EqMessageSuccess,
  errorMsgFn = EqMessageError,
}: Props): CatalogueItemsStore {
  const [loadingMore, setLoadingMore] = useState(false);
  const [state, setState] = useState<CatalogueItemsState>({
    searchEnabled,
    searchPlaceholderText: "Search for companies...",
    loading: false,
    searchKeyword: "",
    items: null,
    hasMore: false,
  });

  const debouncedSearchTerm = useDebounce(state.searchKeyword, 500);
  const handler = useCallback((text: string) => {
    setState((cstate) => ({ ...cstate, searchKeyword: text }));
  }, []);

  const [mutation, { loading }] = useAddCompanyV2DestinationMutation();

  const results = useCompanyListQuery({
    variables: { search: debouncedSearchTerm, first },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    setState((cstate) => ({
      ...cstate,
      loading: results.loading,
      error: results.error,
      items:
        results.data?.companiesV2.edges.map((d) => ({
          uuid: d.node?.uuid,
          name: d.node?.name ?? "",
        })) ?? [],
      hasMore: results.data?.companiesV2.pageInfo.hasNextPage ?? false,
    }));
  }, [results]);

  return useMemo(
    () => ({
      ...state,
      loadMore: async () => {
        if (loadingMore) {
          return;
        }
        setLoadingMore(true);

        try {
          await results.fetchMore({
            variables: {
              search: debouncedSearchTerm,
              first,
              after: results.data?.companiesV2.pageInfo.endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }): CompanyListQuery => {
              setLoadingMore(false);

              const edges = [
                ...prev.companiesV2?.edges,
                ...(fetchMoreResult?.companiesV2?.edges ?? []),
              ];

              const edgeMap = edges.reduce((carry, item) => {
                if (item.node == null) return carry;
                carry[item.node.uuid] = item;
                return carry;
              }, {} as { [key: string]: CompanyListQuery["companiesV2"]["edges"][number] });
              return {
                ...prev,
                companiesV2: {
                  ...prev.companiesV2,
                  edges: Object.values(edgeMap),
                  pageInfo:
                    fetchMoreResult?.companiesV2.pageInfo ??
                    prev.companiesV2.pageInfo,
                },
              };
            },
          });
        } catch (e) {
          console.error(e);
        }
      },
      clearSearch: () =>
        setState((cstate) => ({ ...cstate, searchKeyword: "" })),
      handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        handler(e.currentTarget.value);
      },
      save: {
        loading,
        fn: async (selectedItems: ItemSelector) => {
          const selectedCompaniesUuid = Object.keys(selectedItems);
          if (selectedCompaniesUuid.length === 0 || destinationUuid == null) {
            return;
          }

          const result = await mutation({
            variables: {
              companies: selectedCompaniesUuid,
              destination: destinationUuid,
            },
          });

          if (
            result.data?.addCompanyV2Destination.__typename ===
            "SuccessResponse"
          ) {
            successMsgFn({ text: "Companies successfully added." });
            await saveSuccessCallback();
          }
          if (
            result.data?.addCompanyV2Destination.__typename ===
            "FailureResponse"
          ) {
            errorMsgFn({ text: result.data?.addCompanyV2Destination.reason });
          }
        },
      },
    }),
    [
      debouncedSearchTerm,
      destinationUuid,
      errorMsgFn,
      first,
      handler,
      loading,
      loadingMore,
      mutation,
      results,
      saveSuccessCallback,
      state,
      successMsgFn,
    ]
  );
}
