import React, { useState } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";

import {
  SyncBuildingTaxes,
  SyncBuildingTaxRate,
} from "../../../generated/admin";
import { SubTitle } from "../../../components";
import { AddButton } from "../../../components/AddButton";
import { OptionType } from "../../../model/OptionType";
import { BuildingDetailsTaxRateHistory } from "./BuildingDetailsTaxRateHistory";
import { BuildingDetailsTabProps } from "./BuildingDetailsTabProps";
import { toPercentageString, toRate } from "../../../util/toPercentageString";

const Radio = styled.input`
  margin: 0;
`;

const RadioLabel = styled.label`
  margin-left: 0.25rem;
  margin-right: 16px;
`;

const AddButtonCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const defaultTaxes: SyncBuildingTaxes = {
  taxInclusive: true,
  currency: "AUD",
  taxRates: [],
};
const defaultInputRate: SyncBuildingTaxRate = { label: "", rate: 0 };

const currencies: OptionType[] = [
  { value: "AUD", label: "Australian Dollars" },
  { value: "USD", label: "United States Dollars" },
  { value: "GBP", label: "Pound Sterling" },
  { value: "EUR", label: "Euro" },
  { value: "CAD", label: "Canadian Dollars" },
  { value: "CHF", label: "Swiss Franc" },
  { value: "DKK", label: "Danish Krone" },
  { value: "CZK", label: "Czech Koruna" },
  { value: "HUF", label: "Hungarian Forint" },
  { value: "ILS", label: "Israeli Shekel" },
  { value: "JPY", label: "Japanese Yen" },
  { value: "NOK", label: "Norwegian Krone" },
  { value: "PLN", label: "Polish Zloty" },
  { value: "RON", label: "Romanian Leu" },
  { value: "SEK", label: "Swedish Krone" },
];

const TaxRateRow: React.FC<{
  rate: SyncBuildingTaxRate;
  setRate: (rate: SyncBuildingTaxRate) => void;
  onAddClicked?: () => void;
}> = ({ rate, setRate, onAddClicked }) => {
  const labelValid = rate.label.length > 0;
  const rateValid = !Number.isNaN(rate.rate) || rate.rate > 0;
  return (
    <>
      <Col md="8">
        <Form.Control
          type="text"
          placeholder="Tax Name"
          value={rate.label}
          isInvalid={onAddClicked == null && !labelValid}
          onChange={(e) => setRate({ ...rate, label: e.target.value })}
        />
      </Col>
      <Col md="3" style={{ whiteSpace: "nowrap" }}>
        <Form.Control
          style={{ display: "inline-block", textAlign: "right" }}
          type="number"
          min="0"
          max="999"
          value={toPercentageString(rate.rate)}
          isInvalid={!rateValid}
          onChange={(e) => setRate({ ...rate, rate: toRate(e.target.value) })}
        />
        %
      </Col>
      <AddButtonCol className="col-md-1">
        {onAddClicked && (
          <AddButton
            style={{ cursor: labelValid && rateValid ? "pointer" : "unset" }}
            onClick={() => {
              if (labelValid && rateValid) {
                onAddClicked();
              }
            }}
          />
        )}
      </AddButtonCol>
    </>
  );
};

export const BuildingDetailsTaxRates: React.FC<BuildingDetailsTabProps> = ({
  state,
  building,
  setState,
}) => {
  const taxes = state.taxes ?? defaultTaxes;

  const setTaxState = (newTaxes: Partial<SyncBuildingTaxes>) => {
    setState({ ...state, taxes: { ...taxes, ...newTaxes } });
  };

  const [inputRate, setInputRate] = useState(defaultInputRate);

  return (
    <>
      <SubTitle>Tax Rates</SubTitle>
      <p>Tax rates apply to resources created in the Bookings application.</p>

      <Form.Group
        controlId="tax-type"
        as={Row}
        style={{ alignItems: "center" }}
      >
        <Form.Label column md="3" lg="2">
          Tax Type
        </Form.Label>
        <Col md="9" lg="6">
          <Radio
            type="radio"
            id="tax-type-inclusive"
            checked={taxes.taxInclusive}
            onClick={() => setTaxState({ taxInclusive: true })}
          />
          <RadioLabel htmlFor="tax-type-inclusive">Tax Inclusive</RadioLabel>
          <Radio
            type="radio"
            id="tax-type-exclusive"
            checked={!taxes.taxInclusive}
            onClick={() => setTaxState({ taxInclusive: false })}
          />
          <RadioLabel htmlFor="tax-type-exclusive">Tax Exclusive</RadioLabel>
        </Col>
      </Form.Group>

      <Form.Group controlId="currency" as={Row}>
        <Form.Label column md="3" lg="2">
          Currency
        </Form.Label>
        <Col md="9" lg="6">
          <Select<OptionType>
            className="react-select"
            classNamePrefix="eq"
            options={currencies}
            defaultValue={currencies.find((c) => c.value === taxes.currency)}
            onChange={(o) =>
              setTaxState({ currency: o?.value ?? taxes.currency })
            }
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="tax-rates" as={Row}>
        <Form.Label column md="3" lg="2">
          Tax Rates
        </Form.Label>
        <Col md="9" lg="6">
          <Container fluid>
            {taxes.taxRates.map((rate, i) => (
              <Row
                key={i}
                className={`pb-2 border ${i === 0 ? "" : "border-top-0"}`}
              >
                <TaxRateRow
                  rate={rate}
                  setRate={(newRate) =>
                    setTaxState({
                      taxRates: [
                        ...taxes.taxRates.slice(0, i),
                        newRate,
                        ...taxes.taxRates.slice(i + 1),
                      ],
                    })
                  }
                />
              </Row>
            ))}
            <Row
              className={`pb-2 border ${
                taxes.taxRates.length === 0 ? "" : "border-top-0"
              }`}
            >
              <TaxRateRow
                rate={inputRate}
                setRate={setInputRate}
                onAddClicked={() => {
                  setTaxState({ taxRates: [...taxes.taxRates, inputRate] });
                  setInputRate(defaultInputRate);
                }}
              />
            </Row>
          </Container>
        </Col>
      </Form.Group>

      {building != null && (
        <Row className="mt-5">
          <Col>
            <BuildingDetailsTaxRateHistory history={building.history} />
          </Col>
        </Row>
      )}
    </>
  );
};
