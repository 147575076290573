import React, { useContext } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { SubTitle } from "../../../components";
import { Container, Row, Col } from "react-bootstrap";
import { CompanyDetailsForm } from "./CompanyDetailsForm";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { useConfig } from "../../../providers/ConfigProvider";
import { UserContext } from "../../user/UserContext";
import { useCompanyDetails } from "./hook/useCompanyDetails";
import { CompanyDetailsNavigation } from "./CompanyDetailsNavigation";
import { CompanyIntegrationsForm } from "./CompanyIntegrationsForm";

/**
 * Company details.
 */
export const CompanyDetails: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const config = useConfig();
  const userCtx = useContext(UserContext);
  const activeDestination = userCtx?.activeDestination?.uuid ?? null;

  const {
    error,
    loading,
    industries,
    destinations,
    attributes,
    company,
    activeDestinationBuildings,
  } = useCompanyDetails(config.installation, uuid, activeDestination);

  const subHeading =
    activeDestination != null ? (
      uuid == null ? (
        <div className="mx-3 mt-3 alert alert-danger">
          Companies are used throughout reporting metrics so take care when
          entering these details. First check the company catalogue to ensure
          the company doesn’t already exist in the system. Once created, the
          company will be added to the company catalogue and only an
          administrator can change specific aspects of it.
        </div>
      ) : (
        <div className="mx-3 mt-3 alert alert-danger">
          This company belongs to the company catalogue. Certain values are view
          only and can only be edited by an administrator.
        </div>
      )
    ) : undefined;

  return (
    <>
      <div className={`mt-n3`}>
        <div className="my-3 pl-3">
          <h1 className="text-primary font-weight-light m-0">
            {uuid != null ? "Edit Company" : "Create New Company"}
          </h1>
        </div>
        <hr className="mb-0 bg-secondary" />
      </div>
      <CompanyDetailsNavigation showIntegrations={company != null} />
      {subHeading}
      <div className="pt-5">
        <Routes>
          <Route path={`/integrations`} element={<LoadingOrErrorDisplay loading={loading} error={error}>
              {company && (
                <CompanyIntegrationsForm
                  activeDestination={activeDestination}
                  company={company}
                />
              )}
            </LoadingOrErrorDisplay>} />
          <Route path="*" element={<Container>
              <Row>
                <Col className="pb-5" xs="12">
                  <SubTitle>Company Details</SubTitle>

                  <LoadingOrErrorDisplay loading={loading} error={error}>
                    <CompanyDetailsForm
                      activeDestination={activeDestination}
                      activeDestinationBuildings={activeDestinationBuildings}
                      attributes={attributes}
                      industries={industries}
                      destinations={destinations}
                      company={company}
                    />
                  </LoadingOrErrorDisplay>
                </Col>
              </Row>
            </Container>} />
        </Routes>
      </div>
    </>
  );
};
