import React, { useState, useCallback } from "react";
import { CreateButton } from "../../../components/CreateButton";
import { SubTitle } from "../../../components";
import { Modal, Row, Col } from "react-bootstrap";
import { Catalogue } from "../../catalogue/Catalogue";
import { useApartmentCatalogueFactory } from "./useApartmentCatalogue";

interface Props {
  destinationUuid: string;
  callback: () => Promise<void>;
}

const text = {
  selectedItemsTitle: "My Apartments",
  itemsTitle: "Apartments Catalogue",
  saveBtnTxt: "Add Apartments"
};

/**
 * Apartments catalogue landing.
 */
export const ApartmentsCatalogueLanding: React.FC<Props> = ({
  destinationUuid,
  callback
}) => {
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => setShow(false), [setShow]);
  const handleShow = useCallback(() => setShow(true), [setShow]);
  const result = useApartmentCatalogueFactory({
    first: 20,
    destinationUuid,
    saveSuccessCallback: async () => {
      handleClose();
      await callback();
    }
  });

  return (
    <div>
      <SubTitle>Apartments Catalogue</SubTitle>
      <p>
        Choose from a catalogue of existing apartments to add to your site.
        These apartments are shared across all sites, however, you are able to
        set certain custom values that are unique to your site.
      </p>
      <Row className="justify-content-end">
        <Col md="4" lg="3">
          <CreateButton onClick={handleShow}>Open Catalogue</CreateButton>
        </Col>
      </Row>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header
          className="border-bottom-0 pb-0 pt-2"
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <Catalogue catalogueItemsStore={result} text={text} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
