import React from "react";
import { Container } from "react-bootstrap";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import {
  AdminAccessControlSolution,
  SiteDetailsQuery,
} from "../../../../generated/admin";
import { Bookings } from "./Bookings";
import { VisitorManagement } from "./VisitorManagement";
import { AccessControlSolution } from "./AccessControlSolution";
import { NewAccessControlConnection } from "./AccessControlConnection/NewAccessControlConnection";
import { useUser } from "../../../user/UserContext";
import { Requests } from "./Requests";
import { StatsProvider } from "./StatsProvider";
import { NewIntegrationConfiguration } from "./AccessOne/NewIntegrationConfiguration";
import { IntegrationConfigurationList } from "./AccessOne/IntegrationConfigurationList";
import { NewQrCodeSetup } from "./AccessOne/NewQrCodeSetup";
import { QrCodeSetupList } from "./AccessOne/QrCodeSetupList";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const ModulesSettings: React.FC<Props> = ({ methods }) => {
  const user = useUser();
  const siteUuid: string = methods.values.destinationUuid;

  return (
    <Container className="mb-5">
      <Requests methods={methods} />
      <Bookings methods={methods} />
      <VisitorManagement methods={methods} />
      <StatsProvider methods={methods} />

      <AccessControlSolution methods={methods} />

      {
        // Access One
        methods.values.accessControlSettings?.solution ===
          AdminAccessControlSolution.AccessOne && user.isAdmin ? (
          <>
            <h5>New Configurations</h5>
            <NewQrCodeSetup siteUuid={siteUuid} />
            <NewIntegrationConfiguration siteUuid={siteUuid} />
            <QrCodeSetupList siteUuid={siteUuid} />
            <IntegrationConfigurationList siteUuid={siteUuid} />
          </>
        ) : // Visitor/Vicinitee Access Control
        methods.values.accessControlSettings?.solution ===
            AdminAccessControlSolution.Vicinitee && user.isAdmin ? (
          <NewAccessControlConnection siteFormData={methods} />
        ) : null
      }
    </Container>
  );
};
