import React, { useState, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  SiteDetailsQuery,
  useConnectOpenpathMutation,
} from "../../../../generated/admin";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";
import { validEmail } from "../../../../util/validEmail";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { Password } from "./Password";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
  successCb: (email: string, connectedTs: number) => void;
}

interface FormValue {
  invalid: boolean;
  value: string | null;
}

interface ConnectForm {
  email: FormValue;
  password: FormValue;
  orgId: FormValue;
}

const defaultState: ConnectForm = {
  email: { invalid: false, value: null },
  password: { invalid: false, value: null },
  orgId: { invalid: false, value: null },
};

export const OpenpathConnectForm: React.FC<Props> = ({ dest, successCb }) => {
  const [mutation, { loading }] = useConnectOpenpathMutation();
  const [state, setState] = useState<ConnectForm>(defaultState);

  const connectAccount = useCallback(async () => {
    if (
      state.email.invalid ||
      state.password.invalid ||
      state.orgId.invalid ||
      loading
    ) {
      return;
    }

    try {
      const result = await mutation({
        variables: {
          email: state.email.value ?? "",
          orgId: state.orgId.value ? +state.orgId.value : 0,
          password: state.password.value ?? "",
          site: dest.uuid,
        },
      });

      if (result.data?.openpath?.connect == null) {
        throw new Error("Connection returning no data.");
      }

      if (
        result.data.openpath.connect.__typename ===
        "OpenpathAuthenticationFailure"
      ) {
        throw new Error(result.data.openpath.connect.reason);
      }

      EqMessageSuccess({ text: "Successfully connected to Openpath." });

      setState(defaultState);

      const info = result.data.openpath.connect.info;
      successCb(info.email, info.connectedTimestamp);
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [state, mutation, dest.uuid, successCb, loading]);

  const emailOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        email: { invalid: !validEmail(value), value },
      }));
    },
    []
  );

  const passwordOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        password: { invalid: !stringNotEmpty(value), value },
      }));
    },
    []
  );

  const orgIdOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((pstate) => ({
        ...pstate,
        orgId: {
          invalid: !stringNotEmpty(value) || !/^[0-9]+$/.test(value),
          value,
        },
      }));
    },
    []
  );

  return (
    <div>
      Log in with your Openpath user account to connect to the Openpath API.
      Ensure the user is fully set up in the{" "}
      <a href="https://control.openpath.com/" target="_blank" rel="noreferrer">
        Openpath
      </a>{" "}
      platform beforehand.
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          Email
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="openpathEmail"
            type="text"
            isInvalid={state.email.invalid}
            disabled={loading}
            onChange={emailOnChange}
          />
          <Form.Text className="text-muted">
            Enter your Openpath email address
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Password
        </Form.Label>
        <Col md="9" lg="6">
          <Password
            name="openpathPassword"
            onPasswordChange={passwordOnChange}
            loading={loading}
            isInvalid={state.password.invalid}
          />
          <Form.Text className="text-muted">
            Enter your Openpath password
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group controlId="destinationUuid" as={Row}>
        <Form.Label column md="3" lg="2">
          ORG ID
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="openpathOrgid"
            type="text"
            disabled={loading}
            isInvalid={state.orgId.invalid}
            onChange={orgIdOnChange}
          />
          <Form.Text className="text-muted">
            Enter your Openpath ORG ID. These digits can be found in the url of
            your Openpath Command Centre e.g.
            https://control.openpath.com/o/[ORG ID]/dashboards/activityDashboard
          </Form.Text>
        </Col>
      </Form.Group>
      <div className="text-right">
        <Button
          name="openpathConnect"
          variant="outline-primary"
          onClick={connectAccount}
          disabled={loading}
        >
          {loading ? (
            <span>
              <Spinner size="sm" animation="grow" /> Loading...
            </span>
          ) : (
            "Connect account"
          )}
        </Button>
      </div>
    </div>
  );
};
