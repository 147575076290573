import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { ActionButton, TableButton } from "../../../components/TableButton";
import { deleteCheck } from "../../../components/deleteCheck";
import { Group } from "./model/Group";
import { GroupStore } from "./model/GroupStore";
import { ToggleGroupCurationStatus } from "./ToggleGroupCurationStatus";

interface NormalEditProps {
  group: Group;
  setEditing: (edit: boolean) => void;
  store: GroupStore;
}

/**
 * Edit row.
 */
export const EditGroupRow: React.FC<NormalEditProps> = ({
  group,
  store,
  setEditing,
}) => {
  const [name, setName] = React.useState(group.name);
  const setNameHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setName(e.target.value.slice(0, 50)),
    [setName]
  );
  const stopEditing = useCallback(() => setEditing(false), [setEditing]);
  const saveGroup = useCallback(async () => {
    if (name === "") {
      return;
    }
    const result = await store.saveGroup(group.uuid, name);
    if (result) {
      setEditing(false);
    }
  }, [setEditing, name, group, store]);
  return (
    <tr>
      <td className="py-1">
        <Form.Control value={name} onChange={setNameHandler} />
      </td>
      <ToggleGroupCurationStatus group={group} key={group.uuid} />
      <td>
        {!store.saving && (
          <>
            <TableButton onClick={stopEditing}>Cancel</TableButton>
            &nbsp;|&nbsp;
          </>
        )}
        <ActionButton
          onClick={saveGroup}
          actionLabel="Saving..."
          restLabel="Save"
          isInAction={store.saving}
        />
      </td>
    </tr>
  );
};

export const NormalGroupRow: React.FC<NormalEditProps> = ({
  group,
  setEditing,
  store,
}) => {
  const startEditing = useCallback(() => setEditing(true), [setEditing]);
  const deleteHandler = useCallback(async () => {
    await deleteCheck(async () => await store.deleteGroup(group.uuid), {
      confirmButtonText: "Delete group",
    });
  }, [store, group]);
  return (
    <tr>
      <td>{group.name}</td>
      <ToggleGroupCurationStatus group={group} key={group.uuid} />
      <td>
        <TableButton onClick={startEditing}>Edit</TableButton> |&nbsp;
        <ActionButton
          onClick={deleteHandler}
          actionLabel="Deleting..."
          restLabel="Delete"
          isInAction={false}
        />
      </td>
    </tr>
  );
};

interface ToggleEditProps {
  group: Group;
  store: GroupStore;
}

/**
 * Toggle edit.
 */
export const ToggleEdit: React.FC<ToggleEditProps> = ({ group, store }) => {
  const [editing, setEditing] = React.useState(false);

  return editing ? (
    <EditGroupRow group={group} store={store} setEditing={setEditing} />
  ) : (
    <NormalGroupRow group={group} store={store} setEditing={setEditing} />
  );
};
