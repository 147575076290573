import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext
} from "react";
import {
  useCompanyAttributesQuery,
  useUpsertCompanyAttributesMutation,
  useDeleteCompanyAttributeMutation
} from "../../../../generated/admin";
import {
  EqMessageError,
  EqMessageSuccess,
  MessageFn
} from "../../../message/EqMessage";
import { CompanyAttribute } from "../model/CompanyAttribute";

interface State {
  readonly attributes: CompanyAttribute[];
  readonly initalLoadError?: { message: string };
  readonly saveErrors: Array<{ message: string }>;
  readonly loading: boolean;
  readonly newAttributeName: string;
  readonly attributesToAdd: Array<{ name: string }>;
  readonly addingAttribute: boolean;
  readonly deleting: string;
  readonly siteUuid: string;
}

export type CompanyAttributeStore = ReturnType<
  typeof useCompanyAttributeStoreFactory
>;

export const attributesSort = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base"
  });

export function useCompanyAttributeStoreFactory(
  siteUuid: string,
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
) {
  const [state, setState] = useState<State>({
    attributes: [],
    saveErrors: [],
    loading: false,
    newAttributeName: "",
    attributesToAdd: [],
    addingAttribute: false,
    deleting: "",
    siteUuid
  });

  const [sortedAttributes, setSortedAttributes] = useState<CompanyAttribute[]>(
    []
  );

  const set = (update: Partial<State>) =>
    setState((state) => ({ ...state, ...update }));

  const results = useCompanyAttributesQuery({
    fetchPolicy: "network-only",
    variables: { siteUuid }
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: results.loading,
      initalLoadError: results.error,
      attributes: results.data?.destination.companyAttributes ?? []
    }));
  }, [results]);

  useEffect(() => {
    setSortedAttributes([...state.attributes].sort(attributesSort));
  }, [state.attributes]);

  const [deleteMutation] = useDeleteCompanyAttributeMutation();

  const [
    attributeUpsertMutation,
    addResult
  ] = useUpsertCompanyAttributesMutation();
  useEffect(() => {
    set({ addingAttribute: addResult.loading });
  }, [addResult.loading]);

  return useMemo(
    () => ({
      ...state,
      sortedAttributes,
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAttributeName = event.target.value;
        setState((state) => ({
          ...state,
          newAttributeName
        }));
      },
      addAttribute: () => {
        if (state.newAttributeName === "") {
          errorMsgFn({ text: "Name cannot be empty." });
          return;
        }
        if (
          state.attributesToAdd
            .map((l) => l.name)
            .includes(state.newAttributeName)
        ) {
          errorMsgFn({
            text: "Cannot create duplicate company attribute name."
          });
          return;
        }
        setState((state) => ({
          ...state,
          attributesToAdd: [
            ...state.attributesToAdd,
            { name: state.newAttributeName }
          ],
          newAttributeName: ""
        }));
      },
      addAttributes: async () => {
        if (state.attributesToAdd.length === 0) {
          errorMsgFn({
            text: "Please click on add before saving."
          });
          return;
        }
        const result = await attributeUpsertMutation({
          variables: {
            input: state.attributesToAdd.map((i) => ({
              ...i,
              siteUuid
            }))
          }
        });

        const addedAttributes: CompanyAttribute[] =
          result.data?.upsertCompanyAttributes.flatMap((attr) => {
            if (attr.__typename === "CompanyAttributeSuccessResponse") {
              return [
                {
                  uuid: attr.companyAttribute.uuid,
                  name: attr.companyAttribute.name
                }
              ];
            }

            return [];
          }) ?? [];

        const errors =
          result.data?.upsertCompanyAttributes.flatMap((attr) => {
            if (attr.__typename === "FailureResponse") {
              return [attr.reason];
            }
            return [];
          }) ?? [];

        setState((state) => ({
          ...state,
          attributes: [...state.attributes, ...addedAttributes],
          saveErrors: errors.map((message) => ({
            message
          })),
          attributesToAdd: []
        }));

        if (errors.length > 0) {
          errorMsgFn({
            html: errors.join("<br/>")
          });
        }

        if (addedAttributes.length > 0) {
          successMsgFn({
            text: `Company ${
              addedAttributes.length > 1 ? "attributes" : "attribute"
            } created.`
          });
        }
      },
      deleteAttribute: async (uuid: string) => {
        setState((state) => ({ ...state, deleting: uuid }));

        const result = await deleteMutation({ variables: { uuid } });
        setState((state) => ({ ...state, deleting: "" }));

        if (result.data == null) {
          errorMsgFn({
            text: "Something went wrong, please refresh the page and try again."
          });
          return;
        }

        if (
          result.data.deleteCompanyAttribute.__typename === "FailureResponse"
        ) {
          errorMsgFn({ text: result.data.deleteCompanyAttribute.reason });
          return;
        }

        successMsgFn({ text: "Company attribute has been deleted!" });
        setState((state) => ({
          ...state,
          attributes: state.attributes.filter((l) => l.uuid !== uuid)
        }));
      },
      removeAttribute: (name: string) => {
        setState((state) => ({
          ...state,
          attributesToAdd: state.attributesToAdd.filter((l) => l.name !== name)
        }));
      },
      updateAttribute: (uuid: string, name: string) => {
        setState((state) => ({
          ...state,
          attributes: state.attributes.map((l) => {
            if (l.uuid === uuid) {
              return {
                ...l,
                name,
              }
            }
            return l;
          })
        }));
      },
      updateLocalAttribute: (original: string) => (
        e: React.ChangeEvent<HTMLInputElement>
      ) => {
        const value = e.target.value;
        setState((state) => ({
          ...state,
          attributesToAdd: state.attributesToAdd.map((l) =>
            l.name === original ? { name: value } : l
          )
        }));
      }
    }),
    [
      state,
      sortedAttributes,
      errorMsgFn,
      attributeUpsertMutation,
      siteUuid,
      successMsgFn,
      deleteMutation
    ]
  );
}

export const CompanyAttributeContext = createContext<CompanyAttributeStore | null>(
  null
);

export function useCompanyAttributeStore(): CompanyAttributeStore {
  const store = useContext(CompanyAttributeContext);
  if (store == null) {
    throw Error("Company attribute store context not initialized.");
  }
  return store;
}

export const CompanyAttributeStoreProvider: React.FC<{ siteUuid: string, children?: React.ReactNode }> = ({
  children,
  siteUuid
}) => {
  const store = useCompanyAttributeStoreFactory(siteUuid);

  return (
    <CompanyAttributeContext.Provider value={store}>
      {children}
    </CompanyAttributeContext.Provider>
  );
};
