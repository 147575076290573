import React, { SyntheticEvent } from "react";
import { Sorter } from "./Sorter";
import { ArrayPagination } from "./Pagination";
import { FlushRightTable } from "./FlushRightTable";
import get from "lodash/get";
import { ActionButton } from "./TableButton";
import { Spinner, Form } from "react-bootstrap";
import { useShiftKeyDown } from "../modules/buildings/detail-view/useShiftKeyDown";
import { selectRange } from "./selectRange";

interface SelectableProps {
  selected: string[];
  setSelected: (uuids: string[]) => void;
}

export type Operation<T> =
  | ((item: T) => {
      render?: undefined;
      label: string;
      actionLabel: string;
      operation: (item: T) => void;
      isInAction: boolean;
    })
  | ((item: T) => { render: () => JSX.Element });

interface Props<T extends { uuid: string }> {
  sorter: Sorter;
  selectable?: SelectableProps;
  clickable?: boolean;
  clickHandler?: (item: T) => void;
  operations?: Array<Operation<T>>;
  items: T[];
  serverLoading?: {
    total: number;
    loadMore: () => void;
    hasNextPage: boolean;
    loading: boolean;
  };
  emptyMessage?: string;
  compact?: boolean;
  pageSize?: number;
  topLeftContent?: JSX.Element;
  flushRight?: boolean;
  pagination?: boolean;
  alignlast?: "left" | "right" | "center";
  positionfixed?: boolean;
  wrapHeadings?: boolean;
  urlSearchParams?: boolean;
}

export function EqTable<T extends { uuid: string }>({
  selectable,
  sorter,
  items,
  operations = [],
  clickable = false,
  clickHandler,
  emptyMessage = "Search retrieved no results.",
  serverLoading,
  compact = false,
  pageSize,
  topLeftContent,
  flushRight = true,
  pagination = true,
  alignlast,
  positionfixed,
  urlSearchParams = false,
  wrapHeadings = false,
}: Props<T>) {
  return pagination ? (
    <ArrayPagination
      urlSearchParams={urlSearchParams}
      serverLoading={serverLoading}
      topLeftContent={topLeftContent}
      items={serverLoading != null ? items : sorter.sortItems(items)}
      pageSize={pageSize}
    >
      {(sorted) => {
        return (
          <TableInner
            selectable={selectable}
            sorter={sorter}
            items={sorted}
            clickHandler={clickHandler}
            operations={operations}
            clickable={clickable}
            emptyMessage={emptyMessage}
            serverLoading={serverLoading}
            compact={compact}
            flushRight={flushRight}
            alignlast={alignlast}
            positionfixed={positionfixed}
            wrapHeadings={wrapHeadings}
          />
        );
      }}
    </ArrayPagination>
  ) : (
    <TableInner
      selectable={selectable}
      sorter={sorter}
      items={items}
      clickHandler={clickHandler}
      operations={operations}
      clickable={clickable}
      emptyMessage={emptyMessage}
      serverLoading={serverLoading}
      compact={compact}
      flushRight={flushRight}
      alignlast={alignlast}
      positionfixed={positionfixed}
      wrapHeadings={wrapHeadings}
    />
  );
}

function useTableProps<T extends { uuid: string }>(
  sorted: T[],
  selectable?: SelectableProps
) {
  const [uniqueId] = React.useState(() => Math.random());
  const [lastSelected, setLastSelected] = React.useState<string | null>(null);
  const shiftKeyDown = useShiftKeyDown();
  const allSelected =
    selectable == null
      ? false
      : selectable.selected.length === 0
      ? false
      : sorted.filter((s) => selectable.selected.includes(s.uuid)).length ===
        sorted.length;
  const selectAll = () => {
    if (selectable == null) {
      return;
    }
    selectable.setSelected(
      allSelected
        ? selectable.selected.filter(
            (uuid) => !sorted.map((s) => s.uuid).includes(uuid)
          )
        : Array.from(
            new Set([...selectable.selected, ...sorted.map((s) => s.uuid)])
          )
    );
  };

  const select = (item: T, checked: boolean) => (e: SyntheticEvent) => {
    e.stopPropagation();
    if (selectable == null) {
      return;
    }
    if (shiftKeyDown && lastSelected != null) {
      const selected = selectRange(
        sorted.map((l) => l.uuid),
        item.uuid,
        lastSelected,
        checked,
        selectable.selected
      );
      selectable.setSelected(selected);
    } else {
      selectable.setSelected(
        checked
          ? selectable.selected.filter((uuid) => uuid !== item.uuid)
          : [...selectable.selected, item.uuid]
      );
    }
    setLastSelected(item.uuid);
  };

  return {
    allSelected,
    selectAll,
    uniqueId,
    select,
  };
}

const thStyles = { width: "50px" };

export function TableInner<T extends { uuid: string }>({
  selectable,
  sorter,
  items,
  operations = [],
  clickable = false,
  clickHandler,
  emptyMessage = "Search retrieved no results.",
  serverLoading,
  compact = false,
  flushRight = true,
  positionfixed,
  alignlast,
  wrapHeadings = false,
}: Props<T>) {
  const sorted = items;

  const { selectAll, allSelected, uniqueId, select } = useTableProps(
    sorted,
    selectable
  );

  const compactClass = compact ? "table-sm" : "";

  return (
    <FlushRightTable
      positionfixed={positionfixed}
      alignlast={
        alignlast ?? items.length === 0
          ? "left"
          : flushRight
          ? "right"
          : "center"
      }
      className={compactClass}
      hover={clickable}
    >
      <sorter.Heading
        before={
          selectable != null ? (
            <th style={thStyles}>
              <Form.Check
                className="table-checkbox"
                type="checkbox"
                checked={allSelected}
                onChange={selectAll}
              />
            </th>
          ) : undefined
        }
        wrap={wrapHeadings}
      >
        {operations.length > 0 ? <th>Operations</th> : null}
      </sorter.Heading>
      <tbody>
        {serverLoading?.loading ? (
          <tr>
            <td className="text-left" colSpan={sorter.fields.length + 1}>
              <Spinner size="sm" animation="grow" /> Loading...
            </td>
          </tr>
        ) : sorted.length === 0 ? (
          <tr className="bg-light">
            <td
              colSpan={
                sorter.fields.length +
                operations.length +
                (selectable != null ? 1 : 0)
              }
            >
              {emptyMessage}
            </td>
          </tr>
        ) : (
          sorted.map((item) => {
            const checked = selectable?.selected.includes(item.uuid) ?? false;
            return (
              <tr
                onClick={() => (clickHandler ?? (() => {}))(item)}
                key={`${uniqueId}${item.uuid}`}
              >
                {selectable != null ? (
                  <td>
                    <Form.Check
                      className="table-checkbox"
                      type="checkbox"
                      readOnly
                      onClick={select(item, checked)}
                      checked={checked}
                      data-testid={`checkbox-user-${item.uuid}`}
                    />
                  </td>
                ) : null}
                {sorter.fields.map((s, i) => {
                  const value = get(item, s.key);
                  return <td key={`${s.key}-${i}`}>{value}</td>;
                })}
                {operations.length > 0 ? (
                  <td>
                    {operations
                      .map((op) => op(item))
                      .map((op, index) =>
                        op.render !== undefined ? (
                          op.render()
                        ) : (
                          <>
                            <ActionButton
                              isInAction={op.isInAction}
                              onClick={() => op.operation(item)}
                              restLabel={op.label}
                              actionLabel={op.actionLabel}
                              key={`${op.label}-${index}`}
                            />
                            {index === operations.length - 1 ? null : " | "}
                          </>
                        )
                      )}
                  </td>
                ) : null}
              </tr>
            );
          })
        )}
      </tbody>
    </FlushRightTable>
  );
}
