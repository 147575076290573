import React, { useContext } from "react";
import { useAuth0 } from "../../providers/Auth0Provider";
import { UserContext } from "./UserContext";

export const RoleCheck: React.FC<{ children?: React.ReactNode }> = (props) => {
  const user = useContext(UserContext);
  const auth0 = useAuth0();

  if (user == null) {
    return null;
  }
  if (
    !user.roles.includes("ADMIN") &&
    !user.roles.includes("REGIONAL_MANAGER") &&
    !user.roles.includes("SUPER_ADMIN") &&
    !user.roles.includes("SITE_MANAGER")
  ) {
    return (
      <>
        <div className="w-100 min-vh-100 bg-secondary text-muted text-center pb-5">
          <div className="modal-body text-center">
            <div className="mode-noaccess">
              <div className="mt-4 font-weight-light text-primary">
                <h2 className="font-weight-light mb-4">
                  Sorry, you do not have access
                </h2>
                <p>
                  <b>{user.email}</b> does not have access to Admin Panel.
                </p>
                <p>
                  If you require access please email our{" "}
                  <a href="mailto:support@getequiem.com">Support Team</a>.
                </p>
                <button
                  className="btn btn-outline-primary px-5 shadow-none"
                  type="button"
                  onClick={auth0.logout}
                >
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <>{props.children}</>;
};
