import { Maybe } from "graphql/jsutils/Maybe";
import {
  useCompanyIndustriesQuery,
  useCompanyAttributesQuery,
  useDestinationBuildingsQuery,
  useSiteListQuery,
  useCompanyDetailsQuery
} from "../../../../generated/admin";

/**
 * Company details hook.
 */
export function useCompanyDetails(
  installation: string,
  companyUuid?: Maybe<string>,
  activeDestination?: Maybe<string>
) {
  const {
    loading: industryLoading,
    error: industryError,
    data: industryData
  } = useCompanyIndustriesQuery();

  const {
    loading: attributeLoading,
    error: attributeError,
    data: attributeData
  } = useCompanyAttributesQuery({
    variables: { siteUuid: activeDestination },
    skip: activeDestination == null
  });

  const {
    loading: buildingLoading,
    error: buildingError,
    data: buildingData
  } = useDestinationBuildingsQuery({
    variables: { uuid: activeDestination },
    skip: activeDestination == null
  });

  const {
    loading: sitesLoading,
    error: sitesError,
    data: sitesData
  } = useSiteListQuery({
    variables: { name: "", installation },
    skip: !(companyUuid != null && activeDestination == null)
  });

  const {
    loading: companyLoading,
    error: companyError,
    data: companyData
  } = useCompanyDetailsQuery({
    fetchPolicy: "network-only",
    variables: { uuid: companyUuid },
    skip: companyUuid == null
  });

  return {
    error:
      industryError ??
      attributeError ??
      sitesError ??
      companyError ??
      buildingError,
    loading:
      industryLoading ||
      attributeLoading ||
      sitesLoading ||
      companyLoading ||
      buildingLoading,
    industries:
      industryData?.companyIndustries.map((i) => ({
        uuid: i.uuid as string,
        name: i.name
      })) ?? [],
    destinations:
      sitesData?.searchDestinations?.map((d) => ({
        uuid: d.uuid as string,
        name: d.name
      })) ?? [],
    attributes:
      attributeData?.destination?.companyAttributes?.map((a) => ({
        uuid: a.uuid as string,
        name: a.name
      })) ?? [],
    company: companyData?.companyV2,
    activeDestinationBuildings: buildingData?.destination.buildings ?? []
  };
}
