import React, { useContext, useMemo } from "react";
import { Row, Col, Spinner, Form, Container } from "react-bootstrap";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { CreateButton } from "../../../components/CreateButton";
import { Divider } from "../../../components/Divider";
import { ArrayPagination } from "../../../components/Pagination";
import { FlushRightTable } from "../../../components/FlushRightTable";
import { Sorter } from "../../../components/Sorter";
import { SubTitle } from "../../../components";
import { LevelRow } from "./EditLevelRow";
import { levelsSort } from "./levelHook";
import { useLevelStore } from "./LevelsContext";
import { AddButton } from "../../../components/AddButton";
import { UserContext } from "../../user/UserContext";
import { RemoveButton } from "../../../components/RemoveButton";

export const Levels = () => {
  const user = useContext(UserContext);
  const store = useLevelStore();
  const [sort, setSort] = React.useState<{
    field: string;
    asc: boolean;
  }>({ field: "name", asc: true });

  const sorter = useMemo(() => new Sorter([{ key: "name", label: "Level" }], sort, setSort), [sort]);
  return (
    <>
      <Row>
        <Col>
          <Divider />
          <SubTitle>
            {user?.activeDestination != null ? "My Levels" : "Manage Levels"}
          </SubTitle>
          {user?.activeDestination != null ? (
            <p>
              Levels displayed below are a catalogue of levels available for use
              at your site. You can add additional levels, unique to your site,
              using the form at the bottom of this page.
            </p>
          ) : (
            <p>
              Levels displayed below are a catalogue of levels available for
              selection across all buildings.
            </p>
          )}
        </Col>
      </Row>
      <LoadingOrErrorDisplay
        loading={store.loading}
        error={store.initalLoadError}
      >
        {store.levels.length === 0 ? null : (
          <ArrayPagination
            items={sorter.sortItems(store.levels.slice(), levelsSort)}
          >
            {(items) => (
              <FlushRightTable>
                <sorter.Heading>
                  <th>Operations</th>
                </sorter.Heading>
                <tbody>
                  {items.map((level) => (
                    <LevelRow
                      key={"level-row" + level.uuid}
                      level={level}
                      store={store}
                    />
                  ))}
                </tbody>
              </FlushRightTable>
            )}
          </ArrayPagination>
        )}
      </LoadingOrErrorDisplay>
      <Row>
        <Col>
          <Divider />

          <SubTitle>Add Levels</SubTitle>
          {user?.activeDestination != null ? (
            <p>
              Additional levels will be added to the list of levels in the above
              table and are unique to your site.
            </p>
          ) : (
            <p>
              Enter additional levels to add to the catalogue of levels
              available to all buildings.
            </p>
          )}
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col md="7" lg="8">
          <Container>
            {store.levelsToAdd.map((level, i) => (
              <Row key={`list-${i}`}>
                <Col
                  className="py-1 px-3 border border-bottom-0 border-right-0"
                  xs="10"
                  lg="11"
                >
                  <Form.Control
                    className="w-50"
                    required
                    value={level.name}
                    onChange={store.updateLocalLevel(level.name)}
                  />
                </Col>
                <Col
                  lg="1"
                  xs="2"
                  className="justify-content-end d-flex align-items-center border border-bottom-0 border-left-0"
                >
                  <RemoveButton onClick={() => store.removeLevel(level.name)} />
                </Col>
              </Row>
            ))}

            <Row
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  store.addLevel();
                }
              }}
            >
              <Col className="border border-right-0 px-3" xs="10" lg="11">
                <Form.Control
                  required
                  className="border-bottom-0"
                  placeholder="Level Name"
                  value={store.newLevelName}
                  onChange={store.handleChange}
                />
              </Col>
              <Col
                lg="1"
                xs="2"
                className="border border-left-0 d-flex justify-content-end align-items-center"
              >
                <AddButton onClick={store.addLevel} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md="4" lg="3">
          <CreateButton
            className="mw-50"
            disabled={store.loading}
            onClick={store.addLevels}
          >
            {store.addingLevel ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Save Levels"
            )}
          </CreateButton>
        </Col>
      </Row>
    </>
  );
};
