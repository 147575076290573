import React from "react";
import { Auth0Provider } from "./Auth0Provider";
import { LocalApolloProvider } from "./ApolloProvider";
import { ConfigProvider, ConfigContext } from "./ConfigProvider";

/**
 * External Service Provider
 * add services here that you intend to mock in functional testing
 */
export const ExternalServiceProvider: React.FC< { children?: React.ReactNode } > = ({ children }) => (
  <ConfigProvider>
    <ConfigContext.Consumer>
      {config => (
        <Auth0Provider config={config}>
          <LocalApolloProvider>{children}</LocalApolloProvider>
        </Auth0Provider>
      )}
    </ConfigContext.Consumer>
  </ConfigProvider>
);
