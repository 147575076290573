import React from "react";
import { useUserSiteAttributesQuery } from "../../../../generated/admin";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { FormikSelect } from "../../../../components/formik/FormikSelect";

export const AttributeSelector: React.FC<{
  destinationUuid: string;
  name: string;
}> = ({ destinationUuid, name }) => {
  const { data, loading, error } = useUserSiteAttributesQuery({
    variables: { destinationUuid }
  });
  const options =
    data?.userSiteAttributes.map((a) => {
      return {
        value: a.uuid,
        label: a.name
      };
    }) ?? [];
  return (
    <LoadingOrErrorDisplay loading={loading} error={error}>
      <FormikSelect
        placeholder="Select attributes..."
        aria-label={name}
        name={name}
        options={options}
        isMulti
      />
    </LoadingOrErrorDisplay>
  );
};
