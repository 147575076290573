import React from "react";

export function useShiftKeyDown() {
  const [shiftKeyDown, setShiftKeyDown] = React.useState(false);
  const keyPressHandler = (e: KeyboardEvent) => {
    setShiftKeyDown(e.shiftKey);
  };
  React.useEffect(() => {
    window.addEventListener("keydown", keyPressHandler);
    window.addEventListener("keyup", keyPressHandler);
    return () => {
      window.removeEventListener("keydown", keyPressHandler);
      window.removeEventListener("keyup", keyPressHandler);
    };
  }, []);
  return shiftKeyDown;
}
