import { FieldArray, FormikProps } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import ReactDragListView from "react-drag-listview";
import { FaArrowsAlt } from "react-icons/fa";
import { AdminUiWidgetType } from "../../../generated/admin";
import { UIWidgetFormValue } from "./UIWidgetFormValue";
import { trimString } from "../../../util/trimString";

export interface UIWidgetInfo {
  methods: FormikProps<UIWidgetFormValue>;
}

export const UIWidgets: React.FC<UIWidgetInfo> = ({ methods }) => {
  const shortElements = [AdminUiWidgetType.Quicklinks];
  const disabledMoveElements = [
    AdminUiWidgetType.Quicklinks,
    AdminUiWidgetType.Discount,
  ];
  const disabledOnOffElements = [AdminUiWidgetType.Discount];
  const hiddenElements = [AdminUiWidgetType.Discount];

  return methods.values.widgets.length === 0 ? (
    <div className="text-center py-5">None</div>
  ) : (
    <FieldArray name="widgets">
      {(arrayHelpers) => (
        <ReactDragListView
          lineClassName="border-color-lime"
          handleSelector="span.mover"
          nodeSelector="div.bisa-ditarik"
          onDragEnd={(fromIndex, toIndex) => {
            arrayHelpers.move(fromIndex, toIndex);
          }}
        >
          <>
            {methods.values.widgets.map((w, index) => {
              const noMove = disabledMoveElements.includes(w.type);
              const noOnOff = disabledOnOffElements.includes(w.type);
              const hidden = hiddenElements.includes(w.type);

              return (
                <div
                  key={w.uuid}
                  className={`${noMove ? "" : "bisa-ditarik "}${
                    hidden ? "d-none " : "d-flex "
                  }
                  uiwidget justify-content-center p-2 rounded position-relative mb-3`}
                  style={{
                    border: "1px solid #ccc",
                    height: shortElements.includes(w.type) ? "auto" : "150px",
                  }}
                >
                  <input
                    type="hidden"
                    name={`widgets.${index}.uuid`}
                    value={w.uuid}
                  />
                  <span className="font-weight-bold align-self-center">
                    {trimString(w.name)}
                  </span>
                  <div
                    className="d-flex"
                    style={{ position: "absolute", top: "8px", right: "0" }}
                  >
                    {noOnOff ? null : (
                      <Form.Check
                        name={`widgets.${index}.enabled`}
                        id={`widgets.${index}.enabled`}
                        type="switch"
                        label=""
                        onChange={methods.handleChange}
                        defaultChecked={w.enabled ?? false}
                      />
                    )}
                    {noMove ? null : (
                      <span className="mover mr-2">
                        <FaArrowsAlt size="20" />
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        </ReactDragListView>
      )}
    </FieldArray>
  );
};
