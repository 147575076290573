import React, { useCallback } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useCompanyAttributeStore } from "./hook/useCompanyAttributesStore";
import { AddButton } from "../../../components/AddButton";
import { CreateButton } from "../../../components/CreateButton";
import { RemoveButton } from "../../../components/RemoveButton";

/**
 * Add company attribute form.
 */
export const AddCompanyAttributeForm: React.FC = () => {
  const store = useCompanyAttributeStore();
  const handleAddAttribute = useCallback(() => store.addAttribute(), [store]);
  const handleAddAttributes = useCallback(() => store.addAttributes(), [store]);

  return (
    <Container fluid className="p-0">
      <Row className="justify-content-between">
        <Col md="7" lg="8">
          <Container fluid>
            {store.attributesToAdd.map((attribute, i) => (
              <Row key={`list-${i}`}>
                <Col
                  className="py-1 px-3 border border-bottom-0 border-right-0"
                  xs="10"
                  lg="11"
                >
                  <Form.Control
                    className="w-100 w-lg-50"
                    required
                    value={attribute.name}
                    onChange={store.updateLocalAttribute(attribute.name)}
                  />
                </Col>
                <Col
                  lg="1"
                  xs="2"
                  className="justify-content-end d-flex align-items-center border border-bottom-0 border-left-0"
                >
                  <RemoveButton
                    onClick={() => store.removeAttribute(attribute.name)}
                  />
                </Col>
              </Row>
            ))}

            <Row
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  store.addAttribute();
                }
              }}
            >
              <Col className="border border-right-0 px-3" xs="10" lg="11">
                <Form.Control
                  required
                  className="border-bottom-0"
                  placeholder="Attribute Name"
                  value={store.newAttributeName}
                  onChange={store.handleChange}
                />
              </Col>
              <Col
                lg="1"
                xs="2"
                className="border border-left-0 d-flex justify-content-end align-items-center"
              >
                <AddButton
                  data-testid="add-company-attribute-btn"
                  onClick={handleAddAttribute}
                />
              </Col>
            </Row>
          </Container>
        </Col>

        <Col md="4" lg="3" className="mt-3 mt-md-0 text-right">
          <CreateButton disabled={store.loading} onClick={handleAddAttributes}>
            {store.addingAttribute ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Add Company Attributes"
            )}
          </CreateButton>
        </Col>
      </Row>
    </Container>
  );
};
