import { Formik } from "formik";
import { Spinner, Container, Row, Col, Form } from "react-bootstrap";

import { SubTitle } from "../../../components";
import { CreateButton } from "../../../components/CreateButton";
import { trimmedOrNull } from "../../../util/trimmedOrNull";
import { notNullOrUndefined } from "../../../util/notNullOrUndefined";
import {
  CompanyFragment,
  useUpdateCompanyV2IntegrationsMutation,
  useUpdateCompanyDestinationIntegrationsMutation,
} from "../../../generated/admin";
import { TextFormGroup } from "../../sites/site-details/fields/TextFormGroup";
import { useUser } from "../../user/UserContext";
import { EqMessage } from "../../message/EqMessage";

interface ICompanyDetailsForm {
  activeDestination?: string | null;
  company: CompanyFragment;
}

interface FormData {
  global: {
    tenancyId?: string;
  };
  destination: {
    yardi: { tenantCode?: string };
  };
}

/**
 * Company details form.
 */
export const CompanyIntegrationsForm: React.FC<ICompanyDetailsForm> = ({
  activeDestination,
  company,
}) => {
  const user = useUser();
  const [globalMutation, { loading: globalSaving }] =
    useUpdateCompanyV2IntegrationsMutation();
  const [destinationMutation, { loading: destinationSaving }] =
    useUpdateCompanyDestinationIntegrationsMutation();

  const activeCompanyDestination =
    activeDestination != null
      ? company.companyDestinations.find(
          (d) => d.destinationUuid === activeDestination
        )
      : null;

  const handleSubmit = async (values: FormData) => {
    const [globalResult, destinationResult] = await Promise.all([
      globalMutation({
        variables: {
          integrations: {
            companyUuid: company.uuid,
            evolution:
              values.global.tenancyId != null
                ? { tenancyId: values.global.tenancyId }
                : undefined,
          },
        },
      }),
      user.integrations.yardiEnabled
        ? destinationMutation({
            variables: {
              integrations: {
                destinationUuid: activeDestination,
                companyUuid: company.uuid,
                yardi: {
                  tenantCode: trimmedOrNull(
                    values.destination.yardi.tenantCode
                  ),
                },
              },
            },
          })
        : null,
    ]);

    const globalUpdate = globalResult.data?.updateCompanyV2Integrations;
    const destinationUpdate = user.integrations.yardiEnabled
      ? destinationResult?.data?.updateCompanyDestinationIntegrations
      : { success: true, message: null };

    const failureMessage = trimmedOrNull(
      [
        globalUpdate?.success ? null : globalUpdate?.message,
        destinationUpdate?.success ? null : destinationUpdate?.message,
      ]
        .filter(notNullOrUndefined)
        .join(" ")
    );

    const icon =
      globalUpdate?.success && destinationUpdate?.success ? "success" : "error";
    EqMessage({
      icon,
      text:
        failureMessage ??
        globalUpdate?.message ??
        destinationUpdate?.message ??
        icon,
    });
  };

  return (
    <Container>
      <Formik<FormData>
        initialValues={{
          global: {
            tenancyId: company.integrations?.evolution.tenancyId ?? undefined,
          },
          destination: {
            yardi: {
              tenantCode:
                activeCompanyDestination?.integrations?.yardi?.tenantCode ?? "",
            },
          },
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {user.integrations.evolutionEnabled && (
              <Row>
                <Col className="pb-5" xs="12">
                  <SubTitle>JLL Evolution</SubTitle>
                  <TextFormGroup
                    title={"Evolution tenant code"}
                    name={"global.tenancyId"}
                    subText={
                      "Enter the Evolution tenant code so that approved Evolution users, associated with this company, can raise requests for their site."
                    }
                    handleChange={handleChange}
                    value={values.global.tenancyId}
                    error={errors.global?.tenancyId}
                  />
                </Col>
              </Row>
            )}

            {user.integrations.yardiEnabled && (
              <Row>
                <Col className="pb-5" xs="12">
                  <SubTitle>Yardi</SubTitle>
                  <Form.Group as={Row} controlId="destination.yardi.tenantCode">
                    <Form.Label column md="3">
                      Tenant code
                    </Form.Label>
                    <Col md="9" lg="6">
                      <Form.Control
                        type="text"
                        name="destination.yardi.tenantCode"
                        onChange={handleChange}
                        value={values.destination.yardi.tenantCode ?? ""}
                        maxLength={8}
                      />
                      <Form.Text className="text-muted">
                        Enter the Tenant code (T-code) for this company to use
                        on the Yardi bookings export.
                      </Form.Text>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <div className="mt-5 text-right">
              <CreateButton
                disabled={globalSaving || destinationSaving}
                type="submit"
              >
                {globalSaving || destinationSaving ? (
                  <span>
                    <Spinner animation="grow" size="sm" /> Saving...
                  </span>
                ) : (
                  "Save"
                )}
              </CreateButton>
            </div>
          </form>
        )}
      </Formik>
    </Container>
  );
};
