import React, { useState, useMemo, useContext } from "react";
import { Form } from "react-bootstrap";
import { UserFormHeader } from "./UserFormHeader";
import { UserFormImage } from "./UserFormImage";
import { UserFormMainDetails } from "./UserFormMainDetails";
import { UserFormProfile } from "./UserFormProfile";
import { UserFormCommunicationPreference } from "./UserFormCommunicationPreference";
import { UserFormSiteSelector } from "./UserFormSiteSelector";
import { ProfileDetailsQuery } from "../../../generated/admin";
import { UserFormContext } from "./context/UserFormContext";
import { UserFormHistory } from "./UserFormHistory";
import { UserFormSiteAccess } from "./UserFormSiteAccess";
import { UserFormRoles } from "./UserFormRoles";
import { UserFormAttributes } from "./UserFormAttributes";
import { UserContext } from "../UserContext";
import { UserFormSystemPermission } from "./system-permissions/UserFormSystemPermission";
import { RenderForSystemAdmin } from "../../../components/RenderForSystemAdmin";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { EvolutionPermission } from "./system-permissions/EvolutionPermission";
import { useConfig } from "../../../providers/ConfigProvider";
import { DebugInfo } from "./DebugInfo";
import { FormProvider, useForm } from "react-hook-form";
import { UserFormData } from "./model/UserFormData";

const noOp = () => {};

interface UserFormProps {
  user: ProfileDetailsQuery["user"];
  destinations: ProfileDetailsQuery["destinations"];
  closeFn?: () => void;
}

/**
 * User form.
 */
export const UserForm: React.FC<UserFormProps> = (props) => {
  const userContext = useContext(UserContext);
  const config = useConfig();
  const { user: currentUser } = props;
  const [activeSite, setActiveSite] = useState<string | null>(null);
  const methods = useForm<UserFormData>({
    defaultValues: {
      email: currentUser?.profile?.email,
      firstName: currentUser?.profile?.firstName ?? undefined,
      lastName: currentUser?.profile?.lastName ?? undefined,
    },
  });

  const value = useMemo(
    () => ({
      activeSite,
      user: props.user,
      destinations: props.destinations,
    }),
    [props, activeSite]
  );

  return (
    <FormProvider {...methods}>
      <UserFormContext.Provider value={value}>
        <Form data-testid="user-details-form">
          <UserFormHeader
            closeFn={props.closeFn != null ? props.closeFn : noOp}
          />

          <div className="p-4">
            <div className="d-flex">
              <UserFormImage />
              <UserFormMainDetails className="w-100 pl-4" />
            </div>
            <UserFormSiteSelector setActiveSite={setActiveSite} />
            {activeSite && (
              <>
                <UserFormProfile key={`profile-${activeSite}`} />

                <UserFormCommunicationPreference
                  key={`communication-${activeSite}`}
                />

                <UserFormRoles key={`roles-${activeSite}`} />

                <UserFormAttributes key={`attributes-${activeSite}`} />
              </>
            )}
            <UserFormHistory />

            <RenderForSystemAdmin>
              <UserFormSystemPermission
                permissions={currentUser?.profile?.allPermissions}
              />
            </RenderForSystemAdmin>
            {userContext?.activeDestination != null &&
              config.featureToggles.integrations &&
              userContext.activeDestinationDetails?.integrations?.evolution
                .enabled && (
                <ToggleContainer title="Integrations">
                  <EvolutionPermission />
                </ToggleContainer>
              )}
            {userContext?.activeDestination == null && <UserFormSiteAccess />}
            {userContext?.activeDestination == null && (
              <DebugInfo user={currentUser} />
            )}
          </div>
        </Form>
      </UserFormContext.Provider>
    </FormProvider>
  );
};
