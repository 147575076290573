import React from "react";
import { Button } from "react-bootstrap";
import { FaUndo } from "react-icons/fa";

/**
 * Select all button.
 * @todo: How to fix this any?
 */
export const SelectAllResetButton: React.FC<any> = (props) => {
  return (
    <Button
      {...props}
      className="p-0 ml-3 text-dark font-weight-bold"
      variant="link"
    >
      <FaUndo /> Reset
    </Button>
  );
};
