import React from "react";

export const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#7E7E7E"
      transform="translate(1 1)"
    >
      <circle cx="10" cy="10" r="10" strokeWidth="1.2" />
      <g strokeWidth="1.5" transform="translate(4 5)">
        <circle cx="6" cy="3" r="3" />
        <path d="M12 12.949C10.866 10.006 8.61 8 6.01 8 3.395 8 1.127 10.03 0 13" />
      </g>
    </g>
  </svg>
);
