import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { OptionType } from "../../../model/OptionType";
import { UserFormContext } from "./context/UserFormContext";
import { UserFormData, userFormNameCheck } from "./model/UserFormData";

type EquiemOneOptionType = OptionType;

interface Props {
  options: EquiemOneOptionType[];
}

/**
 * User form groups component.
 */
export const UserFormRolesDropdown: React.FC<Props> = ({ options }) => {
  const ctx = useContext(UserFormContext);
  const methods = useFormContext<UserFormData>();
  const siteProfile = ctx.user?.profile?.siteProfiles.edges.find((e) => {
    return e.node?.site.uuid === ctx.activeSite;
  });
  const selected: OptionType[] =
    siteProfile?.node?.groups?.map(({ uuid, name }) => {
      return {
        value: uuid,
        label: name,
      };
    }) ?? [];

  return (
    <Controller
      render={({field: { value, onChange }}) =>
        <Select<EquiemOneOptionType, boolean>
          isMulti
          menuPlacement="top"
          classNamePrefix="eq"
          options={options}
          formatOptionLabel={({ label }) => <>{label}</>}
          isClearable={true}
          placeholder="Select groups"
          className={`react-select`}
          onChange={onChange}
          value={value as any}
          />
        }
      defaultValue={selected.length > 0 ? selected : undefined}
      control={methods.control}
      name={userFormNameCheck("groups")}
    />
  );
};
