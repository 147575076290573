import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import {
  ApartmentListDocument,
  ApartmentListQueryVariables,
  ApartmentListQuery
} from "../../../generated/admin";
import AsyncSelect from "react-select/async";
import { OptionType } from "../../../model/OptionType";
import { onChangeSelected } from "./UserFormUserStatusDropdown";
import debounce from "debounce-promise";
import { useApolloClient } from "@apollo/client";

interface UserFormApartmentDropdownProps {
  name: string;
  defaultValue: OptionType | null;
  destinationUuid: string;
}

const rules = {
  required: "Required."
};

/**
 * Apartments dropdown.
 * @todo: need to seperate the logic from ui.
 */
export const UserFormApartmentDropdown: React.FC<UserFormApartmentDropdownProps> = (
  {name, ...props}
) => {
  const methods = useFormContext();
  const client = useApolloClient();

  const loadOptions = async (search: string) => {
    if (search.length < 1) {
      return [];
    }

    const result = await client.query<
      ApartmentListQuery,
      ApartmentListQueryVariables
    >({
      query: ApartmentListDocument,
      variables: {
        first: 100,
        search,
        destinationUuid: props.destinationUuid,
      }
    });

    return result.data.apartments.edges.flatMap((c) => {
      return c.node != null && c.node.name != null
        ? [
            {
              value: c.node.uuid,
              label: c.node.name
            }
          ]
        : [];
    });
  };
  // Loading debounce: https://github.com/JedWatson/react-select/issues/3075.
  const debouncedLoadOptions = debounce(loadOptions, 700, {
    leading: true
  });

  return (
    <div>
      <Controller
        render={() =>
          <AsyncSelect<OptionType>
            loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
            isSearchable
            classNamePrefix="eq"
            noOptionsMessage={(obj: { inputValue: string }) => {
              return obj.inputValue.length < 1
                ? "Search for an apartment"
                : "No options";
            }}
            placeholder="Select an apartment"
            className={`react-select${
              methods.formState.errors.name ? " is-invalid" : ""
            }`}
            onChange={onChangeSelected}
          />
        }
        rules={rules}
        control={methods.control}
        name={name}
        defaultValue={props.defaultValue ?? undefined}
      />
      {methods.formState.errors.name && (
        <Form.Control.Feedback type="invalid">
          {methods.formState.errors.name.message as string}
        </Form.Control.Feedback>
      )}
    </div>
  );
};
