import React from "react";
import { Divider } from "../../../../components/Divider";
import { SubTitle } from "../../../../components";
import { LevelsSelection } from "../../../../components/LevelsSelection";
import { useCurrentSiteLevels } from "./useCurrentSiteLevels";

export const ApartmentLevels: React.FC<ReturnType<
  typeof useCurrentSiteLevels
>> = ({ state, setBuildingLevels }) => {
  if (state.buildings.length === 0) {
    return null;
  }
  return (
    <>
      <Divider />
      <SubTitle>Levels</SubTitle>
      <p>
        It’s important to assign the apartment to the correct level in the
        building in order that content can be segmented to it. If you are unsure
        what level the apartment belongs to, first check and then come back and
        complete this form. If a level isn’t available in the below table,
        return to the Buildings section and add the level. If your site is
        comprised of multiple buildings, use the links at the top to toggle
        between buildings.
      </p>

      <LevelsSelection
        buildings={state.buildings}
        state={state}
        setState={setBuildingLevels}
      />
    </>
  );
};
