import React from "react";

/**
 * Catalog box.
 */
export const CatalogueBox: React.FC<{ title: string, children?: React.ReactNode }> = (props) => {
  return (
    <div className="border border-radius-10-px h-100">
      <div className="border-bottom border-dark text-center font-weight-bold py-2">
        {props.title}
      </div>
      <div className="pt-2">{props.children}</div>
    </div>
  );
};
