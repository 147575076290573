import React from "react";

export function FatListIcon() {
  return (
    <svg width="28px" height="28px" viewBox="0 0 25 23" version="1.1">
      <title>Thick button</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="View-density"
          transform="translate(-30.000000, 0.000000)"
          fill="#2600AA"
        >
          <g id="Thick-button" transform="translate(30.000000, 0.000000)">
            <rect
              id="Rectangle-Copy"
              x="0"
              y="16"
              width="25"
              height="7"
              rx="2"
            ></rect>
            <rect
              id="Rectangle-Copy-2"
              x="0"
              y="8"
              width="25"
              height="7"
              rx="2"
            ></rect>
            <rect
              id="Rectangle-Copy-3"
              x="0"
              y="0"
              width="25"
              height="7"
              rx="2"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}
