import React from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage, Field, FormikProps } from "formik";
import { renderErrorMessage } from "./renderErrorMessage";
import { validateRequired } from "./validateRequired";

interface Values {
  name?: string;
}

export const LeaseNameField = React.forwardRef<
  HTMLInputElement | undefined,
  Pick<FormikProps<Values>, "touched" | "errors">
>(({ touched, errors }, ref) => {
  const name: keyof Values = "name";

  return (
    <Form.Group>
      <Form.Label>Leasing entity *</Form.Label>
      <Field name={name} validate={validateRequired}>
        {({ field }: any) => (
          <Form.Control
            placeholder="the legal name of the lease"
            ref={ref}
            isInvalid={touched[name] ? errors[name] : undefined}
            {...field}
          ></Form.Control>
        )}
      </Field>
      <ErrorMessage name={name} render={renderErrorMessage} />
    </Form.Group>
  );
});
