import React from "react";
import { BuildingStoreProvider } from "../modules/buildings/list-view/BuildingStore";
import { LevelsStoreProvider } from "../modules/buildings/list-view/LevelsContext";
import { DeletedCompaniesStoreProvider } from "../modules/companies/list-view/merge-companies/OptionStore";
import { UpdatedUserStoreProvider } from "../modules/user/user-details/user-store/UpdatedUserStore";

// @todo: need to find a way to lazy load this provider.
export const InternalServiceProvider: React.FC<{children?: React.ReactNode}> = ({ children }) => (
  <BuildingStoreProvider>
    <LevelsStoreProvider>
      <DeletedCompaniesStoreProvider>
        <UpdatedUserStoreProvider>{children}</UpdatedUserStoreProvider>
      </DeletedCompaniesStoreProvider>
    </LevelsStoreProvider>
  </BuildingStoreProvider>
);
