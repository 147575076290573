import React from "react";

export interface UserListActionButton {
  onclick?: () => void;
  label?: string;
}

export const UserListActions: React.FC< { children?: React.ReactNode } > = (props) => (
  <div>{props.children}</div>
);
