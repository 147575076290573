import React from "react";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { TextFormGroup } from "../fields/TextFormGroup";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const General: React.FC<Props> = ({ methods }) => (
  <ToggleContainer testId="mobile-general-tab" className="lg" title="General">
    <div className="pb-4">
      <TextFormGroup
        title="iOS app id"
        name="iosAppId"
        subText="The id of the iOS version of the app."
        error={methods.errors.iosAppId}
        disabled
      />
      <TextFormGroup
        title="iOS app build id"
        name="iosAppBuildId"
        subText="The build id of the iOS version of the app."
        error={methods.errors.iosAppBuildId}
        disabled
      />
      <TextFormGroup
        title="Android app id"
        name="androidAppId"
        subText="The id of the Android version of the app."
        error={methods.errors.androidAppId}
        disabled
      />
    </div>
  </ToggleContainer>
);
