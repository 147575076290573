import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormikProps, useFormikContext } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const Bookings: React.FC<Props> = ({ methods }) => {
  const fm = useFormikContext<SiteConfigFormData>();
  if (methods.values.featureModules?.bookings == null) {
    return null;
  }

  const commissionErrorExist = stringNotEmpty(
    fm.errors.bookingModuleSettings?.commissionPercentage
  );

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between pb-2 border-bottom">
        <h4 className="font-weight-normal">Bookings</h4>
        <Form.Check
          name="featureModules.bookings.enabled"
          type="switch"
          label=""
          id="featureModules.bookings.enabled"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            methods.setFieldValue(
              "featureModules.bookings.enabled",
              e.target.checked
            );
          }}
          defaultChecked={methods.values.featureModules.bookings.enabled}
        />
      </div>
      {fm.values.featureModules?.bookings.enabled === true && (
        <div>
          <div className="pt-4">
            <Form.Group controlId="bookingsEnableMenu" as={Row}>
              <Form.Label column md="3" lg="2">
                Enable End User Access
              </Form.Label>
              <Col className="pt-1">
                <div className="d-flex align-items-center">
                  <Form.Check
                    name="featureModules.bookings.enableMenu"
                    type="switch"
                    label=""
                    id="featureModules.bookings.enableMenu"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      methods.setFieldValue(
                        "featureModules.bookings.enableMenu",
                        e.target.checked
                      );
                    }}
                    defaultChecked={
                      methods.values.featureModules.bookings.enableMenu
                    }
                  />
                </div>
                <Form.Text className="text-muted">
                  Once enabled, a link to the module will be shown in the
                  user-facing menu on both web and mobile. If disabled,
                  end-users will still be able to access the module directly.
                </Form.Text>
              </Col>
            </Form.Group>
          </div>
          <div className="pt-4">
            <h5 className="mb-4">Payments</h5>
            <Form.Group controlId="commissionPercentage" as={Row}>
              <Form.Label column md="3" lg="2">
                Commission
              </Form.Label>

              <Col>
                <div className="d-flex align-items-center">
                  <Form.Control
                    style={{ width: "100px" }}
                    type="number"
                    isInvalid={commissionErrorExist}
                    onChange={(e) => {
                      const raw = e.target.value;
                      const val = Number(raw);
                      if (isNaN(val)) {
                        return null;
                      }

                      if (val < 0) {
                        methods.setFieldValue(
                          "bookingModuleSettings.commissionPercentage",
                          0
                        );
                        return;
                      }

                      if (val > 100) {
                        methods.setFieldValue(
                          "bookingModuleSettings.commissionPercentage",
                          100
                        );
                        return;
                      }

                      const processed = raw.includes(".")
                        ? val.toFixed(1)
                        : val;

                      methods.setFieldValue(
                        "bookingModuleSettings.commissionPercentage",
                        processed
                      );
                    }}
                    step="0.1"
                    value={fm.values.bookingModuleSettings.commissionPercentage}
                  />
                  %
                </div>
                {commissionErrorExist && (
                  <div className="text-warning">
                    <small>
                      {fm.errors.bookingModuleSettings?.commissionPercentage}
                    </small>
                  </div>
                )}
                <Form.Text className="text-muted">
                  The commission is the amount Equiem retains for each
                  transaction and applies to credit card payments only.
                </Form.Text>
              </Col>
            </Form.Group>
          </div>
          <div className="pt-4">
            <h5 className="mb-4">Yardi</h5>
            <Form.Group controlId="yardiEnabled" as={Row}>
              <Form.Label column md="3" lg="2">
                Enable Yardi
              </Form.Label>

              <Col>
                <div className="d-flex align-items-center">
                  <Form.Check
                    name="featureModules.bookings.yardiEnabled"
                    type="switch"
                    label=""
                    id="featureModules.bookings.yardiEnabled"
                    checked={fm.values.bookingModuleSettings.yardiEnabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      methods.setFieldValue(
                        "bookingModuleSettings.yardiEnabled",
                        e.target.checked
                      );
                    }}
                  />
                </div>
                <Form.Text className="text-muted">
                  Once enabled, this will allow Yardi data to be stored against
                  buildings and companies, for the purpose of generating a Yardi
                  export for bookings at your site.
                </Form.Text>
              </Col>
            </Form.Group>
          </div>
        </div>
      )}
    </div>
  );
};
