import React, { useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SearchBar } from "../../../admin-components/Searchbar";
import { CreateButton } from "../../../components/CreateButton";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { useDeleteBuildingMutation } from "../../../generated/admin";
import { Link } from "react-router-dom";
import { RootRoutes } from "../../../RouterOutlet";
import { Levels } from "./Levels";
import { ActionButton } from "../../../components/TableButton";
import { ArrayPagination } from "../../../components/Pagination";
import { FlushRightTable } from "../../../components/FlushRightTable";
import { SubTitle } from "../../../components";
import { Sorter } from "../../../components/Sorter";
import { deleteCheck } from "../../../components/deleteCheck";
import { EqMessageSuccess, EqMessageError } from "../../message/EqMessage";
import { useBuildingStore } from "./BuildingStore";
import { PageHeading } from "../../../components/PageHeading";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { useUser } from "../../user/UserContext";
import { useTableSearch } from "../../../hooks/useTableSearch";

export const BuildingList = () => {
  const ctxHelper = useSiteContextHelper();
  const user = useUser();
  const store = useBuildingStore();
  const [action, { loading }] = useDeleteBuildingMutation();

  const {
    defaultSortQuery,
    onTableSort
  } = useTableSearch({ defaultSortFieldName: "name" });

  const [sort, setSort] = React.useState<{
    field: string;
    asc: boolean;
  } | null >(defaultSortQuery);

  const fields = useMemo(() => [
    { key: "name", label: "Building" },
    { key: "destination.name", label: "Site" },
    { key: "area", label: "Area" },
    { key: "occupants", label: "Occupants" }
  ], []);

  const sorter = useMemo(() => new Sorter(
    user.activeDestination == null
      ? fields
      : fields.filter((f) => f.key !== "destination.name"),
    sort,
    setSort,
    onTableSort
  ), [fields, onTableSort, sort, user.activeDestination]);

  const sorted = useMemo(() =>sorter.sortItems(store.filteredBuildings.slice()), [sorter, store.filteredBuildings]);

  return (
    <>
      <PageHeading title="Buildings"></PageHeading>
      <Container>
        <Row>
          <Col>
            <SubTitle>Manage Buildings</SubTitle>
          </Col>
        </Row>
        <Row className="justify-content-between pb-4">
          <Col md="7" lg="4">
            <SearchBar
              value={store.searchTerm}
              clear={() => store.setSearch("")}
              onChange={(e) =>
                store.setSearch(e.currentTarget.value.toLowerCase())
              }
              placeholder={"Search for buildings or sites..."}
            />
          </Col>
          <Col className="py-3 py-md-0" md="4" lg="3">
            <Link to={ctxHelper.appendUrl(RootRoutes.BUILDINGS + "/create")}>
              <CreateButton>Create New Building</CreateButton>
            </Link>
          </Col>
        </Row>
        <LoadingOrErrorDisplay loading={store.loading} error={store.error}>
          <ArrayPagination urlSearchParams={true} items={sorted}>
            {(items) => (
              <FlushRightTable>
                <sorter.Heading>
                  <th>Operations</th>
                </sorter.Heading>
                <tbody>
                  {items.length === 0 ? (
                    <tr className="bg-light">
                      <td colSpan={sorter.fields.length}>
                        Search retrieved no results.
                      </td>
                      <td></td>
                    </tr>
                  ) : (
                    items.map((building) => (
                      <tr key={building.uuid}>
                        <td>{building.name}</td>
                        {user.activeDestination != null ? null : (
                          <td>{building.destination?.name}</td>
                        )}
                        <td>{building.area}</td>
                        <td>{building.occupants}</td>
                        <td>
                          <Link
                            className="action-button"
                            to={ctxHelper.appendUrl(
                              RootRoutes.BUILDINGS + "/edit/" + building.uuid
                            )}
                          >
                            Edit
                          </Link>{" "}
                          |{" "}
                          <ActionButton
                            actionLabel="Deleting..."
                            restLabel="Delete"
                            isInAction={loading}
                            onClick={async () => {
                              await deleteCheck(
                                async () => {
                                  const result = await action({
                                    variables: {
                                      uuid: building.uuid
                                    }
                                  });
                                  if (result.data?.deleteBuilding) {
                                    store.removeNode(building.uuid);
                                    EqMessageSuccess({
                                      text: "Building has been deleted!"
                                    });
                                  } else {
                                    EqMessageError({
                                      text: "Failed to delete building"
                                    });
                                  }
                                },
                                { confirmButtonText: "Delete building" }
                              );
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </FlushRightTable>
            )}
          </ArrayPagination>
        </LoadingOrErrorDisplay>
        <Levels />
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};
