import React from "react";
import { Link, useParams } from "react-router-dom";
import { PageHeading } from "../../../components/PageHeading";
import { PlatformType } from "../../../generated/admin";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { RootRoutes } from "../../../RouterOutlet";
import { SiteBreadCrumb } from "../SiteBreadCrumb";
import { HomeUIWidgetForm } from "./HomeUIWidgetForm";

interface Props {
  platform: PlatformType;
}

export const HomeUIWidgetModule: React.FC<Props> = ({ platform }) => {
  const { uuid } = useParams<{ uuid: string }>();
  const siteCtxHelper = useSiteContextHelper();
  const title = `${
    platform === PlatformType.Web ? "Homepage" : "Home Screen"
  } Configuration`;

  const setting =
    platform === PlatformType.Web ? (
      <Link to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${uuid}/web`)}>
        Web Settings
      </Link>
    ) : (
      <Link to={siteCtxHelper.appendUrl(`${RootRoutes.SITES}/${uuid}/mobile`)}>
        Mobile App Settings
      </Link>
    );

  return (
    <>
      <SiteBreadCrumb
        className="pb-3"
        siteUuid={uuid!}
        paths={[setting, <span>{title}</span>]}
      />
      <PageHeading title={title} />

      <HomeUIWidgetForm
        uuid={uuid!}
        platform={platform}
        showLogo={platform === PlatformType.Web}
        showFooter={platform === PlatformType.Web}
        className="pb-5"
      />
    </>
  );
};
