import React, { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { SiteConfigFormData } from "../../SiteConfigFormData";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { TextFormGroup } from "../../fields/TextFormGroup";
import { OptionType } from "../../../../../model/OptionType";
import Select from "react-select";
import {
  GetCafmConfigurationsBySiteUuidDocument,
  ReqMgtCafmConfigurationType,
  useUpsertCafmConfigurationMutation,
} from "../../../../../generated/admin";
import {
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";
import { stringNotEmpty } from "../../../../../util/stringNotEmpty";
import { RequestManagementConnectionList } from "./RequestManagementConnectionList";
import {
  cafmProviderOptions,
  NewAccessControlConnectionFormData,
  translateProviderSettingsFormValuesToGraphQL,
  validateNewRequestManagementConnectionForm,
} from "./RequestManagementConnectionAuxiliary";
import { Password } from "../../mobile/Password";

interface Props {
  siteFormData: FormikProps<SiteConfigFormData>;
}

export const NewRequestManagementConnection: React.FC<Props> = ({
  siteFormData,
}) => {
  const [save, { loading: loadingCreateConfig }] =
    useUpsertCafmConfigurationMutation();

  const getInitialValues = (): NewAccessControlConnectionFormData => {
    return {
      cafmProviderType: null,
    };
  };

  const createConfig = useCallback(
    async (values: NewAccessControlConnectionFormData, siteUuid: string) => {
      if (
        !stringNotEmpty(values.name) ||
        !stringNotEmpty(values.endpoint) ||
        values.cafmProviderType == null
      ) {
        return;
      }

      try {
        const result = await save({
          variables: {
            input: {
              user: values.username,
              password: values.password,
              name: values.name,
              endpoint: values.endpoint!,
              siteUuid: siteUuid,
              type: values.cafmProviderType.value,
              cafmProviderSettings:
                translateProviderSettingsFormValuesToGraphQL(values),
            },
          },
          refetchQueries: [
            {
              query: GetCafmConfigurationsBySiteUuidDocument,
              variables: {
                siteUuid: siteUuid,
              },
            },
          ],
        });
        if (!result.data?.reqMgt.upsertCafmConfiguration.name) {
          throw new Error("Unknown error response from server.");
        }
        EqMessageSuccess({
          text: "Successfully saved request management cafm provider connection.",
        });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [save]
  );

  return (
    <div>
      <Formik<NewAccessControlConnectionFormData>
        initialValues={getInitialValues()}
        onSubmit={(values, { resetForm }) => {
          console.log(siteFormData.values.destinationUuid);
          createConfig(values, siteFormData.values.destinationUuid);
          resetForm({ values: getInitialValues() });
        }}
        validate={validateNewRequestManagementConnectionForm}
      >
        {(methods) => (
          <>
            <Row className="pt-3">
              <Col>
                <h5 className="font-weight-normal">
                  Request Management Connection
                </h5>
              </Col>
            </Row>

            <Row className="pt-3">
              <Col>
                <h5>New Connection</h5>
              </Col>
            </Row>
            <div className="border p-4 mb-2">
              <p className="pb-4 pt-3">
                Set up an provider connection to enable CAFM integration for
                Request Management.
              </p>
              <TextFormGroup
                title={"Name"}
                subText={"The unique name for the CAFM provider connection."}
                name={"name"}
                error={methods.errors.name}
              />
              <Form.Group as={Row} controlId="newCafmConnProviderOptions">
                <Form.Label column md="3">
                  {"Provider types"}
                </Form.Label>
                <Col md="9" lg="6" className="site-client-select">
                  <Select<OptionType<ReqMgtCafmConfigurationType>, boolean>
                    classNamePrefix="eq"
                    isSearchable
                    options={cafmProviderOptions}
                    onChange={(option) => {
                      methods.setFieldValue("cafmProviderType", option);
                    }}
                    placeholder="Select..."
                    aria-label="site-client-select"
                    className={`react-select${
                      methods.errors.cafmProviderType != null
                        ? " is-invalid"
                        : ""
                    }`}
                  />
                  <Form.Text className="text-muted">
                    Select provider type to use with this connection.
                  </Form.Text>
                  {methods.errors.cafmProviderType &&
                    methods.touched.cafmProviderType && (
                      <Form.Control.Feedback type="invalid">
                        {methods.errors.cafmProviderType}
                      </Form.Control.Feedback>
                    )}
                </Col>
              </Form.Group>
              <TextFormGroup
                title={"End point"}
                error={methods.errors.endpoint}
                subText={"Enter the end point"}
                name={"endpoint"}
              />
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.Simpro && (
                <TextFormGroup
                  title={"Simpro Site Id"}
                  error={methods.errors.simproSiteId}
                  subText={"Enter Simpro Site Id"}
                  name={"simproSiteId"}
                />
              )}
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.ConceptEvolution && (
                <>
                  <TextFormGroup
                    title={"Create method name"}
                    error={methods.errors.conceptEvolutionCreateMethodName}
                    subText={"Enter Concept Evolution create method name"}
                    name={"conceptEvolutionCreateMethodName"}
                  />
                  <TextFormGroup
                    title={"Update method name"}
                    error={methods.errors.conceptEvolutionUpdateMethodName}
                    subText={"Enter Concept Evolution update method name"}
                    name={"conceptEvolutionUpdateMethodName"}
                  />
                </>
              )}
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.Maximo && (
                <>
                  <TextFormGroup
                    title={"Create method name"}
                    error={methods.errors.maximoCreateMethodName}
                    subText={"Enter Maximo create method name"}
                    name={"maximoCreateMethodName"}
                  />
                  <TextFormGroup
                    title={"Update method name"}
                    error={methods.errors.maximoUpdateMethodName}
                    subText={"Enter Maximo update method name"}
                    name={"maximoUpdateMethodName"}
                  />
                </>
              )}
              {methods.values.cafmProviderType?.value ===
                ReqMgtCafmConfigurationType.Elogbooks && (
                <>
                  <TextFormGroup
                    title={"Service provider name"}
                    error={methods.errors.elogbooksServiceProviderName}
                    subText={"Enter Elogbooks service provider name"}
                    name={"elogbooksServiceProviderName"}
                  />
                  <TextFormGroup
                    title={"Site name"}
                    error={methods.errors.elogbooksSiteName}
                    subText={"Enter Elogbooks site name"}
                    name={"elogbooksSiteName"}
                  />
                  <TextFormGroup
                    title={"Priority name"}
                    error={methods.errors.elogbooksPriorityName}
                    subText={"Enter Elogbooks priority name"}
                    name={"elogbooksPriorityName"}
                  />
                </>
              )}
              <TextFormGroup title={"Username"} name={"username"} />
              <Form.Group as={Row}>
                <Form.Label column md="3" lg="3">
                  Password
                </Form.Label>
                <Col md="9" lg="6">
                  <Password
                    name="password"
                    onPasswordChange={(e) => {
                      void methods.setFieldValue("password", e.target.value);
                    }}
                    loading={false}
                  />
                </Col>
              </Form.Group>
              <div className="text-right">
                <Button
                  name="createNewCafmConn"
                  variant="outline-primary"
                  size="sm"
                  className="align-self-center m-2"
                  onClick={() => methods.handleSubmit()}
                  disabled={!methods.isValid || !methods.dirty}
                >
                  {loadingCreateConfig ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Connect account"
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
      <RequestManagementConnectionList siteFormData={siteFormData} />
    </div>
  );
};
