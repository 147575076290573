import React, { useState } from "react";
import { CapsuleButton } from "../../../components/CapsuleButton";
import { FaBolt } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { UserStore } from "../user-details/user-store/UserStore";
import { SelectedUserForm } from "./operations/SelectedUserForm";
import { EqMessageWarning } from "../../message/EqMessage";

export const UserOperations: React.FC<{
  selected: string[];
  store: UserStore;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ selected, store, setSelected }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (selected.length === 0) {
      EqMessageWarning({
        text: "No users have been selected. You must first select the user checkboxes before performing this operation.",
      });
      return;
    }
    setShow(true);
  };

  return (
    <>
      <CapsuleButton onClick={handleShow} data-testid="updateStatusButton">
        <FaBolt className="user-icon-align-top"></FaBolt>
        <span>&nbsp;Operations</span>
      </CapsuleButton>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="pl-3">Operations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectedUserForm
            store={store}
            selected={selected}
            onSuccess={() => setSelected([])}
            onCancel={() => setShow(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
