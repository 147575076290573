import React from "react";

/**
 * Page heading.
 */
export const PageHeading: React.FC<{
  title: string | JSX.Element;
  subHeading?: JSX.Element;
  isSticky?: boolean;
}> = ({ title, isSticky = false, subHeading }) => {
  return (
    <div
      className={`${isSticky ? "zindex-10 sticky-top bg-white" : "pb-5 mt-n3"}`}
    >
      <div className="my-3 pl-3">
        <h1 className="text-primary font-weight-light m-0">{title}</h1>
      </div>
      <hr className="mb-0 bg-secondary" />
      {subHeading}
    </div>
  );
};
