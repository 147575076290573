import React, { useState } from "react";
import { Form, Row, Tab, Tabs } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FaLink } from "react-icons/fa";
import styled from "styled-components";
import { AddToIris } from "./AddToIris";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { ProfileFragmentFragment, Role } from "../../../../generated/admin";
import { useConfig } from "../../../../providers/ConfigProvider";
import { EqCol } from "../../../buildings/detail-view/BuildingDetailsGeneral";
import { UserFormData, userFormNameCheck } from "../model/UserFormData";
import { StoreSuperAdminPermission } from "./StoreSuperAdminPermission";

interface CheckinPermission {
  name: string;
  label: string;
  key: Role;
  selected: boolean;
}

const checkinPermissions: CheckinPermission[] = [
  {
    label: "Access attendee reports",
    name: "access attendee reports",
    key: Role.AccessAttendeeReport,
    selected: false,
  },
];

const GoToSystemButton = styled(FaLink)`
  margin-top: -2px;
`;

const GoToSystemTabTitle: React.FC<{ title: string; url: string }> = ({
  title,
  url,
}) => (
  <span>
    {title}
    <small className="ml-2">
      <GoToSystemButton onClick={() => window.open(url, "_blank")} />
    </small>
  </span>
);

/**
 * User system permission form component.
 */
export const UserFormSystemPermission: React.FC<{
  permissions: ProfileFragmentFragment["allPermissions"];
}> = ({ permissions }) => {
  const config = useConfig();
  const userPermissions = permissions?.map((p) => p.key);
  const [key, setKey] = useState("checkin");
  const methods = useFormContext<UserFormData>();
  const [allPermissions, setAllPermissions] = useState(
    checkinPermissions.map((p) => ({
      ...p,
      selected: userPermissions?.includes(p.key) ? true : false,
    }))
  );

  return (
    <ToggleContainer title="System permissions">
      <div>
        <p>
          To update a permission for a particular system, click on the system
          link icon and you will be redirected to the system to update the
          user’s permissions.
        </p>
      </div>
      <div>
        <Tabs
          className="eq-tabs mt-4"
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k!)}
        >
          <Tab
            eventKey="checkin"
            title={
              <GoToSystemTabTitle
                title="Checkin"
                url={config.permissionsSystem.checkinUrl}
              />
            }
          >
            <Row className="mt-3 px-3">
              {allPermissions.map((permission) => (
                <EqCol
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                  key={permission.key}
                >
                  <Form.Check
                    type="checkbox"
                    label={permission.label}
                    value={permission.key}
                    id={`checkbox-${permission.key}`}
                    checked={permission.selected}
                    onClick={() => {
                      setAllPermissions(
                        allPermissions.map((p) => ({
                          ...p,
                          selected: !permission.selected,
                        }))
                      );
                    }}
                    {...methods.register(userFormNameCheck("newWorldPermissions"))}
                  />
                </EqCol>
              ))}
            </Row>
          </Tab>
          <Tab
            eventKey="iris"
            title={
              <GoToSystemTabTitle
                title="Iris"
                url={config.permissionsSystem.irisUrl}
              />
            }
          >
            <Row className="mt-3 px-3">
              <AddToIris />
            </Row>
          </Tab>
          <Tab
            eventKey="vms"
            title={
              <GoToSystemTabTitle
                title="VMS"
                url={config.permissionsSystem.vmsUrl}
              />
            }
          >
            <Row className="mt-3 px-3">
              <EqCol
                style={{
                  paddingRight: "0px",
                  paddingLeft: "0px",
                }}
              >
                <StoreSuperAdminPermission />
              </EqCol>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </ToggleContainer>
  );
};
