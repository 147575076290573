import React, { useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";

import { DestinationLevelsQuery } from "../../../generated/admin";
import { SubTitle } from "../../../components";
import { AddressInputField } from "../../../components/AddressInputField";
import { UserContext } from "../../user/UserContext";
import { OptionType } from "../../../model/OptionType";
import { Divider } from "../../../components/Divider";
import { BuildingDetailsTabProps } from "./BuildingDetailsTabProps";
import { BuildingLevelsTable } from "./BuildingLevelsTable";
import { Areas } from "./areas/Areas";

export type Area = NonNullable<
  DestinationLevelsQuery["destination"]["areas"]
>[0];

const Checkbox = styled(Form.Check)`
  z-index: unset;
`;

const getAllLevels = ({
  levels,
  building,
}: Pick<BuildingDetailsTabProps, "levels" | "building">) => {
  const levelNames = levels.map((l) => l.name);
  const blNames = building?.buildingLevels.map((bl) => bl.name) ?? [];
  const allNames = [...levelNames, ...blNames];
  const uniqueNames = Array.from(new Set(allNames));
  return uniqueNames.map((name) => ({ name }));
};

export const BuildingDetailsGeneral = ({
  levels,
  sites,
  building,
  state,
  setState,
  touched,
  setTouched,
  errors,
}: BuildingDetailsTabProps) => {
  const user = useContext(UserContext);
  const allLevels = getAllLevels({ levels, building });
  const unitOptions = [
    { value: "square feet", label: "Square Feet" },
    { value: "square meters", label: "Square Meters" },
  ];
  const siteOptions = sites.map((site) => ({
    value: site.uuid,
    label: site.name,
  }));

  return (
    <>
      <SubTitle>Building Details</SubTitle>
      {user?.activeDestination != null ? null : (
        <Form.Group controlId="destinationUuid" as={Row}>
          <Form.Label column md="3" lg="2">
            Site
          </Form.Label>
          <Col md="9" lg="6">
            <Select<OptionType>
              classNamePrefix="eq"
              aria-label="site-list"
              required
              options={siteOptions}
              className={`react-select site-list ${
                touched.destinationUuid && errors.destinationUuid != null
                  ? "is-invalid"
                  : ""
              }`}
              isDisabled={state.uuid != null}
              onChange={(value: any) => {
                setTouched({ ...touched, destinationUuid: true });
                setState({
                  ...state,
                  destinationUuid: (value as OptionType).value,
                });
              }}
              onBlur={() => {
                setTouched({ ...touched, destinationUuid: true });
              }}
              defaultValue={siteOptions.find(
                (x) => x.value === state.destinationUuid
              )}
            />
            {touched.destinationUuid && errors.destinationUuid != null && (
              <Form.Control.Feedback type="invalid">
                {errors.destinationUuid}
              </Form.Control.Feedback>
            )}
            {state.uuid != null ? (
              <Form.Text className="text-muted">
                A site cannot be disassociated with a building once it has been
                created. Delete the building first and create a new entry.
              </Form.Text>
            ) : null}
          </Col>
        </Form.Group>
      )}

      <Form.Group controlId="name" as={Row}>
        <Form.Label column md="3" lg="2">
          Name
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            required
            placeholder="e.g. Tower 1"
            name="name"
            isInvalid={touched.name && errors.name != null}
            value={state.name}
            onBlur={() => setTouched({ ...touched, name: true })}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTouched({ ...touched, name: true });
              setState({ ...state, name: e.target.value });
            }}
          />

          {errors.name != null && touched.name && (
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Form.Group className="pl-0" as={Col} controlId="hasBuildingAddress">
        <Checkbox
          type="switch"
          label="Enable a building address"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTouched({ ...touched, buildingAddress: false });
            setState({
              ...state,
              buildingAddress: e.target.checked
                ? {
                    address: "",
                    streetName: null,
                    city: null,
                    postcode: null,
                    state: null,
                    country: null,
                    latitude: building?.latitude ?? 0,
                    longitude: building?.longitude ?? 0,
                    timezone: building?.timezone ?? "",
                  }
                : null,
            });
          }}
          defaultChecked={building?.hasBuildingAddress ?? false}
        />
        <Form.Text className="text-muted">
          This enables you to set a separate address to the main address used
          for the site.
        </Form.Text>
      </Form.Group>

      {state.buildingAddress != null && (
        <Form.Group controlId="buildingAddress" as={Row}>
          <Form.Label column md="3" lg="2">
            Address
          </Form.Label>

          <Col md="9" lg="6">
            <AddressInputField
              name="buildingAddress"
              value={state.buildingAddress}
              touched={touched.buildingAddress}
              error={errors.buildingAddress}
              onChange={(newVal) => {
                setTouched({ ...touched, buildingAddress: true });
                setState({ ...state, buildingAddress: newVal });
              }}
            />
          </Col>
        </Form.Group>
      )}

      <Form.Group controlId="buildingArea" as={Row}>
        <Form.Label column md="3" lg="2">
          Area
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            required
            type="number"
            name="buildingArea"
            value={String(state.area)}
            isInvalid={errors.area != null}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setState({ ...state, area: Number(e.target.value) });
            }}
          />
          {errors.area != null && (
            <Form.Control.Feedback type="invalid">
              {errors.area}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Form.Group controlId="units" as={Row}>
        <Form.Label column md="3" lg="2">
          Units
        </Form.Label>

        <Col md="9" lg="6">
          <Select<OptionType>
            classNamePrefix="eq"
            options={unitOptions}
            className="react-select"
            onChange={(value: any) => {
              setState({ ...state, units: (value as OptionType).value });
            }}
            defaultValue={unitOptions.find((x) => x.value === state.units)}
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="occupants" as={Row}>
        <Form.Label column md="3" lg="2">
          Occupants
        </Form.Label>

        <Col md="9" lg="6">
          <Form.Control
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setState({ ...state, occupants: Number(e.target.value) });
            }}
            value={String(state.occupants)}
            required
            type="number"
            isInvalid={errors.occupants != null}
            name="occupants"
          />
          {errors.occupants != null && (
            <Form.Control.Feedback type="invalid">
              {errors.occupants}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Divider />

      <SubTitle>Levels</SubTitle>
      <p>
        Levels displayed below are the associations with the current building.
        If a level is not available for selection, add it to the catalogue of
        levels, at which point it will be available here.
      </p>

      <BuildingLevelsTable
        levels={allLevels}
        state={state}
        setState={setState}
        building={building}
      />

      <Divider />
      <Areas building={building} />
    </>
  );
};

export const EqCol = styled(Col)`
  &&& label {
    color: var(--dark);
  }
  &&& input {
    color: var(--dark);
  }
  font-size: 14px;
  line-height: 28px;
`;
