import React from "react";
import { Routes, Route } from "react-router-dom";
import { EditWhitelabelDetails } from "./detail-view/EditWhitelabelDetails";
import { CreateWhitelabelApp } from "./detail-view/CreateWhitelabelApp";
import { WhitelabelList } from "./list-view/WhitelabelList";

export const WhitelabelModule = () => {
  return (
    <Routes>
      <Route path={`/edit/:uuid`} element={<EditWhitelabelDetails />} />
      <Route path={`/create`} element={<CreateWhitelabelApp />} />
      <Route path="*" element={<WhitelabelList />} />
    </Routes>
  );
};
