import React, { useEffect, useState } from "react";
import {
  useBuildingDetailsQuery, useDestinationLevelsLazyQuery
} from "../../../../generated/admin";
import { SubTitle } from "../../../../components";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { Heading1 } from "../../../../components/text";
import { AreaTable } from "./AreaTable";
import { AreaForm } from "./AreaForm";
import { Modal } from "react-bootstrap";
import { CapsuleButton } from "../../../../components/CapsuleButton";

export const Areas = ({
  building
}: {
  building?: NonNullable<
    ReturnType<typeof useBuildingDetailsQuery>["data"]
  >["building"];
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [query, { data, loading, error }] = useDestinationLevelsLazyQuery();

  useEffect(() => {
    if (building?.destination?.uuid != null) {
      query({ variables: { uuid: building.destination.uuid } });
    }
  }, [query, building])

  return (
    <>
      <SubTitle>Areas</SubTitle>

      {building?.destination?.uuid != null ? (
        <>
          <p>
            Areas can be created to group levels together, within a single
            building, or across multiple buildings. These can be used for
            segmentation purposes.
          </p>
          <LoadingOrErrorDisplay loading={loading} error={error}>
            {data?.destination != null && (
              <>
                <div className="pb-4">
                  <CapsuleButton onClick={handleShow}>
                    Add new
                  </CapsuleButton>
                </div>
                <Modal size="xl" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add a new area</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <AreaForm
                      destination={data?.destination}
                      submitCallback={handleClose}
                    />
                  </Modal.Body>
                </Modal>
              </>
            )}

            <Heading1>Existing areas</Heading1>
            <p>
              The following areas are currently associated with the
              building.
            </p>

            <AreaTable data={data!} />
          </LoadingOrErrorDisplay>
        </>
      ) : (
        <p>
          Areas will be available for creation once the building and levels have
          been established.
        </p>
      )}
    </>
  );
};
