import React, { useContext, useState, useCallback, useEffect } from "react";
import {
  ProfileRegistrationType, useSiteListLazyQuery,
} from "../../../generated/admin";
import { useConfig } from "../../../providers/ConfigProvider";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { DestinationsCheckbox } from "../../../components/DestinationsCheckbox";
import { UserFormContext } from "./context/UserFormContext";
import { UserFormData, userFormNameCheck } from "./model/UserFormData";
import { SearchBar } from "../../../admin-components/Searchbar";
import useDebounce from "../../../hooks/useDebounce";
import { useFormContext } from "react-hook-form";

interface Props {
  showToggle?: boolean;
}

/**
 * User form site access.
 */
export const UserFormSiteAccess: React.FC<Props> = ({ showToggle }) => {
  const config = useConfig();
  const ctx = useContext(UserFormContext);
  const formContext = useFormContext<UserFormData>();
  const [search, setSearch] = useState<string | undefined>();
  const clear = useCallback(() => setSearch(""), [setSearch]);
  const [query, { data, loading, error }] = useSiteListLazyQuery();
  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
       setSearch(e.currentTarget.value)
    },
    [setSearch]
  );
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    formContext.setValue("siteSearchKeyword", debouncedSearchTerm)
  }, [debouncedSearchTerm, formContext])

  useEffect(() => {
    query({
      variables: {
        installation: config.installation,
        name: debouncedSearchTerm ?? "",
      },
      fetchPolicy: "network-only",
    })
  }, [query, config.installation, debouncedSearchTerm])

  const hasNonCommercialProfile = !!ctx.user?.profile?.siteProfiles.edges.find(
    (e) => {
      return e.node?.registrationType !== ProfileRegistrationType.Commercial;
    }
  );

  const selectedUuids =
    ctx.user?.destinationsV2?.edges.map((d) => d.node?.destination.uuid) ?? [];

  return (
    <ToggleContainer title="Site access" show={showToggle ?? false}>
      <div className="pb-4">
        <p>
          Sites shown below are all sites that the user is associated with.
          Adding a new site will create a new profile for that particular site
          and will be available in the site selector drop-down after saving.
        </p>

        <div className="pb-2 site-access-picker">
          <SearchBar
            clear={clear}
            value={search}
            onChange={onSearchChange}
            placeholder={"Search by sites or clients..."}
          />
        </div>

        {hasNonCommercialProfile ? (
          <div className="pb-2">
            <div className="mt-3 alert alert-danger">
              This user has a visitor or residential profile. They cannot have
              sites added or removed.
            </div>
          </div>
        ) : null}
        <LoadingOrErrorDisplay loading={loading} error={error}>
          {data != null && (
            <DestinationsCheckbox
              destinations={data.searchDestinations.map((d) => ({
                uuid: d.uuid,
                name: d.name,
              }))}
              selectedUuids={selectedUuids}
              checkboxName={userFormNameCheck("sites")}
              totalColumnDisplay={3}
              disabled={hasNonCommercialProfile}
            />
          )}
        </LoadingOrErrorDisplay>
      </div>
    </ToggleContainer>
  );
};
