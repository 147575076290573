import React from "react";
import {
  ApartmentListDocument,
  useDeleteApartmentMutation
} from "../../../generated/admin";
import { ActionButton } from "../../../components/TableButton";
import { deleteCheck } from "../../../components/deleteCheck";
import {
  EqMessageWarning,
  EqMessageSuccess,
  EqMessageError
} from "../../message/EqMessage";
import { Maybe } from "graphql/jsutils/Maybe";

interface ApartmentDeleteProps {
  apartmentUuid: string;
  allowDelete: boolean;
  searchTerm: string;
  activeDestination?: Maybe<string>;
}

/**
 * Apartment delete for apartment list.
 */
export const ApartmentDelete: React.FC<ApartmentDeleteProps> = ({
  allowDelete,
  apartmentUuid,
  searchTerm,
  activeDestination
}) => {
  const [action, { loading }] = useDeleteApartmentMutation();
  if (allowDelete) {
    return (
      <ActionButton
        actionLabel="Deleting..."
        restLabel="Delete"
        isInAction={false}
        onClick={async () => {
          EqMessageWarning({
            text:
              "This apartment cannot be deleted as it is currently being used at one or more sites."
          });
        }}
      />
    );
  }

  const deleteText = activeDestination
    ? `
      Once deleted, the apartment will no longer be available in registration
      and the users associated with this apartment will have their site profile removed.
      If they have a single site access, their account will be deactivated.
    `
    : "Once deleted, you cannot retrieve this record.";

  return (
    <ActionButton
      actionLabel="Deleting..."
      restLabel="Delete"
      isInAction={loading}
      onClick={async () => {
        await deleteCheck(
          async () => {
            const result = await action({
              variables: {
                uuid: apartmentUuid,
                destinationUuid: activeDestination
              },
              refetchQueries: [
                {
                  query: ApartmentListDocument,
                  variables: {
                    search: searchTerm
                  }
                }
              ]
            });
            if (result.data?.deleteApartment) {
              EqMessageSuccess({
                text: "Apartment has been deleted!"
              });
            } else {
              EqMessageError({
                text: "Failed to delete apartment"
              });
            }
          },
          {
            confirmButtonText: "Delete apartment",
            text: deleteText
          }
        );
      }}
    />
  );
};
