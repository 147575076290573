import React from "react";
import { Col, Container, Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaExternalLinkAlt, FaRedoAlt } from "react-icons/fa";
import { InputRow } from "./components/InputRow";
import { FormikContextType, useFormikContext } from "formik";
import { WhitelabelFormData, randomString } from "./WhitelabelFormData";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { FileUpload } from "./components/FileUpload";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { WhitelabelStatus } from "../../../generated/admin";

interface Props {
    methods: FormikContextType<WhitelabelFormData>;
}

export const DeveloperSettings: React.FC<Props> = ({ methods }) => {
    const fm = useFormikContext<WhitelabelFormData>();
    const show = fm.values.status !== WhitelabelStatus.Draft && 
        fm.values.status !== WhitelabelStatus.ReadyForDevelopment;
    if (show) {
        return (
            <ToggleContainer className="lg" title="Developer Settings" show={false}>
                <div className="alert alert-info">
                This section will be completed by the Mobile Development Team.
                </div>
                <InputRow title="Edition ID">
                    <div className="border-bottom d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="editionId"
                        onChange={(e) => {}}
                        value={methods.values.editionId ?? ""}
                        readOnly
                    />
                    </div>
                </InputRow>
                <InputRow title="Apple Store review account">
                    <Container className="p-0">
                        <Row>
                            <Col>
                                <div className="border-bottom d-flex align-items-center">
                                <Form.Control
                                    type="text"
                                    name="appleReviewEmail"
                                    onChange={(e) => {}}
                                    value={methods.values.appleReviewEmail ?? ""}
                                    readOnly
                                />
                                </div>
                                <Form.Text className="text-muted">
                                This email address is used to complete the Apple Store app review.
                                </Form.Text>
                            </Col>
                            <OverlayTrigger overlay={<Tooltip id={"refreshAppleEmail"}>Generate email address</Tooltip>}>
                                <button 
                                    onClick={(e) => {
                                        const alias = randomString();
                                        methods.setFieldValue("appleReviewEmail", `apple.review+${alias}@getequiem.com`);
                                    }}
                                    className="btn btn-link pt-0 align-top">
                                    <FaRedoAlt />
                                </button>
                            </OverlayTrigger>
                        </Row>
                    </Container>
                </InputRow>
                <InputRow title="Google Store review account">
                    <Container className="p-0">
                        <Row>
                            <Col>
                                <div className="border-bottom d-flex align-items-center">
                                <Form.Control
                                    type="text"
                                    name="googleReviewEmail"
                                    onChange={(e) => {}}
                                    value={methods.values.googleReviewEmail ?? ""}
                                    readOnly
                                />
                                </div>
                                <Form.Text className="text-muted">
                                This email address is used to complete the Google Store app review.
                                </Form.Text>
                            </Col>
                            <OverlayTrigger overlay={<Tooltip id={"refreshGoogleEmail"}>Generate email address</Tooltip>}>
                                <button 
                                    onClick={(e) => {
                                        const alias = randomString();
                                        methods.setFieldValue("googleReviewEmail", `google.review+${alias}@getequiem.com`);
                                    }}
                                    className="btn btn-link pt-0 align-top">
                                    <FaRedoAlt />
                                </button>
                            </OverlayTrigger>
                        </Row>
                    </Container>
                </InputRow>
                <InputRow title="Dynamic link domain">
                    <div className="border-bottom d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="dynamicLinkUrl"
                        isInvalid={methods.errors.dynamicLinkUrl != null}
                        onChange={methods.handleChange}
                        value={methods.values.dynamicLinkUrl ?? ""}
                        data-1p-ignore
                    />
                    </div>
                    {stringNotEmpty(methods.errors.dynamicLinkUrl) && (
                    <div className="invalid-feedback d-block">{methods.errors.dynamicLinkUrl}</div>
                    )}
                    <Form.Text className="text-muted">
                    A dynamic link URL.
                    </Form.Text>
                </InputRow>
                <InputRow title="Firebase project ID">
                    <div className="border-bottom d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="firebaseProjectId"
                        isInvalid={methods.errors.firebaseProjectId != null}
                        onChange={methods.handleChange}
                        value={methods.values.firebaseProjectId ?? ""}
                        data-1p-ignore
                    />
                    </div>
                    {stringNotEmpty(methods.errors.firebaseProjectId) && (
                    <div className="invalid-feedback d-block">{methods.errors.firebaseProjectId}</div>
                    )}
                    <Form.Text className="text-muted">
                    The unique identifier of the Firebase project.
                    </Form.Text>
                </InputRow>
                <InputRow title="Firebase web API key">
                    <div className="border-bottom d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="firebaseWebApiKey"
                        isInvalid={methods.errors.firebaseWebApiKey != null}
                        onChange={methods.handleChange}
                        value={methods.values.firebaseWebApiKey ?? ""}
                        data-1p-ignore
                    />
                    </div>
                    {stringNotEmpty(methods.errors.firebaseWebApiKey) && (
                    <div className="invalid-feedback d-block">{methods.errors.firebaseWebApiKey}</div>
                    )}
                    <Form.Text className="text-muted">
                    The Firebase web API Key.
                    </Form.Text>
                </InputRow>
                <InputRow title="Firebase service key">
                    <FileUpload
                        defaultValue={methods.values.firebaseServiceKey.id}
                        handleUpload={(val) => {
                        if (val == null) {
                            methods.setFieldValue("firebaseServiceKey", {});
                        }
                        methods.setFieldValue("firebaseServiceKey.json", val);
                        }}
                    ></FileUpload>
                    <Form.Text className="text-muted">
                        Upload the Firebase service key.
                    </Form.Text>
                </InputRow>
                <ToggleContainer className="lg" title="iOS" show={false}>
                    <InputRow title="Bundle ID">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="bundleId"
                            isInvalid={methods.errors.bundleId != null}
                            onChange={(e) => {
                                const raw = e.target.value;
                                methods.setFieldValue("bundleId", raw);
                                methods.setFieldValue("applePayMerchantId", `merchant.${raw.replace(' ','.')}`);
                            }}
                            value={methods.values.bundleId ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.bundleId) && (
                        <div className="invalid-feedback d-block">{methods.errors.bundleId}</div>
                        )}
                        <Form.Text className="text-muted">
                        The bundle identifier of the iOS app which uniquely identifies the app on the device.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="Apple Pay merchant ID">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="applePayMerchantId"
                            isInvalid={methods.errors.applePayMerchantId != null}
                            onChange={methods.handleChange}
                            value={methods.values.applePayMerchantId ?? "merchant."}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.applePayMerchantId) && (
                        <div className="invalid-feedback d-block">{methods.errors.applePayMerchantId}</div>
                        )}
                        <Form.Text className="text-muted">
                        The Apple Pay merchant ID.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="Team ID">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="appleTeamId"
                            isInvalid={methods.errors.appleTeamId != null}
                            onChange={methods.handleChange}
                            value={methods.values.appleTeamId ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.appleTeamId) && (
                        <div className="invalid-feedback d-block">{methods.errors.appleTeamId}</div>
                        )}
                        <Form.Text className="text-muted">
                        The unique Apple Team ID of the developer account.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="ITC team ID">
                        <Container className="p-0">
                            <Row>
                                <Col>
                                    <div className="border-bottom d-flex align-items-center">
                                    <Form.Control
                                        type="text"
                                        name="appleITCTeamId"
                                        isInvalid={methods.errors.appleITCTeamId != null}
                                        onChange={methods.handleChange}
                                        value={methods.values.appleITCTeamId ?? ""}
                                        data-1p-ignore
                                    />
                                    </div>
                                    {stringNotEmpty(methods.errors.appleITCTeamId) && (
                                    <div className="invalid-feedback d-block">{methods.errors.appleITCTeamId}</div>
                                    )}
                                    <Form.Text className="text-muted">
                                    The iTunes Connect content provider Id.
                                    </Form.Text>
                                </Col>
                                <OverlayTrigger overlay={<Tooltip id={"gotoLink"}>Go to link</Tooltip>}>
                                    <a
                                        target="_blank"
                                        className="btn btn-link pt-0 align-top"
                                        rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                        href={"https://appstoreconnect.apple.com/WebObjects/iTunesConnect.woa/ra/user/detail"}>
                                        <FaExternalLinkAlt />
                                    </a>
                                </OverlayTrigger>
                            </Row>
                        </Container>
                    </InputRow>
                    <InputRow title="Apple ID">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="appleAppStoreId"
                            isInvalid={methods.errors.appleAppStoreId != null}
                            onChange={methods.handleChange}
                            value={methods.values.appleAppStoreId ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.appleAppStoreId) && (
                        <div className="invalid-feedback d-block">{methods.errors.appleAppStoreId}</div>
                        )}
                        <Form.Text className="text-muted">
                        The unique Apple Store ID of the app.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="Code signing identity">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="appleCodeSigningIdentity"
                            isInvalid={methods.errors.appleCodeSigningIdentity != null}
                            onChange={methods.handleChange}
                            value={methods.values.appleCodeSigningIdentity ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.appleCodeSigningIdentity) && (
                        <div className="invalid-feedback d-block">{methods.errors.appleCodeSigningIdentity}</div>
                        )}
                        <Form.Text className="text-muted">
                        The name of the distribution certificate.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="Fastlane Match Git branch">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="matchGitBranch"
                            isInvalid={methods.errors.matchGitBranch != null}
                            onChange={methods.handleChange}
                            value={methods.values.matchGitBranch ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.matchGitBranch) && (
                        <div className="invalid-feedback d-block">{methods.errors.matchGitBranch}</div>
                        )}
                        <Form.Text className="text-muted">
                        The specific git branch to use.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="APNS key">
                        <FileUpload
                            defaultValue={methods.values.apnsKey.id}
                            handleUpload={(val) => {
                            if (val == null) {
                                methods.setFieldValue("apnsKey", {});
                            }
                            methods.setFieldValue("apnsKey.key", val);
                            }}
                        ></FileUpload>
                        {stringNotEmpty(methods.errors.apnsKey?.key) && (
                            <div className="invalid-feedback d-block">
                            {methods.errors.apnsKey?.key}
                            </div>
                        )}
                        <Form.Text className="text-muted">
                            Upload the client's APNS key.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="APNS Key ID">
                        <div className="border-bottom d-flex align-items-center">
                            <Form.Control
                            type="text"
                            name="apnsKey.keyId"
                            isInvalid={methods.errors.apnsKey != null}
                            onChange={methods.handleChange}
                            value={methods.values.apnsKey?.keyId ?? ""}
                            />
                        </div>
                        {stringNotEmpty(methods.errors.apnsKey?.keyId) && (
                            <div className="invalid-feedback d-block">
                            {methods.errors.apnsKey?.keyId}
                            </div>
                        )}
                        <Form.Text className="text-muted">
                            The key id for the client's APNS key.
                        </Form.Text>
                        </InputRow>
                </ToggleContainer>
                <ToggleContainer className="lg" title="Android" show={false}>
                    <InputRow title="Package name">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="packageName"
                            isInvalid={methods.errors.packageName != null}
                            onChange={methods.handleChange}
                            value={methods.values.packageName ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.packageName) && (
                        <div className="invalid-feedback d-block">{methods.errors.packageName}</div>
                        )}
                        <Form.Text className="text-muted">
                        The package name of the Android app which uniquely identifies the app on the device.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="Firebase App ID">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="firebaseAndroidAppId"
                            isInvalid={methods.errors.firebaseAndroidAppId != null}
                            onChange={methods.handleChange}
                            value={methods.values.firebaseAndroidAppId ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.firebaseAndroidAppId) && (
                        <div className="invalid-feedback d-block">{methods.errors.firebaseAndroidAppId}</div>
                        )}
                        <Form.Text className="text-muted">
                        The number which uniquly identifies the app in the Firbase project.
                        </Form.Text>
                    </InputRow>
                    <InputRow title="Product flavor">
                        <div className="border-bottom d-flex align-items-center">
                        <Form.Control
                            type="text"
                            name="androidFlavor"
                            isInvalid={methods.errors.androidFlavor != null}
                            onChange={methods.handleChange}
                            value={methods.values.androidFlavor ?? ""}
                            data-1p-ignore
                        />
                        </div>
                        {stringNotEmpty(methods.errors.androidFlavor) && (
                        <div className="invalid-feedback d-block">{methods.errors.androidFlavor}</div>
                        )}
                        <Form.Text className="text-muted">
                        The product flavor name.
                        </Form.Text>
                    </InputRow>
                </ToggleContainer>
            </ToggleContainer>
        );
    } else {
        return null;
    }
};