import React from "react";
import {
  useDeleteLevelMutation,
  useAddLevelsMutation
} from "../../../generated/admin";
import { EqMessageError, EqMessageSuccess } from "../../message/EqMessage";
import { levelsSort } from "./levelHook";

export interface State {
  readonly levels: Array<{ uuid: string; name: string }>;
  readonly initalLoadError?: { message: string };
  readonly saveErrors: Array<{ message: string }>;
  readonly loading: boolean;
  readonly newLevelName: string;
  readonly levelsToAdd: Array<{ name: string }>;
  readonly addingLevel: boolean;
  readonly deleting: string;
  readonly sortedLevels: Array<{ uuid: string; name: string }>;
}

export class LevelStore implements State {
  get levels() {
    return this.state.levels;
  }
  get initalLoadError() {
    return this.state.initalLoadError;
  }
  get saveErrors() {
    return this.state.saveErrors;
  }
  get loading() {
    return this.state.loading;
  }
  get newLevelName() {
    return this.state.newLevelName;
  }
  get levelsToAdd() {
    return this.state.levelsToAdd;
  }
  get addingLevel() {
    return this.state.addingLevel;
  }
  get deleting() {
    return this.state.deleting;
  }
  public sortedLevels: { uuid: string; name: string }[] = [];
  constructor(
    private state: State,
    private setState: React.Dispatch<React.SetStateAction<State>>,
    private addLevelsMutation: ReturnType<typeof useAddLevelsMutation>[0],
    private deleteMutation: ReturnType<typeof useDeleteLevelMutation>[0],
    private errorMsg = EqMessageError,
    private successMsg = EqMessageSuccess
  ) {
    this.sortedLevels = [...state.levels].sort(levelsSort);
  }

  public addLevel = () => {
    if (this.state.newLevelName === "") {
      this.errorMsg({
        text: "Level name cannot be empty."
      });
      return;
    }
    if (
      this.state.levelsToAdd
        .map((l) => l.name)
        .includes(this.state.newLevelName)
    ) {
      this.errorMsg({
        text: "Cannot create duplicate level name."
      });
      return;
    }
    this.setState((state) => ({
      ...state,
      levelsToAdd: [...state.levelsToAdd, { name: state.newLevelName }],
      newLevelName: ""
    }));
  };

  public deleteLevel = async (uuid: string, showSuccess: boolean) => {
    this.setState((state) => ({
      ...state,
      deleting: uuid
    }));
    const result = await this.deleteMutation({
      variables: {
        uuid
      }
    });
    this.setState((state) => ({
      ...state,
      deleting: ""
    }));
    if (result.data?.deleteLevel ?? false) {
      if (showSuccess) {
        this.successMsg({
          text: "Level has been deleted."
        });
      }
      this.setState((state) => ({
        ...state,
        levels: state.levels.filter((l) => l.uuid !== uuid)
      }));
    } else {
      this.errorMsg({
        text: "Failed to delete level."
      });
    }
  };

  public addLevels = async () => {
    if (this.state.levelsToAdd.length === 0) {
      this.errorMsg({
        text: "Click on add before saving levels."
      });
      return;
    }
    const result = await this.addLevelsMutation({
      variables: {
        input: this.state.levelsToAdd
      }
    });
    const levels = result.data?.addLevels.levels ?? [];
    const errors = result.data?.addLevels.errors ?? [];
    const names = levels.map((l) => l.name);
    this.setState((state) => ({
      ...state,
      levels: [
        ...state.levels.filter((l) => !names.includes(l.name)),
        ...levels
      ],
      saveErrors: errors.map((message) => ({
        message
      })),
      levelsToAdd: []
    }));

    if (errors.length > 0) {
      this.errorMsg({
        html: `The following errors occurred: <br/> ${errors.join("<br/>")}`
      });
    } else {
      this.successMsg({
        text: "Levels created."
      });
    }
  };

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLevelName = event.target.value;
    this.setState((state) => ({
      ...state,
      newLevelName
    }));
  };

  public updateLocalLevel = (original: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = e.target.value;
    this.setState((state) => ({
      ...state,
      levelsToAdd: state.levelsToAdd.map((l) =>
        l.name === original ? { name } : l
      )
    }));
  };

  public removeLevel = (name: string) => {
    this.setState((state) => ({
      ...state,
      levelsToAdd: state.levelsToAdd.filter((l) => l.name !== name)
    }));
  };

  public updateLevel = async (uuid: string, name: string) => {
    this.setState((state) => ({
      ...state,
      levels: state.levels.map((l) => {
        if (l.uuid === uuid) {
          return {
            ...l,
            name,
          }
        }
        return l;
      })
    }));
  };
}
