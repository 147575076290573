import React from "react";
import { Container } from "react-bootstrap";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { DomainSettings } from "./DomainSettings";
import { WebBranding } from "./WebBranding";
import { Marketplace } from "./Marketplace";
import { SiteDetailsQuery } from "../../../../generated/admin";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { RootRoutes } from "../../../../RouterOutlet";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const WebSettings: React.FC<Props> = ({ methods, dest }) => (
  <Container className="mb-5">
    <DomainSettings methods={methods} />
    <WebBranding methods={methods} />
    <Marketplace methods={methods} />
    <ToggleContainer
      title="Homepage Configuration"
      className="lg"
      link={`${RootRoutes.SITES}/${dest.uuid}/web-homeuiwidget`}
    />
  </Container>
);
