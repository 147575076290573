import React, { useContext } from "react";
import { Navigation } from "./Navigation";
import { PageHeading } from "../../components/PageHeading";
import { Container, Row, Col } from "react-bootstrap";
import { Divider } from "../../components/Divider";
import { UserAttributesHome } from "./userAttributes/UserAttributesHome";
import { CompanyAttributesHome } from "./companyAttributes/CompanyAttributesHome";
import { UserContext } from "../user/UserContext";

/**
 * Attributes home.
 */
export const AttributesHome: React.FC = () => {
  const user = useContext(UserContext);

  return (
    <>
      <PageHeading title="Attributes" subHeading={<Navigation />}></PageHeading>
      <Container>
        <Row>
          <Col className="mt-n2">
            Attributes are tags that can be assigned to companies and user
            profiles which can be used throughout other applications for
            segmentation purposes. Attributes can be created at a site level and
            remain unique to that site. Administrators are able to create user
            attributes, which form part of a catalogue that are available for
            all sites to use.
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col>
            <UserAttributesHome />
          </Col>
        </Row>

        {user?.activeDestination?.uuid && (
          <Row>
            <Col>
              <Divider />
              <CompanyAttributesHome siteUuid={user.activeDestination.uuid} />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
