import { useState } from "react";
import { useAddProfileCommentMutation } from "../../../../generated/admin";

/**
 * Hooks for the history form.
 */
export function useUserFormHistoryActions(
  profileUuid: string,
  destinationUuid?: string,
  finishCallback?: () => void
) {
  const [message, setMessage] = useState("");

  const [
    addProfileCommentMutation,
    { loading }
  ] = useAddProfileCommentMutation();

  const onSubmit = async () => {
    if (message.trim() === "" || loading) {
      return;
    }

    await addProfileCommentMutation({
      variables: {
        profileUuid,
        destinationUuid,
        message
      }
    });

    setMessage("");

    finishCallback && finishCallback();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  return {
    message,
    loading,
    onChangeInput,
    onSubmit
  };
}
