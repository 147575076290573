import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { PageHeading } from "../../../components/PageHeading";
import { ConfigContext } from "../../../providers/ConfigProvider";
import { UserContext } from "../../user/UserContext";
import { Container, Row, Col } from "react-bootstrap";
import { ApartmentDetailsForm } from "./ApartmentDetailsForm";
import { SubTitle } from "../../../components";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { useApartmentDetails } from "./hooks/useApartmentDetails";

/**
 * Apartment details.
 */
export const ApartmentDetails: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const config = useContext(ConfigContext);
  const userCtx = useContext(UserContext);
  const activeDestination = userCtx?.activeDestination?.uuid ?? null;

  const {
    error,
    loading,
    destinations,
    apartment,
    activeDestinationBuildings,
  } = useApartmentDetails(config.installation, uuid, activeDestination);

  const subHeading =
    activeDestination != null ? (
      uuid == null ? (
        <></>
      ) : (
        <div className="mx-3 mt-3 alert alert-danger">
          This apartment belongs to the apartment catalogue. Certain values are
          view only and can only be edited by an administrator.
        </div>
      )
    ) : undefined;

  return (
    <>
      <PageHeading
        title={uuid != null ? "Edit Apartment" : "Create New Apartment"}
        subHeading={subHeading}
      ></PageHeading>
      <Container>
        <Row>
          <Col className="pb-5" xs="12">
            <SubTitle>Apartment Details</SubTitle>

            <LoadingOrErrorDisplay loading={loading} error={error}>
              <ApartmentDetailsForm
                activeDestination={activeDestination}
                activeDestinationBuildings={activeDestinationBuildings}
                destinations={destinations}
                apartment={apartment}
              />
            </LoadingOrErrorDisplay>
          </Col>
        </Row>
      </Container>
    </>
  );
};
