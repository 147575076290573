import React from "react";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { UserFormHistoryTable } from "./UserFormHistoryTable";

/**
 * User form history component.
 */
export const UserFormHistory: React.FC = () => {
  return (
    <ToggleContainer title="History">
      <div>
        <p>
          Automated messages will be added to the table below for certain
          changes made to a user's profile. A custom message can also be added.
        </p>
        <UserFormHistoryTable />
      </div>
    </ToggleContainer>
  );
};
