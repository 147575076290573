import { Routes, Route } from "react-router-dom";
import React from "react";
import { UserList } from "./user-list/UserList";
import { UserDetailsPage } from "./user-details/UserDetails";
import { UserInvites } from "./user-invites/UserInvites";

export const UserModule = () => {
  return (
    <Routes>
      <Route path={`/company/:companyUuid`} element={<UserList />} />
      <Route path={`/details/:uuid`} element={<UserDetailsPage />} />
      <Route path={`/invites`} element={<UserInvites />} />
      <Route path="*" element={<UserList />} />
    </Routes>
  );
};
