import React, { useContext, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { userFormNameCheck } from "./model/UserFormData";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { UserFormContext } from "./context/UserFormContext";
import { UserFormUserTypeDropdown } from "./UserFormUserTypeDropdown";
import { ProfileRegistrationType } from "../../../generated/admin";
import { UserFormApartmentDropdown } from "./UserFormApartmentDropdown";

/**
 * User profile form component.
 */
export const UserFormProfile: React.FC = () => {
  const ctx = useContext(UserFormContext);
  const methods = useFormContext();
  const siteProfile = ctx.user?.profile?.siteProfiles.edges.find((e) => {
    return e.node?.site.uuid === ctx.activeSite;
  });
  const [registrationType, setRegistrationType] = useState<
    ProfileRegistrationType | undefined
  >(siteProfile?.node?.registrationType);

  return (
    <ToggleContainer title="Profile information">
      <p>
        Profile information is private unless otherwise required as part of
        using a feature. Information will only be shared in these instances and
        where the user has consented to its use.
      </p>
      <Container fluid className="p-0">
        <Row>
          <Col md="6">
            {ctx.activeSite ? (
              <Form.Group controlId="registrationType" as={Row}>
                <Form.Label column md="5" xl="4" className="pr-md-1">
                  User type
                </Form.Label>
                <Col md="7" xl="8" className="pl-md-0">
                  <UserFormUserTypeDropdown
                    defaultValue={
                      siteProfile?.node?.registrationType ??
                      ProfileRegistrationType.Commercial
                    }
                    siteUuid={ctx.activeSite}
                    setRegistrationType={setRegistrationType}
                  />
                </Col>
              </Form.Group>
            ) : null}
          </Col>
          <Col md="6">
            {ctx.activeSite &&
            registrationType === ProfileRegistrationType.Residential ? (
              <Form.Group controlId="apartment" as={Row}>
                <Form.Label column md="5" xl="4" className="pr-md-1">
                  Apartment
                </Form.Label>
                <Col md="7" xl="8" className="pl-md-0">
                  <UserFormApartmentDropdown
                    name={userFormNameCheck("apartment")}
                    defaultValue={
                      siteProfile?.node?.apartment != null
                        ? {
                            value: siteProfile?.node?.apartment.apartment.uuid,
                            label: siteProfile?.node?.apartment.apartment.name
                          }
                        : null
                    }
                    destinationUuid={ctx.activeSite}
                  />
                </Col>
              </Form.Group>
            ) : null}
          </Col>
          <Col md="6">
            <Form.Group controlId="mobile" as={Row}>
              <Form.Label column md="5" xl="4" className="pr-md-1">
                Mobile
              </Form.Label>
              <Col md="7" xl="8" className="pl-md-0">
                <Form.Control
                  defaultValue={ctx.user?.profile?.mobileNumber ?? ""}
                  {...methods.register(userFormNameCheck("mobile"))}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="position" as={Row}>
              <Form.Label column md="5" xl="4" className="pr-md-1">
                Position
              </Form.Label>
              <Col md="7" xl="8" className="pl-md-0">
                <Form.Control
                  defaultValue={ctx.user?.profile?.jobTitle ?? ""}
                  {...methods.register(userFormNameCheck("position"))}
                />
              </Col>
            </Form.Group>
          </Col>
          {siteProfile?.node?.fields &&
            siteProfile.node.fields.map((f) => (
              <Col key={f.field.key} md="6">
                <Form.Group controlId={f.field.key} as={Row}>
                  <Form.Label column md="5" xl="4" className="pr-md-1">
                    {f.field.title}
                  </Form.Label>
                  <Col md="7" xl="8" className="pl-md-0">
                    <Form.Control
                      defaultValue={f.value}
                      {...methods.register(`${userFormNameCheck("fields")}[${f.field.key}]`)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            ))}
        </Row>
      </Container>
    </ToggleContainer>
  );
};
