import { Maybe } from "graphql/jsutils/Maybe";
import {
  useDestinationBuildingsQuery,
  useSiteListQuery,
  useApartmentDetailsQuery
} from "../../../../generated/admin";

/**
 * Apartment details hook.
 */
export function useApartmentDetails(
  installation: string,
  apartmentUuid?: Maybe<string>,
  activeDestination?: Maybe<string>
) {
  const {
    loading: buildingLoading,
    error: buildingError,
    data: buildingData
  } = useDestinationBuildingsQuery({
    variables: { uuid: activeDestination },
    skip: activeDestination == null
  });

  const {
    loading: sitesLoading,
    error: sitesError,
    data: sitesData
  } = useSiteListQuery({
    variables: { name: "", installation },
    skip: !(apartmentUuid != null && activeDestination == null)
  });

  const {
    loading: apartmentLoading,
    error: apartmentError,
    data: apartmentData
  } = useApartmentDetailsQuery({
    fetchPolicy: "network-only",
    variables: { uuid: apartmentUuid },
    skip: apartmentUuid == null
  });

  return {
    error: sitesError ?? apartmentError ?? buildingError,
    loading: sitesLoading || apartmentLoading || buildingLoading,
    destinations:
      sitesData?.searchDestinations?.map((d) => ({
        uuid: d.uuid as string,
        name: d.name
      })) ?? [],
    apartment: apartmentData?.apartment,
    activeDestinationBuildings: buildingData?.destination.buildings ?? []
  };
}
