import React from "react";
import {
  CompanyListDocument, useDeleteCompanyMutation
} from "../../../generated/admin";
import { ActionButton } from "../../../components/TableButton";
import { deleteCheck } from "../../../components/deleteCheck";
import {
  EqMessageWarning,
  EqMessageSuccess,
  EqMessageError
} from "../../message/EqMessage";
import { useDeletedCompanyStore } from "./merge-companies/OptionStore";
import { Maybe } from "graphql/jsutils/Maybe";

interface CompanyDeleteProps {
  companyUuid: string;
  allowDelete: boolean;
  searchTerm: string;
  activeDestination?: Maybe<string>;
}

/**
 * Company delete for company list.
 */
export const CompanyDelete: React.FC<CompanyDeleteProps> = ({
  allowDelete,
  companyUuid,
  searchTerm,
  activeDestination
}) => {
  const deletedStore = useDeletedCompanyStore();
  const [action, { loading }] = useDeleteCompanyMutation();
  if (allowDelete) {
    return (
      <ActionButton
        actionLabel="Deleting..."
        restLabel="Delete"
        isInAction={false}
        onClick={async () => {
          EqMessageWarning({
            text:
              "This company cannot be deleted as it is currently being used at one or more sites."
          });
        }}
      />
    );
  }

  const deleteText = activeDestination
    ? `
      Once deleted, the company will no longer be available in registration
      and the users associated with this company will have their site profile removed.
      If they have a single site access, their account will be deactivated.
    `
    : "Once deleted, you cannot retrieve this record.";

  return (
    <ActionButton
      actionLabel="Deleting..."
      restLabel="Delete"
      isInAction={loading}
      onClick={async () => {
        await deleteCheck(
          async () => {
            const result = await action({
              variables: {
                uuid: companyUuid,
                destinationUuid: activeDestination
              },
              refetchQueries: [
                {
                  query: CompanyListDocument,
                  variables: {
                    search: searchTerm
                  }
                }
              ]
            });
            if (result.data?.deleteCompanyV2) {
              deletedStore.deleteCompany(companyUuid);
              EqMessageSuccess({
                text: "Company has been deleted!"
              });
            } else {
              EqMessageError({
                text: "Failed to delete company"
              });
            }
          },
          {
            confirmButtonText: "Delete company",
            text: deleteText
          }
        );
      }}
    />
  );
};
