import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useField, FieldAttributes } from "formik";
import { FormikInputWithCharCount } from "../../../../components/FormikInputWithCharCount";
import { CopyField } from "../../../../components/CopyField";

interface Props {
  error?: string;
  title: string;
  subText?: string;
  maxLength?: number;
  asTextarea?: boolean;
  asPassword?: boolean;
  fullWidth?: boolean;
  append?: string;
  hideLabel?: boolean;
}

export const TextFormGroup = ({
  error,
  title,
  subText,
  maxLength,
  asTextarea = false,
  asPassword = false,
  fullWidth = false,
  append,
  copyField = false,
  copyFieldName,
  hideLabel = false,
  ...props
}: FieldAttributes<any> & Props) => {
  const [field] = useField(props);
  return (
    <Form.Group as={Row} controlId={field.name}>
      {!hideLabel && (
        <Form.Label column md="3">
          {title}
        </Form.Label>
      )}
      <Col
        md="9"
        lg={fullWidth ? "9" : "6"}
        style={
          copyField
            ? {
                marginRight: "inherit",
                paddingRight: "5px",
              }
            : {}
        }
      >
        <Wrap append={append != null}>
          {maxLength !== undefined ? (
            <FormikInputWithCharCount
              {...(asTextarea
                ? {
                    as: "textarea",
                    rows: props.rows ?? 3,
                  }
                : { type: asPassword ? "password" : "text" })}
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={props.disabled}
              value={field.value ?? ""}
              isInvalid={error != null}
              maxLength={maxLength}
              style={props.disabled ? { background: "#f2f2f2" } : {}}
              placeholder={props.placeholder}
            />
          ) : (
            <Form.Control
              {...(asTextarea
                ? {
                    as: "textarea",
                    rows: props.rows ?? 3,
                  }
                : { type: asPassword ? "password" : "text" })}
              name={field.name}
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={props.disabled}
              value={field.value ?? ""}
              isInvalid={error != null}
              maxLength={maxLength}
              style={props.disabled ? { background: "#f2f2f2" } : {}}
              placeholder={props.placeholder}
            />
          )}
          {append && (
            <InputGroup.Append>
              <InputGroup.Text className="bg-white border-top-0 border-right-0 border-left-0">
                {append}
              </InputGroup.Text>
            </InputGroup.Append>
          )}
          {error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
          {subText != null ? (
            <Form.Text className="text-muted">{subText}</Form.Text>
          ) : null}
        </Wrap>
      </Col>
      {copyField ? (
        <CopyField
          value={field.value ?? ""}
          fieldName={copyFieldName ?? field.name}
        />
      ) : null}
    </Form.Group>
  );
};

const Wrap: React.FC<{ append: boolean; children?: React.ReactNode }> = ({
  append,
  children,
}) => (append ? <InputGroup>{children}</InputGroup> : <>{children}</>);
