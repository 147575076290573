// The fact that building details works with the building sync input as its
// state means we need to deal with a lot of floating point errors as we convert
// the tax rate between a percentage(0 - 100) and a rate(0 - 1).
//
// We only support 2 decimal places of input, so we can be pretty agressive at
// rounding away the precision errors.
export const truncate = (num: number, decimalPlaces: number): number => {
  const [iPart, fPart] = num.toFixed(decimalPlaces + 1).split(".");
  return Number(`${iPart}.${fPart.slice(0, decimalPlaces)}`);
};
export const toPercentageString = (rate: number): string => {
  return String(truncate(rate * 100, 1));
};
export const toRate = (rateString: string): number => {
  const rate = Number(rateString) / 100;
  return isNaN(rate) ? 0 : truncate(rate, 4);
};
