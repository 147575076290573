import React, { useCallback, useEffect, useMemo } from "react";
import {
  UserContextQuery,
  useDestinationOptionsQuery,
  DestinationContextFragment,
  DestinationOptionsQuery,
  useUserContextQuery,
} from "../../generated/admin";
import { Alert } from "react-bootstrap";
import { UserContext } from "./UserContext";
import { UserDestination } from "./User";
import { useConfig } from "../../providers/ConfigProvider";
import { AppLoadingPage } from "../../admin-components/AppLoadingPage";
import { stringNotEmpty } from "../../util/stringNotEmpty";
import { useLocation } from "react-router-dom";

export function useUserObject(viewer: UserContextQuery["viewer"]) {
  const config = useConfig();
  const [activeDestination, setActiveDestination] = React.useState<
    UserDestination | null | undefined
  >(null);
  const [activeDestinationSettings, setActiveDestinationSettings] =
    React.useState<
      | Partial<
          DestinationOptionsQuery["searchDestinations"][number]["settings"]
        >
      | null
      | undefined
    >(null);
  const [activeDestinationDetails, setActiveDestinationDetails] =
    React.useState<DestinationContextFragment | null | undefined>(null);
  const [roles, isAdminOrRegionalManager, isAdmin, isRegionalManager] =
    useMemo(() => {
      const roles = viewer.adminPermissions?.roles ?? [];
      // Treat all 3 groups as super user for now. Incrementally
      // Admin and RegionalManager will be treated differently.
      const isAdminOrRegionalManager =
        roles.includes("ADMIN") || roles.includes("REGIONAL_MANAGER");

      const isAdmin = roles.includes("ADMIN");

      const isRegionalManager = roles.includes("REGIONAL_MANAGER");
      return [roles, isAdminOrRegionalManager, isAdmin, isRegionalManager];
    }, [viewer]);

  const result = useDestinationOptionsQuery({
    variables: {
      name: "",
      installation: config.installation,
    },
    skip: !isAdminOrRegionalManager,
  });
  return useMemo(() => {
    const destinations = isAdminOrRegionalManager
      ? result.data?.searchDestinations ?? []
      : viewer.adminPermissions?.destinations.flatMap((e) =>
          e != null ? [e] : []
        ) ?? [];

    // When active destination empty and query site exist, set it!
    if (activeDestination == null) {
      const query = new URLSearchParams(window.location.search);
      const site = query.get("site");

      if (site != null && site.length > 0) {
        const targetDest = destinations.find((d) => d.uuid === site);
        targetDest != null && setActiveDestination(targetDest);
      }

      // When the user is not super admin and only have 1
      // site then we set that site as default.
      if (!isAdminOrRegionalManager && destinations.length === 1) {
        setActiveDestination(destinations[0]);
      }
    }

    const d = destinations.find((d) => d.uuid === activeDestination?.uuid);
    const ads = d ? d.settings : null;
    setActiveDestinationSettings(ads);
    setActiveDestinationDetails(d);

    return {
      uuid: viewer.profile?.uuid,
      email: viewer.profile?.email ?? "Unknown",
      name: `${viewer.profile?.firstName} ${viewer.profile?.lastName}`,
      avatar: formatAvatar(viewer.profile?.avatar),
      roles,
      isAdminOrRegionalManager,
      isAdmin,
      isRegionalManager,
      company: {
        uuid: viewer.profile?.companyV2?.uuid ?? "Unknown",
        name: viewer.profile?.companyV2?.name ?? "Unknown",
      },
      destinations,
      inSiteContext: activeDestination != null,
      setActiveDestination,
      activeDestination,
      activeDestinationSettings,
      activeDestinationDetails,
      activeAtDifferentDestination: (uuid: string) => {
        return activeDestination != null && activeDestination.uuid !== uuid;
      },
      integrations: {
        evolutionEnabled:
          activeDestination == null ||
          (activeDestinationDetails?.integrations?.evolution.enabled ?? false),
        yardiEnabled:
          activeDestination != null &&
          (activeDestinationDetails?.settings.booking.yardiEnabled ?? false),
      },
      autoDeployPicker: () => {
        if (
          isAdminOrRegionalManager ||
          activeDestination != null ||
          destinations.length === 1
        ) {
          return false;
        }

        return true;
      },
    };
  }, [
    viewer,
    activeDestination,
    result,
    isAdminOrRegionalManager,
    isAdmin,
    isRegionalManager,
    roles,
    activeDestinationSettings,
    activeDestinationDetails,
  ]);
}

const UserProviderInternal: React.FC<{
  viewer: UserContextQuery["viewer"];
  children?: React.ReactNode;
}> = (props) => {
  const transformedUser = useUserObject(props.viewer);
  const { destinations, activeDestination, setActiveDestination } =
    transformedUser;

  const findAndSetDestination = useCallback(
    (target?: string) => {
      const dest = destinations.find((d) => d.uuid === target) ?? undefined;
      setActiveDestination(dest);
    },
    [destinations, setActiveDestination]
  );

  const searchQuery = useLocation().search;
  const siteQuery = new URLSearchParams(searchQuery).get("site");
  const site = stringNotEmpty(siteQuery) ? siteQuery : undefined;
  const activeSite = activeDestination?.uuid ?? undefined;
  useEffect(() => {
    if (site !== activeSite) {
      findAndSetDestination(site);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, activeSite]);

  return (
    <UserContext.Provider value={transformedUser}>
      {props.children}
    </UserContext.Provider>
  );
};

const UserProviderLoadingOrError: React.FC<{
  loading: boolean;
  error?: { message: string };
  data?: UserContextQuery;
  children?: React.ReactNode;
}> = (props) => {
  if (props.error != null) {
    return <Alert variant="danger">{props.error.message}</Alert>;
  }
  if (props.loading || props.data == null) {
    return <AppLoadingPage />;
  }

  return (
    <UserProviderInternal viewer={props.data.viewer}>
      {props.children}
    </UserProviderInternal>
  );
};

export const UserProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { data, loading, error } = useUserContextQuery();

  return (
    <UserProviderLoadingOrError data={data} loading={loading} error={error}>
      {children}
    </UserProviderLoadingOrError>
  );
};

function formatAvatar(avatar?: string | null) {
  return avatar != null && !avatar.includes("equiem-portal-proxy")
    ? avatar + "&fm=png&mask=ellipse&w=40&h=40"
    : undefined;
}
