import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { AttributeList } from "./list-view/AttributeList";
import { SubTitle } from "../../../components";
import { CompanyAttributeStoreProvider } from "./hook/useCompanyAttributesStore";
import { AddCompanyAttributeForm } from "./AddCompanyAttributeForm";
import { Divider } from "../../../components/Divider";

interface Props {
  siteUuid: string;
}

/**
 * Company attributes home.
 */
export const CompanyAttributesHome: React.FC<Props> = ({ siteUuid }) => {
  return (
    <CompanyAttributeStoreProvider siteUuid={siteUuid}>
      <Container className="px-0">
        <Row>
          <Col>
            <SubTitle>Manage Company Attributes</SubTitle>
            <p>
              Company attributes are unique to a site and can be assigned to a
              company working at your site. These can be used throughout other
              Equiem systems for segmentation purposes.
            </p>
            <AttributeList />
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col className="pb-5">
            <SubTitle>Add Company Attributes</SubTitle>
            <p>
              Company attributes, unique to your site, can be added using the
              below form. These will be added to the list of company attributes
              in the above table.
            </p>
            <AddCompanyAttributeForm />
          </Col>
        </Row>
      </Container>
    </CompanyAttributeStoreProvider>
  );
};
