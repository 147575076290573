import React from "react";
import { useUser } from "../modules/user/UserContext";

export const HideForRegionalManagerInAllSitesContext: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const ctx = useUser();

  if (!ctx.isRegionalManager) {
    return <>{children}</>;
  }

  if (ctx.inSiteContext) {
    return <>{children}</>;
  }

  return null;
};
