import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { FormikContextType } from "formik";
import { AddressInputField } from "../../../../../components/AddressInputField";
import type { AddressInfo } from "../../../../../util/address";

interface Props {
  methods: Pick<
    FormikContextType<{ addressInput: AddressInfo }>,
    "values" | "setFieldValue" | "errors" | "touched"
  >;
}

export const AddressInput: React.FC<Props> = ({ methods }) => {
  return (
    <div>
      <Form.Group as={Row} controlId={"addressInput.address"}>
        <Form.Label column md="3">
          Address
        </Form.Label>
        <Col md="9" lg="6">
          <AddressInputField
            name="addressInput.address"
            touched={methods.touched.addressInput?.address ?? false}
            error={methods.errors.addressInput?.address}
            value={methods.values.addressInput}
            onChange={(newVal) => methods.setFieldValue("addressInput", newVal)}
          />
        </Col>
      </Form.Group>
      <Form.Group disabled as={Row} controlId="addressInput.timezone">
        <Form.Label column md="3">
          Time zone
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            value={methods.values.addressInput.timezone}
            style={{ background: "#f2f2f2" }}
            disabled
          />
          <Form.Text className="text-muted">
            The time zone is automatically configured from the address.
          </Form.Text>
        </Col>
      </Form.Group>
    </div>
  );
};
