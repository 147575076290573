import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { FormikProps, useFormikContext } from "formik";
import { AdminAccessControlSolution } from "../../../../generated/admin";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { OptionType } from "../../../../model/OptionType";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
  disabled?: boolean;
}

/**
 * note: the "Legacy" option here is an access control solution originally written by Dave Surrey
 * to work with Vicinitee and Equiem. it is known as the "Vicinitee" solution internally.
 */
const disabledSolution: OptionType = { value: "disabled", label: "Disabled" };
const solutionOptions: OptionType[] = [
  disabledSolution,
  { value: AdminAccessControlSolution.AccessOne, label: "Access One" },
  {
    value: AdminAccessControlSolution.Vicinitee,
    label: "Visitor Access Control",
  },
];

export const AccessControlSolution: React.FC<Props> = ({
  methods,
  disabled = false,
}) => {
  const fm = useFormikContext<SiteConfigFormData>();
  return (
    <div className="py-3">
      <div className="d-flex justify-content-between pb-2 border-bottom">
        <h4 className="font-weight-normal">Access Control</h4>
      </div>
      <div>
        <div className="pt-4">
          <Form.Group
            as={Row}
            controlId="solution"
            className="pb-5 access-control-solution"
          >
            <Form.Label column md="3" lg="2">
              Access Control Solution
            </Form.Label>
            <Col md="5">
              <Select<OptionType, boolean>
                classNamePrefix="eq"
                isSearchable={false}
                options={solutionOptions}
                value={
                  solutionOptions.find(
                    ({ value }) =>
                      value === fm.values.accessControlSettings?.solution
                  ) ?? disabledSolution
                }
                onChange={(option: any) => {
                  if (option.value === "disabled") {
                    methods.setFieldValue("accessControlSettings", null);
                  } else {
                    methods.setFieldValue(
                      "accessControlSettings.solution",
                      option.value
                    );
                  }
                }}
                aria-label="solution-select"
                className={`react-select${
                  fm.errors.accessControlSettings != null ? " is-invalid" : ""
                }`}
              />
              <Form.Text className="text-muted">
                Select the access control solution for this site.
              </Form.Text>
              {fm.errors.accessControlSettings &&
                fm.touched.accessControlSettings && (
                  <Form.Control.Feedback type="invalid">
                    {fm.errors.accessControlSettings}
                  </Form.Control.Feedback>
                )}
            </Col>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};
