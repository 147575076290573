import React, { useContext } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { AttributeList } from "./list-view/AttributeList";
import { SubTitle } from "../../../components";
import {
  UserAttributeStoreProvider,
  useUserAttributeStore
} from "./hook/useUserAttributesStore";
import { AddUserAttributeForm } from "./AddUserAttributeForm";
import { Divider } from "../../../components/Divider";
import { UserContext } from "../../user/UserContext";
import {
  UserSiteAttributeStoreProvider,
  useUserSiteAttributeStore
} from "./hook/useUserSiteAttributesStore";
import { UserAttributesCatalogueLanding } from "./catalogue/UserAttributesCatalogueLanding";

const InternalEquiemAdmin: React.FC = () => {
  const store = useUserAttributeStore();

  return (
    <Container className="px-0">
      <Row>
        <Col>
          <SubTitle>Manage User Attributes</SubTitle>
          <p>
            User attributes displayed below are a catalogue of attributes
            available for selection across all sites. New attributes can be
            created by an admininstrator and also at a site level.
          </p>
          <AttributeList store={store} />
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col className="pb-5">
          <SubTitle>Add User Attributes</SubTitle>
          <AddUserAttributeForm store={store} />
        </Col>
      </Row>
    </Container>
  );
};

const InternalSiteManager: React.FC = () => {
  const store = useUserSiteAttributeStore();

  return (
    <Container className="px-0">
      <Row>
        <Col>
          <UserAttributesCatalogueLanding store={store} />
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubTitle>My User Attributes</SubTitle>
          <p>
            User attributes displayed below are available to assign to user
            profiles at your site and can be used throughout other applications
            for segmentation purposes. You can add additional attributes, unique
            to your site, using the form below this table.
          </p>
          <AttributeList store={store} />
          <Divider />
        </Col>
      </Row>

      <Row>
        <Col className="pb-4">
          <SubTitle>Add User Attributes</SubTitle>
          <p>
            Additional attributes, unique to your site, can be added using the
            below form. These will be added to the list of user attributes in
            the above table.
          </p>
          <AddUserAttributeForm store={store} />
        </Col>
      </Row>
    </Container>
  );
};

/**
 * User attributes home.
 */
export const UserAttributesHome: React.FC = () => {
  const ctx = useContext(UserContext);

  return ctx?.activeDestination?.uuid ? (
    <UserSiteAttributeStoreProvider>
      <InternalSiteManager />
    </UserSiteAttributeStoreProvider>
  ) : (
    <UserAttributeStoreProvider>
      <InternalEquiemAdmin />
    </UserAttributeStoreProvider>
  );
};
