import React, { useState, useMemo } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useSiteContextHelper } from "../hooks/useSiteContextHelper";

interface ToggleContainerProps {
  title: string;
  className?: string;
  show?: boolean;
  testId?: string;
  memoize?: boolean;
  link?: string;
  children?: React.ReactNode
}

/**
 * Toggle container component.
 */
export const ToggleContainer: React.FC<ToggleContainerProps> = ({
  title,
  children = "",
  testId,
  className = "",
  show = false,
  memoize = false,
  link = null,
}) => {
  const siteCtxHelper = useSiteContextHelper();
  const [showToggle, setShowToggle] = useState(show);

  const contents = useMemo(
    () => (showToggle ? <div className="tcbody py-2">{children}</div> : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showToggle]
  );

  const showClass = showToggle ? " show" : "";
  const linkClass = link != null ? " link" : "";
  const additionalClass = ` ${className}` ?? "";

  return (
    <div
      className={`mb-4 toggle-container${additionalClass}${showClass}${linkClass}`}
    >
      <div
        data-testid={testId}
        onClick={() => {
          if (link != null) {
            siteCtxHelper.redirect(link);
          } else {
            setShowToggle((currentState) => !currentState);
          }
        }}
        className="tcheader d-flex justify-content-between"
      >
        {title} <FaAngleDown className="icon" />
      </div>
      {memoize ? (
        contents
      ) : showToggle ? (
        <div className="tcbody py-2">{children}</div>
      ) : null}
    </div>
  );
};
