import React, { useCallback } from "react";
import { IndustryStore } from "../hook/useIndustryStore";
import { Industry } from "../model/Industry";
import { TableButton, ActionButton } from "../../../../components/TableButton";
import { deleteCheck } from "../../../../components/deleteCheck";

interface Props {
  industry: Industry;
  store: IndustryStore;
  setEditing: (edit: boolean) => void;
}

export const NormalRow: React.FC<Props> = ({ industry, store, setEditing }) => {
  const startEdit = useCallback(() => setEditing(true), [setEditing]);
  const deleteHandler = useCallback(async () => {
    await deleteCheck(async () => await store.deleteIndustry(industry.uuid), {
      confirmButtonText: "Delete industry"
    });
  }, [store, industry]);
  return (
    <tr>
      <td>{industry.name}</td>
      <td>{industry.companyCount}</td>
      <td>
        <TableButton onClick={startEdit}>Edit</TableButton> |&nbsp;
        <ActionButton
          onClick={deleteHandler}
          actionLabel="Deleting..."
          restLabel="Delete"
          isInAction={store.deleting === industry.uuid}
        />
      </td>
    </tr>
  );
};
