import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";

/**
 * Remove button.
 */
export const RemoveButton: React.FC<any> = ({ ...props }) => {
  return (
    <FaRegTrashAlt
      {...props}
      size="16px"
      className="text-danger cursor-pointer"
    />
  );
};
