import { stringNotEmpty } from "./stringNotEmpty";

export const validURL = (value: string | null) => {
    if (stringNotEmpty(value)) {
        let url;
        try {
            url = new URL(value);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }
    return false;
};