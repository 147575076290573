import React, { createContext, useContext } from "react";
import { useLevelStoreFactory } from "./levelHook";
import { LevelStore } from "./LevelStore";

const LevelsContext = createContext<LevelStore | null>(null);

export function useLevelStore(): LevelStore {
  const store = useContext(LevelsContext);
  if (store == null) {
    throw Error("level store context not initialized.");
  }
  return store;
}

export const LevelsStoreProvider: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  const store = useLevelStoreFactory();
  return (
    <LevelsContext.Provider value={store}>{children}</LevelsContext.Provider>
  );
};
