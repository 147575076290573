import { FormikProps, useFormikContext } from "formik";
import { WhitelabelFormData } from "../WhitelabelFormData";
import { useDeleteWhitelabelAppMutation, WhitelabelStatus } from "../../../../generated/admin";
import { DeleteButton } from "../../../../components/DeleteButton";
import { deleteCheck } from "../../../../components/deleteCheck";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { useSiteContextHelper } from "../../../../hooks/useSiteContextHelper";

interface Props {
    methods: FormikProps<WhitelabelFormData>;
}

export const DeleteWhitelabelButton: React.FC<Props> = ({methods}) => {
  const siteCtxHelper = useSiteContextHelper();
    const fm = useFormikContext<WhitelabelFormData>();
    const [action, { loading: deleteLoading } ] = useDeleteWhitelabelAppMutation();
    const showButton = fm.values.status === WhitelabelStatus.Draft || methods.initialValues.status === WhitelabelStatus.Retired;
    const onDelete = async () => {
      await deleteCheck(
        async () => {
          const result = await action({
            variables: {
              uuid: fm.values.uuid,
            },
          });
          if (
            result.data?.deleteWhitelabelApp.__typename === "SuccessResponse"
          ) {
            EqMessageSuccess({
              text: "White label App has been deleted!",
            });
          } else {
            EqMessageError({
              text: "Failed to delete white label app!",
            });
          }
          siteCtxHelper.goBack();
        },
        {
          confirmButtonText: "Delete white label app",
        }
      );
    };
    return showButton ? (
      <DeleteButton
        type="submit"
        disabled={deleteLoading}
        onClick={onDelete}
        className={`mr-4 float-right`}
      >
        Delete
      </DeleteButton>
     ) : null;
  }