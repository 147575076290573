import styled from "styled-components";

export const fontWeights = {
  light: 300,
  regular: 400,
  heavy: 700
};

export const Heading1 = styled.h1`
  font-size: 18px;
  font-weight: ${fontWeights.regular};
`;

export const DataTableTitle = styled.span`
  font-weight: ${fontWeights.heavy};
`;

export const FieldExampleText = styled.span`
  opacity: 0.5;
  color: rgba(0, 0, 0, 0.6);
`;

export const HelpText = styled.span`
  opacity: 0.75;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
`;
