const lightBlue = "#2600aa";
const revolver = "#35353F";
const black = "#000000";
const orange = "#ffc107";

export const colors = {
  lightBlue,
  revolver,
  black,
  orange,
  grey: "#686868",
  green: "#28a745",
  danger: "#dc3545",
  lightGrey: "#dee2e6",
  primary: "#2600aa"
};
