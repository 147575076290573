import React, { FC, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { EqMessageError } from "../modules/message/EqMessage";

export const LoadingOrErrorDisplay: FC<{
  loading: boolean;
  error?: { message: string } | undefined;
  children?: React.ReactNode;
}> = ({ loading, error, children }) => {
  useEffect(() => {
    if (error) {
      EqMessageError({
        text: error.message,
      });
    }
  }, [error]);

  if (loading) {
    return (
      <div>
        <Spinner size="sm" animation="grow" /> Loading...
      </div>
    );
  }

  return <div>{children}</div>;
};
