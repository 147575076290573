import React, { useContext, useMemo, useState } from "react";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { Sorter } from "../../../components/Sorter";
import { LazyExportToCsv } from "../../../components/export/LazyExportToCsv";
import { CsvColumnConfig } from "../../../components/export/ExportToCsv";
import { ArrayPagination } from "../../../components/Pagination";
import { FlushRightTable } from "../../../components/FlushRightTable";
import { ToggleEdit } from "./GroupEdit";
import { GroupStore } from "./model/GroupStore";
import { UserContext } from "../../user/UserContext";

const exportConfig: CsvColumnConfig[] = [{ column: "name", label: "Group" }];

interface Props {
  store: GroupStore;
}

export const GroupsListView: React.FC<Props> = ({ store }) => {
  const ctx = useContext(UserContext);
  const [sort, setSort] = useState<{
    field: string;
    asc: boolean;
  } | null>(null);

  const sorter = useMemo(() => {
    return new Sorter(
    [{ key: "name", label: "Group" }],
    sort,
    setSort
  )}, [sort]);

  const sorted = useMemo(() => {
    return sorter.sortItems(
      store.groups.slice(),
      store.groupsSort
    );
  }, [store.groups, store.groupsSort, sorter]);

  return (
    <LoadingOrErrorDisplay
      loading={store.loading}
      error={store.initalLoadError}
    >
      <ArrayPagination
        topLeftContent={
          <>
            <LazyExportToCsv items={sorted} config={exportConfig} />
          </>
        }
        items={sorted}
      >
        {(items) => (
          <FlushRightTable>
            <sorter.Heading>
              {ctx?.inSiteContext === true ? <th></th> : null}
              <th className="w-200-px text-right">Operations</th>
            </sorter.Heading>
            <tbody>
              {items.map((group) => (
                <ToggleEdit
                  key={`row-${group.uuid}`}
                  group={group}
                  store={store}
                />
              ))}
            </tbody>
          </FlushRightTable>
        )}
      </ArrayPagination>
    </LoadingOrErrorDisplay>
  );
};
