import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import {
  AdminAccessControlSystem,
  SiteDetailsQuery,
} from "../../../../generated/admin";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import startCase from "lodash/startCase";
import { OpenpathConnect } from "./OpenpathConnect";
import { SwiftconnectConnect } from "./SwiftconnectConnect";
import { useConfig } from "../../../../providers/ConfigProvider";
import { GallagherConnect } from "./GallagherConnect";
import { ImageUpload } from "../fields/ImageUpload";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const AccessControl: React.FC<Props> = ({ methods, dest }) => {
  const config = useConfig();

  const width = 1536;
  const height = 969;

  return (
    <ToggleContainer className="lg" title="Access Control">
      <span>
        Select the appropriate system to enable access control via the mobile
        app.
      </span>
      <div>
        <div className="d-flex pt-3 pb-2 justify-content-between">
          <h5 className="font-weight-normal">
            {startCase(AdminAccessControlSystem.Vicinitee.toLowerCase())}
          </h5>
          <Form.Check
            name="accessControlVicinitee"
            type="switch"
            label=""
            id="accessControlVicinitee"
            onChange={methods.handleChange}
            defaultChecked={methods.values.accessControlVicinitee}
          />
        </div>
        <div className="border-top pt-3 d-flex pb-2 justify-content-between">
          <h5 className="font-weight-normal">{AdminAccessControlSystem.Hid}</h5>
          <Form.Check
            name="accessControlHid"
            type="switch"
            label=""
            id="accessControlHid"
            onChange={methods.handleChange}
            defaultChecked={methods.values.accessControlHid}
          />
        </div>
        <div className="border-top pt-3 pb-2">
          <h5 className="font-weight-normal">Gallagher</h5>
          <GallagherConnect dest={dest} />
        </div>
        <div className="border-top pt-3 pb-2">
          <h5 className="font-weight-normal">Openpath</h5>
          <OpenpathConnect dest={dest} />
        </div>
        {config.featureToggles.swiftconnect ? (
          <div className="border-top pt-3 pb-2">
            <h5 className="font-weight-normal mb-4">SwiftConnect</h5>
            <h5 className="mb-4">Image Assets</h5>
            <Form.Group as={Row} controlId="swiftConnectArtwork">
              <Form.Label column md="3" className="pt-0">
                Access pass artwork
              </Form.Label>
              <Col md="9" lg="6">
                <ImageUpload
                  fieldName="swiftConnectArtwork"
                  methods={methods}
                  existingImage={methods.values.swiftConnectArtwork}
                  allowedExtension={["PNG"]}
                  restrictions={{
                    width,
                    height,
                  }}
                />
                {methods.errors.swiftConnectArtwork && (
                  <div className="invalid-feedback d-block">
                    {methods.errors.swiftConnectArtwork}
                  </div>
                )}
                <Form.Text muted>
                  The access pass artwork will be shown in the mobile app. The
                  format must be PNG and the required width and height is{" "}
                  {width} x {height} px.{" "}
                  A 10px radius will automatically be applied to each corner when viewing the pass in the mobile app.{" "}
                  <strong>
                    Please note this is a required field and needs to be added
                    before adding your connection.
                  </strong>
                </Form.Text>
              </Col>
            </Form.Group>

            <SwiftconnectConnect dest={dest} />
          </div>
        ) : null}
      </div>
    </ToggleContainer>
  );
};
