import React, { useCallback } from "react";
import { Formik, FormikErrors, FormikProps } from "formik";
import { SiteConfigFormData } from "../../SiteConfigFormData";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { TextFormGroup } from "../../fields/TextFormGroup";
import { OptionType } from "../../../../../model/OptionType";
import Select from "react-select";
import {
  BarcodeType,
  BarrierControlConfigsDocument,
  useBuildingListQuery,
  useCreateBarrierControlConfigMutation,
} from "../../../../../generated/admin";
import {
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";
import { AccessControlConnectionList } from "./AccessControlConnectionList";

interface Props {
  siteFormData: FormikProps<SiteConfigFormData>;
}

interface NewAccessControlConnectionFormData {
  name: string | null;
  buildingUuids: { value: string; label: string }[] | null;
  codeType: BarcodeType | null;
}

export const NewAccessControlConnection: React.FC<Props> = ({
  siteFormData,
}) => {
  const { data, loading } = useBuildingListQuery({
    variables: {
      first: 100,
      destinationUuid: siteFormData.values.destinationUuid,
    },
  });

  const barcodeOptions: OptionType[] = [
    { value: "CODE128", label: "Barcode" },
    { value: "QR", label: "QR" },
    { value: "None", label: "None" }
  ];

  const [save, { loading: loadingCreateConfig }] =
    useCreateBarrierControlConfigMutation();

  const getInitialValues = (): NewAccessControlConnectionFormData => {
    return {
      buildingUuids: null,
      codeType: null,
      name: null,
    };
  };

  const createConfig = useCallback(
    async (values: NewAccessControlConnectionFormData, siteUuid: string) => {
      try {
        const result = await save({
          variables: {
            input: {
              buildingUuids: values.buildingUuids!.map((x) => x.value),
              siteUuid: siteUuid,
              codeType: values.codeType!,
              name: values.name!,
            },
          },
          refetchQueries: [
            {
              query: BarrierControlConfigsDocument,
              variables: {
                siteUuid: siteUuid,
              },
            },
          ],
        });

        if (!result.data?.createBarrierControlConfig.uuid) {
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          text: "Successfully saved access control connection.",
        });
      } catch (e) {
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [save]
  );

  return (
    <div>
      <Formik<NewAccessControlConnectionFormData>
        initialValues={getInitialValues()}
        onSubmit={(values, { resetForm }) => {
          createConfig(values, siteFormData.values.destinationUuid);
          resetForm({ values: getInitialValues() });
        }}
        validate={(values) => {
          const errors: FormikErrors<NewAccessControlConnectionFormData> = {};
          if (values.name === "" || values.name === null) {
            errors.name = "Required.";
          }
          if (values.codeType === null) {
            errors.codeType = "Required.";
          }
          if (
            values.buildingUuids === undefined ||
            values.buildingUuids === null ||
            values.buildingUuids.length < 1
          ) {
            errors.buildingUuids = "Required.";
          }
          return errors;
        }}
      >
        {(methods) => (
          <>
            <Row className="pt-3">
              <Col>
                <h5 className="font-weight-normal">
                  Access Control Connection
                </h5>
              </Col>
            </Row>

            <Row className="pt-3">
              <Col>
                <h5>New Connection</h5>
              </Col>
            </Row>
            <div className="border p-4 mb-2">
              <p className="pb-4 pt-3">
                Set up an access control connection to enable access control for
                Visitor Management.
              </p>
              <Form.Group as={Row} controlId="buildingUuids">
                <Form.Label column md="3">
                  {"Buildings"}
                </Form.Label>
                <Col md="9" lg="6" className="site-client-select">
                  <Select<OptionType, boolean>
                    classNamePrefix="eq"
                    isLoading={loading}
                    isMulti={true}
                    isSearchable
                    value={
                      data?.buildings.edges
                        .filter(
                          (x) =>
                            methods.values.buildingUuids?.find(
                              (y) => x.node?.uuid === y.value
                            ) !== undefined
                        )
                        .map((x) => ({
                          value: x.node!.uuid,
                          label: x.node!.name,
                        })) ?? null
                    }
                    options={
                      data?.buildings.edges.map((c) => ({
                        value: c.node!.uuid,
                        label: c.node!.name,
                      })) ?? []
                    }
                    onChange={(options) => {
                      methods.setFieldValue("buildingUuids", options);
                    }}
                    placeholder="Select..."
                    aria-label="site-client-select"
                    className={`react-select${
                      methods.errors.buildingUuids != null ? " is-invalid" : ""
                    }`}
                  />
                  <Form.Text className="text-muted">
                    Select the buildings that can utilise this access control
                    connection.
                  </Form.Text>
                  {methods.errors.buildingUuids &&
                    methods.touched.buildingUuids && (
                      <Form.Control.Feedback type="invalid">
                        {methods.errors.buildingUuids}
                      </Form.Control.Feedback>
                    )}
                </Col>
              </Form.Group>
              <TextFormGroup
                title={"Name"}
                subText={"The unique name for the access control connection."}
                name={"name"}
                handleChange={methods.handleChange}
                error={methods.errors.name}
              />
              <Form.Group as={Row} controlId="codeType" className="pb-5">
                <Form.Label column md="3">
                  {"Format"}
                </Form.Label>
                <Col md="9" lg="6" className="site-client-select">
                  <Select<OptionType, boolean>
                    classNamePrefix="eq"
                    isSearchable
                    options={barcodeOptions}
                    value={
                      barcodeOptions.find(
                        (x) => x.value === methods.values.codeType
                      ) ?? null
                    }
                    onChange={(option: any) => {
                      methods.setFieldValue("codeType", option.value);
                    }}
                    placeholder="Select..."
                    aria-label="site-client-select"
                    className={`react-select${
                      methods.errors.codeType != null ? " is-invalid" : ""
                    }`}
                  />
                  <Form.Text className="text-muted">
                    The format can either be Barcode or QR code.
                  </Form.Text>
                  {methods.errors.codeType && methods.touched.codeType && (
                    <Form.Control.Feedback type="invalid">
                      {methods.errors.codeType}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>
              <div className="text-right">
                <Button
                  name="createCredentials"
                  variant="outline-primary"
                  size="sm"
                  className="align-self-center m-2"
                  onClick={() => methods.handleSubmit()}
                  disabled={loading || !methods.isValid}
                >
                  {loadingCreateConfig ? (
                    <span>
                      <Spinner size="sm" animation="grow" /> Loading...
                    </span>
                  ) : (
                    "Create connection"
                  )}
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
      <AccessControlConnectionList siteFormData={siteFormData} />
    </div>
  );
};
