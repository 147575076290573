import React from "react";
import { StylesConfig } from "react-select";
import { FormikSelect } from "../../../../components/formik/FormikSelect";
import { OptionType } from "../../../../model/OptionType";

export const UserStatusSelector = () => {
  const colourStyles: StylesConfig<OptionType> = {
    option: (styles, { data, isSelected }) => {
      return {
        ...styles,
        color: data.value ? "var(--lime)" : "var(--deactivated)",
        backgroundColor: isSelected ? "#FFFFFF" : styles.backgroundColor,
      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: data.value ? "var(--lime)" : "var(--deactivated)",
      };
    },
  };
  const options = [
    { value: true, label: "Active" },
    { value: false, label: "Deactivated" },
  ];

  return (
    <>
      <FormikSelect
        styles={colourStyles}
        aria-label="user-status-select"
        name={"active"}
        options={options}
      />
    </>
  );
};
