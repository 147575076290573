import { useState, useMemo } from "react";
import { Item } from "../model/Item";
import { ItemSelector } from "../model/ItemSelector";
import { CatalogueSelectorStore } from "../model/CatalogueSelectorStore";

/**
 * Catalogue selector.
 */
export function useCatalogueSelectorFactory(): CatalogueSelectorStore {
  const [itemsSelected, setItemsSelected] = useState<ItemSelector>({});

  return useMemo(
    () => ({
      selectItem: (item: Item) => {
        const current = { ...itemsSelected };
        if (current[item.uuid] == null) {
          current[item.uuid] = { ...item, status: "selected" };
        } else if (current[item.uuid].status === "selected") {
          delete current[item.uuid];
        }
        setItemsSelected(current);
      },
      selectAddedItem: (item: Item) => {
        const current = { ...itemsSelected };
        if (current[item.uuid] != null) {
          current[item.uuid].status =
            current[item.uuid].status === "addedSelected"
              ? "added"
              : "addedSelected";
        }
        setItemsSelected(current);
      },
      addItems: () => {
        const current = { ...itemsSelected };
        Object.keys(current).forEach((uuid) => {
          if (current[uuid].status === "selected") {
            current[uuid].status = "added";
          }
        });
        setItemsSelected(current);
      },
      removeItems: () => {
        const current = { ...itemsSelected };
        Object.keys(current).forEach((uuid) => {
          if (current[uuid].status === "addedSelected") {
            delete current[uuid];
          }
        });
        setItemsSelected(current);
      },
      addedItems: () => {
        const items = {
          ...itemsSelected
        };
        Object.keys(items).forEach((uuid) => {
          if (
            items[uuid].status !== "added" &&
            items[uuid].status !== "addedSelected"
          ) {
            delete items[uuid];
          }
        });
        return items;
      },
      itemsSelected
    }),
    [itemsSelected]
  );
}
