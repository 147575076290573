import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { SelectAllResetButton } from "./SelectAllResetButton";
import { Destination } from "../generated/admin";
import { useDestinationSelectAll } from "../hooks/useDestinationSelectAll";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { CapsuleButton } from "./CapsuleButton";

const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(
    ${(props: { totalRow: number }) => props.totalRow},
    1fr
  );
`;

interface DestinationsCheckboxProps {
  destinations: Pick<Destination, "uuid" | "name">[];
  selectedUuids: string[];
  checkboxName?: string;
  totalColumnDisplay?: number;
  disabled?: boolean;
}

/**
 * Destinations checkbox.
 */
export const DestinationsCheckbox: React.FC<DestinationsCheckboxProps> = ({
  destinations,
  selectedUuids,
  checkboxName = "destinations",
  totalColumnDisplay = 4,
  disabled = false,
}) => {
  const { register } = useFormContext();
  const {
    totalRow,
    allDestinations,
    onSelectAll: onDestinationSelectAll,
    onReset: onDestinationReset,
    onClick: onDestinationClicked,
  } = useDestinationSelectAll(destinations, selectedUuids, totalColumnDisplay);

  if (allDestinations.length === 0) {
    return <></>;
  }

  return (
    <>
      <Container>
        <Row className="border-bottom border-dark py-2">
          <Col className="text-right">
            <CapsuleButton onClick={onDestinationSelectAll}>
              Select all
            </CapsuleButton>
            <SelectAllResetButton onClick={onDestinationReset} />
          </Col>
        </Row>
      </Container>
      <Grid totalRow={totalRow}>
        {allDestinations.map((d, i) => {
          return (
            <div className="py-2 border-bottom" key={d.uuid}>
              {d.dummy ? (
                " "
              ) : (
                <Form.Check
                  value={d.uuid}
                  type="checkbox"
                  id={`checkbox-${d.uuid}`}
                  data-testid={`checkbox-${d.uuid}`}
                  label={d.name}
                  {...register(checkboxName)}
                  checked={d.selected}
                  disabled={disabled}
                  onClick={(
                    e: React.MouseEvent<HTMLInputElement, MouseEvent>
                  ) => onDestinationClicked(d.uuid, e.shiftKey)}
                  onChange={() => {}}
                />
              )}
            </div>
          );
        })}
      </Grid>
    </>
  );
};
