import React from "react";
import { components, DropdownIndicatorProps } from "react-select";
import { Option } from "./Option";
import { FaSearch } from "react-icons/fa";
import { colors } from "../../../../components/colors";

const style = { marginRight: "10px", color: colors.black };

export const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => (
  <components.DropdownIndicator {...props}>
    <FaSearch style={style} />
  </components.DropdownIndicator>
);
