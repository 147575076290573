import React, { useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { validate } from "uuid";
import { TextFormGroup } from "../../fields/TextFormGroup";
import { OptionType } from "../../../../../model/OptionType";
import { CardAccordion } from "../../../../../components/SingleEqAccordion";
import { useCreateAc1IntegrationMutation } from "../../../../../generated/admin";
import { trimmedOrUndefined } from "../../../../../util/trimmedOrUndefined";
import { validURL } from "../../../../../util/validURL";
import { stringNotEmpty } from "../../../../../util/stringNotEmpty";
import {
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";

import { useAc1Data } from "./useAc1Data";

interface FormValues {
  name: string;
  provider: string | null;
  accessPassName: string;
  externalApiBaseUrl: string;
  externalApiDataUrl: string;
  externalApiKey: string;
  externalApiSuffix: string;
  externalApiTokenSuffix: string;
  externalClientId: string;
  externalClientPassword: string;
  externalClientScope: string;
  externalClientSecret: string;
  externalClientUsername: string;
  connectionAndKeyPassword: string;
  connectionCertificate: string;
  connectionKey: string;
  config: string;
}

const initialValues: FormValues = {
  name: "",
  provider: null,
  accessPassName: "",
  externalApiBaseUrl: "",
  externalApiDataUrl: "",
  externalApiKey: "",
  externalApiSuffix: "",
  externalApiTokenSuffix: "",
  externalClientId: "",
  externalClientPassword: "",
  externalClientScope: "",
  externalClientSecret: "",
  externalClientUsername: "",
  connectionAndKeyPassword: "",
  connectionCertificate: "",
  connectionKey: "",
  config: "",
};

interface Props {
  siteUuid: string;
}

export const NewIntegrationConfiguration: React.FC<Props> = ({ siteUuid }) => {
  const [createAc1Integration, { loading }] = useCreateAc1IntegrationMutation();
  const { providerOptions } = useAc1Data(siteUuid);

  const handleValidate = useCallback((values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (!stringNotEmpty(values.name)) {
      errors.name = "Required.";
    }
    if (values.provider === null) {
      errors.provider = "Required.";
    }
    if (!stringNotEmpty(values.accessPassName)) {
      errors.accessPassName = "Required.";
    }

    if (!stringNotEmpty(values.externalApiBaseUrl)) {
      errors.externalApiBaseUrl = "Required.";
    } else if (!validURL(values.externalApiBaseUrl)) {
      errors.externalApiBaseUrl =
        "Please enter a valid URL. For example: https://api.cert.origo.hidglobal.com";
    }

    if (
      stringNotEmpty(values.externalApiDataUrl) &&
      !validURL(values.externalApiDataUrl)
    ) {
      errors.externalApiDataUrl =
        "Please enter a valid URL. For example: https://api.cert.origo.hidglobal.com";
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(
    async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        if (siteUuid == null || !validate(siteUuid)) {
          return;
        }

        if (values.provider == null) {
          return;
        }

        const result = await createAc1Integration({
          variables: {
            input: {
              siteUuid,
              provider: values.provider,
              name: values.name.trim(),
              accessPassName: values.accessPassName.trim(),
              externalApiBaseUrl: values.externalApiBaseUrl.trim(),
              externalApiDataUrl: trimmedOrUndefined(values.externalApiDataUrl),
              externalApiKey: trimmedOrUndefined(values.externalApiKey),
              externalApiSuffix: trimmedOrUndefined(values.externalApiSuffix),
              externalApiTokenSuffix: trimmedOrUndefined(
                values.externalApiTokenSuffix
              ),
              externalClientId: trimmedOrUndefined(values.externalClientId),
              externalClientPassword: trimmedOrUndefined(
                values.externalClientPassword
              ),
              externalClientScope: trimmedOrUndefined(
                values.externalClientScope
              ),
              externalClientSecret: trimmedOrUndefined(
                values.externalClientSecret
              ),
              externalClientUsername: trimmedOrUndefined(
                values.externalClientUsername
              ),
              connectionAndKeyPassword: trimmedOrUndefined(
                values.connectionAndKeyPassword
              ),
              connectionCertificate: stringNotEmpty(
                values.connectionCertificate
              )
                ? values.connectionCertificate
                : undefined,
              connectionKey: trimmedOrUndefined(values.connectionKey),
              config: stringNotEmpty(values.config) ? values.config : undefined,
            },
          },
          refetchQueries: ["Ac1Integrations"],
        });

        if (result.errors != null && result.errors.length > 0) {
          console.error(result.errors);
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          text: "Successfully saved integration configuration.",
        });
        resetForm({ values: initialValues });

        return result;
      } catch (e) {
        console.error(e);
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [siteUuid, createAc1Integration]
  );

  return (
    <div>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={handleValidate}
      >
        {(methods) => (
          <CardAccordion
            cardClassName="flex-nowrap"
            title="Set up a new integration"
            collapse={true}
          >
            <div data-testid="new-integration-configuration">
              <TextFormGroup
                title="Name"
                subText="The unique name for this integration configuration."
                name="name"
                error={methods.errors.name}
              />
              <Form.Group as={Row} controlId="provider">
                <Form.Label column md="3">
                  Provider
                </Form.Label>
                <Col md="9" lg="6" className="provider-select">
                  <Select<OptionType, boolean>
                    classNamePrefix="eq"
                    isSearchable={false}
                    options={providerOptions}
                    value={
                      providerOptions.find(
                        (x) => x.value === methods.values.provider
                      ) ?? null
                    }
                    onChange={({ value }: any) => {
                      methods.setFieldValue("provider", value);
                    }}
                    placeholder="Select..."
                    aria-label="provider-select"
                    className={`react-select${
                      methods.errors.provider != null ? " is-invalid" : ""
                    }`}
                  />
                  <Form.Text className="text-muted">
                    Select the provider for this integration configuration.
                  </Form.Text>
                  {methods.errors.provider && methods.touched.provider && (
                    <Form.Control.Feedback type="invalid">
                      {methods.errors.provider}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>
              <TextFormGroup
                title="Pass Name"
                subText="The unique name that the user will see in the mobile app."
                name="accessPassName"
                error={methods.errors.accessPassName}
              />
              <TextFormGroup
                title="Provider API Base URL"
                subText="The base URL for the provider API."
                name="externalApiBaseUrl"
                error={methods.errors.externalApiBaseUrl}
              />
              <h5>Optional Fields</h5>
              <p className="pb-4 pt-3">
                The following fields are optional and can be left blank if not
                applicable.
              </p>
              <TextFormGroup
                title="Provider API Data URL"
                subText="The data URL for the provider API."
                name="externalApiDataUrl"
                error={methods.errors.externalApiDataUrl}
              />
              <TextFormGroup
                title="Provider API Key"
                subText="The API key for the provider API."
                name="externalApiKey"
              />
              <TextFormGroup
                title="Provider API Suffix"
                subText="The suffix for the provider API."
                name="externalApiSuffix"
              />
              <TextFormGroup
                title="Provider API Token Suffix"
                subText="The token suffix for the provider API."
                name="externalApiTokenSuffix"
              />
              <TextFormGroup
                title="Provider Client ID"
                subText="The client ID for the provider client."
                name="externalClientId"
              />
              <TextFormGroup
                title="Provider Client Password"
                subText="The password for the provider client."
                name="externalClientPassword"
              />
              <TextFormGroup
                title="Provider Client Scope"
                subText="The scope for the provider client."
                name="externalClientScope"
              />
              <TextFormGroup
                title="Provider Client Secret"
                subText="The secret for the provider client."
                name="externalClientSecret"
              />
              <TextFormGroup
                title="Provider Client Username"
                subText="The username for the provider client."
                name="externalClientUsername"
              />
              <TextFormGroup
                title="Connection and Key Password"
                subText="The connection and key password."
                name="connectionAndKeyPassword"
              />
              <TextFormGroup
                title="Connection Certificate"
                subText="The connection certificate."
                name="connectionCertificate"
                rows={12}
                asTextarea
              />
              <TextFormGroup
                title="Connection Key"
                subText="The connection key."
                name="connectionKey"
                rows={12}
                asTextarea
              />
              <TextFormGroup
                title="Misc Configuration"
                subText="Any other extra configuration in JSON"
                name="config"
                rows={12}
                asTextarea
              />
            </div>
            <div className="text-right">
              <Button
                name="createIntegrationConfiguration"
                variant="outline-primary"
                size="sm"
                className="align-self-center m-2"
                onClick={() => methods.handleSubmit()}
                disabled={loading || !methods.isValid}
              >
                {loading ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Create Integration Configuration"
                )}
              </Button>
            </div>
          </CardAccordion>
        )}
      </Formik>
    </div>
  );
};
