import { FormikProps, useFormikContext } from "formik";
import { WhitelabelFormData } from "../WhitelabelFormData";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";
import { WhitelabelStatus } from "../../../../generated/admin";
import { CreateButton } from "../../../../components/CreateButton";

interface Props {
    methods: FormikProps<WhitelabelFormData>;
}

export const DevelopmentReadyButton: React.FC<Props> = ({methods}) => {
    const fm = useFormikContext<WhitelabelFormData>();
    const showButton = fm.values.status === WhitelabelStatus.Draft && 
    stringNotEmpty(fm.values.client) &&
    stringNotEmpty(fm.values.name) &&
    stringNotEmpty(fm.values.appName) &&
    stringNotEmpty(fm.values.appleAccountName) &&
    stringNotEmpty(fm.values.appleCompanyName) &&
    stringNotEmpty(fm.values.googleAccountName) &&
    stringNotEmpty(fm.values.description) &&
    stringNotEmpty(fm.values.subtitle) &&
    stringNotEmpty(fm.values.keywords) &&
    fm.values.categories.length > 0 &&
    stringNotEmpty(fm.values.privacyPolicyUrl) &&
    stringNotEmpty(fm.values.supportUrl) &&
    stringNotEmpty(fm.values.contactEmail) &&
    stringNotEmpty(fm.values.language) &&
    stringNotEmpty(fm.values.releaseNotes) &&
    stringNotEmpty(fm.values.android.featureGraphic) &&
    stringNotEmpty(fm.values.appleKey.id) &&
    stringNotEmpty(fm.values.androidKey.id) &&
    stringNotEmpty(fm.values.ios.appIcon) &&
    stringNotEmpty(fm.values.ios.splashScreen) &&
    stringNotEmpty(fm.values.ios.myAccountLarge) &&
    stringNotEmpty(fm.values.android.appIcon) &&
    stringNotEmpty(fm.values.android.pushNotification) &&
    stringNotEmpty(fm.values.android.myAccountLargest);
    return showButton ? (
      <CreateButton
        type="submit"
        disabled={methods.isSubmitting}
        onClick={() => {
          methods.setFieldValue(
            "status",
            WhitelabelStatus.ReadyForDevelopment
          );
          methods.handleSubmit();
        }}
        className={`mr-4 float-right`}
      >
        Mark Complete
      </CreateButton>
     ) : null;
  }