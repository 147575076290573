import { FormikContextType } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { WhitelabelImageUpload } from "./WhitelabelImageUpload";
import { WhitelabelFormData } from "./WhitelabelFormData";
import { DownloadButton } from "./components/DownloadButton";
import { EqMessageInfo } from "../../message/EqMessage";

interface Props {
  methods: FormikContextType<WhitelabelFormData>;
}

export const WhitelabelIos: React.FC<Props> = ({ methods }) => {
  const hasAtLeastOneImage = methods.values.ios.appIcon != null || methods.values.ios.splashScreen != null || methods.values.ios.myAccountLarge != null;
  return (
    <>
      { hasAtLeastOneImage ?
        <Row>
          <Col className="text-right">
            <DownloadButton
              onClick={() => {
                if (methods.values.ios.compressedUrl == null) {
                  EqMessageInfo({
                    text: "We're working on your iOS images. Please come back later to see the results.",
                  });
                } else {
                  window.open(methods.values.ios.compressedUrl, "_blank");
                }
              }}
            >
              Download all
            </DownloadButton>
          </Col>
        </Row>
        : null
      }
      <WhitelabelImageUpload
        title="App icon"
        height={1024}
        width={1024}
        alphaChannel={false}
        hint={
          "RGB colour space (no transparency), Must not contain layers or rounded corners."
        }
        existingImage={methods.values.ios.appIcon}
        fieldName="ios.appIcon"
        methods={methods}
      />
      <WhitelabelImageUpload
        title="Splash screen"
        height={2436}
        width={1125}
        hint={
          "This asset is used in aspect fill mode resulting in cropping of this image on some devices. Please ensure any core content such as copy is within the bounds of 3:4 ratio."
        }
        existingImage={methods.values.ios.splashScreen}
        fieldName="ios.splashScreen"
        methods={methods}
      />
      <WhitelabelImageUpload
        title="My account background"
        height={549}
        width={1125}
        existingImage={methods.values.ios.myAccountLarge}
        fieldName="ios.myAccountLarge"
        methods={methods}
      />
    </>
  );
};
