import React from "react";
import { Form } from "react-bootstrap";
import { FormikContextType } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";

interface Props {
  methods: FormikContextType<SiteConfigFormData>;
}

export const InactiveUser: React.FC<Props> = ({ methods }) => {
  const autoDeactivateUser = methods.values.autoDeactivateUsers ?? false;
  const autoDeactivate3MonthReminder =
    methods.values.autoDeactivate3MonthReminder ?? false;
  const autoDeactivate6MonthReminder =
    methods.values.autoDeactivate6MonthReminder ?? false;
  const autoDeactivate9MonthReminder =
    methods.values.autoDeactivate9MonthReminder ?? false;

  return (
    <div className="pb-5">
      <h5 className="mb-4">Inactive Users</h5>
      <p>
        Inactive users can automatically be removed from a site after 12 months
        of inactivity. Enabling this setting will enable further settings to
        configure the frequency of email reminders that the user receives,
        prompting them to log back into the site. User profiles will remain
        active, enabling them to reinstate their profile at a future date.
      </p>
      <Form.Check
        type="switch"
        id="deactivation"
        label="Enable automatic removal of inactive users from your site"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          methods.setFieldValue(
            "autoDeactivateUsers",
            e.target.checked ?? false
          );
        }}
        defaultChecked={autoDeactivateUser}
      />
      {autoDeactivateUser && (
        <div className="pt-4">
          <p>
            Automated email reminders can be scheduled at quarterly intervals.
            All users will receive an email at 12 months of inactivity in
            addition to the emails enabled below.
          </p>
          <Form.Check
            className="mb-3"
            type="switch"
            id="deactivation-3month"
            label="Send an email after 3 months of inactivity"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setFieldValue(
                "autoDeactivate3MonthReminder",
                e.target.checked ?? false
              );
            }}
            defaultChecked={autoDeactivate3MonthReminder}
          />

          <Form.Check
            className="mb-3"
            type="switch"
            id="deactivation-6month"
            label="Send an email after 6 months of inactivity"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setFieldValue(
                "autoDeactivate6MonthReminder",
                e.target.checked ?? false
              );
            }}
            defaultChecked={autoDeactivate6MonthReminder}
          />

          <Form.Check
            className="mb-3"
            type="switch"
            id="deactivation-9month"
            label="Send an email after 9 months of inactivity"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              methods.setFieldValue(
                "autoDeactivate9MonthReminder",
                e.target.checked ?? false
              );
            }}
            defaultChecked={autoDeactivate9MonthReminder}
          />
        </div>
      )}
    </div>
  );
};
