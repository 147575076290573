import styled from "styled-components";

export const SuggestionContainer = styled.div.attrs({
  className: "border border-light"
})`
  background: #f6f6f6;

  .list {
    .name {
      margin: 1px 0;
    }
    .btn-action {
      background: #f6f6f6;
      display: none;
    }

    &:hover {
      background: #d8d8d8;

      .btn-action {
        display: block;
      }
    }
  }
`;
