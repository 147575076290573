import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext
} from "react";
import {
  useCompanyIndustriesQuery,
  useUpsertIndustriesMutation,
  useDeleteIndustryMutation
} from "../../../../generated/admin";
import {
  EqMessageError,
  EqMessageSuccess,
  MessageFn
} from "../../../message/EqMessage";
import { Industry } from "../model/Industry";

interface State {
  readonly industries: Industry[];
  readonly initalLoadError?: { message: string };
  readonly saveErrors: Array<{ message: string }>;
  readonly loading: boolean;
  readonly newIndustryName: string;
  readonly industriesToAdd: Array<{ name: string }>;
  readonly addingIndustry: boolean;
  readonly deleting: string;
}

export interface IndustryStore extends State {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addIndustry: () => void;
  updateLocalIndustry: (
    original: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeIndustry: (name: string) => void;
  deleteIndustry: (uuid: string) => Promise<void>;
  updateIndustry: (uuid: string, name: string) => void;
  addIndustries: () => Promise<void>;
}

export function useIndustryStoreFactory(
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
): IndustryStore {
  const [state, setState] = useState<State>({
    industries: [],
    saveErrors: [],
    loading: false,
    newIndustryName: "",
    industriesToAdd: [],
    addingIndustry: false,
    deleting: ""
  });

  const set = (update: Partial<State>) =>
    setState((state) => ({ ...state, ...update }));

  const results = useCompanyIndustriesQuery({
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: results.loading,
      initalLoadError: results.error,
      industries: results.data?.companyIndustries ?? []
    }));
  }, [results]);

  const [deleteMutation] = useDeleteIndustryMutation();

  const [industryUpsertMutation, addResult] = useUpsertIndustriesMutation();
  useEffect(() => {
    set({ addingIndustry: addResult.loading });
  }, [addResult.loading]);

  return useMemo(
    () => ({
      ...state,
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const newIndustryName = event.target.value;
        setState((state) => ({
          ...state,
          newIndustryName
        }));
      },
      addIndustry: () => {
        if (state.newIndustryName === "") {
          errorMsgFn({ text: "Please click on add before saving." });
          return;
        }
        if (
          state.industriesToAdd
            .map((l) => l.name)
            .includes(state.newIndustryName)
        ) {
          errorMsgFn({ text: "Cannot create duplicate industry name." });
          return;
        }
        setState((state) => ({
          ...state,
          industriesToAdd: [
            ...state.industriesToAdd,
            { name: state.newIndustryName }
          ],
          newIndustryName: ""
        }));
      },
      addIndustries: async () => {
        if (state.industriesToAdd.length === 0) {
          errorMsgFn({
            text: "Please click on add before saving."
          });
          return;
        }

        const result = await industryUpsertMutation({
          variables: {
            input: state.industriesToAdd
          }
        });

        const addedIndustries: Industry[] =
          result.data?.upsertIndustries.flatMap((item) => {
            if (item.__typename === "IndustrySuccessResponse") {
              return [
                {
                  uuid: item.industry.uuid,
                  name: item.industry.name
                }
              ];
            }

            return [];
          }) ?? [];

        const errors =
          result.data?.upsertIndustries.flatMap((item) => {
            if (item.__typename === "FailureResponse") {
              return [item.reason];
            }
            return [];
          }) ?? [];

        setState((state) => ({
          ...state,
          industries: [...state.industries, ...addedIndustries],
          saveErrors: errors.map((message) => ({
            message
          })),
          industriesToAdd: []
        }));

        if (errors.length > 0) {
          errorMsgFn({
            html: errors.join("<br/>")
          });
        }
        if (addedIndustries.length > 0) {
          successMsgFn({
            text: `${
              addedIndustries.length > 1 ? "Industries" : "Industry"
            } created.`
          });
        }
      },
      deleteIndustry: async (uuid: string) => {
        setState((state) => ({ ...state, deleting: uuid }));

        const result = await deleteMutation({ variables: { uuid } });
        setState((state) => ({ ...state, deleting: "" }));

        if (result.data == null) {
          errorMsgFn({
            text: "Something went wrong, please refresh the page and try again."
          });
          return;
        }

        if (result.data.deleteIndustry.__typename === "FailureResponse") {
          errorMsgFn({ text: result.data.deleteIndustry.reason });
          return;
        }

        successMsgFn({ text: "Industry has been deleted!" });
        setState((state) => ({
          ...state,
          industries: state.industries.filter((l) => l.uuid !== uuid)
        }));
      },
      removeIndustry: (name: string) => {
        setState((state) => ({
          ...state,
          industriesToAdd: state.industriesToAdd.filter((l) => l.name !== name)
        }));
      },
      updateIndustry: (uuid: string, name: string) => {
        setState((state) => ({
          ...state,
          industries: state.industries.map((l) => {
            if (l.uuid === uuid) {
              return {
                ...l,
                name,
              }
            }
            return l;
          })
        }));
      },
      updateLocalIndustry: (original: string) => (
        e: React.ChangeEvent<HTMLInputElement>
      ) => {
        setState((state) => ({
          ...state,
          industriesToAdd: state.industriesToAdd.map((l) =>
            l.name === original ? { name: e.target.value } : l
          )
        }));
      }
    }),
    [state, deleteMutation, errorMsgFn, industryUpsertMutation, successMsgFn]
  );
}

export const IndustryContext = createContext<IndustryStore | null>(null);

export function useIndustryStore(): IndustryStore {
  const store = useContext(IndustryContext);
  if (store == null) {
    throw Error("Industry store context not initialized.");
  }
  return store;
}

export const IndustryStoreProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const store = useIndustryStoreFactory();

  return (
    <IndustryContext.Provider value={store}>
      {children}
    </IndustryContext.Provider>
  );
};
