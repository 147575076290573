import React from "react";
import { Spinner, ProgressBar, Button } from "react-bootstrap";
import {
  useImageUploadActions,
  UseImageUploadActionsProps,
} from "./useImageUploadActions";
import axios from "axios";
import { FormikContextType } from "formik";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import Select from "react-select";

const UploadContainer = styled.div.attrs({
  className: "p-2",
})`
  background: #f7f7f7;
`;

type AllowedExtension = "JPG" | "PNG" | "GIF" | "ICO";
interface Props<T> {
  fieldName: string;
  existingImage: string | null | undefined;
  methods: FormikContextType<T>;
  allowedExtension?: AllowedExtension[];
  restrictions?: UseImageUploadActionsProps["restrictions"];
  sizeDropdown?: {
    selectedValue: string;
    options: Array<{ label: string; value: string }>;
    onChange: (option: any) => void;
  };
}

export const ImageUpload = <T extends unknown>({
  fieldName,
  existingImage,
  methods,
  restrictions,
  sizeDropdown,
}: Props<T>) => {
  const { uploading, onChange, onRemove, displayPic, uploadProgress } =
    useImageUploadActions({
      existingPhoto: existingImage ?? null,
      axios,
      restrictions,
      addToForm: (pic: string) => methods.setFieldValue(fieldName, pic),
      removeFromForm: () => methods.setFieldValue(fieldName, null),
    });

  return (
    <UploadContainer>
      {uploading ? (
        <div className="text-center">
          {uploadProgress != null ? (
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
          ) : (
            <>
              <Spinner size="sm" animation="grow" /> Loading...
            </>
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center">
          {displayPic && (
            <a
              href={displayPic}
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img
                className="w-70-px h-70-px object-fit-cover border border-dark"
                alt=""
                src={displayPic}
              />
            </a>
          )}

          {!uploading && (
            <>
              {displayPic && sizeDropdown != null ? (
                <div className="w-50">
                  <Select
                    placeholder="Select size..."
                    classNamePrefix="eq"
                    options={sizeDropdown.options}
                    value={sizeDropdown.options.find(
                      (o) => o.value === sizeDropdown.selectedValue
                    )}
                    onChange={sizeDropdown.onChange}
                    className="react-select"
                  />
                </div>
              ) : (
                <div>
                  <input style={{
                    color: "transparent",
                    overflow: "hidden",
                    borderBottom: "none",
                  }} className="eq-img-upload" type="file" onChange={(e) => onChange(e.target.files as any)} accept="image/*" multiple={false} />
                </div>
              )}
            </>
          )}

          {!uploading && displayPic && (
            <div className="ml-auto">
              <Button variant="link" className="p-0" onClick={onRemove}>
                <FaTimes className="text-danger" size="20px" />
              </Button>
            </div>
          )}
        </div>
      )}
    </UploadContainer>
  );
};
