import styled from "styled-components";
import { Table } from "react-bootstrap";

interface FlushRightTableProps {
  positionfixed?: boolean;
  alignlast?: "left" | "right" | "center";
}

export const FlushRightTable = styled(Table).attrs({
  className: "eq-table",
})`
  table-layout: ${({ positionfixed = false }: FlushRightTableProps) =>
    positionfixed ? "fixed" : undefined};
  & td {
    word-wrap: break-word;
  }
  & tr td:last-child {
    white-space: nowrap;
    width: 1px;
    text-align: ${({ alignlast = "right" }: FlushRightTableProps) => alignlast};
  }
  & thead tr th:last-child {
    text-align: left;
  }

  &&& th {
    border-bottom: 1px solid #000000;
    border-top: 1px solid #000000;
    vertical-align: top;
  }
`;
