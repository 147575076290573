import React from "react";
import { PageHeading } from "../../../../components/PageHeading";
import { Col, Container, Row } from "react-bootstrap";
import { CreateButton } from "../../../../components/CreateButton";
import { FormikContextType } from "formik";
import { WhitelabelFormData } from "../WhitelabelFormData";
import { DevelopmentReadyButton } from "./DevelopmentReadyButton";
import { DeleteWhitelabelButton } from "./DeleteWhitelabelButton";

interface Props {
    title: string;
    methods: FormikContextType<WhitelabelFormData>;
}

export const DetailsPageHeading: React.FC<Props> = ({ title, methods }) => {
    return (<PageHeading
        isSticky
        title={
        <Container className="pl-0" fluid>
            <Row className="justify-content-between">
            <Col md="6">{title}</Col>
            <Col md="6">
                <CreateButton
                type="submit"
                disabled={methods.isSubmitting}
                onClick={methods.handleSubmit}
                className={`mr-4 float-right`}
                >
                Save
                </CreateButton>
                <DevelopmentReadyButton methods={methods} />
                <DeleteWhitelabelButton methods={methods} />
            </Col>
            </Row>
        </Container>
        }
        ></PageHeading>
    );
};