import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";

export const TableButton = styled.span`
  cursor: pointer;
  color: var(--primary);
  &:hover {
    text-decoration: underline;
  }
`;

export const ActionButton: React.FC<{
  actionLabel: string;
  restLabel: string;
  isInAction: boolean;
  onClick?: MouseEventHandler;
}> = ({ actionLabel, restLabel, isInAction, onClick }) => {
  return (
    <TableButton onClick={onClick}>
      {isInAction ? (
        <span>
          <Spinner animation="grow" size="sm" /> {actionLabel}
        </span>
      ) : (
        restLabel
      )}
    </TableButton>
  );
};
