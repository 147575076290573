import React, { useState, useCallback } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  type SiteDetailsQuery,
  useConnectGallagherMutation,
} from "../../../../generated/admin";
import { useConfig } from "../../../../providers/ConfigProvider";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";
import { trimmedOrUndefined } from "../../../../util/trimmedOrUndefined";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { Password } from "./Password";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

interface FormValue {
  invalid: boolean;
  value: string | null;
}

interface ConnectForm {
  label: FormValue;
  apiGateway: FormValue;
  apiKey: FormValue;
  useIntegrationLicense: boolean;
}

export const GallagherConnectForm: React.FC<Props> = ({ dest }) => {
  const { region } = useConfig();
  const gatewayRegion: "au" | "us" = ["au", "staging"].includes(region)
    ? "au"
    : "us";
  const defaultState: ConnectForm = {
    label: { invalid: false, value: "Gallagher" },
    apiGateway: {
      invalid: false,
      value: `commandcentre-api-${gatewayRegion}.security.gallagher.cloud`,
    },
    apiKey: { invalid: false, value: null },
    useIntegrationLicense: false,
  };

  const [mutation, { loading }] = useConnectGallagherMutation({
    refetchQueries: ["SiteDetails"],
  });
  const [state, setState] = useState<ConnectForm>(defaultState);

  const connectAccount = useCallback(async () => {
    if (state.apiGateway.invalid || state.apiKey.invalid || loading) {
      return;
    }

    try {
      const result = await mutation({
        variables: {
          label:
            state.label.value?.trim() === "Gallagher"
              ? undefined
              : trimmedOrUndefined(state.label.value),
          apiGateway: state.apiGateway.value ?? "",
          apiKey: state.apiKey.value ?? "",
          site: dest.uuid,
          useIntegrationLicense: state.useIntegrationLicense,
        },
      });

      if (null == result.data?.gallagher?.connect) {
        throw new Error("Connection returning no data.");
      }

      if (
        "GallagherAuthenticationFailure" ===
        result.data.gallagher.connect.__typename
      ) {
        throw new Error(result.data.gallagher.connect.reason);
      }

      EqMessageSuccess({ text: "Successfully connected to Gallagher." });
    } catch (e) {
      EqMessageError({
        text: e instanceof Error ? e.message : "Unknown error.",
      });
    }
  }, [state, mutation, dest.uuid, loading]);

  const apiGatewayOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((connectForm) => ({
        ...connectForm,
        apiGateway: { invalid: !stringNotEmpty(value), value },
      }));
    },
    []
  );

  const apiKeyOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setState((connectForm) => ({
        ...connectForm,
        apiKey: { invalid: !stringNotEmpty(value), value },
      }));
    },
    []
  );

  return (
    <div>
      Complete the below fields to connect to the Gallagher API. Once completed,
      Gallagher access control will be available in the mobile app.
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          Label
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="gallagherLabel"
            type="text"
            isInvalid={state.label.invalid}
            disabled={loading}
            onChange={(e) =>
              setState((connectForm) => ({
                ...connectForm,
                label: { value: e.target.value, invalid: false },
              }))
            }
            defaultValue={state.label.value ?? undefined}
          />
          <Form.Text className="text-muted">
            Integration name used as label in the mobile app
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group className="pt-4" as={Row}>
        <Form.Label column md="3" lg="2">
          API gateway
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Control
            name="gallagherApiGateway"
            type="text"
            isInvalid={state.apiGateway.invalid}
            disabled={loading}
            onChange={apiGatewayOnChange}
            defaultValue={state.apiGateway.value ?? undefined}
          />
          <Form.Text className="text-muted">
            Enter the Gallagher gateway to connect to
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          API key
        </Form.Label>
        <Col md="9" lg="6">
          <Password
            name="gallagherApiKey"
            onPasswordChange={apiKeyOnChange}
            loading={loading}
            isInvalid={state.apiKey.invalid}
          />
          <Form.Text className="text-muted">
            Enter your Gallagher API key
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column md="3" lg="2">
          Enable Equiem Integration Licence
        </Form.Label>
        <Col md="9" lg="6">
          <Form.Check
            id="gallagherUseIntegrationLicense"
            className="mt-2"
            type="switch"
            onChange={(e) => {
              setState((connectForm) => ({
                ...connectForm,
                useIntegrationLicense: e.target.checked,
              }));
            }}
            defaultChecked={state.useIntegrationLicense}
          />
          <Form.Text className="text-muted">
            Enable if you’re using Gallagher system version 9 or above and have
            bought the Equiem Licence. This will add the required licence to API
            requests. If you’re on version 8 or have only bought the API
            licences separately, leave this off
          </Form.Text>
        </Col>
      </Form.Group>
      <div className="text-right">
        <Button
          name="gallagherConnect"
          variant="outline-primary"
          onClick={connectAccount}
          disabled={loading}
        >
          {loading ? (
            <span>
              <Spinner size="sm" animation="grow" /> Loading...
            </span>
          ) : (
            "Connect account"
          )}
        </Button>
      </div>
    </div>
  );
};
