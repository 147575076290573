import React, { useMemo } from "react";
import { OptionType } from "../../../model/OptionType";
import Select, { StylesConfig } from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import {
  UserStatus,
  UserFormData,
  userFormNameCheck,
} from "./model/UserFormData";
import { colors } from "../../../components/colors";
import { ProfileStatus } from "../../../generated/admin";

interface Props {
  status: ProfileStatus;
  syndicateActive: boolean;
}

const options = [
  {
    value: UserStatus.Active,
    label: "Active",
    color: colors.green,
  },
  {
    value: UserStatus.Deactivated,
    label: "Deactivated",
    color: colors.danger,
  },
];

const styles: StylesConfig<OptionType & { color: string }> = {
  option: (styles, { data, isSelected, isFocused }) => {
    return {
      ...styles,
      color: data.color,
      fontWeight: 400,
      backgroundColor: isSelected ? "#ddd" : isFocused ? "#f2f2f2" : undefined,
    };
  },
  singleValue: (styles, { data }) => {
    return { ...styles, color: data.color, fontWeight: 400 };
  },
};

export const onChangeSelected = (selected: any) => selected;

/**
 * User form user status dropdown.
 */
export const UserFormUserStatusDropdown: React.FC<Props> = ({
  syndicateActive,
  status,
}) => {
  const methods = useFormContext<UserFormData>();

  const defaultValue = useMemo(
    () =>
      syndicateActive
        ? {
            value: UserStatus.Active,
            label: "Active",
            color: colors.green,
          }
        : status === ProfileStatus.PendingApproval
        ? {
            value: UserStatus.PendingApproval,
            label: "Pending approval",
            color: colors.orange,
          }
        : {
            value: UserStatus.Deactivated,
            label: "Deactivated",
            color: colors.danger,
          },
    [status, syndicateActive]
  );

  return (
    <Controller
      render={({field: { value, onChange }}) =>
        <Select<OptionType & { color: string }>
          classNamePrefix="eq"
          options={options}
          isClearable={false}
          placeholder="Select status"
          className={`react-select`}
          styles={styles}
          onChange={onChange}
          value={value as OptionType & { color: string }}
        />
      }
      control={methods.control}
      defaultValue={defaultValue}
      name={userFormNameCheck("status")}
    />
  );
};
