import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { EqTable } from "../../../../components/Table";
import { Sorter } from "../../../../components/Sorter";
import {
  DestinationLevelsQuery,
  useDeleteAreaMutation,
  DestinationLevelsDocument,
} from "../../../../generated/admin";
import { Area } from "../BuildingDetailsGeneral";
import { AreaForm } from "./AreaForm";
import { deleteCheck } from "../../../../components/deleteCheck";
import { EqMessageSuccess, EqMessageError } from "../../../message/EqMessage";

export const AreaTable = ({ data }: { data: DestinationLevelsQuery }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [area, setArea] = useState<Area | undefined>();
  const [deleted, setDeleted] = useState<string[]>([]);
  const [deleting, setDeleting] = useState<string[]>([]);
  const [deleteArea] = useDeleteAreaMutation();
  const [sort, setSort] = React.useState<{
    field: string;
    asc: boolean;
  }>({ field: "name", asc: true });

  const sorter = useMemo(() => new Sorter([{ key: "name", label: "Area" }], sort, setSort), [sort]);

  return (
    <>
      <EqTable<Area>
        items={
          data?.destination.areas?.filter((a) => !deleted.includes(a.uuid)) ??
          []
        }
        emptyMessage="No areas have been created yet."
        operations={[
          () => ({
            label: "Edit",
            actionLabel: "...Editing",
            isInAction: false,

            operation: (item) => {
              setArea(item);
              handleShow();
            },
          }),
          (i) => ({
            label: "Delete",
            actionLabel: "...Deleting",
            isInAction: deleting.includes(i.uuid),
            operation: async (item) => {
              setDeleting((d) => [...d, item.uuid]);
              await deleteCheck(
                async () => {
                  const result = await deleteArea({
                    variables: {
                      uuid: item.uuid,
                    },
                    refetchQueries: [
                      {
                        query: DestinationLevelsDocument,
                        variables: {
                          uuid: data.destination.uuid,
                        },
                      },
                    ],
                  });
                  if (result.data?.deleteArea) {
                    setDeleted([...deleted, item.uuid]);
                    EqMessageSuccess({
                      text: "Area has been deleted!",
                    });
                  } else {
                    EqMessageError({
                      text: "Failed to delete area",
                    });
                  }
                },
                { confirmButtonText: "Delete area" }
              );
              setDeleting((d) => d.filter((it) => it !== item.uuid));
            },
          }),
        ]}
        sorter={sorter}
      />
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {area?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data?.destination != null ? (
            <AreaForm destination={data.destination} area={area} />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};
