import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch, FaTimes } from "react-icons/fa";

export const SearchBar = ({
  value,
  onChange,
  placeholder,
  clear,
  disabled = false,
}: {
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  clear: () => void;
  disabled?: boolean;
}) => {
  return (
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text id="basic-addon1" className="bg-secondary">
          <FaSearch />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        className="form-control border border-black-60 border-right-0 font-weight-lighter"
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      <div className="d-flex align-items-center ml-n2 px-2 border border-black-60 rounded bg-white">
        {value ? <FaTimes className="cursor-pointer" onClick={clear} /> : null}
      </div>
    </InputGroup>
  );
};
