import React from "react";
import { PageNavigationButton } from "../../components/NavigationButton";
import { RootRoutes } from "../../RouterOutlet";
import { useUser } from "../user/UserContext";
import { useSiteContextHelper } from "../../hooks/useSiteContextHelper";

/**
 * Navigation within taxonomy.
 */
export const Navigation: React.FC = () => {
  const user = useUser();
  const siteCtxHelper = useSiteContextHelper();

  return (
    <>
      <div className="p-3">
        <PageNavigationButton
          to={siteCtxHelper.appendUrl(RootRoutes.TAXONOMY)}
          title="Attributes"
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
        />
        <PageNavigationButton
          to={siteCtxHelper.appendUrl(`${RootRoutes.TAXONOMY}/groups`)}
          title="Groups"
          className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
        />
        {!user.activeDestination && (
          <>
            <PageNavigationButton
              to={siteCtxHelper.appendUrl(`${RootRoutes.TAXONOMY}/industries`)}
              title="Industries"
              className="mr-3 mb-2 mb-sm-0 d-block d-sm-inline-block"
            />
            <PageNavigationButton
              to={siteCtxHelper.appendUrl(`${RootRoutes.TAXONOMY}/clients`)}
              title="Clients"
              className="d-block d-sm-inline-block"
            />
          </>
        )}
      </div>
      <hr className="my-0 bg-secondary" />
    </>
  );
};
