import { Col, Container, Form, Row } from "react-bootstrap";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { ProfileDetailsQuery } from "../../../generated/admin";

interface Props {
  user: ProfileDetailsQuery["user"];
}

export const DebugInfo = ({ user }: Props) => (
  <ToggleContainer title="Debugging information">
    <Container fluid className="p-0">
      <Row>
        <Col md="6">
          <Form.Group controlId="registrationType" as={Row}>
            <Form.Label column md="5" xl="4" className="pr-md-1">
              UUID
            </Form.Label>
            <Col md="7" xl="8" className="pl-md-0 align-self-center">
              {user?.uuid}
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Form.Group controlId="registrationType" as={Row}>
            <Form.Label column md="5" xl="4" className="pr-md-1">
              Auth maps
            </Form.Label>
            <Col md="7" xl="8" className="pl-md-0 align-self-center">
              {user?.authMaps.map((a) => a.id).join(", ")}
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  </ToggleContainer>
);
