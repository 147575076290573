import React from "react";

export function CompactListIcon() {
  return (
    <svg width="28px" height="28px" viewBox="0 0 25 23" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="View-density" fill="#2600AA">
          <g id="Thin-button">
            <rect
              id="Rectangle-Copy-5"
              x="0"
              y="20"
              width="25"
              height="3"
              rx="1.5"
            ></rect>
            <rect
              id="Rectangle-Copy-6"
              x="0"
              y="16"
              width="25"
              height="3"
              rx="1.5"
            ></rect>
            <rect
              id="Rectangle-Copy-7"
              x="0"
              y="12"
              width="25"
              height="3"
              rx="1.5"
            ></rect>
            <rect
              id="Rectangle-Copy-8"
              x="0"
              y="8"
              width="25"
              height="3"
              rx="1.5"
            ></rect>
            <rect
              id="Rectangle-Copy-9"
              x="0"
              y="4"
              width="25"
              height="3"
              rx="1.5"
            ></rect>
            <rect
              id="Rectangle-Copy-10"
              x="0"
              y="0"
              width="25"
              height="3"
              rx="1.5"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}
