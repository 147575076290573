import React, { useCallback } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useClientStore } from "./hook/useClientStore";
import { AddButton } from "../../../components/AddButton";
import { CreateButton } from "../../../components/CreateButton";
import { RemoveButton } from "../../../components/RemoveButton";

/**
 * Add client form.
 */
export const AddForm: React.FC = () => {
  const store = useClientStore();
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        store.addClient();
      }
    },
    [store]
  );
  return (
    <Container fluid className="p-0">
      <Row className="justify-content-between">
        <Col md="7" lg="8">
          <Container fluid>
            {store.clientsToAdd.map((client, i) => (
              <ClientFormInner key={`ifi-${i}`} client={client} i={i} />
            ))}

            <Row onKeyDown={handleKeyDown}>
              <Col className="border border-right-0 px-3" xs="10" lg="11">
                <Form.Control
                  required
                  className="border-bottom-0"
                  placeholder="Client Name"
                  value={store.newClientName}
                  onChange={store.handleChange}
                />
              </Col>
              <Col
                lg="1"
                xs="2"
                className="border border-left-0 d-flex justify-content-end align-items-center"
              >
                <AddButton onClick={store.addClient} />
              </Col>
            </Row>
          </Container>
        </Col>

        <Col md="4" lg="3" className="mt-3 mt-md-0 text-right">
          <CreateButton disabled={store.loading} onClick={store.addClients}>
            {store.saving ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Add Clients"
            )}
          </CreateButton>
        </Col>
      </Row>
    </Container>
  );
};

const ClientFormInner = ({
  client,
  i
}: {
  client: { name: string };
  i: number;
}) => {
  const store = useClientStore();
  const handleRemove = useCallback(() => store.removeClient(client.name), [
    store,
    client.name
  ]);

  return (
    <Row key={`list-${i}`}>
      <Col
        className="py-1 px-3 border border-bottom-0 border-right-0"
        xs="10"
        lg="11"
      >
        <Form.Control
          className="w-50"
          required
          value={client.name}
          onChange={store.updateLocalClient(client.name)}
        />
      </Col>
      <Col
        lg="1"
        xs="2"
        className="justify-content-end d-flex align-items-center border border-bottom-0 border-left-0"
      >
        <RemoveButton onClick={handleRemove} />
      </Col>
    </Row>
  );
};
