import React from "react";
import { JoditTextEditor } from "../../../../components/JoditTextEditor";
import { FormikContextType } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { CardAccordion } from "../../../../components/SingleEqAccordion";

interface Props {
  methods: FormikContextType<SiteConfigFormData>;
}

export const ThirdPartyServiceProvider: React.FC<Props> = ({ methods }) => {
  return (
    <CardAccordion title="3rd Party Service Providers">
      <JoditTextEditor
        placeholder="Enter 3rd Party Service Providers..."
        content={methods.values.thirdPartyServiceProviders}
        finishCallback={(text: string) => {
          methods.setFieldValue("thirdPartyServiceProviders", text);
        }}
      />
    </CardAccordion>
  );
};
