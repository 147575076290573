import React from "react";
import { UserModule } from "./modules/user/UserModule";
import { Routes, Route } from "react-router-dom";
import { SitesModule } from "./modules/sites/SitesModule";
import { BuildingsModule } from "./modules/buildings/BuildingsModule";
import { RegistrationModule } from "./modules/registration/RegistrationModule";
import { TaxonomyModule } from "./modules/taxonomy/TaxonomyModule";
import { CompaniesModule } from "./modules/companies/CompaniesModule";
import { LeasingModule } from "./modules/leasing/LeasingModule";
import { HelpPage } from "./pages/HelpPage";
import { ApartmentsModule } from "./modules/apartments/ApartmentsModule";
import { User } from "./modules/user/User";
import { WhitelabelModule } from "./modules/whitelabel/WhitelabelModule";

export enum RootRoutes {
  SITES = "/sites",
  BUILDINGS = "/buildings",
  REGISTRATION = "/registration",
  USERS = "/users",
  INVITES = "/users/invites",
  COMPANIES = "/companies",
  LEASING = "/leasing",
  TAXONOMY = "/taxonomy",
  WHITELABEL = "/whitelabel",
  HELP = "/help",
  SITEPICKER = "/site-picker",
  APARTMENTS = "/apartments",
}

export const RouterOutlet: React.FC<{ user: User | null }> = ({ user }) => (
  <Routes>
    <Route path={`${RootRoutes.SITES}/*`} element={<SitesModule />} />
    <Route path={`${RootRoutes.BUILDINGS}/*`} element={<BuildingsModule />} />
    <Route path={`${RootRoutes.REGISTRATION}/*`} element={<RegistrationModule />} />
    <Route path={`${RootRoutes.USERS}/*`} element={<UserModule />} />
    <Route path={`${RootRoutes.COMPANIES}/*`} element={<CompaniesModule />} />
    <Route path={`${RootRoutes.LEASING}/*`} element={<LeasingModule />} />
    <Route path={`${RootRoutes.TAXONOMY}/*`} element={<TaxonomyModule />} />
    {user?.isAdminOrRegionalManager ? (
      <Route path={`${RootRoutes.WHITELABEL}/*`} element={<WhitelabelModule />} />
    ) : undefined}
    <Route path={RootRoutes.HELP} element={<HelpPage />} />
    {(!user?.activeDestinationSettings ||
      user?.activeDestinationSettings?.registration
        ?.residentialSignupEnabled === true) && (
      <Route path={`${RootRoutes.APARTMENTS}/*`} element={<ApartmentsModule />} />
    )}
    <Route path={"*"} element={<CompaniesModule />} />
  </Routes>
);
