import React, { useContext, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useUpdateSiteGroupCurationStatusMutation } from "../../../generated/admin";
import { EqMessageError } from "../../message/EqMessage";
import { UserContext } from "../../user/UserContext";
import { Group } from "./model/Group";

interface Props {
  group: Group;
}

export const ToggleGroupCurationStatus: React.FC<Props> = ({ group }) => {
  const ctx = useContext(UserContext);
  const [mutation, { loading }] = useUpdateSiteGroupCurationStatusMutation();
  const [toggleStatus, setToggleStatus] = useState(
    group.isCuratedForEquiemOne ?? false
  );

  if (!ctx?.inSiteContext) {
    return null;
  }

  if (group.isCuratedForEquiemOne == null) {
    return <td></td>;
  }

  return (
    <td>
      {loading ? (
        <>
          <Spinner size="sm" animation="grow" /> Loading...
        </>
      ) : (
        <Form.Group style={{ marginBottom: 0 }} controlId={group.uuid}>
          <Form.Check
            checked={toggleStatus}
            label={"Workplace manager can assign group to employee"}
            type="switch"
            onChange={async () => {
              const result = await mutation({
                variables: {
                  status: !toggleStatus,
                  uuid: group.uuid,
                },
              });

              if (
                result.data?.setSiteGroupCurationStatus.__typename !==
                "SiteGroupSuccessResponse"
              ) {
                if (
                  result.data?.setSiteGroupCurationStatus.__typename ===
                  "FailureResponse"
                ) {
                  EqMessageError({
                    text: result.data?.setSiteGroupCurationStatus.reason,
                  });
                } else {
                  EqMessageError({
                    text: "An unexpected error occurred",
                  });
                }
              } else {
                setToggleStatus(
                  result.data.setSiteGroupCurationStatus.siteGroup
                    .isCuratedForEquiemOne
                );
              }
            }}
          />
        </Form.Group>
      )}
    </td>
  );
};
