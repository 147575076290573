import React from "react";
import { useApartmentListQuery } from "../../../../generated/admin";
import { LoadingOrErrorDisplay } from "../../../../components/LoadingOrErrorDisplay";
import { useApartmentSuggestion } from "../hooks/useApartmentSuggestion";
import { Spinner } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { useSiteContextHelper } from "../../../../hooks/useSiteContextHelper";
import { SuggestionContainer } from "../../../companies/detail-view/suggestion/SuggestionContainer";

interface Props {
  search: string;
  siteUuid: string;
  hideFn: () => void;
}

const MINIMUM_SEARCH_LENGTH = 2;

/**
 * Apartment suggestion.
 */
export const ApartmentSuggestion: React.FC<Props> = ({
  search,
  siteUuid,
  hideFn,
}) => {
  const siteCtxHelper = useSiteContextHelper();
  const { addApartment, addingDestination } = useApartmentSuggestion(
    siteCtxHelper.redirect
  );
  const { data, loading, error } = useApartmentListQuery({
    variables: {
      search,
      first: 5,
    },
  });
  if (search.length < MINIMUM_SEARCH_LENGTH || siteUuid == null) {
    return <></>;
  }

  return (
    <LoadingOrErrorDisplay error={error} loading={loading}>
      <SuggestionContainer>
        {data != null && data.apartments.edges.length > 0 && (
          <>
            <div className="p-2 font-italic">
              The following apartments already exist, did you mean:
              <FaTimes
                size="20px"
                className="cursor-pointer float-right"
                onClick={hideFn}
              />
            </div>
            {data.apartments.edges.map((c) => (
              <div
                key={`suggestion-${c.node?.uuid}`}
                className="list d-flex"
              >
                <div className="name p-2">{c.node?.name}</div>
                <div
                  onClick={() => addApartment(c.node?.uuid, siteUuid)}
                  className="btn-action ml-auto text-primary py-2 border border-light border-right-0 px-3 cursor-pointer"
                >
                  {addingDestination ? (
                    <span>
                      <Spinner animation="grow" size="sm" /> Saving...
                    </span>
                  ) : (
                    "Add to site"
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </SuggestionContainer>
    </LoadingOrErrorDisplay>
  );
};
