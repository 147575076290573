import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormikProps } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";

interface Props {
  methods: FormikProps<SiteConfigFormData>;
}

export const VisitorManagement: React.FC<Props> = ({ methods }) => {
  if (methods.values.featureModules?.visitors == null) {
    return null;
  }

  return (
    <div className="py-3">
      <div className="d-flex pt-3 pb-2 justify-content-between border-bottom">
        <h4 className="font-weight-normal">Visitor Management</h4>
        <Form.Check
          name="featureModules.visitors.enabled"
          type="switch"
          label=""
          id="featureModules.visitors.enabled"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            methods.setFieldValue(
              "featureModules.visitors.enabled",
              e.target.checked
            );
          }}
          defaultChecked={methods.values.featureModules.visitors.enabled}
        />
      </div>
      {methods.values.featureModules?.visitors.enabled === true && (
        <div className="pt-4">
          <Form.Group controlId="visitorsEnableMenu" as={Row}>
            <Form.Label column md="3" lg="2">
              Enable End User Access
            </Form.Label>
            <Col className="pt-1">
              <div className="d-flex align-items-center">
                <Form.Check
                  name="featureModules.visitors.enableMenu"
                  type="switch"
                  label=""
                  id="featureModules.visitors.enableMenu"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    methods.setFieldValue(
                      "featureModules.visitors.enableMenu",
                      e.target.checked
                    );
                  }}
                  defaultChecked={
                    methods.values.featureModules.visitors.enableMenu
                  }
                />
              </div>
              <Form.Text className="text-muted">
                Once enabled, a link to the module will be shown in the
                user-facing menu on both web and mobile. If disabled, end-users
                will still be able to access the module directly.
              </Form.Text>
            </Col>
          </Form.Group>
        </div>
      )}
    </div>
  );
};
