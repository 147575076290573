import React from "react";
import { ProfileDetailsQuery } from "../../../../generated/admin";

interface UserFormContextType {
  activeSite: string | null;
  user: ProfileDetailsQuery["user"];
  destinations: ProfileDetailsQuery["destinations"];
}

/**
 * User form context.
 */
export const UserFormContext = React.createContext<UserFormContextType>({
  activeSite: null,
  user: null,
  destinations: null
});
