import React, { useContext } from "react";
import { Form, Spinner } from "react-bootstrap";
import {
  ProfileDetailsDocument,
  useUpdateUserStoreAdminMutation,
} from "../../../../generated/admin";
import { useConfig } from "../../../../providers/ConfigProvider";
import { EqMessageError } from "../../../message/EqMessage";
import { UserFormContext } from "../context/UserFormContext";

export const StoreSuperAdminPermission = () => {
  const ctx = useContext(UserFormContext);
  const userIsStoreAdmin = ctx.user?.storePermissions?.equiemAdmin ?? false;

  const [updateStoreAdmin, { loading }] = useUpdateUserStoreAdminMutation();
  const config = useConfig();

  return (
    <Form.Group controlId="super-admin">
      {loading ? (
        <>
          <Spinner size="sm" animation="grow" /> Loading...
        </>
      ) : (
        <Form.Check
          type="checkbox"
          label={"Admin"}
          checked={userIsStoreAdmin}
          disabled={loading}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (ctx.user == null || loading) {
              return;
            }
            const variables = {
              userUuid: ctx.user.uuid,
              isAdmin: !userIsStoreAdmin,
            };
            const refetchQueries = [
              {
                query: ProfileDetailsDocument,
                variables: {
                  uuid: ctx.user.uuid,
                  installation: config.installation,
                },
              },
            ];
            const result = await updateStoreAdmin({
              variables,
              refetchQueries,
              awaitRefetchQueries: true,
            });
            if (
              result.data?.updateUserStoreAdmin.__typename !== "SuccessResponse"
            ) {
              if (
                result.data?.updateUserStoreAdmin.__typename ===
                "FailureResponse"
              ) {
                EqMessageError({
                  text: result.data.updateUserStoreAdmin.reason,
                });
              } else {
                EqMessageError({
                  text: "An unexpected error occurred",
                });
              }
            }
          }}
        />
      )}
    </Form.Group>
  );
};
