import React, { useContext } from "react";
import { Form, Col, Row, Spinner } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { userFormNameCheck, UserFormData } from "./model/UserFormData";
import { CapsuleButton } from "../../../components/CapsuleButton";
import { FaUnlockAlt, FaCheckCircle } from "react-icons/fa";
import { UserFormContext } from "./context/UserFormContext";
import { TimeFormat } from "../../../components/TimeFormat";
import { UserFormUserStatusDropdown } from "./UserFormUserStatusDropdown";
import { CompaniesDropdown } from "./CompaniesDropdown";
import { EqMessageSuccess, EqMessageError } from "../../message/EqMessage";
import { useUserResetPasswordAction } from "./hook/useUserResetPasswordAction";
import { useUserSendEmailVerification } from "./hook/useUserSendEmailVerification";
import { UserContext } from "../UserContext";
import styled from "styled-components";

interface Props {
  className?: string;
}

const VerificationBtn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const EmailInputContainer = styled.div.attrs({
  className: "d-flex flex-wrap",
})`
  gap: 1rem;
`;

/**
 * User form main details component.
 */
export const UserFormMainDetails: React.FC<Props> = ({ className }) => {
  const ctx = useContext(UserFormContext);
  const userCtx = useContext(UserContext);
  const methods = useFormContext<UserFormData>();
  const { resetPassword, loading } = useUserResetPasswordAction();
  const { sendEmailVerification, isLoadingSendEmailVerification } =
    useUserSendEmailVerification();
  const canManageEmail = ctx.user?.canChangeEmail ?? false;

  const sendResetPasswordEmail = async () => {
    const result = await resetPassword();
    if (result.data?.sendResetPasswordEmail.success === true) {
      EqMessageSuccess({
        text: "Password reset email successfully sent to the user.",
      });
    } else {
      EqMessageError({
        text: "Password reset unsuccessful.",
      });
    }
  };

  const sendEmailVerificationAction = async () => {
    const result = await sendEmailVerification();

    if (result?.data?.beginAnonEmailVerification === true) {
      EqMessageSuccess({
        text: "Verification email successfully sent to the user.",
      });
    } else {
      EqMessageError({
        text: "There was an error sending email verification.",
      });
    }
  };

  const getEmailActionBtn = (): JSX.Element | null => {
    let verifiedButton: JSX.Element | null = null;

    if (ctx.user?.profile?.emailVerified === true) {
      verifiedButton = (
        <FaCheckCircle className="text-primary ml-2 verified-email-check" />
      );
    } else if (userCtx?.activeDestination?.uuid != null) {
      verifiedButton = (
        <VerificationBtn>
          <CapsuleButton
            onClick={sendEmailVerificationAction}
            disabled={isLoadingSendEmailVerification}
            className="d-flex flex-wrap"
          >
            {isLoadingSendEmailVerification ? (
              <>
                <Spinner animation="grow" size="sm" /> Sending...
              </>
            ) : (
              "Send verification email"
            )}
          </CapsuleButton>
        </VerificationBtn>
      );
    }

    return verifiedButton;
  };

  const emailActionBtn: JSX.Element | null = getEmailActionBtn();

  return (
    <div className={className}>
      <Form.Group controlId="firstName" as={Row}>
        <Form.Label column md="4" lg="3">
          First name
        </Form.Label>
        <Col md="8" lg="9" className="pl-md-0">
          <Form.Control
            {...methods.register(userFormNameCheck("firstName"), { required: "Required." })}
            isInvalid={methods.formState.errors.firstName != null}
          />
          {methods.formState.errors.firstName && (
            <Form.Control.Feedback type="invalid">
              {methods.formState.errors.firstName.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Form.Group controlId="lastName" as={Row}>
        <Form.Label column md="4" lg="3">
          Last name
        </Form.Label>
        <Col md="8" lg="9" className="pl-md-0">
          <Form.Control
            {...methods.register(userFormNameCheck("lastName"), { required: "Required." })}
            isInvalid={methods.formState.errors.lastName != null}
          />
          {methods.formState.errors.lastName && (
            <Form.Control.Feedback type="invalid">
              {methods.formState.errors.lastName.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Form.Group>

      <Form.Group controlId="email" as={Row}>
        <Form.Label column md="4" lg="3">
          Email
        </Form.Label>
        <Col md="8" lg="9" className="pl-md-0">
          <EmailInputContainer>
            <Col className="pl-md-0 pr-md-0 flex-grow-1 pl-sm-0 pr-sm-0">
              <Form.Control
                disabled={!canManageEmail}
                isInvalid={methods.formState.errors.email != null}
                style={{ minWidth: "max-content" }}
                {...methods.register(userFormNameCheck("email"), {
                  required: "Required.",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email format.",
                  },
                })}
              />
            </Col>
            {emailActionBtn}
          </EmailInputContainer>
          {methods.formState.errors.email && (
            <Form.Control.Feedback type="invalid">
              {methods.formState.errors.email.message}
            </Form.Control.Feedback>
          )}
          {ctx.user?.profile?.unverifiedEmail != null ? (
            <Form.Text className="pl-2 ml-1 text-muted">
              Verifying {ctx.user.profile.unverifiedEmail}
            </Form.Text>
          ) : null}
        </Col>
      </Form.Group>

      <Form.Group controlId="company" as={Row}>
        <Form.Label column md="4" lg="3">
          Company
        </Form.Label>
        <Col md="8" lg="9" className="pl-md-0">
          <CompaniesDropdown
            name={userFormNameCheck("company")}
            defaultValue={
              ctx.user?.profile?.companyV2 != null
                ? {
                    value: ctx.user.profile.companyV2.uuid,
                    label: ctx.user.profile.companyV2.name,
                  }
                : null
            }
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="status" as={Row}>
        <Form.Label column md="4" lg="3">
          Status
        </Form.Label>
        <Col md="8" lg="9" className="pl-md-0">
          <UserFormUserStatusDropdown
            syndicateActive={ctx.user?.active!}
            status={ctx.user?.profile?.status!}
          />
        </Col>
      </Form.Group>

      <Form.Group controlId="lastSeen" as={Row}>
        <Form.Label column md="4" lg="3" className="pt-2">
          Last seen
        </Form.Label>
        <Col md="8" lg="9" className="pt-2 pl-md-0">
          <TimeFormat timestamp={ctx.user?.profile?.lastActivityTime} />
        </Col>
      </Form.Group>

      <Form.Group controlId="accountAge" as={Row}>
        <Form.Label column md="4" lg="3" className="pt-2">
          Account age
        </Form.Label>
        <Col md="8" lg="9" className="pt-2 pl-md-0">
          <TimeFormat timestamp={ctx.user?.profile?.created} type="fromnow" />
        </Col>
      </Form.Group>

      <Form.Group controlId="resetPassword" as={Row}>
        <Form.Label column md="4" lg="3" className="pt-2">
          Password
        </Form.Label>
        <Col md="8" lg="9" className="pt-2 pl-md-0">
          <CapsuleButton onClick={sendResetPasswordEmail} disabled={loading}>
            <FaUnlockAlt />{" "}
            {loading ? (
              <>
                <Spinner animation="grow" size="sm" /> Resetting...
              </>
            ) : (
              "Reset"
            )}
          </CapsuleButton>
        </Col>
      </Form.Group>
    </div>
  );
};
