import { Routes, Route } from "react-router-dom";
import React from "react";
import { BuildingList } from "./list-view/BuildingList";
import { BuildingDetails } from "./detail-view/BuildingDetails";

export const BuildingsModule = () => {
  return (
    <Routes>
      <Route path={`/edit/:uuid`} element={<BuildingDetails />} />
      <Route path={`/create`} element={<BuildingDetails />} />
      <Route path="*" element={<BuildingList />} />
    </Routes>
  );
};
