import React, { useContext } from "react";
import { useConfig } from "../../../../providers/ConfigProvider";
import { UserFormContext } from "../context/UserFormContext";
import { FaUserAlt, FaPlus } from "react-icons/fa";

export const AddToIris: React.FC<{}> = () => {
  const config = useConfig();
  const ctx = useContext(UserFormContext);
  const email = encodeURIComponent(ctx.user?.profile?.email);
  const authMap = ctx.user?.authMaps
    .filter((auth) => auth.isEquiemManagedRealm)
    .pop();
  const authId = authMap != null ? encodeURIComponent(authMap?.id) : null;

  if (authId == null) {
    return null;
  }

  return (
    <a
      href={`${config.addToIrisLink}#email=${email}&auth0_id=${authId}`}
      className="btn btn-outline-primary btn-sm shadow-none"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaUserAlt className="mr-1 user-icon-align-top"></FaUserAlt>
      <FaPlus className="user-icon-align-top"></FaPlus>
      <span>&nbsp;Add to iris</span>
    </a>
  );
};
