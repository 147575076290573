import React from "react";
import { SubTitle } from "../../../../components";
import { Spinner, Container, Row, Col } from "react-bootstrap";
import { CreateButton } from "../../../../components/CreateButton";
import { CompanySearch } from "./CompanySearch";
import { CompanyAdd } from "./CompanyAdd";
import { useMergeCompaniesState } from "./useMergeCompaniesState";

export const MergeCompanies = ({
  companySearchTerm
}: {
  companySearchTerm: string;
}) => {
  const props = useMergeCompaniesState(companySearchTerm);
  return <MergeCompaniesUI {...props} />;
};

export const MergeCompaniesUI = ({
  addMergeRow,
  updateMergeRow,
  removeMergeRow,
  setMaster,
  merge,
  loading,
  input,
  selected
}: ReturnType<typeof useMergeCompaniesState>) => (
  <>
    <SubTitle>Merge Companies</SubTitle>
    <p className="pb-2">
      Select the master company you want to keep and companies selected below
      this will be merged to form a single entity.
    </p>

    <div className="pb-5">
      <b>Master company</b>
      <Container className="border-bottom mt-1">
        <CompanySearch
          selected={selected}
          input={input.master}
          addInput={setMaster}
        />
      </Container>
    </div>

    <b>Merges</b>
    <Container className="mt-1">
      {input.merge.map((option, index) => (
        <CompanySearch
          selected={selected}
          input={option}
          key={option?.value ?? ""}
          remove={removeMergeRow(index)}
          addInput={updateMergeRow(index)}
        />
      ))}
      <CompanyAdd selectedOptions={selected} addInput={addMergeRow} />
      <Row className="justify-content-end">
        <Col md="4" lg="3" className="pr-0 pt-3 mt-3">
          <CreateButton onClick={merge}>
            {loading ? (
              <span>
                <Spinner animation="grow" size="sm" /> {"...Merging"}
              </span>
            ) : (
              "Merge Companies"
            )}
          </CreateButton>
        </Col>
      </Row>
    </Container>
  </>
);
