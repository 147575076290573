import React from "react";
import { Logo } from "./svgs/Logo";
import { LogoSpinner } from "./svgs/LogoSpinner";
import styled from "styled-components";

const AppLoadingContainer = styled.div`
  & .app-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    > div {
      position: relative;
      z-index: 1;
    }
    .loading-page {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  & .app-loading-imgbtm {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    max-width: 760px;
  }
`;

export const AppLoadingPage = () => (
  <AppLoadingContainer>
    <div className="app-loading bg-secondary">
      <div>
        <Logo className="loading-page" />
        <LogoSpinner />
      </div>
    </div>
    <img
      alt="loading-building"
      className="app-loading-imgbtm"
      src="/app-loading-building.png"
    />
  </AppLoadingContainer>
);
