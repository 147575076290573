import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ExternalServiceProvider } from "./providers/ExternalServiceProvider";
import { createRoot } from 'react-dom/client';

if (
  window.location.protocol !== "https:" &&
  process.env.NODE_ENV === "production"
) {
  window.location.replace(
    `https:${window.location.href.substring(window.location.protocol.length)}`
  );
}

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <ExternalServiceProvider>
    <App />
  </ExternalServiceProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
