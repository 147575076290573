import React from "react";
import { UserForm } from "./UserForm";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { useUserDetailsStore } from "./user-store/UserDetailsStore";
import { useParams } from "react-router-dom";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { RootRoutes } from "../../../RouterOutlet";

interface UserDetailsProps {
  uuid: string;
  closeFn: () => void;
}

export const UserDetailsPage = () => {
  const siteCtxHelper = useSiteContextHelper();
  const { uuid } = useParams<{ uuid: string }>();
  if (uuid == null) {
    return <div>User not found</div>;
  }
  return (
    <UserDetails
      uuid={uuid}
      closeFn={() => siteCtxHelper.redirect(RootRoutes.USERS)}
    />
  );
};

/**
 * User details component.
 */
export const UserDetails: React.FC<UserDetailsProps> = (props) => {
  const { data, loading, error } = useUserDetailsStore(props.uuid);

  return (
    <LoadingOrErrorDisplay error={error} loading={loading}>
      {data != null && (
        <UserForm
          user={data.user}
          destinations={data.destinations}
          closeFn={props.closeFn}
        />
      )}
    </LoadingOrErrorDisplay>
  );
};
