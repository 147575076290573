import dayjs from "dayjs";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  LeaseFragmentFragment,
  useLeaseTerminateMutation,
} from "../../../generated/admin";
import { EqMessageError } from "../../message/EqMessage";
import { DateOfChangeField } from "../form-fields/DateOfChangeField";

interface Props {
  onHide: () => void;
  lease: LeaseFragmentFragment;
  onTerminate?: (oldLease: LeaseFragmentFragment) => void;
}

interface FormValue {
  dateOfChange: string;
}

/**
 * LeaseTerminate modal
 */
export const LeaseTerminate: React.FC<Props> = ({
  onHide,
  onTerminate,
  lease,
}) => {
  const [terminateLease] = useLeaseTerminateMutation();
  const dateInputRef = useRef<HTMLInputElement>();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Select value in date input field automatically on load.
    dateInputRef.current?.select();
  }, []);

  return (
    <Modal centered scrollable={false} show={true} onHide={onHide}>
      <Formik<FormValue>
        initialValues={{
          dateOfChange: dayjs().format("YYYY-MM-DD"),
        }}
        validateOnChange={submitted}
        validateOnBlur={submitted}
        onSubmit={async (values) => {
          setSubmitted(true);

          const leaseResult = await terminateLease({
            variables: {
              input: {
                uuid: lease.uuid,
                dateOfChange: new Date(values.dateOfChange).getTime(),
              },
            },
          });

          if (leaseResult.data?.terminateLease == null) {
            EqMessageError({
              text: "Failed to terminate lease. Please try again.",
            });
          } else {
            onHide();
            if (onTerminate != null) {
              onTerminate(leaseResult.data?.terminateLease);
            }
          }
        }}
      >
        {(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;

          return (
            <>
              <Modal.Header>
                <Modal.Title>Terminate Lease for {lease?.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <DateOfChangeField
                    {...formikProps}
                    label="Date of termination"
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner animation="grow" size="sm" />
                      <span className="ml-1">Terminating...</span>
                    </>
                  ) : (
                    "Terminate"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  onClick={onHide}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
