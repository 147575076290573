import React from "react";
import { useEffect } from "react";
import Select from "react-select";
import { useUser } from "../../UserContext";

export type Operation =
  | "status"
  | "attributes"
  | "groups"
  | "delete"
  | null
  | undefined;
type OperationOption = { label: string; value: Operation };

export const OperationSelector: React.FC<{
  onSelectOperation: (operation: Operation) => void;
  operation: Operation;
}> = ({ onSelectOperation, operation }) => {
  const user = useUser();
  const options: OperationOption[] = user?.inSiteContext
    ? [
        { value: "status", label: "Update status" },
        { value: "groups", label: "Modify groups" },
        { value: "attributes", label: "Modify attributes" },
      ]
    : [];

  if (user?.isAdmin) {
    options.push({ value: "delete", label: "Delete users" });
  }

  useEffect(() => {
    if (options.length === 1) {
      onSelectOperation(options[0].value);
    }
    // eslint-disable-next-line
  }, [options]);

  return (
    <>
      <Select<OperationOption>
        classNamePrefix="eq"
        className={`react-select`}
        aria-label="user-operation-select"
        options={options}
        value={options.filter((op) => op.value === operation).pop()}
        onChange={(option) => {
          onSelectOperation(
            !Array.isArray(option) && option != null
              ? (option as { value: Operation }).value
              : null
          );
        }}
      />
    </>
  );
};
