import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  useUserAttributesQuery,
  useUpsertUserAttributesMutation,
  useDeleteUserAttributeMutation,
  useUpsertUserAttributeMutation,
} from "../../../../generated/admin";
import {
  EqMessageError,
  EqMessageSuccess,
  MessageFn,
} from "../../../message/EqMessage";
import { UserAttribute } from "../model/UserAttribute";
import { UserAttributeState } from "../model/UserAttributeState";
import { UserAttributeStore } from "../model/UserAttributeStore";
import { UserAttributeContext } from "../UserAttributeContext";

export const userAttributesSort = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });

export function useUserAttributeStoreFactory(
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
): UserAttributeStore {
  const [state, setState] = useState<UserAttributeState>({
    attributes: [],
    saveErrors: [],
    loading: false,
    newAttributeName: "",
    attributesToAdd: [],
    saving: false,
    deleting: "",
    activeDestination: null,
  });

  const [sortedAttributes, setSortedAttributes] = useState<UserAttribute[]>([]);

  const set = (update: Partial<UserAttributeState>) =>
    setState((state) => ({ ...state, ...update }));

  const results = useUserAttributesQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: results.loading,
      initalLoadError: results.error,
      attributes: results.data?.attributes ?? [],
    }));
  }, [results]);

  useEffect(() => {
    setSortedAttributes([...state.attributes].sort(userAttributesSort));
  }, [state.attributes]);

  const [deleteMutation] = useDeleteUserAttributeMutation();

  const [userAttributesMutation, userAttributesMutationResult] =
    useUpsertUserAttributesMutation();
  useEffect(() => {
    set({ saving: userAttributesMutationResult.loading });
  }, [userAttributesMutationResult.loading]);

  const [userAttributeMutation, userAttributeMutationResult] =
    useUpsertUserAttributeMutation();
  useEffect(() => {
    set({ saving: userAttributeMutationResult.loading });
  }, [userAttributeMutationResult.loading]);

  return useMemo(() => {
    const updateAttribute = (uuid: string, name: string) => {
      setState((state) => ({
        ...state,
        attributes: state.attributes.map((l) => {
          if (l.uuid === uuid) {
            return {
              ...l,
              name,
            }
          }
          return l;
        }),
      }));
    };
    return {
      ...state,
      sortedAttributes,
      userAttributesSort,
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAttributeName = event.target.value;
        setState((state) => ({
          ...state,
          newAttributeName,
        }));
      },
      addAttribute: () => {
        if (state.newAttributeName === "") {
          errorMsgFn({ text: "Name cannot be empty." });
          return;
        }
        if (
          state.attributesToAdd
            .map((l) => l.name)
            .includes(state.newAttributeName)
        ) {
          errorMsgFn({ text: "Cannot create duplicate user attribute name." });
          return;
        }
        setState((state) => ({
          ...state,
          attributesToAdd: [
            ...state.attributesToAdd,
            { name: state.newAttributeName },
          ],
          newAttributeName: "",
        }));
      },
      addAttributes: (newAttributes: UserAttribute[]) => {
        setState((state) => ({
          ...state,
          attributes: [...state.attributes, ...newAttributes],
        }));
      },
      saveAttribute: async (uuid: string, name: string): Promise<boolean> => {
        const result = await userAttributeMutation({
          variables: { uuid, name },
        });
        if (
          result.data?.upsertUserAttribute.__typename ===
          "AttributeSuccessResponse"
        ) {
          updateAttribute(uuid, name);
          await EqMessageSuccess({ text: "User attribute name updated." });
        } else {
          await EqMessageError({
            text:
              result.data?.upsertUserAttribute.__typename === "FailureResponse"
                ? result.data?.upsertUserAttribute.reason
                : "There was an error updating the attribute.",
          });
        }

        return (
          result.data?.upsertUserAttribute.__typename ===
          "AttributeSuccessResponse"
        );
      },
      saveAttributes: async () => {
        if (state.attributesToAdd.length === 0) {
          errorMsgFn({
            text: "Please click on add before saving.",
          });
          return;
        }
        const result = await userAttributesMutation({
          variables: {
            input: state.attributesToAdd,
          },
        });

        const addedAttributes: UserAttribute[] =
          result.data?.upsertUserAttributes.flatMap((attr) => {
            if (attr.__typename === "AttributeSuccessResponse") {
              return [
                {
                  uuid: attr.attribute.uuid,
                  name: attr.attribute.name,
                },
              ];
            }

            return [];
          }) ?? [];

        const errors =
          result.data?.upsertUserAttributes.flatMap((attr) => {
            if (attr.__typename === "FailureResponse") {
              return [attr.reason];
            }
            return [];
          }) ?? [];

        setState((state) => ({
          ...state,
          attributes: [...state.attributes, ...addedAttributes],
          saveErrors: errors.map((message) => ({
            message,
          })),
          attributesToAdd: [],
        }));

        if (errors.length > 0) {
          errorMsgFn({
            html: errors.join("<br/>"),
          });
        }

        if (addedAttributes.length > 0) {
          successMsgFn({
            text: `User ${
              addedAttributes.length > 1 ? "attributes" : "attribute"
            } created.`,
          });
        }
      },
      deleteAttribute: async (uuid: string) => {
        setState((state) => ({ ...state, deleting: uuid }));

        const result = await deleteMutation({ variables: { uuid } });
        setState((state) => ({ ...state, deleting: "" }));

        if (result.data == null) {
          errorMsgFn({
            text: "Something went wrong, please refresh the page and try again.",
          });
          return;
        }

        if (result.data.deleteUserAttribute.__typename === "FailureResponse") {
          errorMsgFn({ text: result.data.deleteUserAttribute.reason });
          return;
        }

        successMsgFn({ text: "User attribute has been deleted!" });
        setState((state) => ({
          ...state,
          attributes: state.attributes.filter((l) => l.uuid !== uuid),
        }));
      },
      removeAttribute: (name: string) => {
        setState((state) => ({
          ...state,
          attributesToAdd: state.attributesToAdd.filter((l) => l.name !== name),
        }));
      },
      updateAttribute,
      updateLocalAttribute:
        (original: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          setState((state) => ({
            ...state,
            attributesToAdd: state.attributesToAdd.map((l) =>
              l.name === original ? { name: value } : l
            ),
          }));
        },
    };
  }, [
    state,
    sortedAttributes,
    errorMsgFn,
    userAttributeMutation,
    userAttributesMutation,
    successMsgFn,
    deleteMutation,
  ]);
}

export function useUserAttributeStore(): UserAttributeStore {
  const store = useContext(UserAttributeContext);
  if (store == null) {
    throw Error("User attribute store context not initialized.");
  }
  return store;
}

export const UserAttributeStoreProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const store = useUserAttributeStoreFactory();

  return (
    <UserAttributeContext.Provider value={store}>
      {children}
    </UserAttributeContext.Provider>
  );
};
