import React, { useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import {
  LeaseFragmentFragment,
  useLeaseDeleteMutation,
} from "../../generated/admin";
import { FaEllipsisV } from "react-icons/fa";
import { LeaseCreate } from "./list-view/LeaseCreate";
import { LeaseTerminate } from "./list-view/LeaseTerminate";
import { LeaseModify } from "./list-view/LeaseModify";
import { LeaseRewrite } from "./list-view/LeaseRewrite";
import { deleteCheck } from "../../components/deleteCheck";
import { EqMessageError, EqMessageSuccess } from "../message/EqMessage";
import { Lease } from "./Lease";
import { LeaseCompany } from "./LeaseCompany";

interface Props {
  company: LeaseCompany;
  lease?: Lease;
  onCreate?: (newLease: LeaseFragmentFragment) => void;
  onModify?: (lease: LeaseFragmentFragment) => void;
  onRewrite?: (lease: LeaseFragmentFragment) => void;
  onTerminate?: (oldLease: LeaseFragmentFragment) => void;
  onDelete?: (deletedLease: LeaseFragmentFragment) => void;
}

export const LeaseOperation: React.FC<Props> = ({
  company,
  lease,
  onCreate,
  onModify,
  onRewrite,
  onTerminate,
  onDelete,
}) => {
  const [createFirst, setCreateFirst] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const [modify, setModify] = useState(false);
  const [terminate, setTerminate] = useState(false);
  const [rewrite, setRewrite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLease] = useLeaseDeleteMutation({
    variables: { uuid: lease?.uuid },
  });

  const handleDelete = async () => {
    await deleteCheck(
      async () => {
        try {
          setLoading(true);
          const result = await deleteLease();
          if (result.data?.deleteLease === true) {
            EqMessageSuccess({ text: "Lease has been deleted!" });
            if (onDelete != null && lease != null) {
              onDelete(lease);
            }
          } else {
            EqMessageError({ text: "Failed to delete lease" });
          }
        } finally {
          setLoading(false);
        }
      },
      {
        confirmButtonText: "Delete lease",
        text: "Deleting a lease will permanently remove all it's historical data.",
      }
    );
  };

  return (
    <>
      {lease == null ? (
        <Button
          variant="link"
          className="text-warning p-0"
          onClick={setCreateFirst.bind(null, true)}
        >
          Add data
        </Button>
      ) : loading ? (
        <Spinner size="sm" animation="grow" />
      ) : (
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="more cursor-pointer"
            id="dropdown-basic"
          >
            <FaEllipsisV />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={setCreateAnother.bind(null, true)}>
              Add another company lease
            </Dropdown.Item>
            <Dropdown.Item onClick={setModify.bind(null, true)}>
              Modify lease
            </Dropdown.Item>
            <Dropdown.Item onClick={setTerminate.bind(null, true)}>
              Terminate lease
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={setRewrite.bind(null, true)}
              className="text-danger"
            >
              Recreate lease
            </Dropdown.Item>
            <Dropdown.Item onClick={handleDelete} className="text-danger">
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {createFirst && (
        <LeaseCreate
          onHide={setCreateFirst.bind(null, false)}
          company={company}
          onCreate={onCreate}
        />
      )}
      {createAnother && (
        <LeaseCreate
          onHide={setCreateAnother.bind(null, false)}
          company={company}
          onCreate={onCreate}
        />
      )}
      {modify && (
        <LeaseModify
          onHide={setModify.bind(null, false)}
          lease={lease!}
          onModify={onModify}
        />
      )}
      {terminate && (
        <LeaseTerminate
          onHide={setTerminate.bind(null, false)}
          lease={lease!}
          onTerminate={onTerminate}
        />
      )}
      {rewrite && (
        <LeaseRewrite
          onHide={setRewrite.bind(null, false)}
          lease={lease!}
          onRewrite={onRewrite}
        />
      )}
    </>
  );
};
