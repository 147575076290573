import React from "react";
import { Form, Row, Tabs, Tab, Col } from "react-bootstrap";
import { Maybe } from "../generated/admin";
import { EqCol } from "../modules/buildings/detail-view/BuildingDetailsGeneral";
import chunk from "lodash/chunk";
import range from "lodash/range";
import { useShiftKeyDown } from "../modules/buildings/detail-view/useShiftKeyDown";
import { selectRange } from "./selectRange";
import { SelectAllResetButton } from "./SelectAllResetButton";
import { BuildingWithLevels } from "../model/BuildingWithLevels";
import { CapsuleButton } from "./CapsuleButton";

export const LevelsSelection = ({
  state,
  setState,
  buildings,
}: {
  buildings?: Maybe<BuildingWithLevels[]>;
  state: { buildingLevelUuids: string[] };
  setState: (buildingLevelUuids: string[]) => void;
}) => {
  const [key, setKey] = React.useState(buildings?.[0].uuid ?? "");
  const selectAll = () =>
    setState(
      buildings?.flatMap((b) =>
        b.uuid === key
          ? b.buildingLevels.map((bl) => bl.uuid)
          : b.buildingLevels.flatMap((bl) =>
              state.buildingLevelUuids.includes(bl.uuid) ? [bl.uuid] : []
            )
      ) ?? []
    );

  const reset = () => {
    const toRemove =
      buildings?.flatMap((b) =>
        b.uuid === key ? b.buildingLevels.map((bl) => bl.uuid) : []
      ) ?? [];
    setState(
      state.buildingLevelUuids.filter((uuid) => !toRemove.includes(uuid))
    );
  };

  return (
    <>
      <div className="text-right pb-2">
        <CapsuleButton onClick={selectAll}>Select all</CapsuleButton>
        <SelectAllResetButton onClick={reset} />
      </div>
      <Tabs
        className="eq-tabs"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k!)}
      >
        {(buildings ?? []).map((building) => (
          <Tab
            key={building.uuid}
            eventKey={building.uuid}
            title={building.name}
          >
            <BuildingTab
              key={building.uuid}
              building={building}
              state={state}
              setState={setState}
            />
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

const BuildingTab = ({
  building,
  state,
  setState,
}: {
  building: BuildingWithLevels;
  state: { buildingLevelUuids: string[] };
  setState: (buildingLevelUuids: string[]) => void;
}) => {
  const [lastSelected, setLastSelected] = React.useState<string | null>(null);
  const shiftKeyDown = useShiftKeyDown();
  const buildings = chunkedBuildings(building, state);
  return (
    <Row
      style={{
        paddingRight: "15px",
        paddingLeft: "15px",
      }}
    >
      {buildings.length === 0 ? (
        <EqCol
          className="bg-light"
          style={{
            padding: "0.5rem 0.75rem",
          }}
          key={Math.random().toString() + "building-level-in-area"}
        >
          No levels have been created yet.
        </EqCol>
      ) : (
        buildings.map((chunk, index) => (
          <Col key={"chunk" + index} className="pl-0 pr-0">
            <div className="d-flex flex-column">
              {chunk.map((bl) => {
                return bl == null ? (
                  <EqCol
                    style={{
                      borderBottom: "1px solid #dee2e6",
                      paddingRight: "0px",
                      paddingLeft: "0px",
                      padding: "14px",
                    }}
                    key={Math.random().toString() + "building-level-in-area"}
                  >
                    {" "}
                  </EqCol>
                ) : (
                  <EqCol
                    style={{
                      borderBottom: "1px solid #dee2e6",
                      paddingRight: "0px",
                      paddingLeft: "0px",
                    }}
                    key={bl.uuid + "building-level-in-area"}
                  >
                    <Form.Check
                      onClick={() => {
                        if (shiftKeyDown && lastSelected != null) {
                          const buildingLevelUuids = selectRange(
                            building.buildingLevels.map((b) => b.uuid),
                            bl.uuid,
                            lastSelected,
                            bl.checked,
                            state.buildingLevelUuids
                          );
                          setState(buildingLevelUuids);
                        } else {
                          setState(
                            bl.checked
                              ? state.buildingLevelUuids.filter(
                                  (uuid) => uuid !== bl.uuid
                                )
                              : [...state.buildingLevelUuids, bl.uuid]
                          );
                        }
                        setLastSelected(bl.uuid);
                      }}
                      onChange={() => {}}
                      checked={bl.checked}
                      label={bl.name}
                      type="checkbox"
                      disabled={bl.disabled}
                      className={bl.disabled ? "disabled-checkbox" : ""}
                    />
                  </EqCol>
                );
              })}
            </div>
          </Col>
        ))
      )}
    </Row>
  );
};

function chunkedBuildings(
  building: BuildingWithLevels,
  state: { buildingLevelUuids: string[] }
) {
  const chunks = chunk(
    [...building.buildingLevels]
      .sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      })
      .map((bl) => {
        const checked = state.buildingLevelUuids.includes(bl.uuid);
        return { ...bl, checked };
      }),
    6
  );
  if (chunks.length < 2) {
    return chunks;
  }
  let [head, ...tail] = chunks.reverse();
  const gap = tail[0].length - head.length;
  if (gap > 0) {
    return [[...head, ...range(gap).map((_) => null)], ...tail].reverse();
  }
  return chunks.reverse();
}
