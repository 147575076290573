import React, { useState, useCallback } from "react";
import { Form, Row, Col, Badge } from "react-bootstrap";
import Select from "react-select";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { useUserOptions } from "../useUserOptions";
import { FormikContextType } from "formik";
import { EqOne } from "../../../../svgs/EqOne";

interface Props {
  error?: string;
  methods: FormikContextType<SiteConfigFormData>;
}

export const DefaultIrisAssigneeFormGroup = ({ error, methods }: Props) => {
  const [search, setSearch] = useState<string | undefined>();
  const { options, loading } = useUserOptions(
    methods.values.defaultIrisAssignee,
    search
  );
  const onInputChange = useCallback(
    (input: string) => {
      setSearch(input);
    },
    [setSearch]
  );

  return (
    <Form.Group
      className="default-iris-assignee"
      as={Row}
      controlId="defaultIrisAssignee"
    >
      <Form.Label column md="3">
        Default Iris post assignee
        <br />
        <Badge variant="primary">
          <EqOne />
        </Badge>
      </Form.Label>
      <Col md="9" lg="6">
        <Select
          classNamePrefix="eq"
          isLoading={loading}
          options={options}
          value={options?.find(
            (option) =>
              option.value === methods.values.defaultIrisAssignee?.value
          )}
          onChange={(option: any) =>
            methods.setFieldValue(
              "defaultIrisAssignee",
              option != null ? option : { label: "", value: null }
            )
          }
          isClearable={true}
          onInputChange={onInputChange}
          name="defaultIrisAssignee"
          aria-label="default-iris-assignee"
          className={`react-select${error != null ? " is-invalid" : ""}`}
        />
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
        <Form.Text className="text-muted">
          The Default Post Assignee is the Iris profile associated with a site
          that will receive the Iris email notifications when a Workplace
          Manager suggests news and/or events.
        </Form.Text>
        <Form.Text className="text-muted">
          Please ensure the email address is assigned to a user in Iris and
          assigned to the site.
        </Form.Text>
      </Col>
    </Form.Group>
  );
};
