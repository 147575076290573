import { Routes, Route } from "react-router-dom";
import React from "react";
import { PageHeading } from "../../components/PageHeading";

export const RegistrationModule = () => {
  return (
    <Routes>
      <Route path="*" element={<PageHeading title="Registration"></PageHeading>} />
    </Routes>
  );
};
