import React, { useCallback } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useIndustryStore } from "./hook/useIndustryStore";
import { AddButton } from "../../../components/AddButton";
import { CreateButton } from "../../../components/CreateButton";
import { RemoveButton } from "../../../components/RemoveButton";

/**
 * Add industry form.
 */
export const AddIndustryForm: React.FC = () => {
  const store = useIndustryStore();
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        store.addIndustry();
      }
    },
    [store]
  );
  return (
    <Container fluid className="p-0">
      <Row className="justify-content-between">
        <Col md="7" lg="8">
          <Container fluid>
            {store.industriesToAdd.map((industry, i) => (
              <IndustryFormInner key={`ifi-${i}`} industry={industry} i={i} />
            ))}

            <Row onKeyDown={handleKeyDown}>
              <Col className="border border-right-0 px-3" xs="10" lg="11">
                <Form.Control
                  required
                  className="border-bottom-0"
                  placeholder="Industry Name"
                  value={store.newIndustryName}
                  onChange={store.handleChange}
                />
              </Col>
              <Col
                lg="1"
                xs="2"
                className="border border-left-0 d-flex justify-content-end align-items-center"
              >
                <AddButton onClick={store.addIndustry} />
              </Col>
            </Row>
          </Container>
        </Col>

        <Col md="4" lg="3" className="mt-3 mt-md-0 text-right">
          <CreateButton disabled={store.loading} onClick={store.addIndustries}>
            {store.addingIndustry ? (
              <span>
                <Spinner animation="grow" size="sm" /> Saving...
              </span>
            ) : (
              "Add Industries"
            )}
          </CreateButton>
        </Col>
      </Row>
    </Container>
  );
};

const IndustryFormInner = ({
  industry,
  i
}: {
  industry: { name: string };
  i: number;
}) => {
  const store = useIndustryStore();
  const handleRemoveIndustry = useCallback(
    () => store.removeIndustry(industry.name),
    [store, industry]
  );

  return (
    <Row key={`list-${i}`}>
      <Col
        className="py-1 px-3 border border-bottom-0 border-right-0"
        xs="10"
        lg="11"
      >
        <Form.Control
          className="w-50"
          required
          value={industry.name}
          onChange={store.updateLocalIndustry(industry.name)}
        />
      </Col>
      <Col
        lg="1"
        xs="2"
        className="justify-content-end d-flex align-items-center border border-bottom-0 border-left-0"
      >
        <RemoveButton onClick={handleRemoveIndustry} />
      </Col>
    </Row>
  );
};
