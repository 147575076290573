import React, { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { UserFormContext } from "./context/UserFormContext";
import { UserFormData, userFormNameCheck } from "./model/UserFormData";
import { OptionType } from "../../../model/OptionType";

interface Props {
  options: OptionType[];
}

/**
 * User form attributes component.
 */
export const UserFormAttributesDropdown: React.FC<Props> = ({ options }) => {
  const ctx = useContext(UserFormContext);
  const methods = useFormContext<UserFormData>();
  const siteProfile = ctx.user?.profile?.siteProfiles.edges.find((e) => {
    return e.node?.site.uuid === ctx.activeSite;
  });
  const selected: OptionType[] =
    siteProfile?.node?.attributes?.map((x) => {
      return {
        value: x.uuid,
        label: x.name
      };
    }) ?? [];

  return (
    <Controller
      render={({field: { value, onChange }}) =>
        <Select<OptionType, boolean>
          isMulti
          menuPlacement="top"
          classNamePrefix="eq"
          options={options}
          isClearable={true}
          placeholder="Select attributes"
          className={`react-select`}
          onChange={onChange}
          value={value as any}
        />
      }
      defaultValue={selected.length > 0 ? selected : undefined}
      control={methods.control}
      name={userFormNameCheck("attributes")}
    />
  );
};
