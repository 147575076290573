import { Formik } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  LeaseFragmentFragment,
  useLeaseRewriteMutation,
} from "../../../generated/admin";
import { EqMessageError } from "../../message/EqMessage";
import { UserContext } from "../../user/UserContext";
import { LeaseSizeField } from "../form-fields/LeaseSizeField";
import { LeaseStartDateField } from "../form-fields/LeaseStartDateField";
import { LeaseExpiryDateField } from "../form-fields/LeaseExpiryDateField";
import { LeaseBreakDateField } from "../form-fields/LeaseBreakDateField";
import { LeaseNotificationDateField } from "../form-fields/LeaseNotificationDateField";
import { LeaseNameField } from "../form-fields/LeaseNameField";
import { BusinessNumberField } from "../form-fields/BusinessNumberField";
import { BusinessNumberTypeField } from "../form-fields/BusinessNumberTypeField";
import { Alert } from "react-bootstrap";
import { EmployeeCountField } from "../form-fields/EmployeeCountField";
import { stringNotEmpty } from "../../../util/stringNotEmpty";

dayjs.extend(utc);

interface Props {
  onHide: () => void;
  lease: LeaseFragmentFragment;
  onRewrite?: (newLease: LeaseFragmentFragment) => void;
}

interface FormValue {
  businessNumber?: string | null;
  businessNumberType?: string | null;
  name: string;
  startDate: string;
  breakDate: string;
  expiryDate: string;
  notificationDate: string;
  leaseSize: number;
  employeeCount?: number;
}

/**
 * LeaseRecreate modal
 */
export const LeaseRewrite: React.FC<Props> = ({ onHide, onRewrite, lease }) => {
  const user = useContext(UserContext);
  const [rewriteLease] = useLeaseRewriteMutation();
  const nameInputRef = useRef<HTMLInputElement>();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Select value in name input field automatically on load.
    nameInputRef.current?.select();
  }, []);

  return (
    <Modal size="xl" centered scrollable={false} show={true} onHide={onHide}>
      <Formik<FormValue>
        initialValues={{
          businessNumber: lease.businessNumber,
          businessNumberType: lease.businessNumberType,
          name: lease.name,
          startDate: dayjs(lease.startDate).utc().format("YYYY-MM-DD"),
          breakDate:
            lease.breakDate == null
              ? ""
              : dayjs(lease.breakDate).utc().format("YYYY-MM-DD"),
          expiryDate: dayjs(lease.expiryDate).utc().format("YYYY-MM-DD"),
          notificationDate:
            lease.notificationDate == null
              ? ""
              : dayjs(lease.notificationDate).utc().format("YYYY-MM-DD"),
          leaseSize: lease.size,
          employeeCount: lease.employeeCount ?? undefined,
        }}
        validateOnChange={submitted}
        validateOnBlur={submitted}
        onSubmit={async (values) => {
          setSubmitted(true);

          if (user?.activeDestination?.uuid == null) {
            throw new Error(
              "Can't create lease without an active site context."
            );
          }

          const leaseResult = await rewriteLease({
            variables: {
              input: {
                uuid: lease.uuid,
                businessNumber: stringNotEmpty(values.businessNumber)
                  ? values.businessNumber
                  : null,
                businessNumberType: stringNotEmpty(values.businessNumberType)
                  ? values.businessNumberType
                  : null,
                startDate: new Date(values.startDate).getTime(),
                breakDate:
                  values.breakDate == null
                    ? null
                    : new Date(values.breakDate).getTime(),
                notificationDate:
                  values.notificationDate == null
                    ? null
                    : new Date(values.notificationDate).getTime(),
                expiryDate: new Date(values.expiryDate).getTime(),
                name: values.name,
                size: values.leaseSize,
                employeeCount: values.employeeCount,
              },
            },
          });

          if (leaseResult.data?.rewriteLease == null) {
            EqMessageError({
              text: "Failed to save lease. Please try again.",
            });
          } else {
            onHide();
            if (onRewrite != null) {
              onRewrite(leaseResult.data?.rewriteLease);
            }
          }
        }}
      >
        {(formikProps) => {
          const { submitForm, isSubmitting } = formikProps;

          return (
            <>
              <Modal.Header>
                <Modal.Title>Recreate Lease for {lease.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Alert variant="warning">
                  <strong>Warning:</strong> Recreating this lease will delete
                  any modifications that have been recorded.
                </Alert>
                <Form>
                  <Row>
                    <Col md={7}>
                      <LeaseNameField {...formikProps} ref={nameInputRef} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7} lg={4}>
                      <BusinessNumberField {...formikProps} />
                    </Col>
                    <Col md={5} lg={3}>
                      <BusinessNumberTypeField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                      <EmployeeCountField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7}>
                      <LeaseSizeField {...formikProps} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <LeaseStartDateField {...formikProps} />
                        </Col>
                        <Col>
                          <LeaseBreakDateField {...formikProps} />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <LeaseNotificationDateField {...formikProps} />
                        </Col>
                        <Col>
                          <LeaseExpiryDateField {...formikProps} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner animation="grow" size="sm" />
                      <span className="ml-1">Recreating...</span>
                    </>
                  ) : (
                    "Recreate"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  onClick={onHide}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
