import React from "react";
import { Container, Form } from "react-bootstrap";
import {
  WhitelabelFormData,
  whitelabelFormValidation,
} from "./WhitelabelFormData";
import { Formik } from "formik";
import {
  useUpsertWhitelabelAppMutation,
  useWhitelabelAppQuery,
} from "../../../generated/admin";
import { ClientNameFormGroup } from "../../sites/site-details/fields/ClientNameFormGroup";
import { useParams } from "react-router-dom";
import { EqMessageError, EqMessageSuccess } from "../../message/EqMessage";
import { useSiteContextHelper } from "../../../hooks/useSiteContextHelper";
import { AppDetails } from "./AppDetails";
import { AppStoreListing } from "./AppStoreListing";
import { ApiKeys } from "./ApiKeys";
import { DeveloperSettings } from "./DeveloperSettings";
import { stringNotEmpty } from "../../../util/stringNotEmpty";
import { WhitelabelGeneralView } from "./WhitelabelGeneralView";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { DetailsPageHeading } from "./components/DetailsPageHeading";

export const EditWhitelabelDetails: React.FC = () => {
  const siteCtxHelper = useSiteContextHelper();
  const [mutation] = useUpsertWhitelabelAppMutation();
  const { uuid } = useParams<{ uuid: string }>();

  const { loading, error, data } = useWhitelabelAppQuery({
    variables: { uuid: uuid },
    fetchPolicy: "network-only",
  });

  if (loading || error != null) {
    return <LoadingOrErrorDisplay loading={loading} error={error} />
  } else if (data == null) {
    return <></>;
  } else {    
    const formData = {
      uuid: uuid,
      android: { ...{ ...data.whitelabelApp.androidImages, __typename: undefined } },
      ios: { ...{ ...data.whitelabelApp.iosImages, __typename: undefined } },
      name: data.whitelabelApp.name ?? "",
      appName: data.whitelabelApp.appName ?? "",
      client: data.whitelabelApp.client?.uuid ?? "",
      clientName: data.whitelabelApp.client?.name ?? "",
      appleAccountName: data.whitelabelApp.appleAccountName ?? "",
      appleCompanyName: data.whitelabelApp.appleCompanyName ?? "",
      googleAccountName: data.whitelabelApp.googleAccountName ?? "",
      color: data.whitelabelApp.color ?? "#000000",
      categories: data.whitelabelApp.categories ?? [],
      keywords: (data.whitelabelApp.keywords ?? []).join(","),
      description: data.whitelabelApp.description ?? undefined,
      subtitle: data.whitelabelApp.subtitle ?? undefined,
      privacyPolicyUrl: data.whitelabelApp.privacyUrl ?? undefined,
      supportUrl: data.whitelabelApp.supportUrl ?? undefined,
      contactEmail: data.whitelabelApp.contactEmail ?? undefined,
      releaseNotes: data.whitelabelApp.releaseNotes ?? undefined,
      language: data.whitelabelApp.language ?? "en-AU",
      appleKey: {
        id: data.whitelabelApp.appleStoreKeyId ?? undefined,
      },
      apnsKey: {
        id: data.whitelabelApp.apnsKeyId ?? undefined,
      },
      androidKey: {
        id: data.whitelabelApp.playStoreKeyId ?? undefined,
      },
      firebaseServiceKey: {
        id: data.whitelabelApp.firebaseServiceKeyId ?? undefined,
      },
      betaUsers:
        data.whitelabelApp.betaUsers.map((a) => {
          return { value: a.emailAddress, type: a.operatingSystem };
        }) ?? [],
      sites: data.whitelabelApp.siteUuids ?? [],
      betaUserInput: { type: "", value: "" },
      bundleId: data.whitelabelApp.bundleId ?? undefined,
      packageName: data.whitelabelApp.packageName ?? undefined,
      appleTeamId: data.whitelabelApp.appleTeamId ?? undefined,
      appleITCTeamId: data.whitelabelApp.appleITCTeamId ?? undefined,
      appleAppStoreId: data.whitelabelApp.appleAppStoreId ?? undefined,
      applePayMerchantId: data.whitelabelApp.applePayMerchantId ?? undefined,
      appleCodeSigningIdentity: data.whitelabelApp.appleCodeSigningIdentity ?? undefined,
      editionId: data.whitelabelApp.editionId ?? uuid,
      firebaseAndroidAppId: data.whitelabelApp.firebaseAndroidAppId ?? undefined,
      androidFlavor: data.whitelabelApp.androidFlavor ?? undefined,
      matchGitBranch: data.whitelabelApp.matchGitBranch ?? undefined,
      dynamicLinkUrl: data.whitelabelApp.dynamicLinkUrl ?? undefined,
      firebaseProjectId: data.whitelabelApp.firebaseProjectId ?? undefined,
      firebaseWebApiKey: data.whitelabelApp.firebaseWebApiKey ?? undefined,
      appleStoreStatus: data.whitelabelApp.appleStoreStatus ?? undefined,
      googleStoreStatus: data.whitelabelApp.googleStoreStatus ?? undefined,
      status: data.whitelabelApp.status,
      jiraIssueUrl: data.whitelabelApp.jiraIssueUrl ?? undefined,
      appleVersion: data.whitelabelApp.appleVersion ?? undefined,
      appleEditVersion: data.whitelabelApp.appleEditVersion ?? undefined,
      appleReviewEmail: data.whitelabelApp.appleReviewEmail ?? undefined,
      appleStatusUpdatedAt: data.whitelabelApp.appleStatusUpdatedAt ?? undefined,
      googleVersion: data.whitelabelApp.googleVersion ?? undefined,
      googleReviewEmail: data.whitelabelApp.googleReviewEmail ?? undefined,
      googleStatusUpdatedAt: data.whitelabelApp.googleStatusUpdatedAt ?? undefined,
      equiemOwner:
        data.whitelabelApp.equiemOwner?.profile != null
          ? {
              value: data.whitelabelApp.equiemOwner.profile.uuid,
              label: `${data.whitelabelApp.equiemOwner.profile.displayName} (${data.whitelabelApp.equiemOwner.profile.email})`,
            }
          : undefined,
    };

    return (
      <Formik<WhitelabelFormData>
        initialValues={formData}
        validate={whitelabelFormValidation}
        onSubmit={(ev) => {
          const betaUsers = ev.betaUsers.map((a) => {
            return { emailAddress: a.value, operatingSystem: a.type };
          });
          if (ev.betaUserInput.type !== "" && ev.betaUserInput.value !== "") {
            betaUsers.push({
              emailAddress: ev.betaUserInput.value,
              operatingSystem: ev.betaUserInput.type,
            });
          }
          const { compressedUrl: androidCompressedUrl, ...androidImages } = ev.android;
          const { compressedUrl: iOSCompressedUrl, ...iosImages } = ev.ios;
          mutation({
            variables: {
              input: {
                androidImages: androidImages,
                iosImages: iosImages,
                clientUuid: ev.client,
                clientName: ev.clientName,
                name: ev.name ?? `${ev.clientName.trim()} App`,
                appName: ev.appName ?? `${ev.clientName.trim()} App`,
                appleAccountName: ev.appleAccountName,
                appleCompanyName: ev.appleCompanyName,
                googleAccountName: ev.googleAccountName,
                uuid: ev.uuid,
                categories: ev.categories,
                color: ev.color,
                description: ev.description,
                keywords: [ev.keywords ?? ""],
                subtitle: ev.subtitle,
                appleStoreKey:
                  stringNotEmpty(ev.appleKey.key)
                    ? {
                      certificate: ev.appleKey.key ?? "",
                      issuerId: ev.appleKey.issuerId ?? "",
                      keyId: ev.appleKey.keyId ?? "",
                    }
                    : undefined,
                apnsKey: 
                  stringNotEmpty(ev.apnsKey.key)
                    ? {
                      certificate: ev.apnsKey.key ?? "",
                      keyId: ev.apnsKey.keyId ?? "",
                    }
                    : undefined,
                googlePlayKey:
                  stringNotEmpty(ev.androidKey.json)
                    ? JSON.parse(ev.androidKey.json)
                    : undefined,
                firebaseServiceKey:
                  stringNotEmpty(ev.firebaseServiceKey.json)
                      ? JSON.parse(ev.firebaseServiceKey.json)
                      : undefined,
                betaUsers: betaUsers,
                siteUuids: ev.sites,
                privacyUrl: ev.privacyPolicyUrl,
                supportUrl: ev.supportUrl,
                contactEmail: ev.contactEmail,
                releaseNotes: ev.releaseNotes,
                language: ev.language,
                distributionRegions: ["All"],
                bundleId: ev.bundleId,
                packageName: ev.packageName,
                appleTeamId: ev.appleTeamId,
                appleITCTeamId: ev.appleITCTeamId,
                appleAppStoreId: ev.appleAppStoreId,
                applePayMerchantId: ev.applePayMerchantId,
                appleCodeSigningIdentity: ev.appleCodeSigningIdentity,
                editionId: ev.editionId,
                firebaseAndroidAppId: ev.firebaseAndroidAppId,
                androidFlavor: ev.androidFlavor,
                matchGitBranch: ev.matchGitBranch,
                dynamicLinkUrl: ev.dynamicLinkUrl,
                firebaseProjectId: ev.firebaseProjectId,
                firebaseWebApiKey: ev.firebaseWebApiKey,
                appleReviewEmail: ev.appleReviewEmail,
                googleReviewEmail: ev.googleReviewEmail,
                equiemOwner: ev.equiemOwner?.value,
                status: ev.status,
                appleStoreStatus: ev.appleStoreStatus,
                googleStoreStatus: ev.googleStoreStatus,
              },
            },
          })
            .then((a) => {
              const results = a.data?.upsertWhitelabelApp;
              if (results === undefined) {
                EqMessageError({
                  text: "There was a problem editing white label app.",
                });
              }
              EqMessageSuccess({
                text: `${results?.name} details edited successfully.`,
              });
              siteCtxHelper.goBack();
            })
            .catch((err) => {
              EqMessageError({ text: err });
            });
        }}
      >
        {(methods) => (
          <>
            <DetailsPageHeading title={"Edit White Label App"} methods={methods} />
            <Container>
              <>
                <br />
                <ClientNameFormGroup methods={methods} disabled={true} label="Select client">
                  <Form.Text className="text-muted">
                    The client associated with the White Label app.
                  </Form.Text>
                </ClientNameFormGroup>
                <WhitelabelGeneralView methods={methods} />
                <AppStoreListing methods={methods} expanded={false} />
                <ApiKeys methods={methods} />
                <AppDetails methods={methods} />
                <DeveloperSettings methods={methods} />
              </>
            </Container>
          </>
        )}
      </Formik>
    );
  }
};
