import React, { useContext, useEffect } from "react";
import { ToggleContainer } from "../../../components/ToggleContainer";
import { UserFormAttributesDropdown } from "./UserFormAttributesDropdown";
import { LoadingOrErrorDisplay } from "../../../components/LoadingOrErrorDisplay";
import { UserFormContext } from "./context/UserFormContext";
import { useUserSiteAttributesLazyQuery } from "../../../generated/admin";

/**
 * User form attributes component.
 */
export const UserFormAttributes: React.FC = () => {
  const ctx = useContext(UserFormContext);
  const [query, { data, loading, error }] = useUserSiteAttributesLazyQuery();

  useEffect(() => {
    query({
      variables: {
        destinationUuid: ctx.activeSite
      },
      fetchPolicy: "network-only"
    });
  }, [query, ctx.activeSite])

  if (ctx.activeSite == null) {
    return <></>;
  }

  return (
    <ToggleContainer title="Attributes">
      <div className="pb-4">
        <p>
          Attributes shown below are the attributes associated with the user at
          the selected site. As an administrator, you can delete them from a
          profile, or add an existing attribute to the profile. If you need to
          add a new attribute altogether, this must be added on the Attributes
          page and then assigned here.
        </p>
        <LoadingOrErrorDisplay loading={loading} error={error}>
          {data != null && (
            <UserFormAttributesDropdown
              options={data.userSiteAttributes.map((a) => {
                return {
                  value: a.uuid,
                  label: a.name
                };
              })}
            />
          )}
        </LoadingOrErrorDisplay>
      </div>
    </ToggleContainer>
  );
};
