import React, { useState, useCallback } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { FormikContextType } from "formik";
import { WhitelabelFormData } from "../WhitelabelFormData";
import { useEquiemOwnerOptions } from "./useEquiemOwnerOptions";

interface Props {
  error?: string;
  methods: FormikContextType<WhitelabelFormData>;
}

export const EquiemOwner = ({ error, methods }: Props) => {
  const [search, setSearch] = useState<string | undefined>();
  const { options, loading } = useEquiemOwnerOptions(
    methods.values.equiemOwner,
    search
  );
  const onInputChange = useCallback(
    (input: string) => {
      setSearch(input);
    },
    [setSearch]
  );

  return (
    <Form.Group
      className="equiem-owner"
      as={Row}
      controlId="equiemOwner"
    >
      <Form.Label column md="3">
      Equiem owner
      </Form.Label>
      <Col md="9" lg="6">
        <Select
          classNamePrefix="eq"
          isLoading={loading}
          options={options}
          value={options?.find(
            (option) => option.value === methods.values.equiemOwner?.value
          )}
          onChange={(option: any) =>
            methods.setFieldValue("equiemOwner", option)
          }
          onInputChange={onInputChange}
          name="equiemOwner"
          aria-label="equiem-owner"
          className={`react-select${error != null ? " is-invalid" : ""}`}
        />
        {error && (
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        )}
      </Col>
    </Form.Group>
  );
};
