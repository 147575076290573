import React from "react";

export const Logo: React.FunctionComponent<React.HTMLAttributes<{}>> = ({
  className
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width="30"
    height="30"
    viewBox="0 0 20 20"
  >
    <path
      fill="#2600aa"
      fillRule="evenodd"
      d="M13.905 9.959c0 3.4 2.615 6.153 5.84 6.153v3.806c-5.22 0-9.452-4.46-9.452-9.96h3.612zm-3.612 6.153v3.804C5.072 19.916.84 15.458.84 9.96c0-5.5 4.232-9.96 9.453-9.96 5.22 0 9.452 4.46 9.452 9.96h-3.611c0-3.398-2.616-6.153-5.841-6.153-3.228 0-5.842 2.755-5.842 6.153 0 3.397 2.614 6.153 5.842 6.153z"
    />
  </svg>
);
