import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "./useQuery";
import { stringNotEmpty } from "../util/stringNotEmpty";

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}
interface SortData {
  field: string;
  asc: boolean;
}

export enum TableSearchParams {
  SortParams = "sortParams",
  Search = "search",
  Start = "start",
  End = "end",
  CurrentPage = "currentPage",
  HasNextPage = "hasNextPage",
  hasPreviousPage = "hasPreviousPage",
  PageSize = "pageSize"
}

interface IProps {
  isDefaultSortNull?: boolean;
  defaultSortFieldName?: string;
}

export const useClearSearchParams = function() {
  const query = useQuery();
  const history = useNavigate();

  const clearTableSearchParams = useCallback( () => {
    const queryParams = new URLSearchParams(query);

    for (const item of enumKeys(TableSearchParams)) {
      if (isNaN(Number(item))) {
        queryParams.delete(TableSearchParams[item]);
      }
    }

    history({
      search: "?" + queryParams.toString()
    }, {
      replace: true,
    })
  }, [history, query])

  return {
    clearTableSearchParams
  };
}

export const useTableSearch = function({
  defaultSortFieldName,
  isDefaultSortNull = false
}: IProps) {
  
  const query = useQuery();
  const history = useNavigate();

  const searchText = query.get(TableSearchParams.Search) ?? "";

  const getDefaultSortQuery =  useCallback((): SortData | null => {    
    const sortParams: string | null = query.get(TableSearchParams.SortParams);

    if (isDefaultSortNull === true && sortParams === null) {
      return null;
    }

    const defaultSortQuery: SortData = {
      field: defaultSortFieldName ?? "",
      asc: true
    };
  
    if (sortParams !== null) {
      const [field, asc] = sortParams.split(";");
  
      defaultSortQuery.field = field;
      defaultSortQuery.asc = asc === "true"
    }

    return defaultSortQuery;
  }, [defaultSortFieldName, isDefaultSortNull, query]);

  const onTableSort = useCallback((data: SortData) => {
    const queryParams = new URLSearchParams(query);

    queryParams.delete(TableSearchParams.SortParams);
    queryParams.append(TableSearchParams.SortParams, `${data.field};${data.asc}`);

    history({
      search: "?" + queryParams.toString()
    }, {
      replace: true,
    });
  }, [query, history]);

  const onTextSearch = useCallback((text: string) => {
    const queryParams = new URLSearchParams(query);

    queryParams.delete(TableSearchParams.Search);
    if (stringNotEmpty(text)) {
      queryParams.append(TableSearchParams.Search, text);
    }

    history({
      search: "?" + queryParams.toString()
    }, {
      replace: true,
    });
  }, [query, history]);

  return {
    defaultSortQuery: getDefaultSortQuery(),
    onTableSort,
    onTextSearch,
    searchText
  };
}