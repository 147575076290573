import React, { useContext } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import { UserContext } from "../modules/user/UserContext";

dayjs.extend(relativeTime);
dayjs.extend(timezone);

interface P {
  timestamp: string | number;
  type?: "default" | "fromnow";
  timezone?: string;
}
export const TimeFormat: React.FC<P> = ({
  timestamp,
  type = "default",
  timezone,
}) => {
  const user = useContext(UserContext);
  const tz =
    timezone ??
    user?.activeDestinationDetails?.timezone ??
    "Australia/Melbourne";

  if (type === "fromnow") {
    return <>{dayjs(timestamp).tz(tz).fromNow(true)}</>;
  }

  return <>{dayjs(timestamp).tz(tz).format("DD/MM/YY HH:mm")}</>;
};
