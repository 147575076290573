import React from "react";
import styled from "styled-components";

const LogoWrapper = styled.div`
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 120;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 120;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 120;
      stroke-dashoffset: -124px;
    }
  }
  height: 120px;
  width: 120px;
  animation: rotate 2s linear infinite;
  transform-origin: center center;

  .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke-dasharray: 1, 120;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke: #2600aa;
  }
`;

export const LogoSpinner = () => (
  <LogoWrapper>
    <svg className="spinner" viewBox="25 25 50 50">
      <circle
        className="path"
        cx="50"
        cy="50"
        fill="none"
        r="20"
        strokeMiterlimit="10"
        strokeWidth="2"
      ></circle>
    </svg>
  </LogoWrapper>
);
