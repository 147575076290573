import { useMemo } from "react";
import { useAddApartmentDestinationMutation } from "../../../../generated/admin";
import {
  EqMessageSuccess,
  EqMessageError,
  MessageFn
} from "../../../message/EqMessage";
import { RootRoutes } from "../../../../RouterOutlet";

/**
 * Hook for apartment suggestion.
 */
export function useApartmentSuggestion(
  redirect: (path: string) => void,
  successMsgFn: MessageFn = EqMessageSuccess,
  errorMsgFn: MessageFn = EqMessageError
) {
  const [
    mutation,
    { loading: addingDestination }
  ] = useAddApartmentDestinationMutation();

  return useMemo(
    () => ({
      addApartment: async (apartmentUuid: string, siteUuid: string) => {
        if (addingDestination) {
          return;
        }

        const result = await mutation({
          variables: {
            apartments: [apartmentUuid],
            destination: siteUuid
          }
        });
        if (result.data) {
          successMsgFn({ text: "Apartments successfully added." });
          redirect(RootRoutes.APARTMENTS);
        }
        if (result.errors) {
          errorMsgFn({ text: result.errors[0].message });
        }
      },
      addingDestination
    }),
    [addingDestination, errorMsgFn, mutation, redirect, successMsgFn]
  );
}
