import dayjs from "dayjs";
import { Diff } from "deep-diff";
import React from "react";
import { Spinner } from "react-bootstrap";
import { CardAccordion } from "../../../../components/SingleEqAccordion";
import { ToggleContainer } from "../../../../components/ToggleContainer";
import { LongText } from "../../../../components/LongText";
import {
  SiteHistoryQuery,
  useSiteHistoryQuery,
  PartialProfile,
} from "../../../../generated/admin";
import { toPercentageString } from "../../../../util/toPercentageString";

const camelCaseToSentence = (camel: string = "") =>
  camel
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, " $1")
    .toLowerCase();

export const HistoryToggleContainer: React.FC<{ uuid: string }> = ({
  uuid,
}) => {
  return (
    <ToggleContainer className="lg" title="History">
      <HistoryInner uuid={uuid} />
    </ToggleContainer>
  );
};

const HistoryInner: React.FC<{ uuid: string }> = (variables) => {
  const { data } = useSiteHistoryQuery({ variables });
  if (!data) {
    return <Spinner animation="grow" size="sm" />;
  }
  return <History history={data.destination.history} />;
};

export interface HistoryProps {
  history: SiteHistoryQuery["destination"]["history"];
}

export const History: React.FC<HistoryProps> = ({ history }) => (
  <div>
    <CardAccordion title={`Configuration Changes`}>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Field</th>
            <th scope="col">Value before</th>
            <th scope="col">Value after</th>
            <th scope="col">Changed by</th>
            <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {history.flatMap((h) => {
            if (h == null) {
              return null;
            }
            const changes = JSON.parse(h.changeSet) as Array<Diff<any, any>>;
            return changes.map((change) => (
              <Change
                change={change}
                profile={h.triggeredBy?.profile ?? undefined}
                timestamp={h.timestamp}
              />
            ));
          })}
        </tbody>
      </table>
    </CardAccordion>
  </div>
);

const dateFormat = "DD/MM/YY HH:mm";

const SpecialHandlingTypeE: React.FC<{ path: string; content: string }> = ({
  path,
  content,
}) => {
  if (path === "bookingModuleSettings commissionPercentage") {
    return (
      <LongText
        limit={20}
        content={`${toPercentageString(Number(content))}%`}
      />
    );
  }

  if (path === "bookingModuleSettings") {
    const translate = JSON.parse(content);

    if (translate == null || translate === "null" || translate === "") {
      return <LongText limit={20} content="null" />;
    }

    const newTxt = Object.keys(translate).map((key) => {
      if (key === "commissionPercentage") {
        return `${camelCaseToSentence(key)}: ${toPercentageString(
          Number(translate[key])
        )}%`;
      }

      return `${camelCaseToSentence(key)}: ${translate[key]}`;
    });

    return <LongText limit={20} content={newTxt.join(", ")} />;
  }

  return <LongText limit={20} content={content} />;
};

const Change: React.FC<{
  change: Diff<any, any>;
  profile?: Pick<PartialProfile, "firstName" | "lastName">;
  timestamp: number;
}> = ({ change, profile, timestamp }) => {
  const changePath = change.path?.join(" ") ?? "";
  switch (change.kind) {
    case "E":
      return (
        <tr>
          <td>{camelCaseToSentence(changePath)}</td>
          <td>
            <SpecialHandlingTypeE
              content={String(change.lhs)}
              path={changePath}
            />
          </td>
          <td>
            <SpecialHandlingTypeE
              content={JSON.stringify(change.rhs)}
              path={changePath}
            />
          </td>
          <td>{profile?.firstName + " " + profile?.lastName}</td>
          <td>{dayjs(timestamp).format(dateFormat)}</td>
        </tr>
      );
    case "N":
      return (
        <tr>
          <td>{camelCaseToSentence(change.path?.join(" "))}</td>
          <td>null</td>
          <td>
            <LongText limit={20} content={JSON.stringify(change.rhs)} />
          </td>
          <td>{profile?.firstName + " " + profile?.lastName}</td>
          <td>{dayjs(timestamp).format(dateFormat)}</td>
        </tr>
      );
    case "D":
      return (
        <tr>
          <td>{camelCaseToSentence(change.path?.join(" "))}</td>
          <td>
            <LongText limit={20} content={String(change.lhs)} />
          </td>
          <td>null</td>
          <td>{profile?.firstName + " " + profile?.lastName}</td>
          <td>{dayjs(timestamp).format(dateFormat)}</td>
        </tr>
      );
    case "A":
      return (
        <Change
          change={{ ...change.item, path: change.path }}
          profile={profile}
          timestamp={timestamp}
        />
      );
    default:
      return null;
  }
};
