import { Routes, Route } from "react-router-dom";
import React from "react";
import { CompanyLeaseList } from "./list-view/CompanyLeaseList";

export const LeasingModule: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<CompanyLeaseList />} />
    </Routes>
  );
};
