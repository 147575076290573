import React from "react";
import { Divider } from "../../../../components/Divider";
import { SubTitle } from "../../../../components";
import { LevelsSelection } from "../../../../components/LevelsSelection";
import { useCurrentSiteLevels } from "./useCurrentSiteLevels";
import { Alert } from "react-bootstrap";
import { FlexTenantFragmentFragment } from "../../../../generated/admin";
import { useUser } from "../../../user/UserContext";

export const CompanyLevels: React.FC<
  ReturnType<typeof useCurrentSiteLevels> & {
    isFlex: boolean;
    flexTenants?: FlexTenantFragmentFragment[];
  }
> = ({ state, setBuildingLevels, isFlex, flexTenants }) => {
  const user = useUser();
  const { buildings } = state;

  const isAboveLaw = user.isAdminOrRegionalManager;
  const hasFlexTenants = flexTenants && flexTenants.length > 0;

  const checkedBuildings = buildings.map((b) => {
    return {
      ...b,
      buildingLevels: b.buildingLevels.map((bl) => {
        const isFlexLevel =
          flexTenants?.some((ft) =>
            ft.locations.find((l) => l.uuid === bl.uuid)
          ) ?? false;
        return {
          ...bl,
          name: isFlexLevel ? `${bl.name} (flex)` : bl.name,
          disabled: isFlexLevel && !isAboveLaw,
        };
      }),
    };
  });

  if (checkedBuildings.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <SubTitle>Levels</SubTitle>
      <p>
        It’s important to assign the company to the correct level in the
        building in order that content can be segmented to it. If you are unsure
        what level the company belongs to, first check and then come back and
        complete this form. If a level isn’t available in the below table,
        return to the Buildings section and add the level. If your site is
        comprised of multiple buildings, use the links at the top to toggle
        between buildings.
      </p>
      {isFlex && (
        <Alert variant="warning">
          This company is a Flex Operator. Any levels assigned to this company
          will be treated as a Flex level, allowing them to assign the levels to
          their own Flex Tenants too.
        </Alert>
      )}
      {hasFlexTenants && !isAboveLaw && (
        <Alert variant="warning">
          Some of the levels this company is on are considered flex, so you are
          unable to edit those levels
        </Alert>
      )}
      {hasFlexTenants && isAboveLaw && (
        <Alert variant="warning">
          Some of the levels this company is on are considered flex. You are
          able to edit these levels, but this will affect the Flex Tenant
          membership.
        </Alert>
      )}

      <LevelsSelection
        buildings={checkedBuildings}
        state={state}
        setState={setBuildingLevels}
      />
    </>
  );
};
