import { Routes, Route } from "react-router-dom";
import React from "react";
import { ApartmentList } from "./list-view/ApartmentList";
import { ApartmentDetails } from "./detail-view/ApartmentDetails";

export const ApartmentsModule = () => {
  return (
    <Routes>
      <Route path={`/edit/:uuid`} element={<ApartmentDetails />} />
      <Route path={`/create`} element={<ApartmentDetails />} />
      <Route path="*" element={<ApartmentList />} />
    </Routes>
  );
};
