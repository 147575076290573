import React, { useCallback, useState } from "react";
import { JoditTextEditor } from "../../../../components/JoditTextEditor";
import { useFormikContext } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import {
  SiteDetailsQuery,
  useUpdateDestinationLegalMutation,
} from "../../../../generated/admin";
import { TermsAndConditionsLogs } from "./TermsAndConditionsLogs";
import { CardAccordion } from "../../../../components/SingleEqAccordion";
import { CreateButton } from "../../../../components/CreateButton";
import { Spinner } from "react-bootstrap";
import { EqMessageError, EqMessageSuccess } from "../../../message/EqMessage";
import { stringNotEmpty } from "../../../../util/stringNotEmpty";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const TermsAndConditions: React.FC<Props> = ({ dest }) => {
  const fm = useFormikContext<SiteConfigFormData>();
  const [value, setValue] = useState(dest.termsAndConditionsRaw);
  const [save, { loading }] = useUpdateDestinationLegalMutation();

  const sendIt = useCallback(async () => {
    const { data } = await save({
      variables: {
        input: {
          uuid: dest.uuid,
          termsAndConditions: value,
          termsAndConditionsChangeReason: stringNotEmpty(
            fm.values.termsAndConditionsChangeReason
          )
            ? fm.values.termsAndConditionsChangeReason
            : undefined,
        },
      },
    });

    if (data?.adminUpdateDestinationLegal?.__typename === "SuccessResponse") {
      EqMessageSuccess({ text: "Successfully saved terms and conditions." });
      return;
    }
    if (data?.adminUpdateDestinationLegal?.__typename === "FailureResponse") {
      EqMessageError({ text: data.adminUpdateDestinationLegal.reason });
    } else {
      EqMessageError({ text: "An unexpected error occurred" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dest.uuid, fm.values.termsAndConditionsChangeReason, value]);

  return (
    <CardAccordion title="Terms &amp; Conditions">
      <JoditTextEditor
        placeholder="Enter Terms & Conditions..."
        content={value}
        finishCallback={setValue}
      />
      <div className="pt-3 text-right">
        <CreateButton disabled={loading} onClick={sendIt}>
          <span>
            {loading ? (
              <>
                <Spinner animation="grow" size="sm" /> Saving...
              </>
            ) : (
              "Save Terms & Conditions"
            )}
          </span>
        </CreateButton>
      </div>
      <TermsAndConditionsLogs dest={dest} />
    </CardAccordion>
  );
};
