import React, { useCallback } from "react";

import { capitalize } from "lodash";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";

import { CardAccordion } from "../../../../../components/SingleEqAccordion";
import { TextFormGroup } from "../../fields/TextFormGroup";
import { OptionType } from "../../../../../model/OptionType";
import {
  EqMessageError,
  EqMessageSuccess,
} from "../../../../message/EqMessage";

import { stringNotEmpty } from "../../../../../util/stringNotEmpty";
import {
  Ac1QrCodeGenerator,
  useUpsertQrCodeCredentialSetupMutation,
} from "../../../../../generated/admin";

interface FormValues {
  generator: string | null;
  passName: string;
}

const initialValues: FormValues = {
  generator: null,
  passName: "",
};

const options = Object.values(Ac1QrCodeGenerator).map((generator) => ({
  value: generator,
  label: capitalize(generator.replaceAll(/[_-]+/g, " ")),
}));

const handleValidate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (values.generator === null) {
    errors.generator = "Required.";
  }

  if (!stringNotEmpty(values.passName)) {
    errors.passName = "Required.";
  }

  return errors;
};

export const NewQrCodeSetup: React.FC<{ siteUuid: string }> = ({
  siteUuid,
}) => {
  const [upsertQrCodeSetup, { loading }] =
    useUpsertQrCodeCredentialSetupMutation({
      refetchQueries: ["Ac1AvailableQrCodeSetups"],
    });

  const handleSubmit = useCallback(
    async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        const result = await upsertQrCodeSetup({
          variables: {
            input: {
              siteUuid,
              passName: values.passName,
              generator: values.generator as Ac1QrCodeGenerator,
            },
          },
        });

        if (result.errors != null && result.errors.length > 0) {
          console.error(result.errors);
          throw new Error("Unknown error response from server.");
        }

        EqMessageSuccess({
          text: "QR Code Type saved",
        });
        resetForm({ values: initialValues });

        return result;
      } catch (e) {
        console.error(e);
        EqMessageError({
          text: e instanceof Error ? e.message : "Unknown error.",
        });
      }
    },
    [siteUuid, upsertQrCodeSetup]
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        {(methods) => (
          <CardAccordion
            cardClassName="flex-nowrap"
            title="Set up a new QR code type"
            collapse={true}
          >
            <div data-testid="new-code-type">
              <Form.Group as={Row} controlId="generator">
                <Form.Label column md="3">
                  QR Code Type
                </Form.Label>
                <Col md="9" lg="6" className="generator-select">
                  <Select<OptionType, boolean>
                    classNamePrefix="eq"
                    isSearchable={false}
                    options={options}
                    onChange={({ value }: any) => {
                      methods.setFieldValue("generator", value);
                    }}
                    placeholder="Select..."
                    aria-label="generator-select"
                    className={`react-select${
                      methods.errors.generator != null ? " is-invalid" : ""
                    }`}
                  />
                  {methods.errors.generator && (
                    <Form.Control.Feedback type="invalid">
                      {methods.errors.generator}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Form.Group>
              <TextFormGroup
                title="Pass Name"
                subText="The unique name that the user will see in the mobile app."
                name="passName"
                error={methods.errors.passName}
              />
            </div>
            <div className="text-right">
              <Button
                name="createCodeType"
                variant="outline-primary"
                size="sm"
                className="align-self-center m-2"
                onClick={() => methods.handleSubmit()}
                disabled={loading || !methods.isValid}
              >
                {loading ? (
                  <span>
                    <Spinner size="sm" animation="grow" /> Loading...
                  </span>
                ) : (
                  "Create QR Code Type"
                )}
              </Button>
            </div>
          </CardAccordion>
        )}
      </Formik>
    </div>
  );
};
