import React from "react";
import { useFormikContext } from "formik";
import { SiteConfigFormData } from "../SiteConfigFormData";
import { SiteDetailsQuery } from "../../../../generated/admin";
import { Table, Form } from "react-bootstrap";
import { TimeFormat } from "../../../../components/TimeFormat";

interface Props {
  dest: NonNullable<SiteDetailsQuery>["destination"];
}

export const TermsAndConditionsLogs: React.FC<Props> = ({ dest }) => {
  const fm = useFormikContext<SiteConfigFormData>();

  return (
    <Table responsive size="sm" className="mt-4">
      <thead>
        <tr>
          <th className="border-top-0 border-bottom border-dark pl-0 w-65">
            Version details
          </th>
          <th className="border-top-0 border-bottom border-dark">Changed by</th>
          <th className="border-top-0 border-bottom border-dark pr-0">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-0" colSpan={3}>
            <Form.Control
              type="text"
              name="termsAndConditionsChangeReason"
              className="p-0 m-0 border-0"
              placeholder="Add details"
              onChange={fm.handleChange}
            />
          </td>
        </tr>
        {dest.termsAndConditionsChanges.map((c) => (
          <tr key={c.uuid}>
            <td className="pl-0">
              {c.message && c.message.length > 0 ? c.message : "-"}
            </td>
            <td>{c.triggeredBy?.profile?.displayName ?? "-"}</td>
            <td className="pr-0">
              <TimeFormat timestamp={c.timestamp} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
