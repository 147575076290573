import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { FormikContextType } from "formik";

interface Props {
  methods: Pick<
    FormikContextType<{ language?: string }>,
    "values" | "setFieldValue" | "touched" | "handleChange"
  >;
}

interface LanguageOption {
  value: string;
  label: string;
}

const languages: LanguageOption[] = [
  { value: "cs", label: "Czech" },
  { value: "nl", label: "Dutch" },
  { value: "en-AU", label: "English (AU)" },
  { value: "en-CA", label: "English (CA)" },
  { value: "en-GB", label: "English (UK)" },
  { value: "en-US", label: "English (US)" },
  { value: "fr", label: "French" },
  { value: "de", label: "German" },
  { value: "hu", label: "Hungarian" },
  { value: "ja", label: "Japanese" },
  { value: "pl", label: "Polish" },
  { value: "pt-PT", label: "Portuguese" },
  { value: "sk", label: "Slovak" },
  { value: "es", label: "Spanish" },
];

export const AppStoreLocale: React.FC<Props> = ({
  methods,
}) => {
  const options = [...languages];

  const value =
    methods.values.language === "en"
      ? options.find((option) => option.value === "en-US")
      : options.find((option) => option.value === methods.values.language);

  return (
    <Form.Group className="locale" as={Row} controlId="language">
      <Form.Label column md="3">
        Language
      </Form.Label>
      <Col md="9" lg="6">
        <Select<LanguageOption>
          className={`react-select${value == null ? " is-invalid" : ""}`}
          classNamePrefix="eq"
          name="language"
          aria-label="language"
          options={options.sort((a, b) => a.label.localeCompare(b.label))}
          value={value}
          onChange={(option) =>
            methods.setFieldValue("language", option?.value ?? "en")
          }
        />
        <Form.Text className="text-muted">
          This language will be used when creating the app in the respective app stores.
        </Form.Text>
      </Col>
    </Form.Group>
  );
};
