import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { Industry } from "../model/Industry";
import { TableButton, ActionButton } from "../../../../components/TableButton";
import { useUpsertIndustryMutation } from "../../../../generated/admin";
import { IndustryStore } from "../hook/useIndustryStore";
import { EqMessageSuccess, EqMessageError } from "../../../message/EqMessage";

interface Props {
  industry: Industry;
  store: IndustryStore;
  setEditing: (edit: boolean) => void;
}

/**
 * Edit row.
 */
export const EditRow: React.FC<Props> = ({ industry, store, setEditing }) => {
  const [name, setName] = React.useState(industry.name);
  const [save, { loading }] = useUpsertIndustryMutation();
  const handleSave = useCallback(async () => {
    if (name === "") {
      return;
    }
    const result = await save({
      variables: { uuid: industry.uuid, name }
    });
    if (result.data?.upsertIndustry.__typename === "IndustrySuccessResponse") {
      setEditing(false);
      store.updateIndustry(industry.uuid, name);
      await EqMessageSuccess({ text: "Industry updated." });
    } else {
      await EqMessageError({
        text:
          result.data?.upsertIndustry.__typename === "FailureResponse"
            ? result.data?.upsertIndustry.reason
            : "There was an error updating the industry."
      });
    }
  }, [setEditing, save, industry, store, name]);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setName(e.target.value.slice(0, 50)),
    [setName]
  );

  const cancelEdit = useCallback(() => setEditing(false), [setEditing]);

  return (
    <tr>
      <td className="py-1">
        <Form.Control value={name} onChange={handleNameChange} />
      </td>
      <td>{industry.companyCount}</td>
      <td>
        <>
          {!loading && (
            <>
              <TableButton onClick={cancelEdit}>Cancel</TableButton> |&nbsp;
            </>
          )}
          <ActionButton
            onClick={handleSave}
            actionLabel="Saving..."
            restLabel="Save"
            isInAction={loading}
          />
        </>
      </td>
    </tr>
  );
};
