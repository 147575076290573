import React from "react";
import { Form } from "react-bootstrap";
import { FieldAttributes, useField } from "formik";

export const FormikTextarea: React.FC<FieldAttributes<any>> = ({
  options,
  ...props
}) => {
  const [field] = useField(props);
  return (
    <Form.Control
      {...props}
      as="textarea"
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
    />
  );
};
